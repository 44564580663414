<app-header></app-header>
<app-sidebar></app-sidebar>



<main>
    <div class="full-wrap" id="" #contentToConvert>
        <div class="report-page">
            <div class="user-list inner-content">
                <div class="page-title">
                    <h3>Refferal Promotion List</h3>
                    <div class="nav-list">
                        <div class="hierarchy">
                           
                        </div>
                    </div>
                    <div class="breadcrumb">
                        <a  class="btn btn-secondary back-btn"><i class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div _ngcontent-serverapp-c232="" class="filterDiv">
                    <div _ngcontent-serverapp-c232="" class="row">
                       <div _ngcontent-serverapp-c232="" class="col-md-12">
                          <form _ngcontent-serverapp-c232="" novalidate="" class="ng-untouched ng-pristine ng-valid">
                             <div _ngcontent-serverapp-c232="" class="row form-field">
                               
                                <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 87.5%;">
                                   <div _ngcontent-serverapp-c232="" class="col-inner">
                                      <div _ngcontent-serverapp-c232="" class="form-group mb-0">
                                         <input _ngcontent-serverapp-c232="" name="searchUser" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="text" autocomplete="off" placeholder="Search Refferal Permotion..." class="search-input dropdown-toggle form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="searchUser">
                                         <div _ngcontent-serverapp-c232="" aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                            <a _ngcontent-serverapp-c232="" class="dropdown-item ng-star-inserted">
                                               <div _ngcontent-serverapp-c232="" class="event-info">
                                                  <div _ngcontent-serverapp-c232="" class="event-format-strip">
                                                     <div _ngcontent-serverapp-c232="" class="event-team">
                                                        <div _ngcontent-serverapp-c232="" class="team-name">user1 </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </a>
                                           
                                         </div>
                                        
                                      </div>
                                   </div>
                                </div>
                                <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 10.499999995%;">
                                   <div _ngcontent-serverapp-c232="" class="col-inner text-end">
                                      <div _ngcontent-serverapp-c232="" class="btn-list">
                                        <button (click)="openPermotionTab(addPermotion)" type="button" class="btn btn-success">
                                            Add Refferal Permotion </button>
                                        </div>
                                   </div>
                                </div>
                             </div>
                          </form>
                       </div>
                    </div>
                 </div>

              

                <div class="report-table">
                    

                    <table class="table hide-on-down">
                        <thead>
                            <tr>
                               
                                <th>S No.
                                </th>
                              
                                <th>Name
                                    
                                </th>
                                <th>Type
                                    
                                </th>
                                <th>Amount
                                    
                                </th>
                                <th>Cap Amount
                                    
                                </th>
                                <th>Status
                                    
                                </th>
                                
                                <th>Action</th>
                                
                            </tr>
                        </thead>

                        <tbody >
                            <tr *ngFor="let p of listData;let i=index;">
                               
                                <td>{{i+1}}.
                                </td>
                              
                                <td>{{p?.name}}
                                    
                                </td>
                                <td>{{p?.type}}
                                    
                                </td>
                                <td>{{p?.amount}}
                                    
                                </td>
                                <td>{{p?.cap_amount}}
                                    
                                </td>
                                <td>
                                    <div class="active" *ngIf="p?.visible">
                                         Active
                                    </div>
                                    <div class="inactive" *ngIf="!p?.visible">
                                        InActive
                                   </div>
                                    
                                </td>
                                
                                <td>
                                    <img *ngIf="!p?.visible" (click)="updateReferList(true,p._id)" src="assets/icon/active.png" width="30px">
                                    <img *ngIf="p?.visible" (click)="updateReferList(false,p._id)" src="assets/icon/remove.jpg" width="30px">
                                    <img  (click)="removeList(p._id)" src="assets/icon/delete.png" width="30px">
                                </td>
                                
                            </tr> 
                        </tbody>
                      
                    </table>
                </div>

                <div class="report-bottom-paginate">
                    <pagination-controls  class="user-list-pagination">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</main>

<ng-template #addPermotion>
    <div class="modal-header">
        <h4 class="modal-title">Add Referral Permotion </h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form [formGroup]='createRefferalPermotionForm'>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
           <div class="row">
          
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Name <span style="color:red">*</span></label>
                    <input type="text"  placeholder="Enter User Name"  formControlName="name"
                         class="password-box form-attribute">
                         <span *ngIf="createRefferalPermotionForm.controls.name.errors && submitted"
                         class="red-alert-message">
                        name is required.!
                     </span>
                </div>
            </div>

            <div class="col col-sm-6">
                <div class="form-list">
                    <label for="html">Banner Upload</label><br>
                    <input _ngcontent-uej-c8=""  (change)="processImageFile($event)" accept="image/png, image/jpeg" id="banner" formControlName="banner" name="banner" type="file" value="banner">
                    <span *ngIf=" createRefferalPermotionForm.controls.image.errors && submitted"
                  class="red-alert-message">
                  Banner is required.!
               </span>
               <img src="{{urlImage}}" *ngIf="urlImage" width="20px" height="20px">
                </div>
            </div>

            <div class="col col-sm-4">
                <div class="form-list">
                    <label for="html">Bonus Type</label><br>
                    <input type="radio" id="html" name="type" (click)="bType('PER')" formControlName="type" value="PER">
                      &nbsp;<label for="html"> Percentage</label>&nbsp;
                    <input type="radio" id="css" name="type" (click)="bType('FLAT')" formControlName="type" value="FLAT">
                      <label for="css">Flat </label> &nbsp;
                    
                      <br>

                    <span *ngIf="createRefferalPermotionForm.controls.type.errors && submitted"
                     class="red-alert-message">
                     Bonus Type is required.!
                  </span>
                </div>
            </div>

            <div class="col col-sm-4">
                <div class="form-list" >
                   <label class="form-heading"> Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter  Amount"  formControlName="amount"
                      class="password-box form-attribute">
                   <span *ngIf="createRefferalPermotionForm.controls.amount.errors && submitted"
                      class="red-alert-message">
                       Amount is required.!
                   </span>
                </div>
             </div>

             <div class="col col-sm-4"  >
                <div class="form-list" *ngIf="bmType=='PER'">
                   <label class="form-heading"> Cap Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter  Cap Amount"  formControlName="cap_amount"
                      class="password-box form-attribute">
                   <span *ngIf="createRefferalPermotionForm.controls.cap_amount.errors && submitted"
                      class="red-alert-message">
                      Cap Amount is required.!
                   </span>
                </div>
             </div>
             

            <div class="col col-sm-12">
                <div class="form-list">
                    <label class="form-heading">Description  <span style="color:red">*</span></label>
                    <textarea type="text"  placeholder="Enter Description"  formControlName="description"
                         class="password-box form-attribute"></textarea>
                         <span *ngIf="createRefferalPermotionForm.controls.description.errors && submitted"
                         class="red-alert-message">
                         Description is required.!
                     </span>
                </div>
            </div>

            <div class="col col-sm-12">
                <div class="form-list">
                    <label class="form-heading">Term Conditions <span style="color:red">*</span></label>
                    <textarea type="text"  placeholder="Enter Term Conditions"  formControlName="term_conditions"
                         class="password-box form-attribute"></textarea>
                         <span *ngIf="createRefferalPermotionForm.controls.term_conditions.errors && submitted"
                         class="red-alert-message">
                         Term Conditions is required.!
                     </span>
                </div>
            </div>
            
           </div>
          
     
    
            
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="subMitForm()" >Submit</button> &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
    </form>
</ng-template>

