import { Component, OnInit, TemplateRef, ElementRef, ViewChild, Output, EventEmitter,ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../services/users.service';
import { CookieService } from 'ngx-cookie-service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SportService } from '../services/sport.service';
import { WalletService } from '../services/wallet.service';
import Swal from 'sweetalert2'
import { json } from 'express';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from '../services/login.service';
import { SocketServiceService } from '../services/socket-service.service';
import { environment } from '../../environments/environment';
import { AppValidationService } from '../app-validation/app-validation.service';
//import { io, Socket } from 'socket.io-client';

import { WebsiteSettingService } from '../services/website-setting.service'
declare var $: any;
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { PreviousRouteService } from '../services/previous-route.service';
import { ValidatorControls } from '../app-validation/validation-controls.directive';
import { JsonpClientBackend } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-referral-list',
  templateUrl: './referral-list.component.html',
  styleUrls: ['./referral-list.component.scss']
})
export class ReferralListComponent implements OnInit {
  modalRef: BsModalRef;
  createRefferalPermotionForm: FormGroup;
  image:any;
  urlImage:any;
  bmType:any='FLAT';
  submitted:any=false;
  adminDetails:any;
  listData:any;
  constructor(private modalService: BsModalService, private sport: SportService,private wallet:WalletService,
    private previousRouteService: PreviousRouteService,public clipboardService: ClipboardService,
    private websiteSettingService: WebsiteSettingService,private cdRef: ChangeDetectorRef,
    private fb: FormBuilder, private toastr: ToastrService, private router: Router, private route: ActivatedRoute,
    private loginService: LoginService, private usersService: UsersService, private cookie: CookieService, private locationBack: Location,
    private appValidationService: AppValidationService, private socketService: SocketServiceService,) { }

  ngOnInit(): void {
    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    console.log(this.adminDetails)
    this.createRefferalPermotionForm = this.fb.group({
      "name": ["", Validators.required], 
      "type": ["FLAT", Validators.required], 
      "image": ["", ""], 
      "cap_amount": [-1, Validators.required],
      "amount": ["", Validators.required],
      "description": ["", Validators.required],
      "term_conditions": ["", Validators.required],
    });
    this.getReferList();
  }

  getReferList()
  {
    let params={
      user_id:this.adminDetails.user_id,
     
    }
    this.wallet.referalBobusList(params).subscribe((res:any) => {
      if(res.status == true){
        this.listData=res.data;
      }
      else{
        this.toastr.error(res?.msg,'',{
          timeOut: 10000,
        });
      }
    });
  }

  updateReferList(visible:any,refer_id:any)
  {
    let params={
      user_id:this.adminDetails.user_id,
      refer_id:refer_id,
      visible:visible
    }
    this.wallet.updateBobusList(params).subscribe((res:any) => {
      if(res.data == true){
        this.toastr.info(res?.msg,'',{
          timeOut: 10000,
        });
        this.getReferList();
      }
      else{
        this.toastr.error(res?.msg,'',{
          timeOut: 10000,
        });
      }
    });
  }

  subMitForm()
  {
    this.submitted=true;
    
   if(this.createRefferalPermotionForm.valid)
   {

    var formData = new FormData();
    formData.append("image",this.image);
    formData.append("name",this.createRefferalPermotionForm.controls.name.value);
    formData.append("user_id",this.adminDetails.user_id);
    formData.append("type",this.createRefferalPermotionForm.controls.type.value);
    formData.append("amount",this.createRefferalPermotionForm.controls.amount.value);
    formData.append("cap_amount",this.createRefferalPermotionForm.controls.cap_amount.value);
    formData.append("desciption",this.createRefferalPermotionForm.controls.description.value);
    formData.append("term_conditions",this.createRefferalPermotionForm.controls.term_conditions.value);
    this.wallet.create_refeal_permotion(formData).subscribe((res:any) => {
      if(res.status == true){
        this.toastr.success(res?.msg,'',{
          positionClass: 'toast-bottom-right',
          timeOut:1000
         });
         this.modalRef.hide();
         this.getReferList();
         this.createRefferalPermotionForm.reset();
      }
      else{
        this.toastr.error(res?.msg,'',{
          timeOut: 10000,
        });
      }
    });
   }
  }

  removeList(p:any){
    let params={
      user_id:this.adminDetails.user_id,
      refer_id:p,
    }
    this.wallet.removeBonusList(params).subscribe((res:any) => {
      if(res.status == true){
        this.toastr.info(res?.msg,'',{
          timeOut: 10000,
        });
        this.getReferList();
      }
      else{
        this.toastr.error(res?.msg,'',{
          timeOut: 10000,
        });
      }
    });
  }

  openPermotionTab(addPermotion: TemplateRef<any>) {
    
    this.modalRef = this.modalService.show(
      addPermotion,
      Object.assign({}, { class: 'comissionSetting-modal modal-lg' })
    );
  }

  bType(val:any){
    this.bmType=val;
    if(val=='FLAT'){
      this.createRefferalPermotionForm.controls['cap_amount'].setValue(-1);
    }
    this.createRefferalPermotionForm.controls['type'].setValue(val);
   
  }

  processImageFile(event: any)
  {
    this.image = event.target.files[0];
    console.log(this.image)
    var reader = new FileReader();
		reader.readAsDataURL(this.image);
	
		reader.onload = (_event) => {
			
			this.urlImage = reader.result; 
   
		}
  }

}
