import { Component, OnInit, ViewChild } from '@angular/core';
import { DateTime } from '../../dateTime';
import { Router } from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Location } from '@angular/common';
import { ReportService } from '../services/report.service';
import { LoginService } from '../services/login.service'
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {DatePipe} from '@angular/common';  

@Component({
  selector: 'app-open-news',
  templateUrl: './open-news.component.html',
  styleUrls: ['./open-news.component.scss']
})
export class OpenNewsComponent implements OnInit {
  allData: any;
  adminData: any;
  agentData: any;

  constructor(private router: Router, public datepipe: DatePipe,private locationBack: Location,
    private cookie: CookieService,private loginService: LoginService, private report: ReportService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getNews();
  }
  getNews(){
    let data = {};
    this.report.getNews(data).subscribe((res) => {
      console.log("33",res);
      if(res.status){
        if(res.data == null){
          this.adminData = [];
          this.agentData = [];
        } else {
          this.allData = res.data;
        }
      }
      
    })
  }
}
