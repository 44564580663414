import { Component, OnInit, TemplateRef, ElementRef, ViewChild, Output, EventEmitter,ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../../services/users.service';
import { CookieService } from 'ngx-cookie-service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SportService } from '../../services/sport.service';
import { WalletService } from '../../services/wallet.service';
import Swal from 'sweetalert2'
import { json } from 'express';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from '../../services/login.service';
import { SocketServiceService } from '../../services/socket-service.service';
import { environment } from '../../../environments/environment';
import { AppValidationService } from '../../app-validation/app-validation.service';
//import { io, Socket } from 'socket.io-client';

import { WebsiteSettingService } from '../../services/website-setting.service'
declare var $: any;
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { PreviousRouteService } from '../../services/previous-route.service';
import { ValidatorControls } from '../../app-validation/validation-controls.directive';
import { JsonpClientBackend } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-wallet-role',
  templateUrl: './wallet-role.component.html',
  styleUrls: ['./wallet-role.component.scss']
})
export class WalletRoleComponent implements OnInit {
  modalRef: BsModalRef;
  createRoleForm: FormGroup;
  adminDetails:any;
  roleList:any;
  role_Data:any;
  submited:any=false;
  credentailArr:any=[{key:'',value:''}];
  userDetails:any;
  permission_arr:any=[
    {name:"DashBoard",all:false,view:false,insert:false,update:false,delete:false},
    {name:"D Report",all:false,view:false,insert:false,update:false,delete:false},
    {name:"W Report",all:false,view:false,insert:false,update:false,delete:false},
    {name:"W Request",all:false,view:false,insert:false,update:false,delete:false},
    {name:"D Request",all:false,view:false,insert:false,update:false,delete:false},
    {name:"Method",all:false,view:false,insert:false,update:false,delete:false},
    {name:"Banner Management",all:false,view:false,insert:false,update:false,delete:false},
    {name:"Social Link Management",all:false,view:false,insert:false,update:false,delete:false},
  ]
  constructor(
    private modalService: BsModalService, private sport: SportService,private wallet:WalletService,
    private previousRouteService: PreviousRouteService,public clipboardService: ClipboardService,
    private websiteSettingService: WebsiteSettingService,private cdRef: ChangeDetectorRef,
    private fb: FormBuilder, private toastr: ToastrService, private router: Router, private route: ActivatedRoute,
    private loginService: LoginService, private usersService: UsersService, private cookie: CookieService, private locationBack: Location,
    private appValidationService: AppValidationService, private socketService: SocketServiceService,
 
  ) { }

  ngOnInit(): void {
    this.createRoleForm = this.fb.group({
      "name": ["", Validators.required], 
    });
    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
   this.getRole()
  }
  getfb()
  {
    
  }

  

  subMitForm()
  {
    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
   if(this.createRoleForm.valid)
   {
    
    this.submited=true;
    let data = {
      parent_user_name:'null',
      parent_id:this.adminDetails.parent_id,
      name:this.createRoleForm.controls.name.value,
      user_name:this.adminDetails.user_name,
      user_id:this.adminDetails.user_id
    }
    this.wallet.createRole(data).subscribe((res:any) => {
      this.submited=false;
      if(res.status == true){
        this.modalRef.hide();
        this.toastr.success(res.msg,'',{
          timeOut: 10000,
        });
      this.getRole()
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  
   }
  }
  
  updateAll(index:any,event:any)
  {
    this.permission_arr[index].all=event.target.checked;
    this.permission_arr[index].view=event.target.checked;
    this.permission_arr[index].insert=event.target.checked;
    this.permission_arr[index].update=event.target.checked;
    this.permission_arr[index].delete=event.target.checked;
    
  }

  updateView(index:any,event:any)
  {
    this.permission_arr[index].view=event.target.checked;
     
    if(event.target.checked==false){
      this.permission_arr[index].all=event.target.checked;
    }
  }

  removeRole(role){
    if(confirm("Are You Sure Delete Role")){
      let params={
        role_id:role._id,
        parent_id:this.adminDetails.user_id
      }
      this.wallet.removeRole(params).subscribe((result) => {
        if (result.data == true) {
          this.getRole();
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
        } else {
          this.toastr.error(result.msg, '', {
            timeOut: 10000,
          });
          if(result.logout == true){
            
          }
        }
      }, (err) => {
        console.log(err);
      });
    }
  }
  

  updateInsert(index:any,event:any)
  {
    this.permission_arr[index].insert=event.target.checked;
    if(event.target.checked==false){
      this.permission_arr[index].all=event.target.checked;
    }
  }
  

  updateSave(index:any,event:any)
  {
    this.permission_arr[index].update=event.target.checked;
    if(event.target.checked==false){
      this.permission_arr[index].all=event.target.checked;
    }
  }
  
  updateDelete(index:any,event:any)
  {
    this.permission_arr[index].delete=event.target.checked;
    if(event.target.checked==false){
      this.permission_arr[index].all=event.target.checked;
    }
  }
  
  

  getRole()
  {
    let data = {
      
      user_id:this.adminDetails.user_id
    }
    this.wallet.getRole(data).subscribe((res:any) => {
    this.roleList=res.data;
      if(res.status == true){
      
    } else {
      
    }
  });
  }

  updateRole(event:any)
  {
    let visible=true;
    if(event?.visible)
    {
    visible=false;
    }
    let data = {
      _id:event?._id,
      visible:visible
    }
    this.wallet.update_role(data).subscribe((res:any) => {
      if(res.status == true){
        this.getRole();
        this.toastr.success(res.msg,'',{
          timeOut: 10000,
        });
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  }

  updateRolePermision(event:any)
  {
    let data = {
      _id:event?._id,
      permission:this.permission_arr
    }
    this.wallet.update_role_permission(data).subscribe((res:any) => {
      if(res.status == true){
        this.getRole();
        this.toastr.success(res.msg,'',{
          timeOut: 10000,
        });
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  }

 
  openRole(addRole: TemplateRef<any>) {
    
    this.modalRef = this.modalService.show(
      addRole,
      Object.assign({}, { class: 'comissionSetting-modal modal-lg' })
    );
  }

  editRole(editrole: TemplateRef<any>,r:any) {
    this.role_Data=r;
    this.permission_arr=this.permission_arr;
       if(r?.credentails.length)this.permission_arr=r?.credentails;
      
    
    this.modalRef = this.modalService.show(
      editrole,
      Object.assign({}, { class: 'comissionSetting-modal modal-lg' })
    );
  }

}
