<app-header></app-header>
<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="website-setting inner-content">
                <div class="page-title">
                    <h3>Website Setting</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>
                <div class="filterDiv">
                    <form [formGroup]='websiteSetting' (ngSubmit)="addWebsite()">
                        <div class="row">
                            <div class="col-md-2 col-6">
                                <div class="form-group">
                                    <label class="form-label">Website Name</label>
                                    <input type="text" class="form-control"
                                        (blur)="onClickWebsiteName(websiteSetting.controls.host_name.value,'add')"
                                        placeholder="eg: https://example.in" formControlName='host_name'>
                                    <div *ngIf="websiteSetting.controls.host_name.errors">
                                        <!-- <div *ngIf="websiteSetting.controls.host_name.errors?.required" class="invalid-feedback">Website Name is
                                            required</div> -->
                                        <div *ngIf="websiteSetting.controls.host_name.errors?.pattern"
                                            class="invalid-feedback">Please enter valid url.
                                        </div>
                                    </div>
                                    <div *ngIf="checkWebsiteName" class="invalid-feedback">{{checkWebsiteName}}</div>
                                </div>
                            </div>
                            <div class="col-md-2 col-6">
                                <div class="form-group">
                                    <label class="form-label">Site Title Data</label>
                                    <input type="text" value="" name="" class="form-control"
                                        placeholder="eg: anytimebet" formControlName='site_title'
                                        (change)="onClickSiteTitle(websiteSetting.controls.site_title.value,'add')">
                                    <div *ngIf="submitted && f.site_title.errors">
                                        <!-- <div *ngIf="f.site_title.errors.required" class="invalid-feedback">Site Title is
                                            required</div> -->

                                    </div>
                                    <div *ngIf="checkSiteTitle" class="invalid-feedback">{{checkSiteTitle}}</div>
                                </div>
                            </div>
                            <div class="col-md-2 col-6">
                                <div class="form-group">
                                    <label class="form-label">Domain Name</label>
                                    <input type="text" value="" name="" class="form-control"
                                        placeholder="eg: abc.com" formControlName='domain_name' readonly>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="btn-group">
                                    <button class="btn btn-success" type="submit">Add</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <br>
                <div class="report-table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Domain</th>
                                <th>Site Title</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor=' let value of websiteList'>
                                <td>{{value.host_name}}</td>
                                <td>{{value.site_title}}</td>
                                <td>
                                    <button type="button" class="td-btn btn-success"
                                        (click)="updateWebsite(value,deletewebsite);openModalSetting(setting);">Update</button>
                                    <button type="button" class="td-btn btn-danger"
                                        (click)='deletewebsite(value,parent)'>Delete</button>
                                    <button type="button" class="td-btn btn-success"
                                        (click)="openModalUploadLogo(logo);">Update Logo</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</main>


<ng-template #setting>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Update Website Setting</h4>
            <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i
                    class="fa fa-times"></i></a>
        </div>
        <form [formGroup]='updatewebsiteSettingForm' (ngSubmit)="addWebsite('update')">
            <div class="modal-body no-scroll-body">
                <div class="body-inner">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Website Name</label>
                                <input type="text" class="form-control" formControlName='host_name'
                                    (blur)="onClickWebsiteName(updatewebsiteSettingForm.controls.host_name.value,'update')"
                                    placeholder="eg: https://example.in">
                                <div *ngIf="submitted && updatewebsiteSettingForm.controls.host_name.errors">
                                    <!-- <div *ngIf="f.host_name.errors.required" class="invalid-feedback">Website Name is
                                        required</div> -->
                                    <div *ngIf="f.host_name.errors.pattern" class="invalid-feedback">Please enter valid
                                        url.
                                    </div>
                                </div>
                                <div *ngIf="updatecheckWebsiteName" class="invalid-feedback">{{updatecheckWebsiteName}}
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Site Title Data</label>
                                <input type="text" value="" name="" class="form-control" placeholder="eg: anytimebet"
                                    formControlName='site_title'
                                    (change)="onClickSiteTitle(updatewebsiteSettingForm.controls.site_title.value,'update')">
                                <div *ngIf="submitted && updatewebsiteSettingForm.controls.site_title.errors">
                                    <!-- <div *ngIf="f.site_title.errors.required" class="invalid-feedback">Site Title is
                                        required</div> -->
                                </div>
                                <div *ngIf="checkUpdateTitle" class="invalid-feedback">{{checkUpdateTitle}}</div>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Logo</label>
                                <input type="file" value="" name="" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Bg Image</label>
                                <input type="file" value="" name="" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Password</label>
                                <input type="password" value="" name="" class="form-control"
                                    placeholder="Enter Password" formControlName='password'>
                                <div *ngIf="submitted && updatewebsiteSettingForm.controls.password.errors">
                                    <!-- <div *ngIf="f.password.errors.required" class="invalid-feedback">Password is required</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success" type="submit" *ngIf='websiteId'>update</button>
                <a href="javascript:void(0)" class="modal-close btn-danger btn" data-dismiss="modal"
                    (click)="modalRef.hide()">Close</a>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #parent>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">User Confirmation</h4>
            <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i
                    class="fa fa-times"></i></a>
        </div>
        <div class="modal-body no-scroll-body">
            <div class="body-inner">
                <div class="form-group">
                    <label class="form-label">Password</label>
                    <input type="text" value="" [(ngModel)]='userPassword' class="form-control"
                        placeholder="enter password" required>
                </div>

            </div>
        </div>
        <div class="modal-footer">
            <a href="javascript:void(0)" class="modal-close btn-success btn" data-dismiss="modal"
                (click)='deleteConfirm()'>Save</a>
            <a href="javascript:void(0)" class="modal-close btn-danger btn" data-dismiss="modal"
                (click)="modalRef.hide()">Close</a>
        </div>
    </div>
</ng-template>


<ng-template #logo>
    <div class="modal-header">
        <h4 class="modal-title">Upload logo </h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form  [formGroup]="logoForm">
    <table class="table">
        <tbody>
            <ng-container>
                <tr>
                    <td>Title : </td>
                    <td><input type="text" placeholder="Title" class="form-control" formControlName="title" [(ngModel)]="titleLogo"></td>
                </tr>
                <tr>
                    <td>Slug : </td>
                    <td><input type="text" placeholder="like as user.domain.com" class="form-control" formControlName="slug" [(ngModel)]="slugLogo"></td>
                </tr>
                <tr>
                    <td>Logo : </td>
                    <td><input type="file" id="file" class="form-control" (change)="handleFileInput($event.target.files)"></td>
                </tr>

                <!-- <tr>
                    <td>user2</td>
                </tr>
                <tr>
                    <td>user3</td>
                </tr> -->
                
            </ng-container>
        </tbody>
    </table>
</form>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success"
                (click)="updatelogo()">Save</button>
            &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>