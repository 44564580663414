import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHeaderResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  public Base_Url = environment['adminServerUrl'];
  public token = localStorage.getItem('adminAccessToken');
  constructor(private http: HttpClient, private cookie: CookieService) { }

  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  reqHeader1 = new HttpHeaders({
    Authorization: 'Bearer ' + this.token
  });
  createRole(param) {
    return this.http.post<any>(this.Base_Url + 'wallet/create-role', param, { headers: this.reqHeader });
  }

  getRole(param) {
    return this.http.post<any>(this.Base_Url + 'wallet/get-role', param, { headers: this.reqHeader });
  }
  getactiveRole(param) {
    return this.http.post<any>(this.Base_Url + 'wallet/get-active-role', param, { headers: this.reqHeader });
  }
  walletagent_create(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/wallet-create-agent', param, { headers: this.reqHeader });
  }

  get_agent_list(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/wallet-get-agent', param, { headers: this.reqHeader });
  }

  closedUser(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/closed-account', param, { headers: this.reqHeader });
  }

  removeRole(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/remove-role', param, { headers: this.reqHeader });
  }

  makeSettlement(param) {
    
    return this.http.post<any>(this.Base_Url + 'refer/affilate-makeSettlement', param, { headers: this.reqHeader });
  }

  ceate_payment_method(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/create-method', param, { headers: this.reqHeader });
  }

  walletagent_edit(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/edit-agent-user', param, { headers: this.reqHeader });
  }

  agentwalletWithdraw(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/get-agent-withdraw-method', param, { headers: this.reqHeader });
  }

  referalBobusList(param) {
    
    return this.http.post<any>(this.Base_Url + 'refer/referal-bonus-list', param, { headers: this.reqHeader });
  }

  updateBobusList(param) {
    
    return this.http.post<any>(this.Base_Url + 'refer/update-referal-bonus', param, { headers: this.reqHeader });
  }

  removeBonusList(param) {
    
    return this.http.post<any>(this.Base_Url + 'refer/remove-referal-bonus', param, { headers: this.reqHeader });
  }

  payment_method(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/get-payment-method', param, { headers: this.reqHeader1 });
  }

  upload_wallet_image(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/upload-wallet-image', param, { headers: this.reqHeader1 });
  }

  upload_wallet_image_qr(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/upload-wallet-image-qr', param, { headers: this.reqHeader1 });
  }


  update_payment(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/update-payment-method', param, { headers: this.reqHeader });
  }

  update_payment_agent_withdraw_method(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/update-payment-withdraw-agentmethod', param, { headers: this.reqHeader });
  }

  update_role(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/update-role', param, { headers: this.reqHeader });
  }

  create_campaign(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/create-campaign-wallet', param, { headers: this.reqHeader1 });
  }

  create_refeal_permotion(param) {
    
    return this.http.post<any>(this.Base_Url + 'refer/create-referal-bonus', param, { headers: this.reqHeader1 });
  }

  edit_campaign(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/edit-campaign-wallet', param, { headers: this.reqHeader1 });
  }

  get_campaign(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/get-campaign', param, { headers: this.reqHeader });
  }

  update_campaign(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/update-campaign', param, { headers: this.reqHeader });
  }

  update_role_permission(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/update-role-permission', param, { headers: this.reqHeader });
  }

  create_withdraw_method(param) {
    
    return this.http.post<any>(this.Base_Url + 'wallet/create-method-withdraw', param, { headers: this.reqHeader1 });
  }
}
