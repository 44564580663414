import { Component, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DateTime } from '../../dateTime';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ReportService } from '../services/report.service';
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { SocketServiceService } from '../services/socket-service.service';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-fraud-bets',
  templateUrl: './fraud-bets.component.html',
  styleUrls: ['./fraud-bets.component.scss']
})
export class FraudBetsComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    "lengthChange": false,
    "ordering": false,
    "paging": false,
    "searching": false
  };
  dtTrigger: Subject<any> = new Subject();
  isSocket;
  modalRef: BsModalRef;
  fraudList: any;
  constructor(private loginService: LoginService,private datePipe: DatePipe, private report: ReportService, private router: Router,private modalService: BsModalService,
    private toastr: ToastrService, private locationBack: Location, private route: ActivatedRoute,private sport: SportService,
    private cookie: CookieService, private socketService: SocketServiceService) { }

  ngOnInit(): void {
    this.fraudBets();
  }
  fraudBets(){
    let data = {};
    this.report.fraudBetCentral(data).subscribe((res)=>{
      console.log("47",res);
      this.fraudList = res.data.data
    })
  }
}
