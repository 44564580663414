<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="closed-user inner-content">
                <div class="page-title" style="margin-bottom: 0;">
                    <h3>Account Statement</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div class="filterDiv" style="margin-top: 0;">
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                <div class="filter-btn text-start">
                                    <div class="form-check"
                                        *ngFor="let type of statementType| keyvalue; let i = index;">
                                        <input name="{{type.value}}" [value]='type.key' id='{{type.value}}'
                                            [(ngModel)]="radioSelect" type="radio"
                                            (ngModelChange)="onSelectionChange(type.key)"
                                            (change)="onSelectionChange(type.key)">
                                        <label class="form-check-label" for="{{type.value}}"
                                            (keyup.space)="radioSelected=type.key">{{type.value}} </label>
                                    </div>

                                </div>
                                <div class="row form-field">
                                    <div class="col-md-2">
                                        <div class="col-inner">
                                            <div class="form-group dateTimePicker">
                                                <!-- <input class="form-control" [max]="min" name="startDate"
                                                    (monthSelected)="chosenMonthHandler($event, dt1)"
                                                    [(ngModel)]="openStartDate" datetime="HH:mm:ss" [owlDateTime]="dt1"
                                                    [owlDateTimeTrigger]="dt1" placeholder=" Start Date Time">
                                                <owl-date-time #dt1 hour12Timer="true" showSecondsTimer="true">
                                                </owl-date-time> -->
                                            <input type="text" class="form-control" [popover]="popTemplate" placement="bottom" [outsideClick]="true" #startpopoverRef="bs-popover" value="{{startdateTime | date:'yyyy-MM-dd HH:mm:ss '}}" (click)="showDate()">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="col-inner">
                                            <div class="form-group dateTimePicker">
                                                <!-- <input class="form-control" [min]="openStartDate" [max]="startAt"
                                                    name="endDate" [(ngModel)]="openEndDate" [owlDateTime]="dt2"
                                                    [owlDateTimeTrigger]="dt2" placeholder=" End Date Time">
                                                <owl-date-time #dt2 showSecondsTimer="true" hour12Timer="false">
                                                </owl-date-time> -->
                                                <input type="text" class="form-control" [popover]="popTemplate1" placement="bottom" [outsideClick]="true" #endpopoverRef="bs-popover" value="{{enddateTime | date:'yyyy-MM-dd HH:mm:ss '}}" (click)="showDate()">
                                            
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="col-inner">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Search by user">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="col-inner text-end">
                                            <div class="btn-list">
                                                <button type="button" class="btn btn-success"
                                                    (click)="getStatement('filterBtnClick')">Filter</button>
                                                <button type="button" class="btn btn-danger" (click)="onClickClear()">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="report-table">
                    <table datatable class="table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Credit</th>
                                <th>Debit</th>
                                
                                    <th *ngIf="(selections == 1 || selections == 4 || selections == 0) && update_user_Type_id == 1">Balance</th>
                                    <th >Balance</th>
                                <th *ngIf="selections == 1 || selections == 4"> Remark</th>

                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="let list of statementList| paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems };let i = index;">
                                <tr>
                                    <td>{{itemsPerPage *(currentPage-1)+i+1}}</td>
                                    <td>
                                        <span class="date">{{list.date| date :'dd-MM-yyyy hh:mm:ss aa'}}</span>
                                    </td>
                                    <td (click)="openModalUserParentList(list,userParentList)">{{list.user_name}}</td>
                                    <td style="color: blue !important;cursor: pointer;" class="dec-cell" (click)="(list.statement_type == 4 || list.statement_type == 2) ? routePath(list) : '' "> {{list.description}}</td>
                                    <td class="green">{{list.credit_debit > '0' ? (list.credit_debit | number : '0.0-2')
                                        : '0'}}</td>
                                    <td class="red">{{list.credit_debit < '0' ? (list.credit_debit | number : '0.0-2' )
                                            : '0' }}</td>
                                    <td [ngClass]="{'red':list.balance < 0 ,'green':list.balance >= 0}"
                                        *ngIf="(selections == 1 || selections == 4 || selections == 0) && update_user_Type_id == 1">{{list.balance | number : '0.0-2'}}
                                    </td>
                                    <td [ngClass]="{'red':list.balance < 0 ,'green':list.balance >= 0}"
                                        >{{list.balance | number : '0.0-2'}}
                                    </td>
                                    <td *ngIf="selections == 1 || selections == 4">{{list.remark}}</td>
                                </tr>
                            </ng-container>
                            <div *ngIf="statementList.length == 0" style="text-align: center;width:100%">
                                No data avalible 
                            </div>
                        </tbody>

                    </table>
                    <!-- <pagination-controls (pageChange)="pageChange($event)" class="user-list-pagination">
                    </pagination-controls> -->
                    <div class="report-bottom-paginate">
                        <div class="col-inner item-perpage-select">
                            <label>Items per page:</label>

                            <select name="betType" class="form-select" [(ngModel)]="itemsPerPage"
                                [ngModelOptions]="{standalone: true}" aria-label="Default select example"
                                (ngModelChange)="pageChange()">
                                <option selected disabled></option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                        </div>
                       
                        <pagination-controls (pageChange)="pageChange($event)"
                        class="user-list-pagination" ></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<!-- Date Time Picker -->
<ng-template #popTemplate>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="date" [minDate]="minDate" [maxDate] ="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="time" [min]="minTime" [max]="maxTime" (ngModelChange)="updateTime()" [showMeridian]="isMeridian" showSeconds="true">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="close()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>
<ng-template #popTemplate1>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDate"[minDate]="date" [maxDate] ="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateEndDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTime" (ngModelChange)="updateEndTime()"
        [showMeridian]="isMeridian" showSeconds="true"  [min]="minTime" [max]="maxTime">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="closeEndDatepicker()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>

<ng-template #userParentList>
    <div class="modal-header">
        <h4 class="modal-title">Parent List of {{userParentName}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <table class="table">
        <tbody>
            <ng-container *ngFor="let parent of parentList; let i =index">
                <tr>
                    <td>{{parent?.name}}({{parent?.user_name}})</td>
                </tr>
                <!-- <tr>
                    <td>user2</td>
                </tr>
                <tr>
                    <td>user3</td>
                </tr> -->
                
            </ng-container>
        </tbody>
    </table>
</ng-template>
