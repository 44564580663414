import { Component, OnInit, TemplateRef ,ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import { UsersService } from '../services/users.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  public Base_Url = environment['adminServerUrl'];
  logoForm: FormGroup;
  bannerForm: FormGroup
  bannerData: any = [];
  fileToUpload:any;
  constructor(private fb: FormBuilder, private locationBack: Location, private cdRef :ChangeDetectorRef,
    private toastr: ToastrService, private modalService: BsModalService, private usersService: UsersService
  ) { }

  async ngOnInit() {
    this.getBanner();
    this.bannerForm = this.fb.group({
      title: ['',[Validators.required]],
      slug:['',[Validators.required]],
      image: ['',[Validators.required]],
    })
  }

  goToBack() {
    this.locationBack.back();
  }
 
  openModalUploadBanner(banner: TemplateRef<any>) {
    this.slugBanner = ''
    this.titleBanner = ''
    this.modalRef = this.modalService.show(
      banner,
      Object.assign({}, { class: 'modal-lg' })
    );
    
  }
  openModalUploadCurrentBanner(currentBanner: TemplateRef<any>,data) {
    console.log("43",data);
    
    this.slugBanner = data.title
    this.titleBanner = data.slug
    this.modalRef = this.modalService.show(
      currentBanner,
      Object.assign({}, { class: 'modal-lg' })
    );
    
  }

  handleFileInput(files:FileList) {
    this.fileToUpload = files.item(0);
    
  }
  
  fileToUploadBanner: File = null;
  titleBanner: any;
  slugBanner: any;
  modalRef: any;
  updateBanner(){
    const formData: FormData = new FormData();
  formData.append('image',  this.fileToUpload);
 
  this.usersService.updateBanner(formData,this.titleBanner,this.slugBanner).subscribe((res) => {
    if(res.status){
      this.toastr.success(res.msg);
      this.getBanner();
      this.modalRef.hide();
    } else {
      this.toastr.error(res.msg)
    }
    
  })
  }
  
  getBanner(){
    this.usersService.getBannerImage().subscribe((res) => {
      if (res.status == true) {
        let a = res.data;
        if(a.length != 0){
          for(let i = 0; i< a.length;i++){
            a[i].bannerUrl = this.Base_Url + a[i].description;
          }
          this.bannerData =a;
          this.cdRef.detectChanges();
        }
      }
    })
  }
}
