<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="closed-user inner-content">
                <div class="page-title">
                    <h3>Casino Open Bets</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div class="filterDiv">
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                
                                <div class="row form-field">
                                    <div class=" row col-md-10">

                                    
                                    <div class="col-md-1" style="width: 13.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group dateTimePicker">
                                                <!-- <input class="form-control" name="startDate" [max]="todayDate"
                                                    [(ngModel)]="openStartDate" datetime="HH:mm:ss" [owlDateTime]="dt1"
                                                    [owlDateTimeTrigger]="dt1" placeholder=" Start Date Time">
                                                <owl-date-time #dt1 hour12Timer="true" showSecondsTimer="true">
                                                </owl-date-time> -->
                                                <input type="text" class="form-control" [popover]="popTemplate" placement="bottom" [outsideClick]="true" #startpopoverRef="bs-popover" value="{{startdateTime | date:'yyyy-MM-dd HH:mm:ss  '}}" (click)="showDate()">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="width: 13.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group dateTimePicker">
                                                <!-- <input class="form-control" name="endDate" [min]="openStartDate"
                                                    [max]="startAt" [(ngModel)]="openEndDate" [owlDateTime]="dt2"
                                                    [owlDateTimeTrigger]="dt2" placeholder=" End Date Time">
                                                <owl-date-time #dt2 showSecondsTimer="true" hour12Timer="false">
                                                </owl-date-time> -->
                                                <input type="text" class="form-control" [popover]="popTemplate1" placement="bottom" [outsideClick]="true" #endpopoverRef="bs-popover" value="{{enddateTime | date:'yyyy-MM-dd HH:mm:ss'}}" (click)="showDate()">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-1" style="width: 12.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group">

                                                <input name="searchSport"
                                                    class="search-input dropdown-toggle form-control"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" type="text" autocomplete="off"
                                                    [(ngModel)]="searchSport" placeholder="Search Sport Name">

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item"
                                                        *ngFor="let sport of SportList |search: searchSport :'sport_name'">
                                                        <div class="event-info"
                                                            (click)="getOpenBetFilterData(0,sport.sport_id,sport.sport_name)">
                                                            <div class="event-format-strip">
                                                                <div class="event-team">
                                                                    <div class="team-name">{{sport?.sport_name}}
                                                                       
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="width: 12.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group mb-0">

                                                <input name="searchSeries"
                                                    class="search-input dropdown-toggle form-control"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" type="text" autocomplete="off"
                                                    [(ngModel)]="searchSeries" placeholder="Search Series Name...">

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item"
                                                        *ngFor="let sport of SeriesList |search: searchSeries :'series_name'">
                                                        <div class="event-info"
                                                            (click)="getOpenBetFilterData(1,sport.series_id,sport.series_name)">
                                                            <div class="event-format-strip">
                                                                <div class="event-team">
                                                                    <div class="team-name">{{sport?.series_name}}</div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="width: 12.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group mb-0">

                                                <input name="searchMatch"
                                                    class="search-input dropdown-toggle form-control"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" type="text" autocomplete="off"
                                                    [(ngModel)]="searchMatch" placeholder="Search Match Name...">

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item"
                                                        *ngFor="let sport of MatchList |search: searchMatch :'match_name'">
                                                        <div class="event-info"
                                                            (click)="getOpenBetFilterData(2,sport.match_id,sport.match_name)">
                                                            <div class="event-format-strip">
                                                                <div class="event-team">
                                                                    <div class="team-name">{{sport?.match_name}} </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-1" style="width: 16.499999995%;margin:0 5px;">
                                    
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchSport"
                                            (change)="getOpenBetFilterData(0,searchSport.sport_id,searchSport.sport_name,'1','filterBtnClick') "
                                            (blur)="getOpenBetFilterData(0,searchSport.sport_id,searchSport.sport_name,'1','filterBtnClick')" placeholder="Search Sport Name"
                                            (clear)="getOpenBetFilterData(0,undefined,undefined,'1','filterBtnClick')">
                                            <ng-option *ngFor="let sport of SportList" [value]="sport">{{sport.sport_name}}</ng-option>
                                        </ng-select>
                                    </div> -->
                                    <div class="col-md-1" style="width: 16.499999995%;margin:0 5px;">
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchSeries"
                                            (change)="getOpenBetFilterData(1,searchSeries.series_id,searchSeries.series_name,'1','filterBtnClick') "
                                            (blur)="getOpenBetFilterData(1,searchSeries.series_id,searchSeries.series_name,'1','filterBtnClick')"
                                            placeholder="Search Series Name" (clear)="getOpenBetFilterData(1,undefined,undefined,'1','filterBtnClick')">
                                            <ng-option *ngFor="let series of SeriesList" [value]="series">{{series.series_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="col-md-1" style="width: 16.499999995%;margin:0 5px;">
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchMatch"
                                            (change)="getOpenBetFilterData(2,searchMatch.match_id,searchMatch.match_name,'1','filterBtnClick') "
                                            (blur)="getOpenBetFilterData(2,searchMatch.match_id,searchMatch.match_name,'1','filterBtnClick')"
                                            placeholder="Search Match Name" (clear)="getOpenBetFilterData(2,undefined,undefined,'1','filterBtnClick')">
                                            <ng-option *ngFor="let match of MatchList" [value]="match">{{match.match_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="col-md-1" style="width: 16.499999995%;margin:0 5px;">
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchMarket"
                                            (change)="(searchMarket.type == '1') ? getOpenBetFilterData(3,searchMarket.event_id,searchMarket.event_name,'1' ,'filterBtnClick') : getOpenBetFilterData(4,searchMarket.event_id,searchMarket.event_name,'1','filterBtnClick') "
                                            (blur)="(searchMarket.type == '1') ? getOpenBetFilterData(3,searchMarket.event_id,searchMarket.event_name,'1','filterBtnClick') : getOpenBetFilterData(4,searchMarket.event_id,searchMarket.event_name,'1','filterBtnClick')"
                                            placeholder="Search Market Name"
                                            (clear)="(searchMarket.type == '1') ? getOpenBetFilterData(3,undefined,undefined,'1','filterBtnClick') :getOpenBetFilterData(4,undefined,undefined,'1','filterBtnClick')">
                                            <ng-option *ngFor="let event of MarketList" [value]="event">{{event.event_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                    <!-- <div class="col-md-1" style="width: 12.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group mb-0">

                                                <input name="searchMarket"
                                                    class="search-input dropdown-toggle form-control"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" type="text" autocomplete="off"
                                                    [(ngModel)]="searchMarket" placeholder="Search Market Name...">

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item"
                                                        *ngFor="let sport of MarketList |search: searchMarket :'event_name'">
                                                        <div class="event-info"
                                                            (click)="(sport.type == '1') ? getOpenBetFilterData(3,sport.event_id,sport.event_name) : getOpenBetFilterData(4,sport.event_id,sport.event_name)">
                                                            <div class="event-format-strip">
                                                                <div class="event-team">
                                                                    <div class="team-name">{{sport?.event_name}} </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                   
                                    <div class="col-md-1 col" style="width: 12.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group inner-addon right-addon">
                                                <!-- <div class="input-group"> -->
                                                <select name="betType" class="form-select" placeholder="Select Bet Type"
                                                    [(ngModel)]="betType" [ngModelOptions]="{standalone: true}"
                                                    aria-label="Default select example"
                                                    (ngModelChange)="getOpenBetFilterData('betType','','filterBtnClick')">
                                                    <option class="select-option"selected disabled value="Bet Type">Bet Type</option>
                                                    <option class="select-option" value="all">All</option>
                                                    <option  class="select-option" value="1">Back</option>
                                                    <option  class="select-option" value="0">Lay</option>
                                                </select>
                                                <!-- <div>
                                                    <i alt="clear"  class="fa fa-times" 
                                                    (click)="clearInput()" ></i>
                                                </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="width: 16.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group mb-0">

                                                <input name="searchUser"
                                                    class="search-input dropdown-toggle form-control"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" type="text" autocomplete="off"
                                                    [(ngModel)]="searchUser" placeholder="Search User Name...">

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item"
                                                        *ngFor="let sport of userlist |search: searchUser :'user_name'">
                                                        <div class="event-info" (click)="getFancy(sport.match_id)">
                                                            <div class="event-format-strip">
                                                                <div class="event-team">
                                                                    <div class="team-name">{{sport?.user_name}} </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <!-- <div class="col-md-7 ">

                                    </div> -->
                                    <div class="col-md-1" style="width: 10.499999995%">
                                        <div class="col-inner text-end">
                                            <div class="btn-list">
                                              <!-- <button type="button" class="btn btn-success"
                                                    (click)="autoResultDeclare()">Auto Result</button> &nbsp;&nbsp; -->
                                                <button type="button" class="btn btn-success"
                                                    (click)="openBets('filterBtnClick')">Filter</button>
                                                <button type="button" class="btn btn-danger"
                                                    (mousedown)="onClickClearBtn()">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="report-table">
                    <div class="report-bottom-paginate">
                        <div class="col-inner item-perpage-select">
                            <label>Items per page:</label>

                            <select name="betType" class="form-select" [(ngModel)]="itemsPerPage"
                                [ngModelOptions]="{standalone: true}" aria-label="Default select example"
                                (ngModelChange)="pageChange()">
                                <option selected disabled></option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                        </div>
                       
                        <pagination-controls (pageChange)="pageChange($event)"
                        class="user-list-pagination" ></pagination-controls>
                    </div>
                    <table datatable class="table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Date</th>
                                <th *ngIf="type == 0">Domain</th>
                                <th>User Name</th>
                                <th>Sports | Series</th>
                                <th>Match | Market</th>
                                <th>Selection | Type</th>
                                <th>Rate/Size</th>
                                <th>Stake</th>
                                <th>P | L</th>
                                <th>BetId</th>
                                <th>IP Address</th>
                                <th *ngIf="type == 0"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="let bet of openBetData| paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems };let i = index;">
                                <tr>
                                    <td>{{itemsPerPage *(currentPage-1)+i+1}}</td>
                                    <td>
                                        <span class="date">{{bet?.createdAt| date :'dd-MM-yyyy'}}</span><br>
                                        <span class="date">{{bet?.createdAt| date :'hh:mm:ss aa'}}</span>
                                    </td>
                                    <td *ngIf="type == 0">{{bet?.domain_name}}</td>
                                    <td > <a (click)="openModalUserParentList(bet,userParentList)">{{bet?.user_name}}</a></td>
                                    <td> 
                                        <span class="sport-name">{{bet?.sport_name}}</span><br>
                                        <span class="series-name">{{bet?.series_name}}</span>
                                    </td>
                                    <td>
                                        <span class="match-name">{{bet?.match_name}}</span><br>
                                        <span class="market-name">{{bet?.market_name}}</span>
                                    </td>
                                    <td>
                                        <span class="selection">{{bet?.selection_name}}</span><br>
                                        <span *ngIf="bet.is_fancy == 0" [ngClass]="{'lay-btn':bet.is_back == 0,'back-btn':bet.is_back == 1}">{{bet.is_back == 1 ? 'Back' : 'Lay'}}</span>
                                        <span *ngIf="bet.is_fancy == 1" [ngClass]="{'lay-btn':bet.is_back == 0,'back-btn':bet.is_back == 1}">{{bet.is_back == 1 ? 'Yes' : 'No'}} </span>
                                    </td>
                                    
                                    <td *ngIf="bet.is_fancy == 0">{{bet?.odds| number : '0.0-2'}}</td>
                                    <td *ngIf="bet.is_fancy == 1">{{bet?.odds| number : '0.0-2'}} / {{bet?.size| number
                                        : '0.0-2'}} </td>
                                    <td>{{bet?.stack| number : '0.0-2'}}</td>
                                    <td [ngClass]="{'red':bet.p_l < 0 ,'green':bet.p_l >= 0}">{{bet?.p_l| number :
                                        '0.0-2'}}</td>
                                    <td>{{bet?.bet_id}}</td>
                                    <td>{{bet?.ip_address}}</td>
                                    <td *ngIf="type == 0">
                                        <button type="button" class="btn btn-sm btn-success"
                                        (click)="casinoStatus(bet?.market_id)">Status</button>&nbsp;&nbsp;
                                        <button type="button" class="btn btn-sm btn-success"
                                        (click)="casinoResultDeclare(bet?.market_id)" *ngIf="resultDeclare == true && bet?.market_id == casinoId">Result</button>
                                        <button type="button" class="btn btn-sm btn-success"
                                        (click)="openModaldeleteBet(deleteBet, bet.bet_id,bet.user_id)" *ngIf="deleteBets == true && bet?.market_id == casinoId">Delete</button>
                                        <button type="button" class="btn btn-sm btn-success"
                                        (click)="casinoResultAbonded(bet?.market_id,bet?.marketId,bet?.match_id)" *ngIf="abond == true && bet?.market_id == casinoId">Abonded</button>
                                    </td>
                                </tr>
                            </ng-container>
                            <div *ngIf="openBetData.length == 0" style="text-align: center;">
                                No data avalible 
                            </div>
                        </tbody>

                    </table>
                    <div class="report-bottom-paginate">
                        <div class="col-inner item-perpage-select">
                            <label>Items per page:</label>

                            <select name="betType" class="form-select" [(ngModel)]="itemsPerPage"
                                [ngModelOptions]="{standalone: true}" aria-label="Default select example"
                                (ngModelChange)="pageChange()">
                                <option selected disabled></option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                        </div>
                       
                        <pagination-controls (pageChange)="pageChange($event)"
                        class="user-list-pagination" ></pagination-controls>
                    </div>

                </div>
            </div>
        </div>
    </div>
</main>


<!-- Date Time Picker -->
<ng-template #popTemplate>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="date" [minDate]="minDate" [maxDate] ="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker style="color:black" [showSeconds]="seconds"  [hidden]="!isDateVisible" [(ngModel)]="time" (ngModelChange)="updateTime()" [showMeridian]="isMeridian">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="close()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>
<ng-template #popTemplate1>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDate" [minDate]="date" [maxDate] ="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateEndDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible"  [(ngModel)]="endTime" (ngModelChange)="updateEndTime()" show-seconds="true"
        [showMeridian]="isMeridian" [showSeconds]="seconds">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="closeEndDatepicker()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>

<ng-template #userParentList>
    <div class="modal-header">
        <h4 class="modal-title">Parent List of {{userParentName}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <table class="table">
        <tbody>
            <ng-container *ngFor="let parent of parentList; let i =index">
                <tr>
                    <td>{{parent?.name}}({{parent?.user_name}})</td>
                </tr>
                <!-- <tr>
                    <td>user2</td>
                </tr>
                <tr>
                    <td>user3</td>
                </tr> -->
                
            </ng-container>
        </tbody>
    </table>
</ng-template>
<ng-template #deleteBet>
    <div class="modal-header">
        <h4 class="modal-title">Delete Bet </h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form>
        <div class="modal-body delete-bet">
            <div class="body-inner">
                <div class="form-list">
                    <label class="form-heading">Password</label>
                    <input type="password" placeholder="Password" class="password-box form-attribute"
                        [(ngModel)]="transactionPassword" [ngModelOptions]="{standalone: true}">
                </div>
                <!-- <div class="title">
                    <div class="left">
                        <i class="fa fa-exclamation-triangle"></i>
                    </div>
                    <div class="right">
                        <h3> Delete Bet</h3>
                        <p>This action can not be undone.</p>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="modal-footer">
            <div class="text-center">
                <button type="button" class="btn btn-info" (click)="modalRef.hide();">Cancel</button>
                <button type="button" class="btn btn-danger"
                    (click)="deleteUserBet(deleteBetId,deleteBetUserId,0)">Delete</button>
                <!-- <button type="button" class="btn btn-danger"
                    (click)="deleteUserBet(deleteBetId,deleteBetUserId,1,is_fancy)">Void</button> -->
            </div>
        </div>
    </form>
</ng-template>