<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="closed-user inner-content">
                <div class="page-title" style="margin-bottom: 0;">
                    <h3>Fraud Bet</h3>
                    <div class="breadcrumb">
                        <!-- <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a> -->
                    </div>
                </div>

                <div class="report-table">
                    <table datatable class="table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>Bet Status</th>
                                <th>Domain</th>
                                <th>Bet Id</th>
                                <th>Sport</th>
                                <th>Series</th>
                                <th>Match</th>
                                <th>Market</th>
                                <th>Runner </th>
                                <th>Bet Type</th>
                                <th>Rate</th>
                                <th>Stake</th>
                                <th>Bet Place Time</th>
                                <th>Player Name</th>
                                <th>IP Address</th>
                                <th>Result</th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="fraudList.length != 0">
                                <tr *ngFor="let list of fraudList; let i = index;">
                                    <td>
                                        <span> <a class="btn btn-warning btn-sm actionbtn"
                                            role="button" tabindex="0"
                                            title="Delete bet"
                                            *ngIf="list.delete_status == '0'"><i
                                                class="fa fa-trash"></i></a>
                                        <button class="btn btn-danger"
                                            *ngIf="list.delete_status =='1'">D</button>
                                        <button class="btn btn-warning"
                                            *ngIf="list.delete_status =='2'">v</button></span>
                                    </td>
                                    <td>{{list.domain_name}}</td>
                                    <td>{{list.bet_id}}</td>
                                    <td>{{list.sport_name}}</td>
                                    <td>{{list.series_name}}</td>

                                    <td>{{list.match_name}}</td>
                                    <td>{{list.market_name}}</td>
                                    <td>{{list.selection_name}}</td> 
                                    <td>{{list.is_back == 1 ? 'Back' : 'Lay'}}</td>
                                    <td>{{list.odds}}</td>
                                    <td>{{list.stack}}</td>
                                    <td>{{list.createdAt}}</td>
                                    <td>{{list.user_name}}</td>

                                    <td>{{list.ip_address}}</td>
                                    <td>{{list.winner_name}}</td>
                                    <td>{{list.is_fraud_bet_comment}}</td> 
                                </tr>
                            </ng-container>
                            <div style="text-align: center;width:100%" *ngIf="fraudList.length == 0">
                                No data avalible
                            </div>
                        </tbody>

                    </table>
                    <!-- <pagination-controls (pageChange)="pageChange($event)" class="user-list-pagination">
                    </pagination-controls> -->
                    <!-- <div class="report-bottom-paginate">
                        <div class="col-inner item-perpage-select">
                            <label>Items per page:</label>

                            <select name="betType" class="form-select" [(ngModel)]="itemsPerPage"
                                [ngModelOptions]="{standalone: true}" aria-label="Default select example"
                                (ngModelChange)="pageChange()">
                                <option selected disabled></option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                        </div>

                        <pagination-controls (pageChange)="pageChange($event)" class="user-list-pagination">
                        </pagination-controls>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</main>


<!-- Date Time Picker -->
<ng-template #popTemplate>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="date" [minDate]="minDate" [maxDate]="todayDateTime"
        [showWeeks]="false" (ngModelChange)="updateDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="time" [min]="minTime" [max]="maxTime"
        (ngModelChange)="updateTime()" [showMeridian]="isMeridian" showSeconds="true">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="close()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>
<ng-template #popTemplate1>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDate" [minDate]="date" [maxDate]="todayDateTime"
        [showWeeks]="false" (ngModelChange)="updateEndDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTime" (ngModelChange)="updateEndTime()"
        [showMeridian]="isMeridian" showSeconds="true" [min]="minTime" [max]="maxTime">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="closeEndDatepicker()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>