<app-header></app-header>
<app-sidebar></app-sidebar>



<main>
    <div class="full-wrap" id="" #contentToConvert>
        <div class="report-page">
            <div class="user-list inner-content">
                <div class="page-title">
                    <h3>Role List</h3>
                    <div class="nav-list">
                        <div class="hierarchy">
                           
                        </div>
                    </div>
                    <div class="breadcrumb">
                        <a  class="btn btn-secondary back-btn"><i class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div _ngcontent-serverapp-c232="" class="filterDiv">
                    <div _ngcontent-serverapp-c232="" class="row">
                       <div _ngcontent-serverapp-c232="" class="col-md-12">
                          <form _ngcontent-serverapp-c232="" novalidate="" class="ng-untouched ng-pristine ng-valid">
                             <div _ngcontent-serverapp-c232="" class="row form-field">
                               
                                <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 87.5%;">
                                   <div _ngcontent-serverapp-c232="" class="col-inner">
                                      <div _ngcontent-serverapp-c232="" class="form-group mb-0">
                                         <input _ngcontent-serverapp-c232="" name="searchUser" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="text" autocomplete="off" placeholder="Search Role..." class="search-input dropdown-toggle form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="searchUser">
                                         <div _ngcontent-serverapp-c232="" aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                            <a _ngcontent-serverapp-c232="" class="dropdown-item ng-star-inserted">
                                               <div _ngcontent-serverapp-c232="" class="event-info">
                                                  <div _ngcontent-serverapp-c232="" class="event-format-strip">
                                                     <div _ngcontent-serverapp-c232="" class="event-team">
                                                        <div _ngcontent-serverapp-c232="" class="team-name">user1 </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </a>
                                           
                                         </div>
                                        
                                      </div>
                                   </div>
                                </div>
                                <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 10.499999995%;">
                                   <div _ngcontent-serverapp-c232="" class="col-inner text-end">
                                      <div _ngcontent-serverapp-c232="" class="btn-list">
                                        <button (click)="openRole(addRole)" type="button" class="btn btn-success">
                                            Add Role</button>
                                        </div>
                                   </div>
                                </div>
                             </div>
                          </form>
                       </div>
                    </div>
                 </div>

              

                <div class="report-table">
                    

                    <table class="table">
                        <thead>
                            <tr>
                               
                                <th>S No.
                                </th>
                              
                                <th>Name
                                    
                                </th>
                                <th>Status
                                    
                                </th>
                                
                                <th>Action</th>
                                
                            </tr>
                        </thead>

                        <tbody >
                            <tr *ngFor="let r of roleList;let i=index;">
                                <td>{{i+1}}</td>
                                <td>
                                    {{r?.name}}
                                </td>
                                <td>
                                    <img *ngIf="r?.visible==true" src="assets/icon/active.png"  height="20px" width="20px">
                                    <img *ngIf="r?.visible==false" src="assets/icon/remove.jpg" height="20px" width="20px">
                                   </td>
                                <td>
                                    <img *ngIf="r?.visible==false" src="assets/icon/active.png" (click)="updateRole(r)" height="20px" width="20px">
                                    <img *ngIf="r?.visible==true" src="assets/icon/remove.jpg" (click)="updateRole(r)" height="20px" width="20px">
                                  
                                    <img  src="assets/icon/edit.png" class="edit-image" (click)="editRole(editrole,r)" height="20px" width="20px">
            
                                    <img  src="assets/icon/delete.png" (click)="removeRole(r)" height="20px" width="20px">
                                </td>
                            </tr>
                        </tbody>
                      
                    </table>
                </div>

                <div class="report-bottom-paginate">
                    <pagination-controls  class="user-list-pagination">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</main>

<ng-template #addRole>
    <div class="modal-header">
        <h4 class="modal-title">Add Role</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form [formGroup]='createRoleForm'>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
           <div class="row">
          
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Name <span style="color:red">*</span></label>
                    <input type="text"  placeholder="Enter User Name"  formControlName="name"
                         class="password-box form-attribute">
                         <span *ngIf="createRoleForm.controls.name.errors && submited"
                         class="red-alert-message">
                        name is required.!
                     </span>
                </div>
            </div>
            
           </div>
          
     
    
            
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="subMitForm()" >Submit</button> &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
    </form>
</ng-template>

<ng-template #editrole>
    <div class="modal-header">
        <h4 class="modal-title">Role Permission {{role_Data?.name}} </h4>
        
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form >
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
            <p>Note: D : Deposit , W : Withdraw</p>
          <div class="row">
            <div class="col sm-2">
                  Menu Name
            </div>

            <div class="col sm-2">
              All
            </div>
            <div class="col sm-2">
              View
            </div>
            <div class="col sm-2">
            Insert
            </div>
            <div class="col sm-2">
            Update
            </div>
          
    
          <div class="col sm-2">
            Delete
            </div>
          </div>


          <div class="row tr-div" *ngFor="let p of permission_arr;let i=index;">
            <div class="col sm-2">
                {{p?.name}}
            </div>

            <div class="col sm-2">
             <input type="checkbox" [checked]="p?.all" (change)="updateAll(i,$event)">
            </div>
            <div class="col sm-2">
                <input type="checkbox"  [checked]="p?.view" (change)="updateView(i,$event)">
            </div>
            <div class="col sm-2">
                <input type="checkbox"  [checked]="p?.insert" (change)="updateInsert(i,$event)">
            </div>
            <div class="col sm-2">
                <input type="checkbox"  [checked]="p?.update" (change)="updateSave(i,$event)">
            </div>
          
          <div class="col sm-2">
            <input type="checkbox"  [checked]="p?.delete" (change)="updateDelete(i,$event)">
            </div>
          </div>

         
            
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="updateRolePermision(role_Data)" >Submit</button> &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
            </div>
    </div>
    </form>
</ng-template>
