import { Component, OnInit, TemplateRef, ElementRef, ViewChild, Output, EventEmitter,ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../services/users.service';
import { CookieService } from 'ngx-cookie-service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SportService } from '../services/sport.service';
import Swal from 'sweetalert2';
import { WalletService } from '../services/wallet.service';
import { json } from 'express';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from '../services/login.service';
import { SocketServiceService } from '../services/socket-service.service';
import { environment } from '../../environments/environment';
import { AppValidationService } from '../app-validation/app-validation.service';
//import { io, Socket } from 'socket.io-client';

import { WebsiteSettingService } from '../services/website-setting.service'
declare var $: any;
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { PreviousRouteService } from '../services/previous-route.service';
import { ValidatorControls } from '../app-validation/validation-controls.directive';
import { JsonpClientBackend } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';


@Component({
  selector: 'app-wallet-deposit-method',
  templateUrl: './wallet-deposit-method.component.html',
  styleUrls: ['./wallet-deposit-method.component.scss']
})
export class WalletDepositMethodComponent implements OnInit {
  modalRef: BsModalRef;
  createPaymentForm: FormGroup;
  submited:any=false;
  adminDetails:any;
  QRImage:any;
  image:any;
  Qrfile:any;
  urlImage:any;
  allPaymentMethod:any;
  websiteList:any;
  fileToUpload:any;
  credentailArr:any=[{key:'---',value:'---'}];
  public Base_Url = environment['adminServerUrl'];
  constructor(
    private modalService: BsModalService, private sport: SportService,private wallet:WalletService,
    private previousRouteService: PreviousRouteService,public clipboardService: ClipboardService,
    private websiteSettingService: WebsiteSettingService,private cdRef: ChangeDetectorRef,
    private fb: FormBuilder, private toastr: ToastrService, private router: Router, private route: ActivatedRoute,
    private loginService: LoginService, private usersService: UsersService, private cookie: CookieService, private locationBack: Location,
    private appValidationService: AppValidationService, private socketService: SocketServiceService,
 
  ) {
    
   }

  ngOnInit(): void {
    this.createPaymentForm = this.fb.group({
      "name": ["", Validators.required],
      "order_no": ["", Validators.required],
      "min_amount": ["", Validators.required],
      "max_amount": ["", Validators.required],
      "payment_type": ["", Validators.required],
      "parent_user_name": ["", ""],
      "parent_id": ["", ""],
      "image": [null, ""],
      "file_name": ["", ""],
      "file_name_1": ["", ""],
      "domain_name": ["", ""],
      "domain": ["", ""],
      "user_name": ["", ""],
      "user_id": ["", ""],
      "payment_details":[[], ""],
    });
    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    this.getPayment();
    this.getLoginUseretails();
  }
  getfb()
  {
    
  }

  getLoginUseretails() {
    let param = {
      "user_id": this.adminDetails._id,
      "user_type_id": this.adminDetails.user_type_id
    }
    this.usersService.getLoginUseretails(param).subscribe(response => {
      if(response.status == true){
        
      
        this.websiteList =response?.data?.domain;
       
       
      } else {
        if(response.logout == true){
         
        }
      }
      
    }, error => {
      console.log(error)
    })
  }
  handleFileInput(files:FileList,type:any,data:any) {
    this.fileToUpload = files.item(0);
    
    let formData = new FormData();
    formData.append('image',  this.fileToUpload);
    formData.append('_id',  data?._id);
    formData.append('type',  type);
     console.log(formData)
    this.usersService.updateBanner_wallet(formData).subscribe((res:any) => {
      if(res.status){
        this.toastr.success(res.msg);
        this.getPayment();
      } else {
        this.toastr.error(res.msg)
      }
      
    })
    
  }

  

  getPayment()
  {
    let parms={
      user_id:this.adminDetails._id
    }
    this.wallet.payment_method(parms).subscribe((res:any) => {
      console.log(res)
   
      if(res.status == true){
        this.allPaymentMethod=res.data;
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  }

  updatePayment(event:any,type:any)
  {
   
    let visible=true;
    let delete_status=false;
    if(event?.visible)
    {
      visible=false;

    }
    if(type==1){

      if(confirm('Are You sure remove it')){
        delete_status=true;  
        visible=false; 
      }
      else{
        return;
      }

    }
    
   
    let parms={
      _id:event?._id,
      visible:visible,
      deleted:delete_status
    }
    this.wallet.update_payment(parms).subscribe((res:any) => {
      if(res.status == true){
        this.getPayment()
        this.toastr.info(res.msg,'',{
          timeOut: 10000,
        });
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  }
  
  processImageFile(event: any)
  {
    this.image = event.target.files[0];
    var reader = new FileReader();
		reader.readAsDataURL(this.image);
	
		reader.onload = (_event) => {
			
			this.urlImage = reader.result; 
   
		}
  }

  processQrFile(event: any)
  {
    
    this.Qrfile = event.target.files[0];
    var reader = new FileReader();
		reader.readAsDataURL(this.Qrfile);
		
		reader.onload = (_event) => {
			
			this.QRImage = reader.result; 
		}
  }
  subMitForm()
  {
    this.createPaymentForm.controls['file_name'].setValue(null);
    this.submited=true;
   if(this.createPaymentForm.valid)
   {
    console.log(this.createPaymentForm.controls.name.value);
    //console.log(this.createPaymentForm.controls.dis_order.value)
    var timestamp=Date.now();
    //console.log(this.createPaymentForm.controls.image_qr.value)
   
    const formData: FormData = new FormData();
    

    
    var timestamp_v=Date.now();
    if(this.image){
 this.createPaymentForm.controls['file_name'].setValue(this.image.name);
    }
   
    this.createPaymentForm.controls['parent_user_name'].setValue('null');
    this.createPaymentForm.controls['parent_id'].setValue(this.adminDetails.parent_id);
    this.createPaymentForm.controls['user_name'].setValue(this.adminDetails.user_name);
    this.createPaymentForm.controls['user_id'].setValue(this.adminDetails._id);
    this.createPaymentForm.controls['name'].setValue(this.createPaymentForm.controls.name.value);
    this.createPaymentForm.controls['domain_name'].setValue(this.websiteList.domain_name);
    this.createPaymentForm.controls['domain'].setValue(this.websiteList._id);
    this.createPaymentForm.controls['payment_details'].setValue(this.credentailArr);
    this.createPaymentForm.controls['order_no'].setValue(this.createPaymentForm.controls.order_no.value);
    this.createPaymentForm.controls['max_amount'].setValue(this.createPaymentForm.controls.max_amount.value);
    this.createPaymentForm.controls['min_amount'].setValue(this.createPaymentForm.controls.min_amount.value);
    this.createPaymentForm.controls['payment_type'].setValue(this.createPaymentForm.controls.payment_type.value);
    if(this.image)
    {
     
      formData.append('image',  this.image);
      this.wallet.upload_wallet_image(formData).subscribe((res:any) => {
        if(res.status == true){
          
          this.toastr.success(res.msg,'',{
            positionClass: 'toast-bottom-right',
            timeOut:1000
           });
      } else {
        this.toastr.error(res.msg,'',{
          timeOut: 10000,
        });
      }
      });
    }
    else{
      this.createPaymentForm.controls['image'].setValue(null);
    }
    this.submited=false;
   

    
    
    this.wallet.ceate_payment_method(this.createPaymentForm.value).subscribe((res:any) => {
      ///console.log(res)
   
      if(res.status == true){
        this.getPayment();
        this.modalRef.hide();
        this.toastr.success(res.msg,'',{
          positionClass: 'toast-bottom-right',
          timeOut:1000
         });
         this.createPaymentForm.reset();
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
   }
  }

  valueChange(event:any,index:any)
  {
    this.credentailArr[index].value=event.target.value;
  }
  keyChange(event:any,index:any)
  {
    this.credentailArr[index].key=event.target.value
  }
  openModalPaymentMethod(paymentMethod: TemplateRef<any>) {
    //this.createPaymentForm.reset();
    this.urlImage="";
    this.QRImage="";
    this.modalRef = this.modalService.show(
      paymentMethod,
      Object.assign({}, { class: 'comissionSetting-modal modal-lg' })
    );
  }

  addRow()
  {
    this.credentailArr.push({key:'',value:''})
  }

  removeRow(index:any)
  {
    this.credentailArr.splice(index, 1)
    
  }

}
