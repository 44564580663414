<app-header></app-header>
<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="website-setting inner-content">
                <div class="page-title">
                    <h3>Social Media Link</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>
                <div class="filterDiv">
                    <form [formGroup]='websiteSetting' (ngSubmit)="addSocialMedia()">
                        <div class="row">
                            <div class="col-md-2 col-6">
                                <div class="form-group">
                                    <label class="form-label">Domain List</label>
                                    <ng-select [items]="websiteList" [autoClose]="false" 
                                    triggers="manual" formControlName='domain_id'
                                     bindLabel="domain_name"
                                    bindValue="_id" placeholder="Select Domain" 
                                    >
                                </ng-select>
                                <div *ngIf="submitted && f.domain_id.errors"
                                            class="invalid-feedback">Please select valid host_name .
                                        </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-6">
                                <div class="form-group">
                                    <label class="form-label">Support Whatsapp</label>
                                    <input type="text" class="form-control"
                             placeholder="eg: 9999999999" formControlName='support_no'>
                                    
                                        
                                        <div *ngIf="submitted && f.support_no.errors"
                                            class="invalid-feedback">Please enter valid support_no .
                                        </div>
                                   
                                    
                                </div>
                            </div>
                            <div class="col-md-2 col-6">
                                <div class="form-group">
                                    <label class="form-label">Whatsapp</label>
                                    <input type="text" class="form-control"
                             placeholder="eg: 9999999999" formControlName='whatsapp'>
                                    
                                        
                                        <div *ngIf="submitted && f.whatsapp.errors"
                                            class="invalid-feedback">Please enter valid whatsapp url.
                                        </div>
                                   
                                    
                                </div>
                            </div>
                            <div class="col-md-2 col-6">
                                <div class="form-group">
                                    <label class="form-label">Instagram</label>
                                    <input type="text" value="" name="" class="form-control"
                                        placeholder="eg: abc365" formControlName='instagram'
                                       >
                                    <div class="invalid-feedback" *ngIf="submitted && f.instagram.errors">
                                        Please enter valid instagram url.
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="col-md-2 col-6">
                                <div class="form-group">
                                    <label class="form-label">Twitter</label>
                                    <input type="text" value="" name="" class="form-control"
                                        placeholder="eg: abc365" formControlName='twitter' >
                                        <div class="invalid-feedback" *ngIf="submitted && f.twitter.errors">
                                            Please enter valid twitter url.
                                        </div>
                                    </div>
                            </div>
                            <div class="col-md-2 col-6">
                                <div class="form-group">
                                    <label class="form-label">Telegram</label>
                                    <input type="text" value="" name="" class="form-control"
                                        placeholder="eg: abc365" formControlName='telegram' >
                                        <div class="invalid-feedback" *ngIf="submitted && f.telegram.errors">
                                            Please enter valid telegram url.
                                        </div>
                                    </div>
                            </div>
                            <div class="col-md-2">
                                <div class="btn-group">
                                    <button class="btn btn-success" type="submit">Add</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <br>
                <div class="report-table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Domain</th>
                                <th>support whatsapp</th>
                                <th>whatsapp</th>
                                <th>telegram</th>
                                <th>twitter</th>
                                <th>Instagram</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor=' let value of socialList;let i=index'>
                                <td>{{i+1}}</td>
                                <td>{{value.host_name}}</td>
                                <td>{{value.support_no}}</td>
                                <td>{{value.whatsapp}}</td>
                                <td>{{value.telegram}}</td>
                                <td>{{value.twitter}}</td>
                                <td>{{value.instagram}}</td>
                                <td>
                                    <button style="display: none;" type="button" class="td-btn btn-success"
                                        >Update</button>
                                    <button type="button" (click)="removeData(value)" class="td-btn btn-danger"
                                        >Delete</button>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</main>


