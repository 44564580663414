import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserDataComponent } from './user-data/user-data.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AddAgentComponent } from './add-agent/add-agent.component';
import { WebsiteSettingComponent } from './website-setting/website-setting.component';
import { importMarketComponent } from './import-market/import-market.component';
import { BlockMarketComponent } from './block-market/block-market.component';
import { MatchDetailComponent } from './match-detail/match-detail.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OnlineUserComponent } from './online-user/online-user.component';
import { ClosedUsersAccountsComponent } from './closed-users-accounts/closed-users-accounts.component';
import { ClosedUserComponent } from './closed-user/closed-user.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { AccountStatementComponent } from './account-statement/account-statement.component';
import { ChipSummaryComponent } from './chip-summary/chip-summary.component';
import { BetHistoryComponent } from './bet-history/bet-history.component';
import { LiveBetHistoryComponent } from './live-bet-history/live-bet-history.component';
import { DeleteBetComponent } from './delete-bet/delete-bet.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import { MyMarketComponent } from './my-market/my-market.component';
import { DownlineReportComponent } from './downline-report/downline-report.component';
import { HomeComponent } from './home/home.component';
import { MessageSettingComponent } from './message-setting/message-setting.component';
import { AuthCheckGuard } from './auth-guard-helper/auth-check.guard';
import { InplayComponent } from './inplay/inplay.component';
import { MatchResultComponent } from './match-result/match-result.component';
import { FancyResultComponent } from './fancy-result/fancy-result.component';
import { MatchRollbackComponent } from './match-rollback/match-rollback.component';
import { FancyResultRollbackComponent } from './fancy-result-rollback/fancy-result-rollback.component';
import { ViewBetsComponent } from './view-bets/view-bets.component';
import { OpenBetsComponent } from './open-bets/open-bets.component';
import { SettledBetsComponent } from './settled-bets/settled-bets.component';
import { SportPlComponent } from './sport-pl/sport-pl.component';
import {  CasinoComponent } from './casino/casino.component';
import { FancyPLComponent } from './fancy-pl/fancy-pl.component';
import { FancyStakeComponent } from './fancy-stake/fancy-stake.component';
import { FancyStakeUserWiseComponent } from './fancy-stake-user-wise/fancy-stake-user-wise.component'; 
import { RentalLoginComponent } from './rental-login/rental-login.component';
import { MaxLoginComponent  } from './max-login/max-login.component';
import { MarketStakeUserWiseComponent } from './market-stake-user-wise/market-stake-user-wise.component';
import { CasinoresultComponent  } from './casinoresult/casinoresult.component';
import { UpdateUserDataComponent } from './update-user-data/update-user-data.component';
import { BetprocesslistComponent } from './betprocesslist/betprocesslist.component';
import { ScoretvComponent } from './scoretv/scoretv.component';
import { SupernovaComponent } from './supernova/supernova.component';
import { NewsUpdateComponent } from './news-update/news-update.component';
import { OpenNewsComponent } from './open-news/open-news.component';
// import { DownlineReportComponent } from './downline-report/downline-report.component';
import { BannerComponent } from './banner/banner.component';
import { LogComponent } from './log/log.component';
import { PlStatementComponent } from './pl-statement/pl-statement.component';
import { SuspeciousBetComponent } from './suspecious-bet/suspecious-bet.component';
import { FraudBetsComponent } from './fraud-bets/fraud-bets.component';
import { WalletDepositMethodComponent } from './wallet-deposit-method/wallet-deposit-method.component';
import { CapaignListComponent } from './campaign/capaign-list/capaign-list.component';
import { WalletUserListComponent } from './wallet/wallet-user-list/wallet-user-list.component';
import { WalletRoleComponent } from './wallet/wallet-role/wallet-role.component';
import { WalletWithdrawMethodComponent } from './wallet/wallet-withdraw-method/wallet-withdraw-method.component';
import { ReferralListComponent } from './referral-list/referral-list.component';
import { AffilateLinkComponent } from './affilate-link/affilate-link.component';
import { SocialMediaListComponent } from './social-media-list/social-media-list.component';
import { SocialMediaAgentListComponent } from './social-media-agent-list/social-media-agent-list.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  // { path: 'login', component: RentalLoginComponent },
  // { path: 'login', component: MaxLoginComponent },
  { path: 'campaign-list', component: CapaignListComponent, canActivate: [AuthCheckGuard] },
  { path: 'social-media-list', component: SocialMediaListComponent, canActivate: [AuthCheckGuard] },
  { path: 'wallet-user-list', component: WalletUserListComponent, canActivate: [AuthCheckGuard] },
  { path: 'wallet-withdraw-method', component: WalletWithdrawMethodComponent, canActivate: [AuthCheckGuard] },
  { path: 'role', component: WalletRoleComponent, canActivate: [AuthCheckGuard] },
  { path: 'wallet-payment-list', component: WalletDepositMethodComponent, canActivate: [AuthCheckGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthCheckGuard] },
  { path: 'casinoResult', component: CasinoresultComponent, canActivate: [AuthCheckGuard] },
  { path: 'dashboard/:sportName', component: DashboardComponent, canActivate: [AuthCheckGuard] },
  { path: 'user', component: UserDataComponent, canActivate: [AuthCheckGuard] },
  { path: 'home/:sportId/:seriesId', component: HomeComponent, canActivate: [AuthCheckGuard] },
  { path: 'downline-report/:userId/:userTypeId', component: DownlineReportComponent, canActivate: [AuthCheckGuard] },
  { path: 'add-user', component: AddUserComponent, canActivate: [AuthCheckGuard] },
  { path: 'addChild-user/:userid/:userTypeId', component: AddUserComponent, canActivate: [AuthCheckGuard] },
  { path: 'add-agent', component: AddAgentComponent, canActivate: [AuthCheckGuard] },
  { path: 'addChild-agent/:userid/:userTypeId', component: AddAgentComponent, canActivate: [AuthCheckGuard] },
  { path: 'website-setting', component: WebsiteSettingComponent, canActivate: [AuthCheckGuard] },
  { path: 'import-market', component: importMarketComponent, canActivate: [AuthCheckGuard] },
  { path: 'block-market', component: BlockMarketComponent, canActivate: [AuthCheckGuard] },
  { path: 'user-block-market/:userid/:userTypeId', component: BlockMarketComponent, canActivate: [AuthCheckGuard] },
  { path: 'match-detail', component: MatchDetailComponent, canActivate: [AuthCheckGuard] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthCheckGuard] },
  { path: 'online-user', component: OnlineUserComponent, canActivate: [AuthCheckGuard] },
  { path: 'closed-user', component: ClosedUserComponent, canActivate: [AuthCheckGuard] },
  { path: 'account-info', component: AccountInfoComponent, canActivate: [AuthCheckGuard] },
  { path: 'statement', component: AccountStatementComponent, canActivate: [AuthCheckGuard] },
  { path: 'pl-statement', component: PlStatementComponent, canActivate: [AuthCheckGuard] },
  { path: 'statement/:id/:userTypeId', component: AccountStatementComponent, canActivate: [AuthCheckGuard] },
  { path: 'child-statement/:userid', component: AccountStatementComponent, canActivate: [AuthCheckGuard] },
  { path: 'chip-summary', component: ChipSummaryComponent, canActivate: [AuthCheckGuard] },
  { path: 'bet-history', component: BetHistoryComponent, canActivate: [AuthCheckGuard] },
  { path: 'live-bet', component: LiveBetHistoryComponent, canActivate: [AuthCheckGuard] },
  { path: 'delete-bet', component: DeleteBetComponent, canActivate: [AuthCheckGuard] },
  { path: 'profit-loss', component: ProfitLossComponent, canActivate: [AuthCheckGuard] },
  { path: 'my-market', component: MyMarketComponent, canActivate: [AuthCheckGuard] },
  { path: 'downline-report/:userId/:userTypeId', component: DownlineReportComponent, canActivate: [AuthCheckGuard] },
  { path: 'message-setting', component: MessageSettingComponent, canActivate: [AuthCheckGuard] },
  { path: 'inplay', component: InplayComponent, canActivate: [AuthCheckGuard] },
  { path: 'match-result', component: MatchResultComponent, canActivate: [AuthCheckGuard] },
  { path: 'fancy-result', component: FancyResultComponent, canActivate: [AuthCheckGuard] },
  { path: 'match-rollback', component: MatchRollbackComponent, canActivate: [AuthCheckGuard] },
  { path: 'fancy-rollback', component: FancyResultRollbackComponent, canActivate: [AuthCheckGuard] },
  { path: 'viewBet/:matchId/:marketId/:type/:sportName/:seriesName/:matchName', component: ViewBetsComponent, canActivate: [AuthCheckGuard] },
  { path: 'viewBet/:matchId/:marketId/:type/:sportName/:seriesName/:matchName/:marketName/:id', component: ViewBetsComponent, canActivate: [AuthCheckGuard] },
  { path: 'viewBet/:matchId/:marketId/:type/:matchName/:id', component: ViewBetsComponent, canActivate: [AuthCheckGuard] },
  { path: 'open-bet', component: OpenBetsComponent, canActivate: [AuthCheckGuard] },
  { path: 'settled-bet', component: SettledBetsComponent, canActivate: [AuthCheckGuard] },
  { path: 'sport-pl', component: SportPlComponent, canActivate: [AuthCheckGuard] },
  { path: 'casino', component: CasinoComponent, canActivate: [AuthCheckGuard] },
  { path: 'fancypl', component: FancyPLComponent, canActivate: [AuthCheckGuard] },
  { path: 'fancyStake', component: FancyStakeComponent, canActivate: [AuthCheckGuard] },
  { path: 'marketStake', component: MarketStakeUserWiseComponent, canActivate: [AuthCheckGuard] },
  { path: 'updateUserData', component: UpdateUserDataComponent, canActivate: [AuthCheckGuard] },
  { path: 'fancyStakeUser/:matchId/:marketId/:matchName', component: FancyStakeUserWiseComponent, canActivate: [AuthCheckGuard] },
  { path: 'sport-pl/:event_id/:type/:matchId/:sportName/:seriesName/:matchName/:eventName', component: SportPlComponent, canActivate: [AuthCheckGuard] },
  { path: 'sport-pl/:event_id/:type/:matchId/:sportName/:userId', component: SportPlComponent, canActivate: [AuthCheckGuard] },
  { path: 'betprocess',component:BetprocesslistComponent, canActivate: [AuthCheckGuard] },
  { path: 'scoreTv',component:ScoretvComponent, canActivate: [AuthCheckGuard] },
  { path: 'snova/:id/:name/:code',component:SupernovaComponent, canActivate: [AuthCheckGuard] },
  { path: 'news',component:NewsUpdateComponent, canActivate: [AuthCheckGuard] },
  { path: 'openNews',component:OpenNewsComponent, canActivate: [AuthCheckGuard] },
  { path: 'banner',component:BannerComponent, canActivate: [AuthCheckGuard] },
  { path: 'wcoLog',component:LogComponent, canActivate: [AuthCheckGuard] },
  { path: 'susBet',component:SuspeciousBetComponent, canActivate: [AuthCheckGuard] },
  { path: 'fraudBet',component:FraudBetsComponent, canActivate: [AuthCheckGuard] },
  { path: 'reffer-account',component:ReferralListComponent, canActivate: [AuthCheckGuard] },
  { path: 'affilate-account',component:AffilateLinkComponent, canActivate: [AuthCheckGuard] },
  { path: 'social-media-agent',component:SocialMediaAgentListComponent, canActivate: [AuthCheckGuard] },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy',
     
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
