<div class="sidebar collapse navbar-collapse" id="navbarCollapseSidebar">

  <div class="scroll-sidebar scroller">
    <div class="menu-section">
    
      <!-- <h4>Sports</h4> -->
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header (click)="dashboard()">
            <a><img src="assets/images/icon/home.png"> <span>Home</span></a>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel *ngFor="let sports of allSportsList;let i= index">
            <mat-expansion-panel-header (click)="getSeriesList(sports.sport_id)" *ngIf="sports.sport_id == '4' || sports.sport_id == '2' || sports.sport_id == '1'">
              <a><img src="assets/images/icon/{{sports.sport_id}}.png">
                <span>{{sports.name}}</span></a>
            </mat-expansion-panel-header>
            <ul class="submenu-list">
              <li *ngFor="let series of allSeriesList;let j= index"
                (click)="goToDashboard(series.sport_id,series.series_id)">
                <a> <span>{{series.name}}</span></a>
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      <h4>Quick Links</h4>

    <mat-accordion>
   
      <mat-expansion-panel>
        <mat-expansion-panel-header >
          <a><img src="assets/images/icon/report.svg"> <span>Reports</span></a>
        </mat-expansion-panel-header>
        <ul class="menu-list">
          <li><a class="dropdown-item" (click)="accountStatement()"><img
                src="assets/images/icon/statement.png"> Statements</a></li>
        </ul>
      </mat-expansion-panel>


      
      




    </mat-accordion>
  </div>
</div>