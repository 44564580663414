<app-header></app-header>
<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="online-user inner-content">
                <div class="page-title">
                    <h3>Online User</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div class="filterDiv">
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                <div class="filter-btn text-start">
                                    <ul>
                                        <li><button type="button" class="btn btn-danger" (click)="allLogOut()"><i class="fa fa-sign-out"></i>
                                                All User Logout</button></li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="report-table">
                    <table datatable class="table">
                        <thead>
                            <tr>
                                <th><input type="checkbox"></th>
                                <th>S.No.</th>
                                <th>UserName</th>
                                <th>Login Time</th>
                                <th>Status</th>
                                <th>IP Address</th>
                                <th>Browser Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let onlineuser of onlineUserDetails| paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: total_items } ;let i = index;">
                                <td><input type="checkbox"></td>
                                <td>{{currentPage != 1 ?(currentPage-1)*itemsPerPage+i+1 : i+1}}</td>
                                <td>{{onlineuser.user_name}}</td>
                                <td>{{(onlineuser?.login_time | date :'dd-MM-yyyy hh:mm:ss aa')}}</td>
                                <td [ngClass]="{'red':onlineuser.is_online == 0 ,'green':onlineuser.is_online != 0}">{{onlineuser.is_online == 0 ? 'Logout' : 'Login'}}</td>
                                <td>{{onlineuser?.ip_address}}</td>
                                <td>
                                    <span>ISP:{{onlineuser?.geolocation?.isp}}/</span>
                                    <span>Lat.:{{onlineuser?.geolocation?.lat}}/</span>
                                    <span>Long:{{onlineuser?.geolocation?.long}}/</span>
                                    <span>City :{{onlineuser?.geolocation?.city}}/</span>
                                    <span>Dist.:{{onlineuser?.geolocation?.district}}/</span>
                                    <span>Zip:{{onlineuser?.geolocation?.zipcode}}/</span>
                                    <span>State:{{onlineuser?.geolocation?.state}}/</span>
                                    <span>Country{{onlineuser?.geolocation?.country}}</span>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="report-bottom-paginate">
                        <pagination-controls (pageChange)="pageChange($event)"
                        class="user-list-pagination" ></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>