import { Component, OnInit, TemplateRef, ElementRef, ViewChild, Output, EventEmitter,ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../../services/users.service';
import { CookieService } from 'ngx-cookie-service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SportService } from '../../services/sport.service';
import Swal from 'sweetalert2';
import { WalletService } from '../../services/wallet.service';
import { json } from 'express';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from '../../services/login.service';
import { SocketServiceService } from '../../services/socket-service.service';
import { environment } from '../../../environments/environment';
import { AppValidationService } from '../../app-validation/app-validation.service';
//import { io, Socket } from 'socket.io-client';

import { WebsiteSettingService } from '../../services/website-setting.service'
declare var $: any;
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { PreviousRouteService } from '../../services/previous-route.service';
import { ValidatorControls } from '../../app-validation/validation-controls.directive';
import { JsonpClientBackend } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';
@Component({
  selector: 'app-wallet-withdraw-method',
  templateUrl: './wallet-withdraw-method.component.html',
  styleUrls: ['./wallet-withdraw-method.component.scss']
})
export class WalletWithdrawMethodComponent implements OnInit {

  modalRef: BsModalRef;
  createPaymentForm: FormGroup;
  submited:any=false;
  adminDetails:any;
  QRImage:any;
  image:any;
  Qrfile:any;
  urlImage:any;
  allPaymentMethod:any;
  websiteList:any;
  fileToUpload:any;
  credentailArr:any=[{key:'',value:''}];
  public Base_Url = environment['adminServerUrl'];
  constructor(
    private modalService: BsModalService, private sport: SportService,private wallet:WalletService,
    private previousRouteService: PreviousRouteService,public clipboardService: ClipboardService,
    private websiteSettingService: WebsiteSettingService,private cdRef: ChangeDetectorRef,
    private fb: FormBuilder, private toastr: ToastrService, private router: Router, private route: ActivatedRoute,
    private loginService: LoginService, private usersService: UsersService, private cookie: CookieService, private locationBack: Location,
    private appValidationService: AppValidationService, private socketService: SocketServiceService,
 
  ) {
    
   }

  ngOnInit(): void {
    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    this.createPaymentForm = this.fb.group({
      "name": ["", Validators.required],
      "image": ["", Validators.required],
      "type": ["", Validators.required],
      "min_amount": ["", Validators.required],
      "max_amount": ["", Validators.required],
      
    });
    this.getPayment();
  }
  openModalPaymentMethod(paymentMethod: TemplateRef<any>) {
   //this.createPaymentForm.reset();
    this.urlImage="";
    this.QRImage="";
    this.modalRef = this.modalService.show(
      paymentMethod,
      Object.assign({}, { class: 'comissionSetting-modal modal-lg' })
    );
  }

  processImageFile(event: any)
  {
    this.image = event.target.files[0];
    console.log(this.image)
    var reader = new FileReader();
		reader.readAsDataURL(this.image);
	
		reader.onload = (_event) => {
			
			this.urlImage = reader.result; 
   
		}
  }

  getPayment()
  {
    let parms={
      user_id:this.adminDetails._id
    }
    this.wallet.agentwalletWithdraw(parms).subscribe((res:any) => {
      console.log(res)
   
      if(res.status == true){
        this.allPaymentMethod=res.data;
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  }


  subMitForm()
  {
  this.submited=true;
if(this.createPaymentForm.valid)
   {
    this.submited=false;
    const formData: FormData = new FormData();
    formData.append('image',  this.image);
    formData.append('user_id',  this.adminDetails._id);
    formData.append('name',  this.createPaymentForm.controls.name.value);
    formData.append('type', this.createPaymentForm.controls.type.value);
    formData.append('min_amount', this.createPaymentForm.controls.min_amount.value);
    formData.append('max_amount', this.createPaymentForm.controls.max_amount.value);
    this.wallet.create_withdraw_method(formData).subscribe((res:any) => {
      console.log(res)
      if(res.status == true){
        this.getPayment()
        this.toastr.success(res.msg,'',{
          positionClass: 'toast-bottom-right',
          timeOut:1000
         });
         this.createPaymentForm.reset();
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
    });
   }
  }

  updatePayment(event:any,type:any)
  {
    
    let visible=true;
    let deleted_status=false;
    if(event?.visible)
    {
      visible=false;
    }
    if(type==1){
      if(confirm("Are You Sure Remove It")){
        deleted_status=true;
        visible=false;
      }
      else{
        return;
      }
    }
    let parms={
      _id:event?._id,
      visible:visible,
      deleted:deleted_status
    }
    this.wallet.update_payment_agent_withdraw_method(parms).subscribe((res:any) => {
      console.log(res)
   
      if(res.status == true){
        this.getPayment()
        this.toastr.info(res.msg,'',{
          timeOut: 10000,
        });
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  }
  
}
