<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->
<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="addUser inner-content">
                <div class="page-title">
                    <h3>Add User Details</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>
                <div class="form-div user-form">
                    <form [formGroup]="addUserFrom" (ngSubmit)="addUser()">
                        <div class="row">
                            <div class="col-md-8 col-12 col pl-0">
                                <div class="col-inner">
                                    <div class="col-title">
                                        <h4>Personal Details</h4>
                                    </div>
                                    <div class="field-list row">
                                        <div class="col-md-6 col-12 col">
                                            <div class="form-group">
                                                <label class="form-label">Parent Name</label>
                                                <input type="text" placeholder="" class="form-control"
                                                    formControlName="parent_user_name" readonly />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 col">
                                            <div class="form-group">
                                                <label class="form-label">Client Name</label>
                                                <input type="text" placeholder="" class="form-control red-tooltip"
                                                    ngbTooltip="{{addUserFrom.controls.name.errors  |DisplayErrorMessage}}"
                                                    #clientName="ngbTooltip" triggers="manual" [autoClose]="false"
                                                    (mouseenter)=" clientName.open()" (key)="clientName.open()"
                                                    (blur)="clientName.open()"
                                                    (keyup)="setUserTypeName($event.target.value)"
                                                    formControlName="name" />
                                                <!-- <span *ngIf="addUserFrom.controls.name.errors && addUserFrom.get('name').touched" class="red-alert-message">
                                                    {{addUserFrom.controls.name.errors |DisplayErrorMessage}}
                                                </span> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 col">
                                            <div class="form-group">
                                                <label class="form-label">Username</label>
                                                <input type="text" placeholder="" class="form-control"
                                                    ngbTooltip="{{addUserFrom.controls.user_name.errors |DisplayErrorMessage}}"
                                                    (change)="checkUserName($event.target.value)"
                                                    formControlName="user_name" #userNameTooltip="ngbTooltip"
                                                    triggers="manual" [autoClose]="false"
                                                    (mouseenter)=" userNameTooltip.open()"
                                                    (key)="userNameTooltip.open()" (blur)="userNameTooltip.open()" />
                                                <!-- <span *ngIf="addUserFrom.controls.user_name.errors && addUserFrom.get('user_name').touched" class="red-alert-message">
                                                    {{addUserFrom.controls.user_name.errors |DisplayErrorMessage}}
                                                </span> -->
                                                <!-- <p *ngIf="isUserNameExist" class="red-alert-message"> user name already exist </p> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 col">
                                            <div class="form-group inner-addon right-addon">
                                                <label class="form-label">Password</label>
                                                <div class="input-group">
                                                    <input [type]="showPassword ? 'text' : 'password'"
                                                        id="password-field" pwd-control formControlName="password"
                                                        placeholder="" class="form-control"
                                                        ngbTooltip="{{addUserFrom.controls.password.errors |DisplayErrorMessage}}"
                                                        #passwordTooltip="ngbTooltip" triggers="manual"
                                                        [autoClose]="false" (mouseenter)=" passwordTooltip.open()"
                                                        (key)="passwordTooltip.open()"
                                                        (blur)="passwordTooltip.open()" />
                                                    <div>
                                                        <i alt="show" class="fa fa-eye eye-show"
                                                            (click)="showPassword = !showPassword"
                                                            [class.hide]="showPassword"></i>
                                                        <i alt="hide" class="fa fa-eye-slash eye-hide"
                                                            (click)="showPassword = !showPassword"
                                                            [class.hide]="!showPassword"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-12 col" *ngIf="loggedInUserTypeId == 0">
                                            <div class="form-group">
                                                <label class="form-label">Domain List</label>
                                                <ng-select [items]="websiteList" (change)="selectedDomain($event)"
                                                    bindLabel="host_name" bindValue="_id" placeholder="Select Domain"
                                                    formControlName='domain'
                                                    ngbTooltip="{{addUserFrom.controls.domain.errors |DisplayErrorMessage}}"
                                                    #domainTooltip="ngbTooltip" triggers="manual" [autoClose]="false"
                                                    (mouseenter)=" domainTooltip.open()" (key)="domainTooltip.open()"
                                                    (blur)="domainTooltip.open()">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 col" style="padding-top: 15px;">
                                            <div class="form-group">
                                                <label class="form-label">Point</label>
                                                <input type="text" *ngIf="userDetail?.user_type_id != 0" placeholder=""
                                                    class="form-control" formControlName="point"
                                                    #pointTooltip="ngbTooltip" triggers="manual" [autoClose]="false"
                                                    (mouseenter)=" pointTooltip.open()" (key)="pointTooltip.open()"
                                                    (blur)="pointTooltip.open()"
                                                    ngbTooltip="{{addUserFrom.controls.point.errors |DisplayErrorMessage}}"
                                                    [readonly]="userDetail?.user_type_id != 0" />
                                                <!-- <span *ngIf="addUserFrom.controls.point.errors && addUserFrom.get('point').touched" class="red-alert-message">
                                                    {{addUserFrom.controls.point.errors |DisplayErrorMessage}}
                                                </span> -->
                                                <select *ngIf="userDetail?.user_type_id == 0" class="form-control"
                                                    formControlName="point" #pointTooltip="ngbTooltip" triggers="manual"
                                                    [autoClose]="false" (mouseenter)=" pointTooltip.open()"
                                                    (key)="pointTooltip.open()" (blur)="pointTooltip.open()"
                                                    ngbTooltip="{{addUserFrom.controls.point.errors |DisplayErrorMessage}}">
                                                    <option value="1">1</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 col">
                                            <div class="form-group">
                                                <label class="form-label">Exposure Limit</label>
                                                <input type="text" value="-1" placeholder="" [autoClose]="false"
                                                    class="form-control"
                                                    ngbTooltip="{{addUserFrom.controls.exposure_limit.errors |DisplayErrorMessage}}"
                                                    formControlName="exposure_limit" #exposureTooltip="ngbTooltip"
                                                    triggers="manual" (mouseenter)="exposureTooltip.open()"
                                                    (key)="exposureTooltip.open()" (blur)="exposureTooltip.open()" />
                                                <span>[ Note: Point (If no limit then enter -1) ]</span><br>
                                                <!-- <span *ngIf="addUserFrom.controls.exposure_limit.errors  && addUserFrom.get('exposure_limit').touched" class="red-alert-message">
                                                    {{addUserFrom.controls.exposure_limit.errors |DisplayErrorMessage}}
                                                </span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="sports-setting-main-section" formArrayName="sports_settings">
                                <div class="market-session-setting" *ngFor="let sportSetting of sportsSettingsFormArr.controls; let j=index">

                                    <div class="accordion-item"  #accordionItem [formGroupName]="j" (click)="accordion(userSportPartnerShip[j]?.sportId?.sport_id);sId == userSportPartnerShip[j]?.sportId?.sport_id ? hide(j) : '';">

                                        <h2> {{sportSetting?.sportId?.name}} Settings </h2>

                                        <h4 class="accordion-header" id="heading-{{j}}" >
                                            <a class="accordion-title" type="button" data-bs-toggle="collapse" aria-expanded="true" data-bs-target="#collapse{{j}}" aria-controls="collapse{{j}}">
                                                {{userSportPartnerShip[j]?.sportId?.name}} Settings  
                                            </a>
                                        </h4>
                                        <div id="collapse{{j}}" class="accordion-collapse collapse show" aria-labelledby="heading-{{j}}"  *ngIf="hiddenpass[j]">
                                            <div class="accordion-body">
                                                
                                                <div class="detail-row">
                                                    <div class="detail-row-heading">
                                                        <h4>Market Setting</h4>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Market Fresh Delay</label>
                                                                    <input type="number" formControlName="market_fresh_delay"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Second]</span><br>
                                                                    <span *ngIf="sportSetting.controls.market_fresh_delay.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.market_fresh_delay.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Market Min. Stack</label>
                                                                    <input type="number" formControlName="market_min_stack"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point]</span><br>
                                                                    <span *ngIf="sportSetting.controls.market_min_stack.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.market_min_stack.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Market Max. Stack</label>
                                                                    <input type="number" formControlName="market_max_stack"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point(if no limit then enter -1)]</span><br>
                                                                    <span *ngIf="sportSetting.controls.market_max_stack.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.market_max_stack.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Market Max Loss</label>
                                                                    <input type="number" formControlName="market_max_loss"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point(if no limit then enter -1)]</span><br>
                                                                    <span *ngIf="sportSetting.controls.market_max_loss.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.market_max_loss.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Market Max Profit</label>
                                                                    <input type="number" formControlName="market_max_profit"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point(if no limit then enter -1)]</span><br>
                                                                    <span *ngIf="sportSetting.controls.market_max_profit.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.market_max_profit.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Market Rate Limit</label>
                                                                    <input type="number" formControlName="market_rate_limit"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point(if no limit then enter -1)]</span><br>
                                                                    <span *ngIf="sportSetting.controls.market_rate_limit.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.market_rate_limit.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Market Rate Limit Point</label>
                                                                    <input type="number"
                                                                        formControlName="market_rate_limit_point" placeholder=""
                                                                        class="form-control" />
                                                                    <span>[Note: Point(if no limit then enter -1)]</span><br>
                                                                    <span *ngIf="sportSetting.controls.market_rate_limit_point.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.market_rate_limit_point.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Point Place Before In-Play</label>
                                                                    <input type="number"
                                                                        formControlName="point_place_before_in_play"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point(if no limit then enter -1)]</span><br>
                                                                    <span *ngIf="sportSetting.controls.point_place_before_in_play.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.point_place_before_in_play.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="detail-row"
                                                    *ngIf="userSportPartnerShip[j]?.sportId?.sport_id == '4'">
                                                    <div class="detail-row-heading">
                                                        <h4>Session Setting</h4>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Session Fresh Delay</label>
                                                                    <input type="number" formControlName="session_fresh_delay"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Second]</span><br>
                                                                    <span *ngIf="sportSetting.controls.session_fresh_delay.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.session_fresh_delay.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Session Min. Stack</label>
                                                                    <input type="number" formControlName="session_min_stack"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point]</span><br>
                                                                    <span *ngIf="sportSetting.controls.session_min_stack.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.session_min_stack.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Session Max. Stack</label>
                                                                    <input type="number" formControlName="session_max_stack"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point(if no limit then enter -1)]</span>
                                                                    <br>
                                                                    <span *ngIf="sportSetting.controls.session_max_stack.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.session_max_stack.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Session Max Loss</label>
                                                                    <input type="number" formControlName="session_max_loss"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point(if no limit then enter -1)]</span><br>
                                                                    <span *ngIf="sportSetting.controls.session_max_loss.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.session_max_loss.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Session Max Profit</label>
                                                                    <input type="number" formControlName="session_max_profit"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point(if no limit then enter -1)]</span><br>
                                                                    <span *ngIf="sportSetting.controls.session_max_profit.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.session_max_profit.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-4 col-12">
                                                            <div class="col-inner">
                                                                <div class="form-group">
                                                                    <label class="form-label">Session Per Rate Max Stack</label>
                                                                    <input type="number"
                                                                        formControlName="session_per_rate_max_stack"
                                                                        placeholder="" class="form-control" />
                                                                    <span>[Note: Point(if no limit then enter -1)]</span><br>
                                                                    <span *ngIf="sportSetting.controls.session_per_rate_max_stack.errors" class="red-alert-message">
                                                                        {{sportSetting.controls.session_per_rate_max_stack.errors |DisplayErrorMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div> -->




                            <!-- <div class="sports-setting-main-section" formArrayName="sports_settings">
                                <div class="market-session-setting" *ngFor="let sportSetting of sportsSettingsFormArr.controls; let j=index">


                            <div class="accordion-item">
                                <h4 class="accordion-header" id="headingTwo">
                                    <a class="accordion-title" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-controls="collapseTwo">
                                        Market Setting
                                    </a>
                                </h4>
                                <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Market Fresh Delay</label>
                                                        <input type="text" value="5" placeholder="" class="form-control" formControlName="market_fresh_delay"/>
                                                        <span>[ Second ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Market Min. Stack</label>
                                                        <input type="text" value="1000" placeholder="" class="form-control" formControlName="market_min_stack" />
                                                        <span>[ Note: Point ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Market Max. Stack</label>
                                                        <input type="text" value="" placeholder="" class="form-control" formControlName="market_max_stack"/>
                                                        <span>[ Note: Point (if no limit then enter -1) ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Market Max Loss</label>
                                                        <input type="text" value="" placeholder="" class="form-control" formControlName="market_max_loss"/>
                                                        <span>[ Note: Point (if no limit then enter -1) ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Market Max Profit</label>
                                                        <input type="text" value="" placeholder="" class="form-control" formControlName="market_max_profit"/>
                                                        <span>[ Note: Point (if no limit then enter -1) ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Market Rate Limit</label>
                                                        <input type="text" value="" placeholder="" class="form-control" formControlName="market_rate_limit"/>
                                                        <span>[ Note: Point (if no limit then enter -1) ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Market Rate Limit Point</label>
                                                        <input type="text" value="" placeholder="" class="form-control"formControlName="market_rate_limit_point" />
                                                        <span>[ Note: Point (if no limit then enter -1) ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Point Place Before In-Play</label>
                                                        <input type="text" value="" placeholder="" class="form-control" formControlName="point_place_before_in_play"/>
                                                        <span>[ Note: Point (if no limit then enter -1) ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h4 class="accordion-header" id="headingThree">
                                    <a class="accordion-title" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-controls="collapseThree">
                                        Session Setting 
                                    </a>
                                </h4>
                                <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Session Fresh Delay</label>
                                                        <input type="text" value="0" placeholder="" class="form-control" formControlName="session_fresh_delay"/>
                                                        <span>[ Second ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Session Min. Stack</label>
                                                        <input type="text" value="1000" placeholder="" class="form-control" formControlName="session_min_stack"/>
                                                        <span>[ Note: Point ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Session Max. Stack</label>
                                                        <input type="text" value="" placeholder="" class="form-control" formControlName="session_max_stack"/>
                                                        <span>[ Note: Point (if no limit then enter -1) ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Session Max Loss</label>
                                                        <input type="text" value="" placeholder="" class="form-control" formControlName="session_max_loss"/>
                                                        <span>[ Note: Point (if no limit then enter -1) ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Session Max Profit</label>
                                                        <input type="text" value="" placeholder="" class="form-control" formControlName="session_max_profit"/>
                                                        <span>[ Note: Point (if no limit then enter -1) ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-12">
                                                <div class="col-inner">
                                                    <div class="form-group">
                                                        <label class="form-label">Session Per Rate Max Stack</label>
                                                        <input type="text" value="" placeholder="" class="form-control" formControlName="session_per_rate_max_stack"/>
                                                        <span>[ Note: Point (if no limit then enter -1) ]</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> -->



                            <div class="col-md-4 col-12 col">
                                <div class="col-inner">
                                    <div class="col-title">
                                        <h4>Commission Setting</h4>
                                    </div>
                                    <div class="field-list">
                                        <div class="form-group">
                                            <label class="form-label">Market Commission</label>
                                            <input type="text" value="0" placeholder="" class="form-control"
                                                formControlName="match_commission" #marketTooltip="ngbTooltip"
                                                triggers="manual" [autoClose]="false"
                                                (mouseenter)="marketTooltip.open()" (key)="marketTooltip.open()"
                                                (blur)="marketTooltip.open()"
                                                ngbTooltip="{{addUserFrom.controls.match_commission.errors |DisplayErrorMessage}}" />
                                            <!-- <span *ngIf="addUserFrom.controls.match_commission.errors && addUserFrom.get('match_commission').touched" class="red-alert-message">
                                                {{addUserFrom.controls.match_commission.errors |DisplayErrorMessage}}
                                            </span> -->
                                        </div>

                                      <!--  <div class="form-group">
                                            <label class="form-label">Market Commission Loss</label>
                                            <input type="text" value="0" placeholder="" class="form-control"
                                                formControlName="match_commission_loss" #marketTooltip="ngbTooltip"
                                                triggers="manual" [autoClose]="false"
                                                (mouseenter)="marketTooltip.open()" (key)="marketTooltip.open()"
                                                (blur)="marketTooltip.open()"
                                                ngbTooltip="{{addUserFrom.controls.match_commission_loss.errors |DisplayErrorMessage}}" />
                                           
                                        </div> -->
                                        <div class="form-group">
                                            <label class="form-label">Session Commission </label>
                                            <input type="text" value="0" placeholder="" #sessionTooltip="ngbTooltip"
                                                triggers="manual" [autoClose]="false"
                                                (mouseenter)="sessionTooltip.open()" (key)="sessionTooltip.open()"
                                                (blur)="sessionTooltip.open()" class="form-control"
                                                formControlName="session_commission"
                                                ngbTooltip="{{addUserFrom.controls.session_commission.errors |DisplayErrorMessage}}" />
                                            <!-- <span *ngIf="addUserFrom.controls.session_commission.errors && addUserFrom.get('match_commission').touched" class="red-alert-message" >
                                                {{addUserFrom.controls.session_commission.errors |DisplayErrorMessage}}
                                            </span> -->
                                        </div>

                                      <!--  <div class="form-group">
                                            <label class="form-label">Session Commission Loss</label>
                                            <input type="text" value="0" placeholder="" #sessionTooltip="ngbTooltip"
                                                triggers="manual" [autoClose]="false"
                                                (mouseenter)="sessionTooltip.open()" (key)="sessionTooltip.open()"
                                                (blur)="sessionTooltip.open()" class="form-control"
                                                formControlName="session_commission_loss"
                                                ngbTooltip="{{addUserFrom.controls.session_commission_loss.errors |DisplayErrorMessage}}" />
                                           
                                        </div> -->
                                    </div>
                                </div>
                                <!-- </div>

                            <div class="col-md-4 col-12 col pr-0"> -->
                                <div class="col-inner">
                                    <div class="col-title">
                                        <h4>Permissions</h4>
                                    </div>
                                    <div class="field-list">
                                        <div class="check-div"
                                            *ngFor="let permission of userPermissionsList ; let ind=index">
                                            <div class="form-check" *ngIf="permission.is_allow">
                                                <input class="form-check-input" type="checkbox"
                                                    (change)='checkedPermissions($event,ind)'
                                                    id="flexCheckDefault+{{ind}}" checked>
                                                <label class="form-check-label"
                                                    for="flexCheckDefault+{{ind}}">{{permission.name}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="btn-col text-right">
                                    <button type="submit" class="btn btn-success">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>