<app-header></app-header>
<app-sidebar></app-sidebar>

<main class="full-wrap dashboard-page">
  <div class="main-div">
      <div class="left-section">
          <div class="row main-row">
            <label>Heading :</label>
            <textarea name="match.match_tv_url" 
            class="form-control"  autocomplete="off"  rows="1" cols="100"
            [(ngModel)]="heading" ></textarea>
            <label>Description :</label>
            <textarea name="match.match_scoreboard_url" 
            class="form-control"  autocomplete="off"  rows="4" cols="100"
            [(ngModel)]="description"></textarea>
            <div class="col-md-1" style="width: 100%;margin-top: 15px;">
              <div class="col-inner text-end">
                  <div class="btn-list">
                    <button type="button" class="btn btn-danger" style="margin-right: 17px;" (click)="deleteNews()">Delete</button>
                      <button type="button" class="btn btn-success" style="margin-right: 17px;" (click)="updateNews()">Update</button>
                  </div>
              </div>
          </div>
             
          </div>
      </div>
      
  </div>
  <!-- <app-footer></app-footer> -->
</main>