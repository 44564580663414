<app-header></app-header>
<app-sidebar></app-sidebar>



<main>
    <div class="full-wrap" id="" #contentToConvert>
        <div class="report-page">
            <div class="user-list inner-content">
                <div class="page-title">
                    <h3>Social Media Agent List</h3>
                    <div class="nav-list">
                        <div class="hierarchy">
                           
                        </div>
                    </div>
                    <div class="breadcrumb">
                        <a  class="btn btn-secondary back-btn"><i class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div _ngcontent-serverapp-c232="" class="filterDiv">
                    <div _ngcontent-serverapp-c232="" class="row">
                       <div _ngcontent-serverapp-c232="" class="col-md-12">
                          <form _ngcontent-serverapp-c232="" novalidate="" class="ng-untouched ng-pristine ng-valid">
                             <div _ngcontent-serverapp-c232="" class="row form-field">
                               
                                <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 87.5%;">
                                   <div _ngcontent-serverapp-c232="" class="col-inner">
                                      <div _ngcontent-serverapp-c232="" class="form-group mb-0">
                                         <input _ngcontent-serverapp-c232="" name="searchUser" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="text" autocomplete="off" placeholder="Search User Name..." class="search-input dropdown-toggle form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="searchUser">
                                         <div _ngcontent-serverapp-c232="" aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                            <a _ngcontent-serverapp-c232="" class="dropdown-item ng-star-inserted">
                                               <div _ngcontent-serverapp-c232="" class="event-info">
                                                  <div _ngcontent-serverapp-c232="" class="event-format-strip">
                                                     <div _ngcontent-serverapp-c232="" class="event-team">
                                                        <div _ngcontent-serverapp-c232="" class="team-name">user1 </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </a>
                                           
                                         </div>
                                        
                                      </div>
                                   </div>
                                </div>
                                <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 10.499999995%;">
                                   <div _ngcontent-serverapp-c232="" class="col-inner text-end">
                                      <div _ngcontent-serverapp-c232="" class="btn-list">
                                        <button (click)="openModaladdUser(addUser)" type="button" class="btn btn-success">
                                            Add Social Media Agent</button>
                                        </div>
                                   </div>
                                </div>
                             </div>
                          </form>
                       </div>
                    </div>
                 </div>

              

                <div class="report-table">
                    

                    <table class="table">
                        <thead>
                            <tr>
                               
                                <th>S No.
                                </th>
                                <th>Role
                                   
                                </th>
                                <th>Name
                                    
                                </th>
                                <th>Status
                                    
                                </th>
                                
                                <th>Action</th>
                                
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let u of agentList;let i=index;">
                                <td>{{i+1}}</td>
                                <td>{{u?.role_user_name}}</td>
                                <td>{{u?.name}}</td>
                                <td>
                                   
                                    <img *ngIf="u?.self_lock_user==0 && u?.parent_lock_user == 0"  src="assets/icon/active.png"  height="20px" width="20px">
                                    <img *ngIf="u?.self_lock_user==1 || u?.parent_lock_user == 1"  src="assets/icon/remove.jpg" height="20px" width="20px">
                           
                                </td>
                                <td>
                                    <a _ngcontent-serverapp-c163="" (click)="openModaleditUser(editUser,u)" class="td-btn btn-secondary ng-star-inserted">Edit</a>
                                    <img *ngIf="u?.self_lock_user==1 || u?.parent_lock_user == 1" src="assets/icon/active.png"  (click)="lockAccountOfUserAndTheirChilds(u._id, u.self_lock_user)" height="20px" width="20px">
                                    <img *ngIf="u?.self_lock_user==0 && u?.parent_lock_user == 0" (click)="lockAccountOfUserAndTheirChilds(u._id, u.self_lock_user)" src="assets/icon/remove.jpg" height="20px" width="20px">
                                    <img  src="assets/icon/delete.png" (click)="closeAccount(u)" height="20px" width="20px">
                                </td>
                               
                            </tr>
                        </tbody>
                      
                    </table>
                </div>

                <div class="report-bottom-paginate">
                    <pagination-controls  class="user-list-pagination">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</main>

<ng-template #addUser>
    <div class="modal-header">
        <h4 class="modal-title">Add User</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form [formGroup]='createUserForm'>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
           <div class="row">
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Role <span style="color:red">*</span></label>
                    <select name="role_user_name" formControlName="role_user_name"  class="password-box form-attribute">
                        <option>Select Role</option>
                        <option *ngFor="let r of roleList" value="{{r?.name}}-{{r?._id}}">{{r?.name}}</option>
                       
                    </select>
                    
                         <span *ngIf="createUserForm.controls.role_user_name.errors && submited "
                         class="red-alert-message">
                        role is required.!
                     </span>
                </div>
            </div>
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">User Name <span style="color:red">*</span></label>
                    <input type="text"  placeholder="Enter User Name"  formControlName="user_name"
                         class="password-box form-attribute">
                         <span *ngIf="createUserForm.controls.user_name.errors && submited"
                         class="red-alert-message">
                        user name is required.!
                     </span>
                </div>
            </div>
            
           </div>
           <div class="row">
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Password <span style="color:red">*</span></label>
                    <input type="password"  formControlName="password"  class="password-box form-attribute"
                        >
                         <span *ngIf="createUserForm.controls.password.errors && submited"
                         class="red-alert-message">
                        password is required.!
                     </span>
                </div>
            </div>
           <div class="col col-sm-6">
            <div class="form-list">
                <label class="form-heading">Confirm Password <span style="color:red">*</span></label>
                    <input type="password"  formControlName="confirm_password" class="password-box form-attribute"
                         >
                         <span *ngIf="confirmPaaword && submited"
                         class="red-alert-message">
                       confirm password is required.!
                     </span>
                     <p style="color:red">{{message}}</p>
            </div>
        </div>
       </div>
     
    
            
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="subMitForm()" >Submit</button> &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
    </form>
</ng-template>


<ng-template #editUser>
    <div class="modal-header">
        <h4 class="modal-title">Edit User</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form [formGroup]='editUserForm'>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
           <div class="row">
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Role <span style="color:red">*</span></label>
                    <select name="role_user_name" formControlName="role_user_name"  class="password-box form-attribute">
                        <option>Select Role</option>
                        <option *ngFor="let r of roleList" value="{{r?._id}}" selected="u?.role_user_id===r?._id">{{r?.name}}</option>
                       
                    </select>
                    
                         <span *ngIf="editUserForm.controls.role_user_name.errors && submited "
                         class="red-alert-message">
                        role is required.!
                     </span>
                </div>
            </div>
           
            
           </div>
          
     
    
            
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="subMitEditForm()" >Submit</button> &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
    </form>
</ng-template>

