import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../services/users.service';
import { SocketServiceService } from '../services/socket-service.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderComponent } from '../header/header.component';
import { LoginService } from '../services/login.service'
import * as moment from 'moment';
@Component({
  selector: 'app-online-user',
  templateUrl: './online-user.component.html',
  styleUrls: ['./online-user.component.scss'],
  providers: [HeaderComponent],
})
export class OnlineUserComponent implements OnInit {
  login_user_id: string;
  onlineUserDetails: any;
  itemsPerPage: number = 50;
  currentPage: number = 1;
  total_items: number = 0;
  usersListReqPageQuery: any;
  moment: any = moment;
  isSocket;
  constructor(private locationBack: Location, private head: HeaderComponent,private usersService: UsersService,private router: Router,private loginService: LoginService,
    private toastr: ToastrService, private cookie: CookieService, private socketService: SocketServiceService) { }

  async ngOnInit() {
    // await this.socketService.setUpSocketConnection();
    this.isSocket = this.cookie.get('is_socket');
    this.login_user_id = localStorage.getItem('userId');
    this.getOnlineUsersList();
  }

  goToBack() {
    this.locationBack.back();
  }

  // socketOnEvent(eventName, callback) {
  //   this.socketService.socket.on(eventName, data => callback(data));
  // }

  // socketEmitEvent(eventName, data) {
  //   this.socketService.socket.emit(eventName, data);
  // }

  // socketListenersUser() {
  //   this.socketOnEvent(`getOnlineUsersList`, data => {
  //     if (data.status == true) {
  //       this.onlineUserDetails = data.data;
  //     } else {
  //       this.toastr.error(data.msg,'',{
  //         timeOut: 10000,
  //       });
  //     }
  //   });
  // }

  getOnlineUsersList() {
    let data = {
      // search: '',
      limit: this.itemsPerPage,
      page: this.currentPage
    }
      this.usersService.getOnlineUsersList(data).subscribe(res => {
        if(res.status == true){
        this.onlineUserDetails = res.data
        this.total_items = res.metadata.total
        this.currentPage = res.metadata.page
        } else {
          if(res.logout == true){
            this.head.logoutUser();
          }
         }
      })
    
  }

  setSelectedOption(selectedOption) {
    this.itemsPerPage = parseInt(selectedOption);
    this.usersListReqPageQuery = {
      page: this.currentPage,
      limit: this.itemsPerPage,
    };
    this.getOnlineUsersList();
  }

  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.usersListReqPageQuery = {
      page: this.currentPage,
      limit: this.itemsPerPage,
    };
    this.getOnlineUsersList();
  }
  
  allLogOut(){
    let data = {}
    this.usersService.logoutAll(data).subscribe((res) => {
      if(res.status){
        this.cookie.delete('userId');
        // this.cookie.delete('accessToken');
        // this.cookie.delete('refreshToken');
        this.cookie.delete('is_socket');
        this.cookie.delete('transaction-password');
        this.cookie.delete('transaction_password_timeout')
        localStorage.removeItem("adminDetails");
        //this.loginService.clearLocalStorage()
        this.loginService.clearLocalStorage()
        this.router.navigate(['login'])
        window.location.replace('login');
      }else {
        this.toastr.error(res.msg);
      }
    })
  }
}
