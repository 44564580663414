<app-header></app-header>
<app-sidebar></app-sidebar>

<main class="full-wrap dashboard-page">
  <div class="main-div">
      <div class="left-section">
          <div class="row main-row" *ngFor="let data of allData; let i = index;">
            <label>Heading : {{data.user_type_id == 0 ? 'Admin': 'Agent'}}</label>
            <textarea name="match.match_tv_url" 
            class="form-control"  autocomplete="off"  rows="1" cols="100"
            [(ngModel)]="data.heading" ></textarea>
            <label>Description :</label>
            <textarea name="match.match_scoreboard_url" 
            class="form-control"  autocomplete="off"  rows="4" cols="100"
            [(ngModel)]="data.description"></textarea>
             
          </div>
      </div>
      
  </div>
  <!-- <app-footer></app-footer> -->
</main>