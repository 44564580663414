<app-header></app-header>
<app-sidebar></app-sidebar>
<main>
   <div class="full-wrap" id="" #contentToConvert>
      <div class="report-page">
         <div class="user-list inner-content">
            <div class="page-title">
               <h3>Compaign List</h3>
               <div class="nav-list">
                  <div class="hierarchy">
                  </div>
               </div>
               <div class="breadcrumb">
                  <a  class="btn btn-secondary back-btn"><i class="fa fa-arrow-left"></i>Back</a>
               </div>
            </div>
            <div _ngcontent-serverapp-c232="" class="filterDiv">
               <div _ngcontent-serverapp-c232="" class="row">
                  <div _ngcontent-serverapp-c232="" class="col-md-12">
                     <form _ngcontent-serverapp-c232="" novalidate="" class="ng-untouched ng-pristine ng-valid">
                        <div _ngcontent-serverapp-c232="" class="row form-field">
                           <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 87.5%;">
                              <div _ngcontent-serverapp-c232="" class="col-inner">
                                 <div _ngcontent-serverapp-c232="" class="form-group mb-0">
                                    <input _ngcontent-serverapp-c232="" name="searchUser" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="text" autocomplete="off" placeholder="Search List..." class="search-input dropdown-toggle form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="searchUser">
                                    <div _ngcontent-serverapp-c232="" aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                       <a _ngcontent-serverapp-c232="" class="dropdown-item ng-star-inserted">
                                          <div _ngcontent-serverapp-c232="" class="event-info">
                                             <div _ngcontent-serverapp-c232="" class="event-format-strip">
                                                <div _ngcontent-serverapp-c232="" class="event-team">
                                                   <div _ngcontent-serverapp-c232="" class="team-name">user1 </div>
                                                </div>
                                             </div>
                                          </div>
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 10.499999995%;">
                              <div _ngcontent-serverapp-c232="" class="col-inner text-end">
                                 <div _ngcontent-serverapp-c232="" class="btn-list">
                                    <button (click)="openCampaign(addCampaign)" type="button" class="btn btn-success">
                                    Add Campaign</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
            <div class="report-table">
               <table class="table hide-on-down">
                  <thead>
                     <tr>
                        <th>S No.
                        </th>
                        <th>DateTime
                        </th>
                        <th>Name
                        </th>
                        <th>Promo Code
                        </th>
                        <th>Bonus Amount
                        </th>
                        <th>Status
                        </th>
                        <th>Action</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let c of campaignList;let i=index">
                         <td>{{i+1}}</td>
                         <td>
                           {{c?.expiry_date_time|date:"medium"}}
                          Expiry: {{c?.expiry_date_time|date:"medium"}}
                        </td>
                         <td>{{c?.campaign_name}}</td>
                         <td>{{c?.promo_code}}</td>
                         <td>
                           <span *ngIf="c?.wagering">
                              Type: {{c?.bonus_amount_bonus_type}},
                              Amount:  {{c?.bonus_amount}},
                              Cap Amount: {{c?.bonus_cap_amount}}
                           </span>

                           <span *ngIf="!c?.wagering">
                            Type:  {{c?.payable_amount_bonus_type}},
                            Amount:  {{c?.payable_amount}},
                            Cap Amount:  {{c?.payable_cap_amount}}
                           </span>
                           
                        
                        </td>
                         <td>

                                    <img *ngIf="c?.visible==true" src="assets/icon/active.png"  height="20px" width="20px">
                                    <img *ngIf="c?.visible==false" src="assets/icon/remove.jpg" height="20px" width="20px">
                           
                         </td>
                     
                         <td>
                           <img *ngIf="c?.visible==false" src="assets/icon/active.png"  (click)="activeSubmit(c,true)"  height="20px" width="20px">
                           <img *ngIf="c?.visible==true" (click)="activeSubmit(c,false)" src="assets/icon/remove.jpg" height="20px" width="20px">
                  
                           <img _ngcontent-serverapp-c253="" (click)="editFunctionCampaign(editCampaign,c)" src="assets/icon/edit.png" height="20px" width="20px" class="edit-image">
                         
                         </td>
                     </tr>
                  </tbody>
               </table>
            </div>
            <div class="report-bottom-paginate">
               <pagination-controls  class="user-list-pagination">
               </pagination-controls>
            </div>
         </div>
      </div>
   </div>
</main>
<ng-template #addCampaign>
   <div class="modal-header">
     
      <h4 class="modal-title">Add Compaign</h4>
      <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
   </div>
   <form [formGroup]='createCampaignForm'>
      <div class="modal-body no-scroll-body">
         <div>
            <button [ngClass]="step_select == '1' ? 'tab-rule select' : 'tab-rule'" (click)="step_select='1'">campaign</button>
            <button [ngClass]="step_select == '2' ? 'tab-rule select' : 'tab-rule'" (click)="step_select='2'">rule</button>
         </div>
      <div *ngIf="step_select=='1'" class="body-inner">
        
         <h3>Campaign Details</h3>
         <div class="row">
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Campaign Name <span style="color:red">*</span></label>
                  <input type="text"  placeholder="Enter  Name"  formControlName="campaign_name"
                     class="password-box form-attribute">
                  <span *ngIf="createCampaignForm.controls.campaign_name.errors && submitted"
                     class="red-alert-message">
                  Campaign name is required.!
                  </span>
               </div>
            </div>
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Promo Code <span style="color:red">*</span></label>
                  <input type="text"  placeholder="Enter Promo Code"  formControlName="promo_code"
                     class="password-box form-attribute">
                  <span *ngIf="createCampaignForm.controls.promo_code.errors && submitted"
                     class="red-alert-message">
                  Promo Code is required.!
                  </span>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Campaign Type <span style="color:red">*</span></label>
                  <select class="password-box form-attribute" formControlName="campaign_type">
                     <Option value="">Select</Option>
                     <Option value="Sport">Sport</Option>
                     <Option value="Casino">Casino</Option>
                  </select>
                  <span *ngIf="createCampaignForm.controls.campaign_type.errors && submitted"
                     class="red-alert-message">
                  Campaign Type is required.!
                  </span>
               </div>
            </div>
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Campaign Sub Type <span style="color:red">*</span></label>
                  <select class="password-box form-attribute" formControlName="campaign_sub_type">
                     <Option value="">Select</Option>
                     <Option value="Cricket">Cricket</Option>
                     <Option value="Soccer">Soccer</Option>
                     <Option value="Tennis">Tennis</Option>
                     <Option value="ALL">All</Option>
                  </select>
                  <span *ngIf="createCampaignForm.controls.campaign_sub_type.errors && submitted"
                     class="red-alert-message">
                  Campaign Sub Type is required.!
                  </span>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Frequently Setting <span style="color:red">*</span></label>
                  <select class="password-box form-attribute" formControlName="frequently_setting">
                     <Option value="">Select</Option>
                     <Option value="1">1</Option>
                     <Option value="2">2</Option>
                     <Option value="3">3</Option>
                     <Option value="-1">UNLIMITED</Option>
                  </select>
                  <span *ngIf="createCampaignForm.controls.frequently_setting.errors && submitted"
                  class="red-alert-message">
                  Frequently Setting is required.!
               </span>
               </div>
            </div>
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Grant Limit <span style="color:red">*</span></label>
                  <input type="number"  placeholder="Enter Grant Limit" value="-1"  formControlName="grant_limit"
                     class="password-box form-attribute">
                  <span *ngIf="createCampaignForm.controls.grant_limit.errors && submitted"
                     class="red-alert-message">
                  Grant Limit is required.!
                  </span>
               </div>
            </div>
         </div>
         <h3>Wagering</h3>
         <div class="row">
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Bonus Wagering <span style="color:red">*</span></label>
                  <input type="checkbox" (click)="getWageingEvent($event)" value=""  formControlName="wagering" 
                     >
                     <span *ngIf="createCampaignForm.controls.wagering.errors && submitted"
                     class="red-alert-message">
                     wagering is required.!
                  </span>
               </div>
            </div>
         </div>

         <div class="row" *ngIf="wager">
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">TurnOver Multiplier <span style="color:red">*</span></label>
                  <input type="number"  value=""  formControlName="wagering_turnover_muly"  class="password-box form-attribute"
                     >
                     <span *ngIf="createCampaignForm.controls.wagering_turnover_muly.errors && submitted"
                     class="red-alert-message">
                     TurnOver Multiplier is required.!
                  </span>
               </div>
            </div>
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">
                     Min Bet Count <span style="color:red">*</span></label>
                  <input type="number"  value=""  formControlName="wagering_min_bet_count"  class="password-box form-attribute"
                     >
                     <span *ngIf="createCampaignForm.controls.wagering_min_bet_count.errors && submitted"
                     class="red-alert-message">
                     Min Bet Count is required.!
                  </span>
               </div>
            </div>
         </div>


         <div class="row" *ngIf="wager">
            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Provider <span style="color:red">*</span></label>
                  <select class="password-box form-attribute" formControlName="wager_provider">
                     <Option value="">Select Provider</Option>
                     <Option value="world_casino_online">World Casino Online</Option>
                    
                     
                  </select>
                  <span *ngIf="createCampaignForm.controls.wager_provider.errors && submitted"
                  class="red-alert-message">
                  Provider is required.!
               </span>
               </div>
            </div>
            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Select Wagering Type  <span style="color:red">*</span></label>
                  <select class="password-box form-attribute" formControlName="wager_type">
                     <Option value="">Select</Option>
                     <Option value="First_Real_Money">First Real Money</Option>
                     <Option value="First_Wagering">First Wagering</Option>
                  
                    
                  </select>
                  <span *ngIf="createCampaignForm.controls.wager_type.errors && submitted"
                  class="red-alert-message">
                  Wagering Type is required.!
               </span>
               </div>
            </div>
            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Wager <span style="color:red">*</span> <input type="radio"   value="PER" formControlName="wagering_bonus_type"> PER <input type="radio" formControlName="wagering_bonus_type"   value="FLAT"> FLAT </label>
                  <input type="number"  value=""  formControlName="wagering_bonus_amount" class="password-box form-attribute"
                     >
                     <span *ngIf="createCampaignForm.controls.wagering_bonus_amount.errors && submitted"
                     class="red-alert-message">
                     amount  is required.!
                  </span>
               </div>
            </div>
         </div>

         <div class="row" *ngIf="wager">
            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Max Payout X<span style="color:red">*</span>  </label>
                  <input type="number"  value=""  formControlName="wagering_payout_x" class="password-box form-attribute"
                     >
                     <span *ngIf="createCampaignForm.controls.wagering_payout_x.errors && submitted"
                     class="red-alert-message">
                     Max Payout X is required.!
                  </span>
               </div>
            </div>

            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Back Count X <span style="color:red">*</span></label>
                  <input type="number"  value=""  formControlName="wagering_back_bet_count" class="password-box form-attribute"
                     >
                     <span *ngIf="createCampaignForm.controls.wagering_back_bet_count.errors && submitted"
                     class="red-alert-message">
                     Back Count X is required.!
                  </span>
               </div>
            </div>
            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Lay Count X <span style="color:red">*</span></label>
                  <input type="number"  value=""  formControlName="wagering_lay_bet_count" class="password-box form-attribute"
                     >
                     <span *ngIf="createCampaignForm.controls.wagering_lay_bet_count.errors && submitted"
                     class="red-alert-message">
                     Lay Count X is required.!
                  </span>
               </div>
            </div>
           
         </div>

         <h3 *ngIf="!wager">Bonus Amount</h3>
         <div class="row" *ngIf="!wager">
            <div class="col col-sm-4">
                <div class="form-list">
                    <label for="html">Bonus Type</label><br>
                    <input type="radio" id="html" name="bonus_amount_bonus_type" (click)="bType('PER')" formControlName="bonus_amount_bonus_type" value="PER">
                      <label for="html">Percentage</label>
                      <input type="radio" id="css" name="bonus_amount_bonus_type" (click)="bType('FLAT')" formControlName="bonus_amount_bonus_type" value="FLAT">
                      <label for="css">Flat</label><br>

                    <span *ngIf="createCampaignForm.controls.bonus_amount_bonus_type.errors && submitted"
                     class="red-alert-message">
                     Bonus Type is required.!
                  </span>
                </div>
             </div>

            <div class="col col-sm-4">
                <div class="form-list">
                   <label class="form-heading">Bonus Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter Bonus Amount"  formControlName="bonus_amount"
                      class="password-box form-attribute">
                   <span *ngIf="createCampaignForm.controls.bonus_amount.errors && submitted"
                      class="red-alert-message">
                      Bonus Amount is required.!
                   </span>
                </div>
             </div>

             <div class="col col-sm-4" *ngIf="bmType=='PER'">
                <div class="form-list">
                   <label class="form-heading">Bonus Cap Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter Bonus Cap Amount"  formControlName="bonus_cap_amount"
                      class="password-box form-attribute">
                   <span *ngIf="createCampaignForm.controls.bonus_cap_amount.errors && submitted"
                      class="red-alert-message">
                      Bonus Cap Amount is required.!
                   </span>
                </div>
             </div>
         </div>


         <h3 *ngIf="!wager">Payable Amount</h3>
         <div  class="row" *ngIf="!wager">
            <div class="col col-sm-4">
                <div class="form-list">
                    <label for="html">Bonus Type</label><br>
                    <input type="radio" id="html" name="payable_amount_bonus_type" (click)="bType('PER')"  formControlName="payable_amount_bonus_type" value="PER">
                      <label for="html">Percentage</label>
                      <input type="radio" id="css" name="payable_amount_bonus_type" (click)="bType('FLAT')" formControlName="payable_amount_bonus_type" value="FLAT">
                      <label for="css">Flat</label><br>


                    <span *ngIf="createCampaignForm.controls.payable_amount_bonus_type.errors && submitted"
                     class="red-alert-message">
                     Bonus Type is required.!
                  </span>
                </div>
             </div>

            <div class="col col-sm-4">
                <div class="form-list">
                   <label class="form-heading">Payable Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter Payable Amount"  formControlName="payable_amount"
                      class="password-box form-attribute">
                   <span *ngIf="createCampaignForm.controls.payable_amount.errors && submitted"
                      class="red-alert-message">
                      Payable Amount is required.!
                   </span>
                </div>
             </div>

             <div class="col col-sm-4" *ngIf="bmType=='PER'">
                <div class="form-list">
                   <label class="form-heading">Payable Cap Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter Payable Cap Amount"  formControlName="payable_cap_amount"
                      class="password-box form-attribute">
                   <span *ngIf="createCampaignForm.controls.payable_cap_amount.errors && submitted"
                      class="red-alert-message">
                      Payable Cap Amount is required.!
                   </span>
                </div>
             </div>
         </div>


         <h3>Configuration</h3>
         <div class="row">
            <div class="col col-sm-4">
                <div class="form-list">
                    <label class="form-heading">Expiry Date <span style="color:red">*</span></label>
                    <input type="date"  placeholder="Enter Expiry Date"  formControlName="expiry_date"
                       class="password-box form-attribute">
                     
                    <span *ngIf=" createCampaignForm.controls.expiry_date.errors && submitted"
                       class="red-alert-message">
                       Expiry Date is required.!
                    </span>
                </div>
             </div>

             <div class="col col-sm-4">
               <div class="form-list">
                   <label class="form-heading">Expiry Time <span style="color:red">*</span></label>
                   
                      <input type="time"  placeholder="Enter Expiry Time"  formControlName="expiry_time"
                      class="password-box form-attribute">
                   <span *ngIf=" createCampaignForm.controls.expiry_time.errors && submitted"
                      class="red-alert-message">
                      Expiry Date is required.!
                   </span>
               </div>
            </div>

            <div class="col col-sm-4">
                <div class="form-list">
                   <label class="form-heading">Min Bet Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter Min Bet Amount"  formControlName="min_bet_amount"
                      class="password-box form-attribute">
                   <span *ngIf="createCampaignForm.controls.min_bet_amount.errors && submitted"
                      class="red-alert-message">
                      Min Bet Amount is required.!
                   </span>
                </div>
             </div>

             <div class="col col-sm-12">
                <div class="form-list">
                   <label class="form-heading">Description <span style="color:red">*</span></label>
                  <textarea class="password-box form-attribute" formControlName="desciption">

                  </textarea>
                   <span *ngIf="createCampaignForm.controls.desciption.errors && submitted"
                      class="red-alert-message">
                      Description is required.!
                   </span>
                </div>
             </div>
         </div>
      </div>
      <div *ngIf="step_select=='2'" class="body-inner">
         <div _ngcontent-uej-c8="" class="form-group">
            <label _ngcontent-uej-c8="" class="mb-1">Image Upload</label>
            <div _ngcontent-uej-c8="" class="col-12 pl-0">
               <div _ngcontent-uej-c8="" class="row">
                  <div _ngcontent-uej-c8="" class="col-12 col-md-2 col-sm-3">
                     <label class="form-heading">Select Banner <span style="color:red">*</span></label>
                  </div>
                     <div _ngcontent-uej-c8="" class="col-12 col-md-10 col-sm-9 d-flex">
                        <input _ngcontent-uej-c8=""  (change)="processImageFile($event)" accept="image/png, image/jpeg" id="banner" formControlName="banner" name="banner" type="file" value="banner">
                        <span *ngIf=" createCampaignForm.controls.banner.errors && submitted"
                      class="red-alert-message">
                      Banner is required.!
                   </span>
                   <img src="{{urlImage}}" *ngIf="urlImage" width="20px" height="20px">
                        <!----><!----></div>
                     </div>
                  </div>
               </div>
         <div class="col col-sm-12">
            <div class="form-list">
               <label class="form-heading">Rules </label>
              <textarea class="password-box form-attribute" formControlName="rules">

              </textarea>
               
            </div>
         </div>

         <div class="col col-sm-12">
            <div class="form-list">
               <label class="form-heading">Terms And Conditions  </label>
              <textarea class="password-box form-attribute" formControlName="term_conditions">

              </textarea>
               
            </div>
         </div>
         </div>
      </div>
      <div class="modal-footer" *ngIf="step_select=='2'">
         <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="subMitForm()" >Submit</button> &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
         </div>
      </div>
   </form>
</ng-template>

<ng-template #editCampaign>
   <div class="modal-header">
      <h4 class="modal-title">Edit Compaign</h4>
      <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
   </div>
   <form [formGroup]='editCampaignForm'>
      <div class="modal-body no-scroll-body">
      <div class="body-inner">
         <h3>Campaign Details</h3>
         <div class="row">
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Campaign Name </label>
                  <input type="text"  placeholder="Enter  Name"  formControlName="campaign_name" readonly
                     class="password-box form-attribute">
                  <span *ngIf="editCampaignForm.controls.campaign_name.errors && submitted"
                     class="red-alert-message">
                  Campaign name is required.!
                  </span>
               </div>
            </div>
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Promo Code </label>
                  <input type="text"  placeholder="Enter Promo Code"  formControlName="promo_code"
                     class="password-box form-attribute">
                  <span *ngIf="editCampaignForm.controls.promo_code.errors && submitted"
                     class="red-alert-message">
                  Promo Code is required.!
                  </span>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Campaign Type <span style="color:red">*</span></label>
                  <select class="password-box form-attribute" formControlName="campaign_type">
                     <Option value="">Select</Option>
                     <Option value="Sport">Sport</Option>
                     <Option value="Casino">Casino</Option>
                  </select>
                  <span *ngIf="editCampaignForm.controls.campaign_type.errors && submitted"
                     class="red-alert-message">
                  Campaign Type is required.!
                  </span>
               </div>
            </div>
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Campaign Sub Type <span style="color:red">*</span></label>
                  <select class="password-box form-attribute" formControlName="campaign_sub_type">
                     <Option value="">Select</Option>
                     <Option value="Cricket">Cricket</Option>
                     <Option value="Soccer">Soccer</Option>
                     <Option value="Tennis">Tennis</Option>
                  </select>
                  <span *ngIf="editCampaignForm.controls.campaign_sub_type.errors && submitted"
                     class="red-alert-message">
                  Campaign Sub Type is required.!
                  </span>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Frequently Setting <span style="color:red">*</span></label>
                  <select class="password-box form-attribute" formControlName="frequently_setting">
                     <Option value="">Select</Option>
                     <Option value="1">1</Option>
                     <Option value="2">2</Option>
                     <Option value="3">3</Option>
                     <Option value="-1">UNLIMITED</Option>
                  </select>
                  <span *ngIf="editCampaignForm.controls.frequently_setting.errors && submitted"
                  class="red-alert-message">
                  Frequently Setting is required.!
               </span>
               </div>
            </div>
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Grant Limit <span style="color:red">*</span></label>
                  <input type="number"  placeholder="Enter Grant Limit" value="-1"  formControlName="grant_limit"
                     class="password-box form-attribute">
                  <span *ngIf="editCampaignForm.controls.grant_limit.errors && submitted"
                     class="red-alert-message">
                  Grant Limit is required.!
                  </span>
               </div>
            </div>
         </div>
         <h3>Wagering</h3>
         <div class="row">
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Bonus Wagering <span style="color:red">*</span></label>
                  <input type="checkbox" (click)="getWageingEvent($event)" value=""  formControlName="wagering" 
                     >
                     <span *ngIf="editCampaignForm.controls.wagering.errors && submitted"
                     class="red-alert-message">
                     wagering is required.!
                  </span>
               </div>
            </div>
         </div>
       

         <div class="row" *ngIf="wager">
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">TurnOver Multiplier <span style="color:red">*</span></label>
                  <input type="number"  value=""  formControlName="wagering_turnover_muly"  class="password-box form-attribute"
                     >
                     <span *ngIf="editCampaignForm.controls.wagering_turnover_muly.errors && submitted"
                     class="red-alert-message">
                     TurnOver Multiplier is required.!
                  </span>
               </div>
            </div>
            <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">
                     Min Bet Count <span style="color:red">*</span></label>
                  <input type="number"  value=""  formControlName="wagering_min_bet_count"  class="password-box form-attribute"
                     >
                     <span *ngIf="editCampaignForm.controls.wagering_min_bet_count.errors && submitted"
                     class="red-alert-message">
                     Min Bet Count is required.!
                  </span>
               </div>
            </div>
         </div>


         <div class="row" *ngIf="wager">
            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Provider <span style="color:red">*</span></label>
                  <select class="password-box form-attribute" formControlName="wager_provider">
                     <Option value="">Select Provider</Option>
                     <Option value="world_casino_online">World Casino Online</Option>
                    
                     
                  </select>
                  <span *ngIf="editCampaignForm.controls.wager_provider.errors && submitted"
                  class="red-alert-message">
                  Provider is required.!
               </span>
               </div>
            </div>
            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Select Wagering Type  <span style="color:red">*</span></label>
                  <select class="password-box form-attribute" formControlName="wager_type">
                     <Option value="">Select</Option>
                     <Option value="First_Real_Money">First Real Money</Option>
                     <Option value="First_Wagering">First Wagering</Option>
                  
                    
                  </select>
                  <span *ngIf="editCampaignForm.controls.wager_type.errors && submitted"
                  class="red-alert-message">
                  Wagering Type is required.!
               </span>
               </div>
            </div>
            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Wager <span style="color:red">*</span> <input type="radio"  value="PER" formControlName="wagering_bonus_type"> PER <input type="radio" formControlName="wagering_bonus_type"   value="FLAT"> FLAT </label>
                  <input type="number"  value=""  formControlName="wagering_bonus_amount" class="password-box form-attribute"
                     >
                     <span *ngIf="editCampaignForm.controls.wagering_bonus_amount.errors && submitted"
                     class="red-alert-message">
                     amount  is required.!
                  </span>
               </div>
            </div>
         </div>

         <div class="row" *ngIf="wager">
            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Payout X <span style="color:red">*</span>  <input formControlName="wagering_payout_type" type="radio"   value="PER"> PER <input type="radio" formControlName="wagering_payout_type"   value="FLAT"> FLAT</label>
                  <input type="number"  value=""  formControlName="wagering_payout_x" class="password-box form-attribute"
                     >
                     <span *ngIf="editCampaignForm.controls.wagering_payout_x.errors && submitted"
                     class="red-alert-message">
                     Payout X is required.!
                  </span>
               </div>
            </div>

            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Back Count X <span style="color:red">*</span></label>
                  <input type="number"  value=""  formControlName="wagering_back_bet_count" class="password-box form-attribute"
                     >
                     <span *ngIf="editCampaignForm.controls.wagering_back_bet_count.errors && submitted"
                     class="red-alert-message">
                     Back Count X is required.!
                  </span>
               </div>
            </div>
            <div class="col col-sm-4">
               <div class="form-list">
                  <label class="form-heading">Lay Count X <span style="color:red">*</span></label>
                  <input type="number"  value=""  formControlName="wagering_lay_bet_count" class="password-box form-attribute"
                     >
                     <span *ngIf="editCampaignForm.controls.wagering_lay_bet_count.errors && submitted"
                     class="red-alert-message">
                     Lay Count X is required.!
                  </span>
               </div>
            </div>
           
         </div>


         <h3 *ngIf="!wager">Bonus Amount</h3>
         <div class="row" *ngIf="!wager">
            <div class="col col-sm-4">
                <div class="form-list">
                    <label for="html">Bonus Type</label><br>
                    <input type="radio" id="html" name="bonus_amount_bonus_type" formControlName="bonus_amount_bonus_type" value="PER">
                      <label for="html">Percentage</label>
                      <input type="radio" id="css" name="bonus_amount_bonus_type" formControlName="bonus_amount_bonus_type" value="FLAT">
                      <label for="css">Flat</label><br>

                    <span *ngIf="editCampaignForm.controls.bonus_amount_bonus_type.errors && submitted"
                     class="red-alert-message">
                     Bonus Type is required.!
                  </span>
                </div>
             </div>

            <div class="col col-sm-4">
                <div class="form-list">
                   <label class="form-heading">Bonus Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter Bonus Amount"  formControlName="bonus_amount"
                      class="password-box form-attribute">
                   <span *ngIf="editCampaignForm.controls.bonus_amount.errors && submitted"
                      class="red-alert-message">
                      Bonus Amount is required.!
                   </span>
                </div>
             </div>

             <div class="col col-sm-4">
                <div class="form-list">
                   <label class="form-heading">Bonus Cap Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter Bonus Cap Amount"  formControlName="bonus_cap_amount"
                      class="password-box form-attribute">
                   <span *ngIf="editCampaignForm.controls.bonus_cap_amount.errors && submitted"
                      class="red-alert-message">
                      Bonus Cap Amount is required.!
                   </span>
                </div>
             </div>
         </div>


         <h3 *ngIf="!wager">Payable Amount</h3>
         <div *ngIf="!wager" class="row">
            <div class="col col-sm-4">
                <div class="form-list">
                    <label for="html">Bonus Type</label><br>
                    <input type="radio" id="html" name="payable_amount_bonus_type"   formControlName="payable_amount_bonus_type" value="PER">
                      <label for="html">Percentage</label>
                      <input type="radio" id="css" name="payable_amount_bonus_type"  formControlName="payable_amount_bonus_type" value="FLAT">
                      <label for="css">Flat</label><br>


                    <span *ngIf="editCampaignForm.controls.payable_amount_bonus_type.errors && submitted"
                     class="red-alert-message">
                     Bonus Type is required.!
                  </span>
                </div>
             </div>

            <div class="col col-sm-4">
                <div class="form-list">
                   <label class="form-heading">Payable Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter Payable Amount"  formControlName="payable_amount"
                      class="password-box form-attribute">
                   <span *ngIf="editCampaignForm.controls.payable_amount.errors && submitted"
                      class="red-alert-message">
                      Payable Amount is required.!
                   </span>
                </div>
             </div>

             <div class="col col-sm-4">
                <div class="form-list">
                   <label class="form-heading">Payable Cap Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter Payable Cap Amount"  formControlName="payable_cap_amount"
                      class="password-box form-attribute">
                   <span *ngIf="editCampaignForm.controls.payable_cap_amount.errors && submitted"
                      class="red-alert-message">
                      Payable Cap Amount is required.!
                   </span>
                </div>
             </div>
         </div>


         <h3>Configuration</h3>
         <div class="row">
            <div class="col col-sm-4">
                <div class="form-list">
                    <label class="form-heading">Expiry Date <span style="color:red">*</span></label>
                    <input type="date"  placeholder="Enter Expiry Date"  formControlName="expiry_date"
                       class="password-box form-attribute">
                     
                    <span *ngIf="editCampaignForm.controls.expiry_date.errors && submitted"
                       class="red-alert-message">
                       Expiry Date is required.!
                    </span>
                </div>
             </div>

             <div class="col col-sm-4">
               <div class="form-list">
                   <label class="form-heading">Expiry Time <span style="color:red">*</span></label>
                   
                      <input type="time"  placeholder="Enter Expiry Time"  formControlName="expiry_time"
                      class="password-box form-attribute">
                   <span *ngIf="editCampaignForm.controls.expiry_time.errors && submitted"
                      class="red-alert-message">
                      Expiry Date is required.!
                   </span>
               </div>
            </div>

            <div class="col col-sm-4">
                <div class="form-list">
                   <label class="form-heading">Min Bet Amount <span style="color:red">*</span></label>
                   <input type="number"  placeholder="Enter Min Bet Amount"  formControlName="min_bet_amount"
                      class="password-box form-attribute">
                   <span *ngIf="editCampaignForm.controls.min_bet_amount.errors && submitted"
                      class="red-alert-message">
                      Min Bet Amount is required.!
                   </span>
                </div>
             </div>
             <div class="col col-sm-6">
               <div class="form-list">
                  <label class="form-heading">Banner</label>
                

                 <input _ngcontent-uej-c8=""  (change)="processImageFile($event)" accept="image/png, image/jpeg" id="banner" formControlName="banner" name="banner" type="file" value="banner">
                      
                 
               </div>
            </div>
            <div class="col col-sm-6">
               <div class="form-list">
                  
                  <img src="{{Base_Url}}wallets/{{image_val}}" *ngIf="image_val" width="100px" height="100px">
                
                 
               </div>
            </div>

             <div class="col col-sm-12">
                <div class="form-list">
                   <label class="form-heading">Description <span style="color:red">*</span></label>
                  <textarea class="password-box form-attribute" formControlName="desciption">

                  </textarea>
                   <span *ngIf="editCampaignForm.controls.desciption.errors && submitted"
                      class="red-alert-message">
                      Description is required.!
                   </span>
                </div>
             </div>
             <div class="col col-sm-12">
               <div class="form-list">
                  <label class="form-heading">Rules </label>
                 <textarea class="password-box form-attribute" formControlName="rules">

                 </textarea>
                  <span *ngIf="editCampaignForm.controls.rules.errors && submitted"
                     class="red-alert-message">
                     Rules is required.!
                  </span>
               </div>
            </div>
            <div class="col col-sm-12">
               <div class="form-list">
                  <label class="form-heading">Term Condition  </label>
                 <textarea class="password-box form-attribute" formControlName="term_conditions">

                 </textarea>
                  <span *ngIf="editCampaignForm.controls.term_conditions.errors && submitted"
                     class="red-alert-message">
                     Term Condition is required.!
                  </span>
               </div>
            </div>
         </div>
      </div>
      </div>
      <div class="modal-footer">
         <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="subMitEditForm()" >Submit</button> &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
         </div>
      </div>
   </form>
</ng-template>