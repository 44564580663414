<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="delete-bet inner-content">
                <div class="page-title">
                    <h3>Delete Bet</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div class="filterDiv">
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                <div class="row form-field">
                                    <div class="col-md-2">
                                        <div class="col-inner">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Search by user">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="col-inner text-end">
                                            <div class="btn-list">
                                                <button type="button" class="btn btn-success">Filter</button>
                                                <button type="button" class="btn btn-danger">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="report-table">
                    <table datatable class="table">
                        <thead>
                            <tr>
                                <th><input type="checkbox"></th>
                                <th>Placed</th>
                                <th>Date-Time</th>
                                <th>UserName</th>
                                <th>Type</th>
                                <th>Odds</th>
                                <th>Stack</th>
                                <th>Profit</th>
                                <th>Ip Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td>Jun 26, 2021, 8:23:20 PM</td>
                                <td>England v Pakistan</td>
                                <td>demome</td>
                                <td>Back</td>
                                <td>1.98</td>
                                <td>100</td>
                                <td>98</td>
                                <td>122.168.144.72</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
</main>