<div class="footer">
    <div class="footer-menu">
        <ul>
            <li>About Us</li>
            <li>Help</li>
            <li>Terms & Conditions</li>
            <li>Game Rules</li>
            <li>Security</li>
            <li>Responsible Gambling</li>
        </ul>
    </div>
    <div class="footer-logo">
        <ul>
            <li><img src="assets/images/18plus.png" alt=""></li>
            <li><img src="assets/images/gamecare.png" alt=""></li>
            <li><img src="assets/images/gt.png" alt=""></li>
            <li><img src="assets/images/bet-logo.png" alt=""></li>
        </ul>
    </div>
    <div class="browser-wrap">
       <img src="assets/images/transparent.gif">
       Our website works best in the newest and last prior version of these browsers: <br>
       Google Chrome. Firefox 
    </div>
  </div>