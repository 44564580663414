<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="closed-user inner-content">
                <div class="page-title">
                    <h3>Fancy Stake</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div class="filterDiv">
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                <div class="row form-field">
                                    <div class="col-md-1" style="width: 13.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group dateTimePicker">
                                                <input type="text" class="form-control" [popover]="popTemplate" placement="bottom" [outsideClick]="true" #startpopoverRef="bs-popover" value="{{startdateTime | date:'yyyy-MM-dd HH:mm:ss '}}" (click)="showDate()">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="width: 13.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group dateTimePicker">
                                                <input type="text" class="form-control" [popover]="popTemplate1" placement="bottom" [outsideClick]="true" #endpopoverRef="bs-popover" value="{{enddateTime | date:'yyyy-MM-dd HH:mm:ss '}}" (click)="showDate()">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-1" style="width: 15.499999995%;margin:0 5px;">
                                    
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchSport"
                                            (change)="getFancyStakeFilterData(0,searchSport.sport_id,searchSport.sport_name) "
                                            (blur)="getFancyStakeFilterData(0,searchSport.sport_id,searchSport.sport_name)" placeholder="Search Sport Name"
                                            (clear)="getFancyStakeFilterData(0,undefined,undefined)">
                                            <ng-option *ngFor="let sport of SportList" [value]="sport">{{sport.sport_name}}</ng-option>
                                        </ng-select>
                                        
                                    </div> -->
                                    <!-- <div class="col-md-1" style="width: 12.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group mb-0">

                                                <input name="searchSeries"
                                                    class="search-input dropdown-toggle form-control"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" type="text" autocomplete="off"
                                                    [(ngModel)]="searchSeries" placeholder="Search Series Name...">

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item"
                                                        *ngFor="let sport of SeriesList |search: searchSeries :'series_name'">
                                                        <div class="event-info"
                                                            (click)="getFancyStakeFilterData(1,sport.series_id,sport.series_name)">
                                                            <div class="event-format-strip">
                                                                <div class="event-team">
                                                                    <div class="team-name">{{sport?.series_name}}</div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-1" style="width: 15.499999995%;margin:0 5px;">
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchSeries"
                                            (change)="getFancyStakeFilterData(1,searchSeries.series_id,searchSeries.series_name,'filterBtnClick') "
                                            (blur)="getFancyStakeFilterData(1,searchSeries.series_id,searchSeries.series_name,'filterBtnClick')"
                                            placeholder="Search Series Name" (clear)="getFancyStakeFilterData(1,undefined,undefined,'filterBtnClick')">
                                            <ng-option *ngFor="let series of SeriesList" [value]="series">{{series.series_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="col-md-1" style="width: 15.499999995%;margin:0 5px;">
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchMatch"
                                            (change)="getFancyStakeFilterData(2,searchMatch.match_id,searchMatch.match_name,'filterBtnClick') "
                                            (blur)="getFancyStakeFilterData(2,searchMatch.match_id,searchMatch.match_name,'filterBtnClick')"
                                            placeholder="Search Match Name" (clear)="getFancyStakeFilterData(2,undefined,undefined,'filterBtnClick')">
                                            <ng-option *ngFor="let match of MatchList" [value]="match">{{match.match_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="col-md-1" style="width: 15.499999995%;margin:0 5px;">
                                        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchMarket"
                                            (change)="(searchMarket.type == '1') ? getFancyStakeFilterData(3,searchMarket.event_id,searchMarket.event_name,'filterBtnClick') : getFancyStakeFilterData(4,searchMarket.event_id,searchMarket.event_name,'filterBtnClick') "
                                            (blur)="(searchMarket.type == '1') ? getFancyStakeFilterData(3,searchMarket.event_id,searchMarket.event_name,'filterBtnClick') : getFancyStakeFilterData(4,searchMarket.event_id,searchMarket.event_name,'filterBtnClick')"
                                            placeholder="Search Market Name"
                                            (clear)="(searchMarket.type == '1') ? getFancyStakeFilterData(3,undefined,undefined,'filterBtnClick') :getFancyStakeFilterData(4,undefined,undefined,'filterBtnClick')">
                                            <ng-option *ngFor="let event of MarketList" [value]="event">{{event.event_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                    <!-- <div class="col-md-1" style="width: 12.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group mb-0">

                                                <input name="searchMatch"
                                                    class="search-input dropdown-toggle form-control"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" type="text" autocomplete="off"
                                                    [(ngModel)]="searchMatch" placeholder="Search Match Name...">

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item"
                                                        *ngFor="let sport of MatchList |search: searchMatch :'match_name'">
                                                        <div class="event-info"
                                                            (click)="getFancyStakeFilterData(2,sport.match_id,sport.match_name)">
                                                            <div class="event-format-strip">
                                                                <div class="event-team">
                                                                    <div class="team-name">{{sport?.match_name}} </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="width: 12.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group mb-0">

                                                <input name="searchMarket"
                                                    class="search-input dropdown-toggle form-control"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" type="text" autocomplete="off"
                                                    [(ngModel)]="searchMarket" placeholder="Search Market Name...">

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item"
                                                        *ngFor="let sport of MarketList |search: searchMarket :'event_name'">
                                                        <div class="event-info"
                                                            (click)="(sport.type == '1') ? getFancyStakeFilterData(3,sport.event_id,sport.event_name) : getFancyStakeFilterData(4,sport.event_id,sport.event_name)">
                                                            <div class="event-format-strip">
                                                                <div class="event-team">
                                                                    <div class="team-name">{{sport?.event_name}} </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-1" style="width: 12.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group mb-0">

                                                <input name="searchUser"
                                                    class="search-input dropdown-toggle form-control"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" type="text" autocomplete="off"
                                                    [(ngModel)]="searchUser" placeholder="Search User Name...">

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item"
                                                        *ngFor="let sport of userlist |search: searchUser :'user_name'">
                                                        <div class="event-info" (click)="getFancy(sport.match_id)">
                                                            <div class="event-format-strip">
                                                                <div class="event-team">
                                                                    <div class="team-name">{{sport?.user_name}} </div>
                                                                </div>
                                                                <!-- <div class="event-full-date">
                                                                    <span class="date">{{moment(sport?.match_date).utcOffset("+0").format('D')}}
                                                                        {{moment(sport?.match_date).utcOffset("+0").format('MMM')}}
                                                                        {{moment(sport?.match_date).utcOffset("+0").format('HH:mm')}} IST</span>
                                                                </div>
                                 -->
                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="width: 10.499999995%">
                                        <div class="col-inner text-end">
                                            <div class="btn-list">
                                                <button type="button" class="btn btn-success"
                                                    (click)="fancyStake('filterBtnClick')">Filter</button>
                                                <button type="button" class="btn btn-danger"
                                                    (mousedown)="onClickClearBtn()">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
 
                <div class="report-table">
                    <table datatable class="table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Date</th>
                                <th>Sports | Series</th>
                                <th>Match | Market</th>
                                <th>Stake</th>
                                <!-- <th>Commission</th>
                                <th>Net PNL</th> -->
                                <th>ViewBets</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let fancyStake of fancyStakeData| paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems };let i = index;">
                                <td>{{itemsPerPage *(currentPage-1)+i+1}}</td>
                                <td>
                                    <span class="date">{{fancyStake?.date_time| date :'dd-MM-yyyy'}}</span><br>
                                    <span class="date">{{fancyStake?.date_time| date :'hh:mm:ss aa'}}</span>
                                </td>
                                <td>
                                    <span class="sport-name">Cricket</span><br>
                                    <span class="series-name">{{fancyStake?.series_name}}</span>
                                </td>
                                <td>
                                    <span class="match-name">{{fancyStake?.match_name}}</span><br>
                                    <span class="event-name">{{fancyStake?.event_name}}</span>
                                </td>
                                <td>{{fancyStake?.stack}}</td>
                                <td><button type="button" class="btn btn-sm btn-success"
                                    (click)="route(fancyStake)">User Stake</button>
                                </td>
                            </tr>
                            <!-- <div *ngIf="fancyStakeData.length == 0" style="text-align: center;">
                                No data avalible 
                            </div> -->
                        </tbody>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total</td>
                                <td [ngClass]="{'red':stake < 0 ,'green':stake >= 0}">{{stake | number : '0.0-2'}}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="report-bottom-paginate">
                        <div class="col-inner item-perpage-select">
                            <label>Items per page:</label>

                            <select name="betType" class="form-select" [(ngModel)]="itemsPerPage"
                                [ngModelOptions]="{standalone: true}" aria-label="Default select example"
                                (ngModelChange)="pageChange()">
                                <option selected disabled></option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                        </div>
                       
                        <pagination-controls (pageChange)="pageChange($event)"
                        class="user-list-pagination" ></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<!-- Date Time Picker -->
<ng-template #popTemplate>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="date" [minDate]="minDate"[maxDate] ="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="time" (ngModelChange)="updateTime()" [showMeridian]="isMeridian" showSeconds="true">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="close()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>
<ng-template #popTemplate1>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDate" [minDate]="date" [maxDate] ="todayDateTime"[showWeeks]="false"
        (ngModelChange)="updateEndDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTime" (ngModelChange)="updateEndTime()"
        [showMeridian]="isMeridian" showSeconds="true">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="closeEndDatepicker()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>