import { Component, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DateTime } from '../../dateTime';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ReportService } from '../services/report.service';
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { SocketServiceService } from '../services/socket-service.service';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-pl-statement',
  templateUrl: './pl-statement.component.html',
  styleUrls: ['./pl-statement.component.scss'],
  providers: [HeaderComponent],
})
export class PlStatementComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    "lengthChange": false,
    "ordering": false,
    "paging": false,
    "searching": false
  };
  dtTrigger: Subject<any> = new Subject();
  statementType: any = [];
  user_id: any;
  update_user_id: any;
  data: any;
  plStatementList: any=[];
  isSocket;
  modalRef: BsModalRef;
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  time: Date;
  date: Date;
  endDate: Date;
  endTime: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  // startdateTime: any
  // enddateTime: any
  selections: any = "0";
  startdateTime: Date = new Date()
  enddateTime: Date = new Date()
  minTime: Date = new Date()
  maxTime: Date = new Date()
  startAt: Date = new Date()
  todayDateTime:Date=new Date()
  itemsPerPage: number = 50;
  currentPage: number = 1;
  totalItems: number = 0;
  userParentName: any;
  parentList: any;
  update_user_Type_id: any;

  constructor(private loginService: LoginService,private datePipe: DatePipe, private report: ReportService, private router: Router,private modalService: BsModalService,
    private toastr: ToastrService, private locationBack: Location,private head: HeaderComponent, private route: ActivatedRoute,private sport: SportService,
    private cookie: CookieService, private socketService: SocketServiceService) {

    this.route.params.subscribe(params => {
      this.update_user_id = params.id;
      this.update_user_Type_id = params.userTypeId;
    });
  }

  async ngOnInit() {

  
    this.user_id = localStorage.getItem('userId');
    // await this.socketService.setUpSocketConnection();
    this.isSocket = this.cookie.get('is_socket');
    // if (this.update_user_id == null || this.update_user_id == undefined || this.update_user_id == '') {
    //   this.update_user_id = this.user_id;
    // }
    this.startdateTime.setDate(this.startdateTime.getDate() - 6);
    this.todayDateTime.setHours(23, 59, 59);
    this.startdateTime.setHours(0, 0, 0, 0);
    this.enddateTime.setHours(23, 59, 59);
    this.startAt.setHours(23, 59, 59);
    this.getPLStatement('filterBtnClick');
    this.minTime.setHours(0);
    this.minTime.setMinutes(0);
    this.maxTime.setHours(23);
    this.maxTime.setMinutes(59);
    this.maxTime.setSeconds(59);
    if (this.startdateTime) {
      this.time = this.date = this.startdateTime;
      return;
    }
    if (this.enddateTime) {
      this.time = this.date = this.enddateTime;
      return;
    }
    this.date = this.time = new Date();
  }

  goToBack() {
    this.locationBack.back();
  }


  dateSelectionDone() {
    this.isDateVisible = true;
  }

  updateDate() {

    if (this.date) {
      this.startdateTime = DateTime.getDateTime(this.date, this.time);
    }
    if (!this.time) {
      this.time = this.date;
    }
  }

  updateEndDate() {
    if (this.endDate) {
      this.enddateTime = DateTime.getDateTime(this.endDate, this.endTime);
      this.enddateTime.setHours(23, 59, 59);
    }
   
  }
  updateTime() {
    if (this.time) {
      this.startdateTime = DateTime.getDateTime(this.date, this.time);
    }
  }
  updateEndTime() {
    if (this.endTime) {
      
      this.enddateTime = DateTime.getDateTime(this.endDate, this.endTime);
    }
  }


  showDate() {
    this.isDateVisible = true;
  }

  showTime() {
    this.isDateVisible = false;
  }

  close() {
    this._startpopoverRef.hide();

  }
  closeEndDatepicker() {
    this._endpopoverRef.hide()
  }
  now() {
    this.startdateTime = DateTime.now(this.date);
    this.time = this.startdateTime;
    this.enddateTime = DateTime.now(this.date);
    this.time = this.enddateTime;
  }

  today() {
    this.date = this.time = new Date();
    this.startdateTime = DateTime.now(this.date);
    this.enddateTime = DateTime.now(this.date);
  }
  clear() {
    //https://valor-software.com/ngx-bootstrap/#/timepicker#dynamic
    //Check component DemoTimepickerDynamicComponent  -> clear() method;  void 0 evaluates to undefined
    this.time = void 0;
    this.date = void 0;
    this.startdateTime = void 0;
    this.enddateTime = void 0;
  
  }

  getPLStatement(from?: string) {

    this.data = {
      "user_id": this.update_user_id == undefined ? this.user_id : this.update_user_id,
    }
    if (from == 'filterBtnClick') {
      
      if (this.startdateTime) {
        this.data["from_date"] = this.startdateTime.toISOString()

      }
      if (this.enddateTime) {
        this.data["to_date"] = this.enddateTime.toISOString()
      }
    }

    if (this.isSocket != 1) {
      this.report.plStatements(this.data).subscribe(data => {
      
      
        if (data.status == true) {
          this.plStatementList = data.data;
         
        } else {
          this.toastr.error(data.msg, '', {
            timeOut: 10000,
          });
          if(data.logout == true){
            this.head.logoutUser();
          }
        }
      })
    }
    else {

      // this.socketEmitEvent('account-satement', this.data);

    }

  }

  onClickClear(){
    this.startdateTime = new Date();
    this.enddateTime = new Date()
    this.startdateTime.setHours(0, 0, 0, 0);
    this.enddateTime.setHours(23, 59, 59);
  
    this.getPLStatement('filterBtnClick')
  }
  onSelectionChange(data) {
    this.selections = data;
    this.getPLStatement('filterBtnClick')

  }


  chosenMonthHandler(normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
    datepicker.close();
  }

  pageChange(event?:any) {
    if(event){
      this.currentPage = event
    }
   
    this.getPLStatement('filterBtnClick')
  }



  

  

}
