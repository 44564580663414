import { Component, OnInit, TemplateRef, ElementRef, ViewChild, Output, EventEmitter,ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../services/users.service';
import { CookieService } from 'ngx-cookie-service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SportService } from '../services/sport.service';
import { WalletService } from '../services/wallet.service';
import Swal from 'sweetalert2'
import { json } from 'express';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from '../services/login.service';
import { SocketServiceService } from '../services/socket-service.service';
import { environment } from '../../environments/environment';
import { AppValidationService } from '../app-validation/app-validation.service';

import { WebsiteSettingService } from '../services/website-setting.service'
declare var $: any;
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { PreviousRouteService } from '../services/previous-route.service';
import { ValidatorControls } from '../app-validation/validation-controls.directive';
import { JsonpClientBackend } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';
import { escapeSelector } from 'jquery';

@Component({
  selector: 'app-social-media-agent-list',
  templateUrl: './social-media-agent-list.component.html',
  styleUrls: ['./social-media-agent-list.component.scss']
})
export class SocialMediaAgentListComponent implements OnInit {


  adminDetails:any;
  message:any;
  modalRef: BsModalRef;
  createUserForm: FormGroup;
  credentailArr:any=[{key:'',value:''}];
  submited:any=false;
  roleList:any;
  selectedUserId:any;
  agentList:any;
  parentLevelIds:any=[];
  userDetail:any;
  param:any;
  u:any;
  confirmPaaword:any=false;
  user_id:any;
  editUserForm:any;
  user_type_id:any;
  domainName:any;
  isSocket:any=0;
  loggedInUserTypeId:any;
  userSportPartnerShip:any;
  shareMessageValidation:any;
  tempSportPartnership:any;
  websiteList:any;
  levels:any;
  permissions:any;
  constructor(
    private modalService: BsModalService, private sport: SportService,private wallet:WalletService,
    private previousRouteService: PreviousRouteService,public clipboardService: ClipboardService,
    private websiteSettingService: WebsiteSettingService,private cdRef: ChangeDetectorRef,
    private fb: FormBuilder, private toastr: ToastrService, private router: Router, private route: ActivatedRoute,
    private loginService: LoginService, private usersService: UsersService, private cookie: CookieService, private locationBack: Location,
    private appValidationService: AppValidationService, private socketService: SocketServiceService,
 
  ) { }

  ngOnInit(): void {
    this.createUserForm = this.fb.group({
      "role_user_name": ["", Validators.required],
      "user_name": ["", Validators.required],
      "password": ["", Validators.required],
      "confirm_password": ["", Validators.required],
      parent_user_name: [''],
      domain: [''],
      role_user_id:[''],
      domain_name:[''],
      name: [''],
      user_type_id: [3],
      parent_id: [null],
      point: [1],
      parent_level_ids: [],
    });
    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    if(this.adminDetails)
    {
      this.user_id=this.adminDetails.user_id;
      this.user_type_id=this.adminDetails.user_type_id;
      this.getLoginUseretails();
      this.getAgentList();
    }
    this.getRole()
  }
  getfb()
  {
    

  }
 
  getLoginUseretails() {
    this.param = {
      "user_id": this.user_id,
      "user_type_id": this.user_type_id
    }
    this.usersService.getLoginUseretails(this.param).subscribe(response => {
      if(response.status == true){
        this.userDetail = response.data;
       
        this.domainName = this.userDetail.domain.host_name;
        this.loggedInUserTypeId = this.userDetail.user_type_id;
        this.createUserForm.controls['parent_user_name'].setValue(this.userDetail.user_name);
        this.createUserForm.controls['parent_id'].setValue(this.userDetail._id);
        this.createUserForm.controls['point'].setValue(this.userDetail.point);

       
        this.parentLevelIds.push({ user_id: this.userDetail._id, user_type_id: this.loggedInUserTypeId
          ,user_name : this.userDetail.user_name, name: this.userDetail.name
         });
      
        this.websiteList = this.userDetail.domain;
       
       
      } else {
        if(response.logout == true){
         
        }
      }
      
    }, error => {
      console.log(error)
    })
  }
 
  subMitForm()
  {
    this.message="";
    this.submited=true;
    if(!this.createUserForm.valid)
    {
      alert('validation failed...')
    }
   if(this.createUserForm.valid)
   {
    let arr=this.createUserForm.controls.role_user_name.value.split("-")
    
if(this.createUserForm.controls.confirm_password.value=="")
{
 this.confirmPaaword=true;
 return;
}
this.confirmPaaword=false;
    if(this.createUserForm.controls.confirm_password.value!==this.createUserForm.controls.password.value)
    {
      this.message="confirm password mismatch";
      return;
    }
   
    delete this.createUserForm.controls['confirm_password'];
    //delete this.createUserForm.controls['role'];
    
    this.createUserForm.controls['parent_user_name'].setValue(this.adminDetails.user_name);
    this.createUserForm.controls['parent_id'].setValue(this.adminDetails._id);
    this.createUserForm.controls['user_name'].setValue(this.createUserForm.controls.user_name.value);
    this.createUserForm.controls['name'].setValue(this.createUserForm.controls.user_name.value);
    this.createUserForm.controls['role_user_name'].setValue(arr[0]);
    this.createUserForm.controls['role_user_id'].setValue(arr[1]);
    this.createUserForm.controls['user_type_id'].setValue(12);
    this.createUserForm.controls['domain_name'].setValue(this.userDetail?.domain?.domain_name);
    this.createUserForm.controls['domain'].setValue(this.userDetail?.domain?._id);
    this.createUserForm.controls['parent_level_ids'].setValue(this.parentLevelIds);
    this.submited=false;
 
  
    this.wallet.walletagent_create(this.createUserForm.value).subscribe((res:any) => {
      
      if(res.status == true){
        this.getAgentList();
        this.modalRef.hide();
        this.toastr.success(res.msg,'',{
          positionClass: 'toast-bottom-right',
          timeOut:1000
         });
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
   }
  }

  subMitEditForm()
  {
    if(!this.editUserForm.valid)
    {
      alert('validation failed...')
    }
    else{
      this.wallet.walletagent_edit(this.editUserForm.value).subscribe((res:any) => {
      
        if(res.status == true){
          this.getAgentList();
          this.modalRef.hide();
          this.toastr.success(res.msg,'',{
            positionClass: 'toast-bottom-right',
            timeOut:1000
           });
      } else {
        this.toastr.error(res.msg,'',{
          timeOut: 10000,
        });
      }
    });
    }
  }
  getRole()
  {
    let data = {
      
      user_id:this.adminDetails.user_id
    }
    
    this.wallet.getactiveRole(data).subscribe((res:any) => {
    
      if(res.status == true){
        this.roleList=res.data;
        console.log(res.data)
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  }

  getAgentList()
  {
    let params={
      parent_id:this.adminDetails.user_id,
      user_type_id:"12"
    }
    this.wallet.get_agent_list(params).subscribe((res:any) => {
      
    
      if(res.status == true){
        this.agentList=res.data;
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  }
 
  openModaladdUser(addUser: TemplateRef<any>) {
    this.submited=false;
    this.modalRef = this.modalService.show(
      addUser,
      Object.assign({}, { class: 'comissionSetting-modal modal-lg' })
    );
  }

  openModaleditUser(openModaleditUser: TemplateRef<any>,u:any) {
    this.submited=false;
    this.u=u;
    this.editUserForm = this.fb.group({
      "role_user_name": ["", Validators.required],
      "_id": ["", ""],
    });
    this.editUserForm.controls['role_user_name'].setValue(u?.role_user_id);
    this.editUserForm.controls['_id'].setValue(u?._id);
    this.modalRef = this.modalService.show(
      openModaleditUser,
      Object.assign({}, { class: 'comissionSetting-modal modal-lg' })
    );
  }

  closeAccount(user){
    if(confirm("Are You Sure Delete Account")){
      let params={
        user_id:user.id,
        parent_id:this.adminDetails.user_id
      }
      this.wallet.closedUser(params).subscribe((result) => {
        if (result.status == true) {
          this.getAgentList();
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
        } else {
          this.toastr.error(result.msg, '', {
            timeOut: 10000,
          });
          if(result.logout == true){
            
          }
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  lockAccountOfUserAndTheirChilds(userid, self_lock_user) {
    
    var obj: any = {};
    var message = '';
    if (self_lock_user == 0) {
      obj.self_lock_user = 1;
      message = "Are you sure you want to lock this user account!"
    }
    if (self_lock_user == 1) {
      obj.self_lock_user = 0;
      message = "Are you sure you want to unlock this user account!"
    }
    this.selectedUserId = userid;
    Swal.fire({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.isSocket != 1) {
          this.usersService.lockUserAccount(this.selectedUserId, obj).subscribe((result) => {
            if (result.status == true) {
              this.getAgentList();
              this.toastr.success(result.msg, '', {
                positionClass: 'toast-bottom-right',
                timeOut: 1000
              });
            } else {
              this.toastr.error(result.msg, '', {
                timeOut: 10000,
              });
              if(result.logout == true){
                
              }
            }
          }, (err) => {
            console.log(err);
          });
        }
        else {
          obj.user_id = userid;
          // this.socketEmitEvent('lock-and-unlock-account-of-user',
          //   obj
          // );
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }


  closeAccountOfUserAndTheirChilds(userid, self_close_account,balance,liability,pl) {
    if(balance == 0 && liability == 0 && pl == 0){
      var obj: any = {};
      var message = '';
      if (self_close_account == 0) {
        obj.self_close_account = 1;
        message = "Are you sure you want to close this user account!"
      }
      if (self_close_account == 1) {
        obj.self_close_account = 0;
        message = "Are you sure you want to reopen this user account!"
      }
      this.selectedUserId = userid;
      Swal.fire({
        title: 'Are you sure?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.isSocket != 1) {
            this.usersService.closeAndReOpenAccountOfUserAndTheirChilds(this.selectedUserId, obj).subscribe((result) => {
              if (result.status == true) {
                 this.getAgentList();
                this.toastr.success(result.msg, '', {
                  positionClass: 'toast-bottom-right',
                  timeOut: 1000
                });
              } else {
                this.toastr.error(result.msg, '', {
                  timeOut: 10000,
                });
                if(result.logout == true){
                  
                }
              }
            }, (err) => {
              console.log(err);
            });
          }
          else {
            obj.user_id = userid;
            // this.socketEmitEvent('close-and-re-open-account-of-user-and-their-childs',
            //   obj
            // );
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      })
    } else {
      alert("First Clear Exposure , Balance & Settlement amount. After then you will close A/C successfully....");
     
    }
    
  }


}
