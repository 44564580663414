import { Component, OnInit, TemplateRef, ElementRef, ViewChild, Output, EventEmitter,ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../../services/users.service';
import { CookieService } from 'ngx-cookie-service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SportService } from '../../services/sport.service';
import Swal from 'sweetalert2'
import { json } from 'express';
import { Location } from '@angular/common';
import { WalletService } from '../../services/wallet.service';
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from '../../services/login.service';
import { SocketServiceService } from '../../services/socket-service.service';
import { environment } from '../../../environments/environment';
import { AppValidationService } from '../../app-validation/app-validation.service';
//import { io, Socket } from 'socket.io-client';

import { WebsiteSettingService } from '../../services/website-setting.service'
declare var $: any;
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { PreviousRouteService } from '../../services/previous-route.service';
import { ValidatorControls } from '../../app-validation/validation-controls.directive';
import { JsonpClientBackend } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';


@Component({
  selector: 'app-capaign-list',
  templateUrl: './capaign-list.component.html',
  styleUrls: ['./capaign-list.component.scss']
})
export class CapaignListComponent implements OnInit {
  bmType:any="PER";
  image_val:any;
  step_select:any="1";
  modalRef: BsModalRef;
  createCampaignForm: FormGroup;
  submitted:any=false;
  adminDetails:any;
  editCampaignForm:any;
  urlImage:any;
  campaignList:any;
  wager:any=false;
  image:any;
  public Base_Url = environment['adminServerUrl'];
  constructor(
    private modalService: BsModalService, private sport: SportService,private wallet:WalletService,
    private previousRouteService: PreviousRouteService,public clipboardService: ClipboardService,
    private websiteSettingService: WebsiteSettingService,private cdRef: ChangeDetectorRef,
    private fb: FormBuilder, private toastr: ToastrService, private router: Router, private route: ActivatedRoute,
    private loginService: LoginService, private usersService: UsersService, private cookie: CookieService, private locationBack: Location,
    private appValidationService: AppValidationService, private socketService: SocketServiceService,
 
  ) { }

  ngOnInit(): void {
    this.createCampaignForm = this.fb.group({
      "campaign_name": ["", Validators.required], 
      "promo_code": ["", Validators.required], 
      "campaign_type": ["", Validators.required], 
      "campaign_sub_type": ["", Validators.required], 
      "frequently_setting": ["", Validators.required], 
      "grant_limit": [-1, Validators.required], 
      "wagering": [false, Validators.required], 
      "wagering_no": [-1, Validators.required], 
      "wager_type": ["-", Validators.required], 
      "wager_provider": ["-", Validators.required], 
      "banner": ["", Validators.required], 
      "rules": ["", ""], 
      "term_conditions": ["", ""], 
      "wagering_bonus_amount": [-1, Validators.required],
      "wagering_bonus_type": [-1, Validators.required],
      "wagering_payout_type": ["PER", ""],
      "wagering_back_bet_count": [-1, Validators.required], 
      "wagering_lay_bet_count": [-1, Validators.required], 
      "wagering_payout_x": [-1, Validators.required], 
      "wagering_turnover_muly": [-1, Validators.required], 
      "wagering_min_bet_count": [-1, Validators.required], 

      "bonus_amount_bonus_type": ["PER", Validators.required], 
      "bonus_amount": ["", Validators.required], 
      "bonus_cap_amount": ["", Validators.required], 
      "payable_amount_bonus_type": ["PER", Validators.required], 
      "payable_amount": ["", Validators.required], 
      "payable_cap_amount": ["", Validators.required], 
      "expiry_time": ["", Validators.required], 
      "expiry_date": ["", Validators.required], 
      "expiry_date_time": ["", ""], 
      "min_bet_amount": ["", Validators.required], 
      "desciption": ["", Validators.required], 
    });

    this.editCampaignForm = this.fb.group({
      "campaign_name": ["", ""], 
      "promo_code": ["", ""], 
      "Id": ["", Validators.required], 
      "campaign_type": ["", Validators.required], 
      "campaign_sub_type": ["", Validators.required], 
      "frequently_setting": ["", Validators.required], 
      "grant_limit": [-1, Validators.required], 
      "wagering": [false, Validators.required], 
      "wagering_no": [-1, Validators.required], 
      "wager_type": ["-", Validators.required], 
      "wager_provider": ["-", Validators.required], 
      "banner": ["", ""], 
      "rules": ["", ""], 
      "term_conditions": ["", ""], 
      "wagering_bonus_amount": [-1, Validators.required],
      "wagering_bonus_type": [-1, Validators.required],
      "wagering_payout_type": ["PER", ""],
      "wagering_back_bet_count": [-1, Validators.required], 
      "wagering_lay_bet_count": [-1, Validators.required], 
      "wagering_payout_x": [-1, Validators.required], 
      "wagering_turnover_muly": [-1, Validators.required], 
      "wagering_min_bet_count": [-1, Validators.required], 
      "bonus_amount_bonus_type": ["-", Validators.required], 
      "bonus_amount": [-1, Validators.required], 
      "bonus_cap_amount": [-1, Validators.required], 
      "payable_amount_bonus_type": ["", Validators.required], 
      "payable_amount": ["", Validators.required], 
      "payable_cap_amount": ["", Validators.required], 
      "expiry_time": ["", Validators.required], 
      "expiry_date": ["", Validators.required], 
      "expiry_date_time": ["", ""], 
      "min_bet_amount": ["", Validators.required], 
      "desciption": ["", Validators.required], 
    });

    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    this.getCampaign();
  
  }
  getfb()
  {
    
  }
  subMitEditForm()
  {
    this.submitted=true;
    if(this.editCampaignForm.valid)
    {
     let date:any;
      date= this.editCampaignForm.controls['expiry_date'].value;
     let time= this.editCampaignForm.controls['expiry_time'].value;
     let new_date=new Date(`${date}T${time}`);
     
 
    // console.log(this.createCampaignForm.controls.name.value);
     this.editCampaignForm.controls['expiry_date_time'].setValue(new_date);
     this.submitted=false;
     var formData = new FormData();
     if(this.image){
      formData.append("image",this.image);
     }
     formData.append("Id",this.editCampaignForm.controls.Id.value);
     formData.append("expiry_date_time",this.editCampaignForm.controls.expiry_date_time.value);
     formData.append("promo_code",this.editCampaignForm.controls.promo_code.value,);
     formData.append("campaign_name",this.editCampaignForm.controls.campaign_name.value);
     formData.append("campaign_type",this.editCampaignForm.controls.campaign_type.value);
     formData.append("campaign_sub_type",this.editCampaignForm.controls.campaign_sub_type.value);
     formData.append("wagering_no",this.editCampaignForm.controls.wagering_no.value);
     formData.append("frequently_setting",this.editCampaignForm.controls.frequently_setting.value);
     formData.append("grant_limit",this.editCampaignForm.controls.grant_limit.value);
     formData.append("wagering",this.editCampaignForm.controls.wagering.value);
     formData.append("bonus_amount_bonus_type",this.editCampaignForm.controls.bonus_amount_bonus_type.value);
     formData.append("bonus_amount",this.editCampaignForm.controls.bonus_amount.value);
     
     formData.append("bonus_cap_amount",this.editCampaignForm.controls.bonus_cap_amount.value);
     formData.append("payable_amount_bonus_type",this.editCampaignForm.controls.payable_amount_bonus_type.value);
     formData.append("payable_amount",this.editCampaignForm.controls.payable_amount.value);
     formData.append("expiry_time",this.editCampaignForm.controls.expiry_time.value);
     formData.append("expiry_date",this.editCampaignForm.controls.expiry_date.value);
     formData.append("min_bet_amount",this.editCampaignForm.controls.min_bet_amount.value);
 
 
     formData.append("desciption",this.editCampaignForm.controls.desciption.value);
     formData.append("rules",this.editCampaignForm.controls.rules.value);
     formData.append("term_conditions",this.editCampaignForm.controls.term_conditions.value);
     //formData.append("banner",this.createCampaignForm.controls.banner.value);
     formData.append("wagering_payout_x",this.editCampaignForm.controls.wagering_payout_x.value);
     formData.append("wager_type",this.editCampaignForm.controls.wager_type.value);
     formData.append("wager_provider",this.editCampaignForm.controls.wager_provider.value);
     formData.append("wagering_min_bet_count",this.editCampaignForm.controls.wagering_min_bet_count.value);
     formData.append("wagering_turnover_muly",this.editCampaignForm.controls.wagering_turnover_muly.value);
     formData.append("wagering_bonus_amount",this.editCampaignForm.controls.wagering_bonus_amount.value);
     formData.append("wagering_bonus_type",this.editCampaignForm.controls.wagering_bonus_type.value);
     formData.append("wagering_payout_type",this.editCampaignForm.controls.wagering_payout_type.value);
     formData.append("wagering_lay_bet_count",this.editCampaignForm.controls.wagering_lay_bet_count.value);
     formData.append("wagering_back_bet_count",this.editCampaignForm.controls.wagering_back_bet_count.value);
 
    
     this.wallet.edit_campaign(formData).subscribe((res:any) => {
       console.log(res)
       if(res.status == true){
          this.getCampaign();
          this.modalRef.hide();
         this.toastr.success(res?.msg,'',{
           positionClass: 'toast-bottom-right',
           timeOut:1000
          });
     } else {
       this.toastr.error(res?.msg,'',{
         timeOut: 10000,
       });
     }
     });
   }
  }
  subMitForm()
  {
    this.submitted=true;
    console.log(this.createCampaignForm)
   if(this.createCampaignForm.valid)
   {
    let date:any;
     date= this.createCampaignForm.controls['expiry_date'].value;
    let time= this.createCampaignForm.controls['expiry_time'].value;
    let new_date=new Date(`${date}T${time}`);
    

   // console.log(this.createCampaignForm.controls.name.value);
    this.createCampaignForm.controls['expiry_date_time'].setValue(new_date);
    this.submitted=false;
    var formData = new FormData();
    formData.append("image",this.image);
    formData.append("parent_user_name","null");
    formData.append("parent_id",this.adminDetails.parent_id);
    formData.append("user_name",this.adminDetails.user_name);
    formData.append("user_id",this.adminDetails.user_id);
    formData.append("expiry_date_time",this.createCampaignForm.controls.expiry_date_time.value);
    formData.append("promo_code",this.createCampaignForm.controls.promo_code.value,);
    formData.append("campaign_name",this.createCampaignForm.controls.campaign_name.value);
    formData.append("campaign_type",this.createCampaignForm.controls.campaign_type.value);
    formData.append("campaign_sub_type",this.createCampaignForm.controls.campaign_sub_type.value);
    formData.append("wagering_no",this.createCampaignForm.controls.wagering_no.value);
    formData.append("frequently_setting",this.createCampaignForm.controls.frequently_setting.value);
    formData.append("grant_limit",this.createCampaignForm.controls.grant_limit.value);
    formData.append("wagering",this.createCampaignForm.controls.wagering.value);
    formData.append("bonus_amount_bonus_type",this.createCampaignForm.controls.bonus_amount_bonus_type.value);
    formData.append("bonus_amount",this.createCampaignForm.controls.bonus_amount.value);

    formData.append("bonus_cap_amount",this.createCampaignForm.controls.bonus_cap_amount.value);
    formData.append("payable_amount_bonus_type",this.createCampaignForm.controls.payable_amount_bonus_type.value);
    formData.append("payable_amount",this.createCampaignForm.controls.payable_amount.value);
    formData.append("expiry_time",this.createCampaignForm.controls.expiry_time.value);
    formData.append("expiry_date",this.createCampaignForm.controls.expiry_date.value);
    formData.append("min_bet_amount",this.createCampaignForm.controls.min_bet_amount.value);


    formData.append("desciption",this.createCampaignForm.controls.desciption.value);
    formData.append("rules",this.createCampaignForm.controls.rules.value);
    formData.append("term_conditions",this.createCampaignForm.controls.term_conditions.value);
    //formData.append("banner",this.createCampaignForm.controls.banner.value);
    formData.append("wagering_payout_x",this.createCampaignForm.controls.wagering_payout_x.value);
    formData.append("wager_type",this.createCampaignForm.controls.wager_type.value);

    formData.append("wager_provider",this.createCampaignForm.controls.wager_provider.value);
    formData.append("wagering_min_bet_count",this.createCampaignForm.controls.wagering_min_bet_count.value);
    formData.append("wagering_turnover_muly",this.createCampaignForm.controls.wagering_turnover_muly.value);
    formData.append("wagering_bonus_amount",this.createCampaignForm.controls.wagering_bonus_amount.value);
    formData.append("wagering_bonus_type",this.createCampaignForm.controls.wagering_bonus_type.value);
    formData.append("wagering_payout_type",this.createCampaignForm.controls.wagering_payout_type.value);
    formData.append("wagering_lay_bet_count",this.createCampaignForm.controls.wagering_lay_bet_count.value);
    formData.append("wagering_back_bet_count",this.createCampaignForm.controls.wagering_back_bet_count.value);

    let data = {
      parent_user_name:'null',
      parent_id:this.adminDetails.parent_id,
      user_name:this.adminDetails.user_name,
      user_id:this.adminDetails.user_id,
      expiry_date_time:this.createCampaignForm.controls.expiry_date_time.value,
      campaign_name:this.createCampaignForm.controls.campaign_name.value,
      promo_code:this.createCampaignForm.controls.promo_code.value,
      campaign_type:this.createCampaignForm.controls.campaign_type.value,
      campaign_sub_type:this.createCampaignForm.controls.campaign_sub_type.value,
      wagering_no:this.createCampaignForm.controls.wagering_no.value,
      frequently_setting:this.createCampaignForm.controls.frequently_setting.value,
      grant_limit:this.createCampaignForm.controls.grant_limit.value,
      wagering:this.createCampaignForm.controls.wagering.value,
      bonus_amount_bonus_type:this.createCampaignForm.controls.bonus_amount_bonus_type.value,
      bonus_amount:this.createCampaignForm.controls.bonus_amount.value,
      bonus_cap_amount:this.createCampaignForm.controls.bonus_cap_amount.value,
      payable_amount_bonus_type:this.createCampaignForm.controls.payable_amount_bonus_type.value,
      payable_amount:this.createCampaignForm.controls.payable_amount.value,
      payable_cap_amount:this.createCampaignForm.controls.payable_cap_amount.value,
      expiry_time:this.createCampaignForm.controls.expiry_time.value,
      expiry_date:this.createCampaignForm.controls.expiry_date.value,
      min_bet_amount:this.createCampaignForm.controls.min_bet_amount.value,
      desciption:this.createCampaignForm.controls.desciption.value,

      rules:this.createCampaignForm.controls.rules.value,
      term_conditions:this.createCampaignForm.controls.term_conditions.value,
      banner:this.createCampaignForm.controls.banner.value,
      wagering_payout_x:this.createCampaignForm.controls.wagering_payout_x.value,
      wager_type:this.createCampaignForm.controls.wager_type.value,
      wager_provider:this.createCampaignForm.controls.wager_provider.value,
      wagering_min_bet_count:this.createCampaignForm.controls.wagering_min_bet_count.value,
      wagering_turnover_muly:this.createCampaignForm.controls.wagering_turnover_muly.value,

    }
    this.wallet.create_campaign(formData).subscribe((res:any) => {
      if(res.status == true){
         this.getCampaign();
         this.modalRef.hide();
         this.createCampaignForm.reset();
        this.toastr.success(res?.msg,'',{
          positionClass: 'toast-bottom-right',
          timeOut:1000
         });
    } else {
      this.toastr.error(res?.msg,'',{
        timeOut: 10000,
      });
    }
    });
  }
  }


  processImageFile(event: any)
  {
    this.image = event.target.files[0];
    console.log(this.image)
    var reader = new FileReader();
		reader.readAsDataURL(this.image);
	
		reader.onload = (_event) => {
			
			this.urlImage = reader.result; 
   
		}
  }

  getCampaign()
  {
    let parms={
      user_id:this.adminDetails._id
    }
    this.wallet.get_campaign(parms).subscribe((res:any) => {
      console.log(res)
      this.campaignList=res.data;
      if(res.status == true){
       
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  }
  bType(val:any)
  {
    this.bmType=val;
    this.createCampaignForm.controls['payable_amount_bonus_type'].setValue(val);
    this.createCampaignForm.controls['bonus_amount_bonus_type'].setValue(val);
  }

  activeSubmit(id:any,visible:any)
  {
    let parms={
      user_id:this.adminDetails._id,
      _id:id._id,
      visible:visible
    }
    this.wallet.update_campaign(parms).subscribe((res:any) => {
      console.log(res)
      this.getCampaign()
      if(res.status == true){
        this.toastr.success(res.msg,'',{
          timeOut: 10000,
        });
    } else {
      this.toastr.error(res.msg,'',{
        timeOut: 10000,
      });
    }
  });
  }


 
  openCampaign(addCampaign: TemplateRef<any>) {
    this.wager=false;
    this.modalRef = this.modalService.show(
      addCampaign,
      Object.assign({}, { class: 'comissionSetting-modal modal-lg' })
    );
  }

  editFunctionCampaign(editCampaign: TemplateRef<any>,value:any) {
    this.editCampaignForm.controls['Id'].setValue(value?._id);
    this.editCampaignForm.controls['campaign_name'].setValue(value?.campaign_name);
    this.editCampaignForm.controls['promo_code'].setValue(value?.promo_code);
    this.editCampaignForm.controls['campaign_type'].setValue(value?.campaign_type);
    this.editCampaignForm.controls['campaign_sub_type'].setValue(value?.campaign_sub_type);
    this.editCampaignForm.controls['frequently_setting'].setValue(value?.frequently_setting);
    this.editCampaignForm.controls['grant_limit'].setValue(value?.grant_limit);
    this.editCampaignForm.controls['bonus_amount_bonus_type'].setValue(value?.bonus_amount_bonus_type);
    this.editCampaignForm.controls['bonus_amount'].setValue(value?.bonus_amount);
    this.editCampaignForm.controls['bonus_cap_amount'].setValue(value?.bonus_cap_amount);
    this.editCampaignForm.controls['payable_amount_bonus_type'].setValue(value?.payable_amount_bonus_type);
    this.editCampaignForm.controls['payable_amount'].setValue(value?.payable_amount);
    this.editCampaignForm.controls['payable_cap_amount'].setValue(value?.payable_cap_amount);
    this.editCampaignForm.controls['expiry_date'].setValue(value?.expiry_date);
    this.editCampaignForm.controls['expiry_time'].setValue(value?.expiry_time);
    this.editCampaignForm.controls['min_bet_amount'].setValue(value?.min_bet_amount);
    this.editCampaignForm.controls['desciption'].setValue(value?.desciption);
    this.editCampaignForm.controls['wagering'].setValue(value?.wagering);
    if(value?.wagering){
      this.wager=true;
    }
    this.editCampaignForm.controls['wagering_turnover_muly'].setValue(value?.wagering_turnover_muly);
    this.editCampaignForm.controls['wagering_min_bet_count'].setValue(value?.wagering_min_bet_count);
    this.editCampaignForm.controls['wager_provider'].setValue(value?.wager_provider);
    this.editCampaignForm.controls['wager_type'].setValue(value?.wager_type);
    this.editCampaignForm.controls['wagering_payout_x'].setValue(value?.wagering_payout_x);

    this.editCampaignForm.controls['wagering_bonus_amount'].setValue(value?.wagering_bonus_amount);
    this.editCampaignForm.controls['wagering_bonus_type'].setValue(value?.wagering_bonus_type);
    this.editCampaignForm.controls['wagering_payout_type'].setValue(value?.wagering_payout_type);
    this.editCampaignForm.controls['wagering_back_bet_count'].setValue(value?.wagering_back_bet_count);
    this.editCampaignForm.controls['wagering_lay_bet_count'].setValue(value?.wagering_lay_bet_count);

    this.image_val=value?.banner;
    this.editCampaignForm.controls['rules'].setValue(value?.rules);
    this.editCampaignForm.controls['term_conditions'].setValue(value?.term_conditions);

     
    this.modalRef = this.modalService.show(
      editCampaign,
      Object.assign({}, { class: 'comissionSetting-modal modal-lg' })
    );
  }

  getWageingEvent(event:any)
  {
    
    this.wager=event.target.checked;
    if(event.target.checked){
      this.createCampaignForm.controls['payable_amount_bonus_type'].setValue('-');
      this.createCampaignForm.controls['payable_amount'].setValue(-1);
      this.createCampaignForm.controls['payable_cap_amount'].setValue(-1);

      this.createCampaignForm.controls['bonus_amount_bonus_type'].setValue('-');
      this.createCampaignForm.controls['bonus_amount'].setValue(-1);
      this.createCampaignForm.controls['bonus_cap_amount'].setValue(-1);


      this.createCampaignForm.controls['wagering_bonus_amount'].setValue('');
      this.createCampaignForm.controls['wagering_bonus_type'].setValue('');
      this.createCampaignForm.controls['wagering_payout_type'].setValue('PER');
      this.createCampaignForm.controls['wagering_back_bet_count'].setValue('');
      this.createCampaignForm.controls['wagering_lay_bet_count'].setValue('');
      this.createCampaignForm.controls['wagering_payout_x'].setValue('');
      this.createCampaignForm.controls['wagering_turnover_muly'].setValue('');
      this.createCampaignForm.controls['wagering_min_bet_count'].setValue('');

    }
    else
    {
      this.createCampaignForm.controls['wagering_no'].setValue(-1);
      this.createCampaignForm.controls['bonus_amount_bonus_type'].setValue('-');
      this.createCampaignForm.controls['bonus_amount'].setValue(-1);
      this.createCampaignForm.controls['bonus_cap_amount'].setValue(-1);
    }

  }

}
