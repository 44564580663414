import { Component, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DateTime } from '../../dateTime';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ReportService } from '../services/report.service';
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { SocketServiceService } from '../services/socket-service.service';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-suspecious-bet',
  templateUrl: './suspecious-bet.component.html',
  styleUrls: ['./suspecious-bet.component.scss']
})
export class SuspeciousBetComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    "lengthChange": false,
    "ordering": false,
    "paging": false,
    "searching": false
  };
  dtTrigger: Subject<any> = new Subject();
  isSocket;
  modalRef: BsModalRef;
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  time: Date;
  date: Date;
  endDate: Date;
  endTime: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  // startdateTime: any
  // enddateTime: any
  selections: any = "0";
  startdateTime: Date = new Date()
  enddateTime: Date = new Date()
  minTime: Date = new Date()
  maxTime: Date = new Date()
  startAt: Date = new Date()
  todayDateTime:Date=new Date()
  itemsPerPage: number = 50;
  currentPage: number = 1;
  totalItems: number = 0;
  userName: any;
  susBetList: any;
  downRunnerRates: any;
  upRunnerRates: any;
  constructor(private loginService: LoginService,private datePipe: DatePipe, private report: ReportService, private router: Router,private modalService: BsModalService,
    private toastr: ToastrService, private locationBack: Location, private route: ActivatedRoute,private sport: SportService,
    private cookie: CookieService, private socketService: SocketServiceService) {

  }
  async ngOnInit() {

  
    // this.user_id = localStorage.getItem('userId');
    // await this.socketService.setUpSocketConnection();
    this.isSocket = this.cookie.get('is_socket');
    // if (this.update_user_id == null || this.update_user_id == undefined || this.update_user_id == '') {
    //   this.update_user_id = this.user_id;
    // }
    this.todayDateTime.setHours(23, 59, 59);
    this.startdateTime.setHours(0, 0, 0, 0);
    this.enddateTime.setHours(23, 59, 59);
    this.startAt.setHours(23, 59, 59);
    this.getsusBet('filterBtnClick');
    this.minTime.setHours(0);
    this.minTime.setMinutes(0);
    this.maxTime.setHours(23);
    this.maxTime.setMinutes(59);
    this.maxTime.setSeconds(59);
    if (this.startdateTime) {
      this.time = this.date = this.startdateTime;
      return;
    }
    if (this.enddateTime) {
      this.time = this.date = this.enddateTime;
      return;
    }
    this.date = this.time = new Date();
  }

  goToBack() {
    this.locationBack.back();
  }


  dateSelectionDone() {
    this.isDateVisible = true;
  }

  updateDate() {

    if (this.date) {
      this.startdateTime = DateTime.getDateTime(this.date, this.time);
    }
    if (!this.time) {
      this.time = this.date;
    }
  }

  updateEndDate() {
    if (this.endDate) {
      this.enddateTime = DateTime.getDateTime(this.endDate, this.endTime);
      this.enddateTime.setHours(23, 59, 59);
    }
   
  }
  updateTime() {
    if (this.time) {
      this.startdateTime = DateTime.getDateTime(this.date, this.time);
    }
  }
  updateEndTime() {
    if (this.endTime) {
      
      this.enddateTime = DateTime.getDateTime(this.endDate, this.endTime);
    }
  }


  showDate() {
    this.isDateVisible = true;
  }

  showTime() {
    this.isDateVisible = false;
  }

  close() {
    this._startpopoverRef.hide();

  }
  closeEndDatepicker() {
    this._endpopoverRef.hide()
  }
  now() {
    this.startdateTime = DateTime.now(this.date);
    this.time = this.startdateTime;
    this.enddateTime = DateTime.now(this.date);
    this.time = this.enddateTime;
  }

  today() {
    this.date = this.time = new Date();
    this.startdateTime = DateTime.now(this.date);
    this.enddateTime = DateTime.now(this.date);
  }
  clear() {
    //https://valor-software.com/ngx-bootstrap/#/timepicker#dynamic
    //Check component DemoTimepickerDynamicComponent  -> clear() method;  void 0 evaluates to undefined
    this.time = void 0;
    this.date = void 0;
    this.startdateTime = void 0;
    this.enddateTime = void 0;
  
  }
  onClickClear(){
    this.startdateTime = new Date();
    this.enddateTime = new Date()
    this.startdateTime.setHours(0, 0, 0, 0);
    this.enddateTime.setHours(23, 59, 59);
  
    this.getsusBet('filterBtnClick')
  }
  onSelectionChange(data) {
    this.selections = data;
    this.getsusBet('filterBtnClick')

  }
  pageChange(event?:any) {
    if(event){
      this.currentPage = event
    }
   
    this.getsusBet('filterBtnClick')
  }
  getsusBet(param){
    let data = {
      from_date: this.startdateTime.toISOString(),
      to_date: this.enddateTime.toISOString(),
      user_name: this.userName
    }
    this.report.susBetXcentral(data).subscribe((res) =>{
      console.log("194",res);
      this.susBetList = res.data;
    })
  }

  openModalBets(bets: TemplateRef<any>, market) {
    console.log("201",market);
    let a = market.downRunnerRates;
    let g = market.upRunnerRates
    let x = market.runners
    for(let i = 0;i< a.length;i++){
      let b = a[i].rate;
      for(let j = 0; j < b.length ; j++){
        for(let k =0 ; k < x.length; k++ ){
          if(b[j].id == x[k].tpRunnerID){
            b[j].selectionName = x[k].name;
          }
        }
        
      }
    }
    for(let i = 0;i< g.length;i++){
      let h = g[i].rate;
      for(let j = 0; j < h.length ; j++){
        for(let k =0 ; k < x.length; k++ ){
          if(h[j].id == x[k].tpRunnerID){
            h[j].selectionName = x[k].name;
          }
        }
        
      }
    }
    this.downRunnerRates = market.downRunnerRates
    this.upRunnerRates = market.upRunnerRates
    console.log("227",this.downRunnerRates,"\n",this.upRunnerRates);
    this.modalRef = this.modalService.show(
      bets,
      Object.assign({}, { class: 'bets-modal modal-lg' })
    );
  }
}
