<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="closed-user inner-content">
                <div class="page-title">
                    <h3>Update User Data </h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

               

                <div class="report-table">
                    <table datatable class="table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Name</th>
                                <th>Liability</th>
                               
                                <th>Balance</th>
                                <th>Settle</th>
                              
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let profit of userList;let i = index;">
                                <td>{{i+1}}</td>
                                <td (click)="profit?.user_type_id == 1 ? '' : sportsPl(profit.user_id,'filterBtnClick')" style="cursor: pointer;">
                                    {{profit?.user_name}}</td>
                                <td [ngClass]="{'red':profit.liability < 0 ,'green':profit.liability >= 0}">
                                    {{profit?.liability == undefined ? '0': profit?.liability | number : '0.0-2'}}</td>
                                <td [ngClass]="{'red':profit.balance < 0 ,'green':profit.balance >= 0}"> {{profit.balance
                                    == undefined ? '0': profit.balance | number : '0.0-2'}}</td>
                               
                                <td>
                                    <button type="button" class="btn btn-sm btn-success"
                                    (click)="openModalUserParentList(profit,userParentList)">P</button> &nbsp;&nbsp;
                                    <button type="button" class="btn btn-sm btn-success"
                                    (click)="openModalUpdateData(profit,updateData)">Settle</button>  &nbsp;&nbsp;
                                    <button type="button" class="btn btn-sm btn-success"
                                    (click)="report(profit)">R</button>
                                </td>
                            </tr>
                            <!-- <div *ngIf="userPlList.length == 0" style="text-align: center;">
                                No data avalible 
                            </div> -->
                        </tbody>
                        <!-- <tbody>
                            <tr>
                                <td></td>
                                <td>Total </td>
                                <td [ngClass]="{'red':cricket < 0 ,'green':cricket >= 0}"> {{cricket | number :
                                    '0.0-2'}}</td>
                                <td [ngClass]="{'red':soccer < 0 ,'green':soccer >= 0}">{{soccer | number : '0.0-2'}}
                                </td>
                                <td [ngClass]="{'red':tennis < 0 ,'green':tennis >= 0}">{{tennis | number : '0.0-2'}}
                                </td>
                                <td [ngClass]="{'red':casino < 0 ,'green':casino >= 0}">{{casino | number : '0.0-2'}}
                                </td>
                                <td [ngClass]="{'red':session < 0 ,'green':session >= 0}">{{session | number : '0.0-2'}}
                                </td>
                                <td [ngClass]="{'red':commission < 0 ,'green':commission >= 0}">{{commission| number :
                                    '0.0-2'}}</td>
                                <td [ngClass]="{'red':total < 0 ,'green':total >= 0}">{{total| number : '0.0-2'}}</td>
                                <td></td>
                            </tr>
                        </tbody> -->
                    </table>
                </div>
            </div>
        </div>
    </div>
</main>

<ng-template #userParentList>
  <div class="modal-header">
      <h4 class="modal-title">Parent List of {{userParentName}}</h4>
      <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
  </div>
  <table class="table">
      <tbody>
          <ng-container *ngFor="let parent of parentList; let i =index">
              <tr>
                  <td>{{parent?.name}}({{parent?.user_name}})</td>
              </tr>
              <!-- <tr>
                  <td>user2</td>
              </tr>
              <tr>
                  <td>user3</td>
              </tr> -->
              
          </ng-container>
      </tbody>
  </table>
</ng-template>

<ng-template #updateData>
  <form>
          <div class="modal-header">
              <h4 class="modal-title">Update Data of ({{updateUserData.user_name}})</h4>
              <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i
                      class="fa fa-times"></i></a>
          </div>
          <div class="modal-body no-scroll-body">
              <div class="body-inner">
                  <table class="table">
                      <tbody>
                          <!-- <tr>
                            <td>User Id :</td>
                              <td>
                                
                                  <input type="text" class="form-control" min="0" max="10000000000"
                                  [(ngModel)]="updateUserId"
                                      readonly>
                                 
                              </td>
                          </tr> -->
                          <tr>
                              <td>Liability :</td>
                              <td><input type="text"  placeholder="Enter Liability Amount"
                                [(ngModel)]="liability"  [ngModelOptions]="{standalone: true}"   required></td>
                          </tr>
                          <tr>
                            <td>Balance :</td>
                            <td><input type="text"  placeholder="Enter Balance "
                              [(ngModel)]="amount"  [ngModelOptions]="{standalone: true}"   required></td>
                        </tr>
                      </tbody>
                  </table>
              </div>
          </div>

          <div class="modal-footer">
              <button (click)="updateCustomerData()" type="button"
                  class="btn btn-success">Update</button>
          </div>
  </form>
</ng-template>