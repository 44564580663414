<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>

    <div class="full-wrap">
        <div class="report-page">
            <div class="bet-history inner-content">
                <div class="page-title">
                    <h3>Socre & TV</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>


               
                <div class="filter-tab">

                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-all">
                            <div class="report-table">
                                <table datatable class="table">
                                     <tr class="header">
                                      <th>
                                        Event Name
                                      </th>
                                      <th>
                                        TV
                                      </th>
                                      <th>
                                        Score
                                      </th>
                                      <th>
                                        Action
                                      </th>
                                     </tr>
                                    <tbody >
                                        
                                            <tr *ngFor="let m of scroeTv;let i=index;">
                                              <td>{{m?.match_name}}: {{(m?.match_date | date :'dd-MM hh:mm
                                                aa')}} IST</td>
                                                <td>
                                                 
                                                  <input _ngcontent-c8="" type="text"
                                                  class="input-text ng-untouched ng-pristine ng-valid box-size" value="{{m?.match_tv_url}}"
                                                  class="input-text" (change)="getTvInput(m,$event,i)"
                                                  style="color:black;font-size: 16px;width:400px; margin-right: 10px;">
                                                </td>

                                                <td>
                                                 
                                                  <input _ngcontent-c8="" type="text" (change)="getScoreInput(m,$event,i)"
                                                  class="input-text ng-untouched ng-pristine ng-valid box-size"  value="{{m?.match_scoreboard_url}}"
                                                  class="input-text"
                                                  style="color:black;font-size: 16px;width:400px; margin-right: 10px;">
                                                </td>
                                                <td>
                                                  <button type="button" class="btn-info td-btn"
                                                        (click)="updateData(m,i)">Submit</button>
                                                </td>
                                               
                                            </tr>
                                    </tbody>
                                   

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

