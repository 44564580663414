import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { CookieService } from 'ngx-cookie-service';
import { HeaderComponent } from '../header/header.component';
@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.scss'],
  providers: [HeaderComponent],
})
export class CasinoComponent implements OnInit {
  exchangeGameList: any;
  constructor( private cookie: CookieService,private loginService: LoginService,private head: HeaderComponent,private router: Router, private sport: SportService) {

  }

  ngOnInit(): void {
    this.exchangeGame();
  }
  exchangeGame() {
    let data = {
      sport_id: "-100"
    };
    this.sport.getHomeMatchesList(data).subscribe((res) => {
      if (res.status == true) {
        this.exchangeGameList = res.data
      } else {
        if(res.logout == true){
          this.head.logoutUser();
        }
      }
    })
  }
}
