import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../services/login.service';
import { UsersService } from '../services/users.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { AppValidationService } from '../app-validation/app-validation.service';
import { WebsiteSettingService } from '../services/website-setting.service';
import { ValidatorControls} from '../app-validation/validation-controls.directive';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  providers: [HeaderComponent],
})
export class AddUserComponent implements OnInit {
	addUserFrom: FormGroup;
	userSportSettingDetails = [];
	public user_id: string;
  public showPassword: boolean;
  update_user_id: string;
  userDetail;
  loggedInUserTypeId;
  parentLevelIds = [];
  levels = []
  userPermissionsList = [];
  permissions = []
  sId: any;
  hiddenpass: Array<boolean> = [];
  userSportPartnerShip: any;
  addedSportShareList = [];
  addedSportShareDetails = {
    parent_share: 0,
    parent_id: null,
    parent_partnership_share: 0,
    user_share: 0,
    user_id: null
  };
  isUserNameExist: boolean = false;
  submitted: boolean = false;
  parentCommission = [];
  websiteList: object[];
  user_type_id: any;
  parentLogInData: any;
  params:any;
  update_user_type_id: any;
  constructor(private locationBack: Location, private fb: FormBuilder, private usersService: UsersService,private head: HeaderComponent,
    private cookie: CookieService, private loginService: LoginService, private router: Router,private websiteSettingService:WebsiteSettingService,
    private toastr: ToastrService, private route: ActivatedRoute, private appValidationService: AppValidationService) {
    this.route.params.subscribe((params) => {
      this.update_user_id = params.userid;
      this.update_user_type_id = params.userTypeId;
    });
  }

  ngOnInit(): void {
    this.parentLogInData = JSON.parse(localStorage.getItem('adminDetails'));
    if (this.update_user_id == null || this.update_user_id == undefined || this.update_user_id == '') {
      this.user_id = this.parentLogInData.user_id;
      this.user_type_id = this.parentLogInData.user_type_id;
      this.getLoginUseretails();
    } else if (this.router.url == '/addChild-user/' + this.update_user_id + '/'+ this.update_user_type_id) {
      this.user_id = this.update_user_id;
      this.user_type_id = this.update_user_type_id;
      this.getLoginUseretails();
    }
    this.createUserForm();
  }

  goToBack() {
    this.locationBack.back();
  }

  createUserForm() {
    this.addUserFrom = this.fb.group({
      parent_user_name: ['', Validators.required],
      domain: ['', Validators.required],
      domain_name:[''],
      name: ['', Validators.required],
      user_name: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      user_type_id: [ 1 ],
      parent_id: [null],
      create_no_of_child: [0],
      child_level: [1],
      point: [1, Validators.required],
      exposure_limit: [-1, Validators.required],
      match_commission: [0, Validators.required],
      session_commission: [0, Validators.required],
      //match_commission_loss: [0, Validators.required],
      //session_commission_loss: [0, Validators.required],
      sports_permission: [],
      sports_share: [],
      parent_level_ids: [],
      match_stack:[],
      is_wallet_parent_allow:[],
      is_wallet_allow:[]
    });
    this.applyValidationToFormGroup(this.addUserFrom, "AddUser")
  }

  get sportsSettingsFormArr(): FormArray {
    return this.addUserFrom.get('sports_settings') as FormArray;
  }

  selectedDomain(event) {
    if(event.domain_name){
      this.addUserFrom.controls.domain_name.setValue(event.domain_name)
    }
   }

  get f() { return this.addUserFrom.controls; }

  getLoginUseretails() {
    this.params= {
      "user_id":this.user_id,
      "user_type_id" : this.user_type_id
    }
    this.usersService.getLoginUseretails(this.params).subscribe(response => {
      if(response.status ==true){
        this.userDetail = response.data;
       
        this.addUserFrom.controls['parent_user_name'].setValue(this.userDetail.user_name);
        this.addUserFrom.controls['is_wallet_allow'].setValue(this.userDetail.is_wallet_allow);
        this.addUserFrom.controls['is_wallet_parent_allow'].setValue(this.userDetail.is_wallet_parent_allow);
        this.addUserFrom.controls['parent_id'].setValue(this.userDetail._id);
        this.addUserFrom.controls['point'].setValue(this.userDetail.point);
        this.addUserFrom.controls['match_stack'].setValue(this.userDetail.match_stack);
        this.loggedInUserTypeId = this.userDetail.user_type_id;
        if(this.loggedInUserTypeId == 0){
          
          this.websiteList = this.userDetail.domain;
        }
        this.userSportPartnerShip = this.userDetail.sports_share;
        if (this.router.url != '/update-user/' + this.user_id) {
          this.addSportsInfo();
        }
       if(this.userDetail.user_type_id != 0){
        this.parentLevelIds = this.userDetail.parent_level_ids.filter(data => data.user_id != null);
       }
        this.parentLevelIds.push({ user_id: this.userDetail._id, user_type_id: this.loggedInUserTypeId
           ,user_name : this.userDetail.user_name, name: this.userDetail.name
          });
        this.addUserFrom.controls['domain'].setValue(this.userDetail.domain._id);
        this.addUserFrom.controls['match_commission'].setValue(this.userDetail.match_commission);
        this.addUserFrom.controls['session_commission'].setValue(this.userDetail.session_commission);
        //this.addUserFrom.controls['match_commission_loss'].setValue(this.userDetail.match_commission_loss);
        //this.addUserFrom.controls['session_commission_loss'].setValue(this.userDetail.session_commission_loss);
        this.addUserFrom.controls['domain'].setValue(this.userDetail.domain._id);
        if (this.userDetail.user_type_id != 0) {
          this.addUserFrom.controls.domain_name.setValue(this.userDetail.domain_name)
          this.addUserFrom.controls['match_commission'].setValue(this.userDetail.match_commission);
          this.addUserFrom.controls['session_commission'].setValue(this.userDetail.session_commission);
        }
        this.addUserFrom.controls['session_commission'].setValidators([ValidatorControls.requiredValidator('Session Commission'),ValidatorControls.minValueValidator(this.userDetail.session_commission,true,("The session commision minimum value should be "+this.userDetail.session_commission))])
        this.addUserFrom.controls['match_commission'].setValidators([ValidatorControls.requiredValidator('Market Commission'),ValidatorControls.minValueValidator(this.userDetail.match_commission,true,("The match commision minimum value should be "+this.userDetail.match_commission))])
        this.addUserFrom.controls['match_commission'].updateValueAndValidity()
        this.addUserFrom.controls['session_commission'].updateValueAndValidity()
        if (this.loggedInUserTypeId != 1) {
          for (let j = this.loggedInUserTypeId - 1; j >= 1; j--) {
            this.levels.push({ 'level': j })
          }
        }
        // Set Sports
        this.userPermissionsList = this.userDetail.sports_permission;
        for (var i = 0; i < this.userPermissionsList.length; i++) {
          this.permissions.push({ 
            name: this.userPermissionsList[i].name,
            is_allow: this.userPermissionsList[i].is_allow,
            sport: this.userPermissionsList[i].sport,
            sport_id: this.userPermissionsList[i].sport_id})
        }
      } else {
        if(response.logout == true){
          this.head.logoutUser();
        }
      }
      
    }, error => {
      console.log(error)
    })
  }

  // sports_share
  addSportsInfo() {
    for (var i = 0; i < this.userSportPartnerShip.length; i++) {
      this.addedSportShareList.push({ sport: this.userSportPartnerShip[i].sport, sport_id: (this.userSportPartnerShip[i].sport_id).toString(), name:this.userSportPartnerShip[i].name ,percentage: [] });
      for (var j = 0; j <= this.userSportPartnerShip[i].percentage.length; j++) {
        if (j < this.userSportPartnerShip[i].percentage.length) {
          var percentage = this.userSportPartnerShip[i].percentage[j];
          delete percentage._id;
          this.addedSportShareList[i].percentage.push(percentage);
        }
      }
    }
  }


  addUser() {
    this.appValidationService.markFormGroupTouched(this.addUserFrom)
    console.log(this.addUserFrom)
      if (this.addUserFrom.invalid) {
        this.toastr.error("Please fill all mandetory fields",'',{
          timeOut: 10000,
        });
        return
      }
      else {
        this.addUserFrom.controls['parent_level_ids'].setValue(this.parentLevelIds);
        this.addUserFrom.controls['sports_share'].setValue(this.addedSportShareList);
        this.addUserFrom.controls['sports_permission'].setValue(this.permissions);
        if (this.addUserFrom.invalid || this.isUserNameExist) {
          return
        }
        delete this.addUserFrom.value['child_level'];
        delete this.addUserFrom.value['parent_user_name'];
        delete this.addUserFrom.value['create_no_of_child'];
        delete this.addUserFrom.value['parent_level_ids'];
        delete this.addUserFrom.value['match_stack'];
        this.addUserFrom.value.sports_permission = this.addUserFrom.value.sports_permission.filter(t => t.is_allow == true );
        this.usersService.addUser(this.addUserFrom.value).subscribe((result) => {
          if (result.status == true) {
            this.toastr.success(result.msg,'',{
              positionClass: 'toast-bottom-right',
              timeOut:1000
             });
            this.router.navigate(['user'])
          } else {
            this.toastr.error(result.msg,'',{
              timeOut: 10000,
            });
            if(result.logout == true){
              this.head.logoutUser();
            }
          }
        }, (err) => {
          console.log(err);
        });
      }
  }

  setUserTypeName(typeName) {
    this.addUserFrom.controls['user_type_id'].setValue(  1 );
  }

  checkedPermissions(event, index) {
    this.permissions[index].is_allow = event.target.checked;
  }


  checkUserName(username) {
    var obj: any = {};
    obj.user_name = username;
    this.usersService.checkUserName(obj).subscribe((result) => {
      if (result.msg != 'Username is already exists. ') {
        this.isUserNameExist = false;
        
        this.addUserFrom.controls['user_name'].setErrors({duplicateCheck:null});
        this.addUserFrom.controls['user_name'].updateValueAndValidity();
        //this.fb.group({ username: ['', Validators.required] })
      } else {
        this.isUserNameExist = true;
        this.addUserFrom.controls['user_name'].setValidators([ValidatorControls.duplicateCheck(this.addUserFrom.controls['user_name'].value,'user name already exist'),ValidatorControls.requiredValidator('user name is required'),ValidatorControls.cannotContainSpace('Invalid Username'),ValidatorControls.minLengthValidator(3,'Username should be minimum 3 characters')]);
        this.addUserFrom.controls['user_name'].updateValueAndValidity();
       // this.fb.group({ username: ['', Validators.required] })
      }
    }, (err) => {
      console.log(err);
    });
  }
  
  accordion(sportId) {
    this.sId = sportId;
  }

  hide(index) {
    this.hiddenpass[index] = !this.hiddenpass[index];
  }

  applyValidationToFormGroup(formGroupName, jsonArrayName) {
    this.appValidationService.applyValidationRulesToFromGroup(formGroupName, jsonArrayName).then((validators) => {
    }).catch(() => { })
  }

}
