<app-header [walletBalance]="walletBalance"></app-header>

<main>
    <div class="full-wrap" id="contentToConvert" #contentToConvert>
        <div class="report-page">
            <div class="user-list inner-content">
                <div class="page-title">
                    <h3>Client List</h3>
                    <div class="nav-list">
                        <div class="hierarchy">
                            <ul>
                                <li *ngFor='let value of hierarchy;let k=index'><a (click)='onUserNameClicked(value.userId,value.user_type_id,k+1,value.page)'>{{value.user_name}}</a></li> 
                            </ul>
                        </div>
                    </div>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div class="filterDiv">
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                <div class="filter-input text-start">
                                    <div class="form-group">
                                        <select class="form-select" style="min-width: 70px !important;"
                                            (change)="setSelectedOption($event.target.value)">
                                            <option value="10" style="color: #2370b0;" >10</option>
                                            <option value="20" style="color: #2370b0;" selected>20</option>
                                            <option value="50" style="color: #2370b0;">50</option>
                                            <option value="100" style="color: #2370b0;">100</option>
                                            <!-- <option value="200">200</option>
                                            <option value="500">500</option> -->
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="searchQuery"
                                            (ngModelChange)="searchFilter()" [ngModelOptions]="{standalone: true}"
                                            placeholder="Search UserName">
                                    </div>
                                    <div class="form-group" *ngIf="loggedInUser?.user_type_id == 0">
                                        <ng-select style="width:170px" [items]="searchedwebsiteList"
                                            bindLabel="host_name" bindValue="_id" (change)="searchFilter()"
                                            [ngModelOptions]="{standalone: true}" placeholder="Search by Domain"
                                            [(ngModel)]="searchdomainId">
                                        </ng-select>
                                    </div>

                                    <div class="form-group">
                                        <select class="form-select " placeholder="Select Child Level"
                                            style="min-width: 70px !important;" [(ngModel)]="searchlevelId"
                                            (ngModelChange)="searchFilter()" [ngModelOptions]="{standalone: true}">
                                            <option class="select-option" value='all' selected="selected">Level </option>
                                            <option class="select-option" *ngFor="let childlevel of levels"
                                                [value]="childlevel.level">{{childlevel.name}}</option>
                                        </select>
                                    </div>
                                    <button type="button" class="btn btn-light" (click)="reset()">Reset</button>
                                </div>
                                <!-- <div class="filter-btn text-center">
                                    <ul>
                                        <li><button type="button" class="btn btn-success">Allow</button></li>
                                        <li><button type="button" class="btn btn-danger">Block</button></li>
                                        
                                        <li><button type="button" class="btn btn-light" (click)="reset()">Reset</button>
                                        </li>
                                    </ul>
                                </div> -->
                                <div class="filter-toggle text-right">
                                    <div class="link-btn-group">
                                        <!-- <button type="button" class="btn btn-info"
                                            (click)="downloadCsv()">Excel</button> -->
                                        <button type="button" class="btn btn-info" (click)='addUser(addUserId,addUserTypeId)'><i
                                                    class="fa fa-user"></i> Add
                                                    Client</button>
                                        <button type="button" class="btn btn-info" (click)='addAgent(addUserId,addUserTypeId)'
                                                    *ngIf="loggedInUser?.user_type_id != '2'">
                                                    <i class="fa fa-user"></i> Add Agent</button>
                                        <button type="button" class="btn btn-info" (click)="downloadPDF()">PDF</button>
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="report-table">
                    <table class="table hide-on-down">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th *ngIf="loggedInUser?.user_type_id == 0">Domain Name</th>
                                <th>UserName
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" [class.hide]="assendingUsername"
                                        (click)="filterList('user_name','asc')"></i>
                                    <i class="fa fa-sort-amount-desc" aria-hidden="true"
                                        [class.hide]="!assendingUsername" (click)="filterList('user_name','desc')"></i>
                                </th>
                                <th>Exposure
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true"
                                        [class.hide]="assendingUsername"></i>
                                    <i class="fa fa-sort-amount-desc" aria-hidden="true"
                                        [class.hide]="!assendingUsername"></i>
                                </th>
                                <th>P | L
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true"
                                        [class.hide]="assendingUsername"></i>
                                    <i class="fa fa-sort  -amount-desc" aria-hidden="true"
                                        [class.hide]="!assendingUsername"></i>
                                </th>
                                <th>Balance
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" [class.hide]="assendingbalance"
                                        (click)="filterList('balance','asc')"></i>
                                    <i class="fa fa-sort-amount-desc" aria-hidden="true"
                                        [class.hide]="!assendingbalance" (click)="filterList('balance','desc')"></i>
                                </th>
                                <th>Credit</th>
                               <!-- <th *ngIf="loggedInUser?.user_type_id == 0 || loggedInUser?.user_type_id == 8 && adminDetails.belongs_to_unlimited">U Credit</th>-->
                                <th>Point
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" [class.hide]="assendingpoint"
                                        (click)="filterList('point','asc')"></i>
                                    <i class="fa fa-sort-amount-desc" aria-hidden="true" [class.hide]="!assendingpoint"
                                        (click)="filterList('point','desc')"></i>
                                </th>
                                <th>Action</th>
                                <th>C | L
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" [class.hide]="assendingCL"
                                        (click)="filterList('user_type_id','asc')"></i>
                                    <i class="fa fa-sort-amount-desc" aria-hidden="true" [class.hide]="!assendingCL"
                                        (click)="filterList('user_type_id','desc')"></i>
                                    <!-- <mat-icon class="material-icons"  )">filter_list</mat-icon> -->
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                *ngFor="let user of userList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: total_items } ; let i=index">
                                <td style="cursor: pointer;" title="{{user._id}}">{{currentPage != 1
                                    ?(currentPage-1)*itemsPerPage+i+1 : i+1}}</td>
                                <td *ngIf="loggedInUser?.user_type_id == 0"><span>{{user?.domain?.site_title}}</span>
                                </td>
                                <td
                                    (click)='user?.user_type_id == 1 ? "" :onUserNameClicked(user._id,user.user_type_id,"onclickusername")'>
                                    <span class="userLabel">
                                        <span *ngIf="user?.user_type_id != 1"> {{getClientRolePrefix(user?.user_type_id)}} </span>
                                        <span *ngIf="user?.user_type_id == 1"> C </span>

                                    </span>
                                    <a> &nbsp;{{user?.user_name}} [{{user?.name}}]&nbsp; <label *ngIf="user?.mobile">({{user?.mobile}})</label> 
                                        &nbsp;<img *ngIf="user?.belongs_to_unlimited" src="assets/images/infinity.png">
                                    </a>
                                    <span *ngIf="user?.self_lock_betting==1 || user?.parent_lock_betting == 1"><img
                                            class="user-name-Icon" src="assets/icon/betlock.png"></span>
                                    <span *ngIf="user?.self_lock_fancy_bet==1 || user?.parent_lock_fancy_bet"><img
                                            class="user-name-Icon" src="assets/icon/facebook-logo.png"></span>
                                    <span *ngIf="user?.self_lock_user==1 || user?.parent_lock_user == 1"><img
                                            class="user-name-Icon" src="assets/icon/user-lock.png"></span>
                                </td>
                                <!-- <td><a class="td-btn btn-danger btn-soft-danger">{{user?.liability | number : '0.0-2'}}</a></td> -->
                                <td><a class="td-btn "
                                        [ngClass]="{'btn-danger btn-soft-danger':user.liability < 0 ,'btn-success btn-soft-success':user.liability >= 0}"
                                        (click)="openModalExposure(user?._id,exposure)">{{user?.liability | number :
                                        '0.0-2'}}</a></td>
                                <td><a class="td-btn"
                                        [ngClass]="{'btn-danger btn-soft-danger':user.profit_loss < 0 ,'btn-success btn-soft-success':user.profit_loss >= 0}">{{user?.profit_loss
                                        | number : '0.0-2'}}</a></td>
                                <td> <a class="td-btn btn-success btn-soft-success">{{user?.balance | number :
                                        '0.0-2'}}</a></td>
                                <td>
                                    <a class="td-btn btn-success btn-soft-success no-radius" ngbTooltip="Chips Deposit"
                                        (click)="openModalDeposit(deposite);getUserAndParentDetails(user._id)">D</a>
                                    <a class="td-btn btn-danger btn-soft-danger no-radius" ngbTooltip="Chips Withdrawl"
                                        (click)="openModalWithdraw(withdraw);getUserAndParentDetails(user._id)">W</a>
                                </td>
                               <!-- <td *ngIf="loggedInUser?.user_type_id == 0 || loggedInUser?.user_type_id == 8 && adminDetails.belongs_to_unlimited">
                                    <a class="td-btn btn-success btn-soft-success no-radius" ngbTooltip="Chips Deposit"
                                        (click)="openModalUDeposit(depositeu);getUserAndParentDetails(user._id)">D</a>
                                        <a class="td-btn btn-danger btn-soft-danger no-radius" ngbTooltip="Chips Withdrawl"
                                        (click)="openModalUWithdraw(withdrawu);getUserAndParentDetails(user._id)">W</a>
                             
                                </td>-->
                                <td>{{user?.point}}</td>
                                <td>
                                    <div class="btn-list">
                                        <span clickOutside (clickOutside)="closeUserPasswordDiv(i)">
                                            <a class="td-btn btn-warning" ngbTooltip="Show Password"
                                                (blur)="closeUserPasswordDiv(i)" *ngIf="loggedInUser?.user_type_id == 0"
                                                (click)="openModalRawPwd(rawPwd,user._id,i);">
                                                <i class="fa fa-eye"></i>
                                            </a>
                                            <div class="UserPasswordDiv"
                                                [ngClass]="displayPasswordBox[i] ? 'displayBlock' : 'displayNone'">
                                                <table class="table tblPermission">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="2">
                                                                Password
                                                                
                                                            
                                                                <span class="close-btn-Round bg-danger pull-right" style="cursor: pointer;" (click)="closeUserPasswordDiv(i)">
                                                                    <i class="fa fa-close"></i>
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="2">
                                                                <span>Your password is 
                                                                    <b style="cursor: pointer;" ngxClipboard [cbContent]="'Content to be copied.'"
                                                                    (cbOnSuccess)="contentCopied($event)" (click)="copyContent(rawPassword)">{{rawPassword}} <div class="box" *ngIf="displayTooltip == true">
                                                                        <h5>Copied</h5>
                                                                    </div>
                                                                </b>
                                                             </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </span>
                                        <a class="td-btn btn-warning"
                                            (click)="openModalSharing(sharing) ; getPartnershipListByUserIdforPopUp(user._id ,user?.user_name)"
                                            ngbTooltip="Partnership Sharing"
                                            *ngIf="user?.user_type_id != 1">S</a>
                                        <a class="td-btn btn-secondary" ngbTooltip="Change Password"
                                            (click)="openModalUserPwd(userPwd);getUserAndParentDetails(user._id)"><i
                                                class="fa fa-key"></i></a>
                                        <span clickOutside (clickOutside)="closeUserPermissionDiv(i)">
                                            <a class="td-btn btn-secondary" ngbTooltip="Permission"
                                                (click)="userPermissionSetting(i)">P</a>
                                            <div class="UserPermissionDiv"
                                                [ngClass]="displayPermissionBox[i] ? 'displayBlock' : 'displayNone'">
                                                <table class="table tblPermission">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="2">
                                                                <span>{{user?.user_name}}</span>
                                                                <span class="close-btn-Round bg-danger pull-right"
                                                                    (click)="closeUserPermissionDiv(i)">
                                                                    <i class="fa fa-close"></i>
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <!-- <tr>
                                                            <td>Active</td>
                                                            <td>
                                                                <label class="switch"> 
                                                                    <input type="checkbox" value="appIsApproved" name="Active" class="chkPermission" checked>
                                                                    <span class="slider round"></span> 
                                                                </label>
                                                            </td>
                                                        </tr> -->
                                                        <tr *ngIf="loggedInUser?.user_type_id == 0">
                                                            <td>Allow MultiLogin</td>
                                                            <td>
                                                                <label class="switch">
                                                                    <input type="checkbox" value="true"
                                                                        (change)="allowAndNotAllowAgentsMultiLogin(user._id)"
                                                                        name="Active" class="chkPermission"
                                                                        [checked]="user.is_multi_login_allow==1?true:false">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bet Allow</td>
                                                            <td>
                                                                <label class="switch">
                                                                    <input type="checkbox" value="true"  
                                                                    [disabled]="user?.parent_lock_betting == 1"
                                                                        (change)='checkedBetAllow($event,user._id,user.parent_id)'
                                                                        name="Active" class="chkPermission"
                                                                        [checked]="user.self_lock_betting == 0 && user?.parent_lock_betting == 0 ? true : false">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fancy Bet Allow</td>
                                                            <td>
                                                                <label class="switch">
                                                                    <input type="checkbox" value="true"
                                                                    [disabled]="user?.parent_lock_fancy_bet == 1"
                                                                        (change)='checkedFancyBetAllow($event,user._id,user.parent_id,user.self_lock_fancy_bet)'
                                                                        name="Active" class="chkPermission"
                                                                        [checked]="user.self_lock_fancy_bet==0 && user?.parent_lock_fancy_bet == 0?true:false">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lock User</td>
                                                            
                                                            <td>
                                                                <label class="switch">
                                                                    <input type="checkbox" value="true"
                                                                        (change)="lockAccountOfUserAndTheirChilds(user._id, user.self_lock_user)"
                                                                        name="Active" class="chkPermission"
                                                                        [checked]="user.self_lock_user == 1 || user.parent_lock_user == 1 ? true :  false">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Close User Account</td>
                                                            <td>
                                                                <label class="switch">
                                                                    <input type="checkbox" value="true"
                                                                        (change)="closeAccountOfUserAndTheirChilds(user._id, user.self_close_account,user.balance,user.liability,user.profit_loss)"
                                                                        name="Active" class="chkPermission"
                                                                        [checked]="user.self_close_account==1?true:false"
                                                                        [disabled]="user.self_close_account==1">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Sports Settings</td>
                                                            <td>
                                                                <label class="switch">
                                                                    <input type="checkbox" value="true"
                                                                        (change)="parentValidationToggle(user,i)"
                                                                        name="Active" class="chkPermission"
                                                                        [checked]="user.check_event_limit">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </td>
                                                        </tr>

                                                        
                                                        <tr>
                                                            <td>Wallet Access</td>
                                                            <td>
                                                                <label class="switch">
                                                                    <input type="checkbox" value="true"
                                                                        (change)="walletAcceessPermision(user,i,$event)"
                                                                        name="Active" class="chkPermission"
                                                                        [checked]="user.is_wallet_allow">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Parent Wallet Access</td>
                                                            <td>
                                                                <label class="switch">
                                                                    <input type="checkbox" value="true"
                                                                        (change)="parentwalletAcceessPermision(user,i,$event)"
                                                                        name="Active" class="chkPermission"
                                                                        [checked]="user.is_wallet_parent_allow">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </td>
                                                        </tr>

                                                        <!-- <tr>
                                                            <td colspan="2">Third Party Game</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SuperNova</td>
                                                            <td>
                                                                <label class="switch"> 
                                                                    <input type="checkbox" value="appIsApproved" name="Active" class="chkPermission">
                                                                    <span class="slider round"></span> 
                                                                </label>
                                                            </td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </div>
                                        </span>
                                        <a class="td-btn btn-info" ngbTooltip="Child Block Market"
                                            (click)="blockMaster(user)">C-B</a>
                                        <a class="td-btn balance-sheet btn-info" ngbTooltip="Report"
                                            (click)="downlineList(user)">R</a>
                                        <a class="td-btn balance-sheet btn-info" ngbTooltip="Market-Limit"
                                            (click)="openModalSportSetting(sportSetting,user);"><i
                                                class="fa fa-cog"></i></a>
                                                
                                        <a class="td-btn balance-sheet btn-info" ngbTooltip="Permission Setting"
                                            (click)="openModalPermissionSetting(permissionSetting)"
                                            *ngIf="loggedInUser?.user_type_id == 0"> P-S</a>
                                        <a class="td-btn balance-sheet btn-info" ngbTooltip="Comission Setting"
                                            (click)="openModalComissionSetting(comissionSetting,user)"> C</a>
                                        <a class="td-btn balance-sheet btn-info" ngbTooltip="Parent List"
                                            (click)="openModalUserParentList(user,userParentList)"> L </a>
                                            <a class="td-btn balance-sheet btn-info" ngbTooltip="Credit Refernce" *ngIf="loggedInUser?.user_type_id == 0 && user?.belongs_to_credit_reference == 1"
                                            (click)="openModalCR(user,creditRefModal)">C-R</a>
                                        <a class="td-btn btn-warning" ngbTooltip="Add Child"
                                            (click)="redirectToAddChild(user._id,user?.user_type_id,user)"
                                            *ngIf="user?.user_type_id != '1' && user?.self_lock_user != 1 && user?.parent_lock_user != 1 && user?.belongs_to_credit_reference == 0">+</a>
                                            <a class="td-btn btn-warning" *ngIf="user?.user_type_id != '1'" ngbTooltip="Promotion Link"
                                            (click)="copyInputMessage(user)"
                                            ><i class="fa fa-handshake-o" aria-hidden="true"></i></a>
                                        </div>
                                </td>
                                <td>
                                    <a class="td-btn btn-secondary" *ngIf="user?.user_type_id != '1'"
                                        ngbTooltip="Child-level">{{user?.user_type_id}}</a>
                                    <a class="td-btn btn-secondary" *ngIf="user?.user_type_id == '1'">-</a>
                                </td>
                            </tr>
                            <tr *ngIf="userList.length == 0">
                                <td colspan="12"> No user data found </td>
                            </tr>
                            <tr *ngIf="userList.length != 0">
                                <td></td>
                                <td></td>
                                <td>Total</td>
                                <td> <a class="td-btn" [ngClass]="{'btn-danger btn-soft-danger':expoTotal < 0 ,'btn-success btn-soft-success':expoTotal >= 0}">{{expoTotal | number : '0.0-2'}}</a></td>
                                <td> <a class="td-btn" [ngClass]="{'btn-danger btn-soft-danger':plTotal < 0 ,'btn-success btn-soft-success':plTotal >= 0}">{{plTotal | number : '0.0-2'}}</a></td>
                                <td> <a class="td-btn" [ngClass]="{'btn-danger btn-soft-danger':balTotal < 0 ,'btn-success btn-soft-success':balTotal >= 0}">{{balTotal | number : '0.0-2'}}</a></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table hide-on-up">
                        <thead>
                            <tr>
                                <!-- <th *ngIf="loggedInUser?.user_type_id == 0">Domain Name</th> -->
                                <th>UserName |
                                    <span *ngIf="loggedInUser?.user_type_id == 0">Domain Name </span>
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" [class.hide]="assendingUsername"
                                        (click)="filterList('user_name','asc')"></i>
                                    <i class="fa fa-sort-amount-desc" aria-hidden="true"
                                        [class.hide]="!assendingUsername" (click)="filterList('user_name','desc')"></i>
                                </th>
                                <th>Exposure
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true"
                                        [class.hide]="assendingUsername"></i>
                                    <i class="fa fa-sort-amount-desc" aria-hidden="true"
                                        [class.hide]="!assendingUsername"></i>
                                </th>
                                <th>P | L
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true"
                                        [class.hide]="assendingUsername"></i>
                                    <i class="fa fa-sort  -amount-desc" aria-hidden="true"
                                        [class.hide]="!assendingUsername"></i>
                                </th>
                                <th>Balance
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" [class.hide]="assendingbalance"
                                        (click)="filterList('balance','asc')"></i>
                                    <i class="fa fa-sort-amount-desc" aria-hidden="true"
                                        [class.hide]="!assendingbalance" (click)="filterList('balance','desc')"></i>
                                </th>
                                <th>Point
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" [class.hide]="assendingpoint"
                                        (click)="filterList('point','asc')"></i>
                                    <i class="fa fa-sort-amount-desc" aria-hidden="true" [class.hide]="!assendingpoint"
                                        (click)="filterList('point','desc')"></i>
                                </th>
                                <th>Action</th>
                                <!-- <th>C | L
                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" [class.hide]="assendingCL"
                                        (click)="filterList('user_type_id','asc')"></i>
                                    <i class="fa fa-sort-amount-desc" aria-hidden="true" [class.hide]="!assendingCL"
                                        (click)="filterList('user_type_id','desc')"></i>
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let user of userList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: total_items } ; let i=index">
                                <!-- <td style="cursor: pointer;" title="{{user._id}}">{{currentPage != 1
                                    ?(currentPage-1)*itemsPerPage+i+1 : i+1}}</td> -->
                                <!-- <td *ngIf="loggedInUser?.user_type_id == 0"><span>{{user?.domain?.site_title}}</span>
                                </td> -->
                                <td
                                    (click)='user?.user_type_id == 1 ? "" :onUserNameClicked(user._id,user.user_type_id,"onclickusername")'>
                                    <span class="userLabel">
                                        <span *ngIf="user?.user_type_id != 1"> A </span>
                                        <span *ngIf="user?.user_type_id == 1"> C </span>
                                    </span>
                                    <a> &nbsp;{{user?.user_name}} [{{user?.name}}]<label *ngIf="user?.mobile">({{user?.mobile}})</label> 
                                        &nbsp;<img *ngIf="user?.belongs_to_unlimited" src="assets/images/infinity.png">
                                    </a>
                                    <span *ngIf="user?.self_lock_betting == 1 || user?.parent_lock_betting == 1"><img
                                            class="user-name-Icon" src="assets/icon/betlock.png"></span>
                                    <span *ngIf="user?.self_lock_fancy_bet==1 || user?.parent_lock_fancy_bet"><img
                                            class="user-name-Icon" src="assets/icon/facebook-logo.png"></span>
                                    <span *ngIf="user?.self_lock_user==1 || user?.parent_lock_user == 1"><img
                                            class="user-name-Icon" src="assets/icon/user-lock.png"></span>
                                            <br>

                                    <span *ngIf="loggedInUser?.user_type_id == 0" class="domain-cell"><i class="fa fa-globe"></i> {{user?.domain?.site_title}}</span>
                                </td>
                                <!-- <td><a class="td-btn btn-danger btn-soft-danger">{{user?.liability | number : '0.0-2'}}</a></td> -->
                                <td><a class="td-btn "
                                        [ngClass]="{'btn-danger btn-soft-danger':user.liability < 0 ,'btn-success btn-soft-success':user.liability >= 0}"
                                        (click)="openModalExposure(user?._id,exposure)">{{user?.liability | number :
                                        '0.0-2'}}</a></td>
                                        <td><a class="td-btn"
                                            [ngClass]="{'btn-danger btn-soft-danger':user.profit_loss < 0 ,'btn-success btn-soft-success':user.profit_loss >= 0}">{{user?.profit_loss
                                            | number : '0.0-2'}}</a></td>
                                <td> <a class="td-btn btn-success btn-soft-success">{{user?.balance | number : '0.0-2'}}</a></td>
                                
                                <td>{{user?.point}}</td>
                                <td>
                                    <div class="dropdown action-menu">
                                    <a class="td-btn btn-secondary" data-toggle="dropdown" (click)="openActionList(user)"><i class="fa fa-ellipsis-h"></i></a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <div class="actionListDiv" >
                                        <div class="btn-list">
                                            <a class="td-btn btn-success btn-soft-success no-radius text-center" style="font-size: 15px;border-bottom:1px solid rgb(109, 105, 105)"><b>{{user?.user_name}}</b></a>
                                        <a class="td-btn btn-success btn-soft-success no-radius" (click)="openModalDeposit(deposite);getUserAndParentDetails(user._id)"><i
                                        class="fa fa-usd"></i> Chips Deposit</a>
                                        <a class="td-btn btn-danger btn-soft-danger no-radius" (click)="openModalWithdraw(withdraw);getUserAndParentDetails(user._id)"><i
                                        class="fa fa-usd"></i> Chips Withdrawl</a>
                                            <span clickOutside (clickOutside)="closeUserPasswordDiv(i)">
                                                <a class="td-btn btn-warning" (blur)="closeUserPasswordDiv(i)" *ngIf="loggedInUser?.user_type_id == 0"
                                                    (click)="openModalRawPwd(rawPwd,user._id,i);">
                                                    <i class="fa fa-eye"></i> Show Password
                                                </a>
                                                <div class="UserPasswordDiv"
                                                    [ngClass]="displayPasswordBox[i] ? 'displayBlock' : 'displayNone'">
                                                    <table class="table tblPermission">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="2">
                                                                    Password
                                                                    <span class="close-btn-Round bg-success " style="cursor: pointer;" (click)="copyTextToClipboard(rawPassword)"><i class="fa fa-copy" ></i></span>
                                                                    <span class="close-btn-Round bg-danger pull-right"
                                                                        style="cursor: pointer;"
                                                                        (click)="closeUserPasswordDiv(i)">
                                                                        <i class="fa fa-close"></i>
                                                                    </span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="2">
                                                                    <span>Your password is 
                                                                        <b style="cursor: pointer;" ngxClipboard [cbContent]="'Content to be copied.'"
                                                                        (cbOnSuccess)="contentCopied($event)" (click)="copyContent(rawPassword)">{{rawPassword}} <div class="box" *ngIf="displayTooltip == true">
                                                                            <h5>Copied</h5>
                                                                        </div>
                                                                    </b>
                                                                 </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </span>
                                            <a class="td-btn btn-warning"
                                                (click)="openModalSharing(sharing) ; getPartnershipListByUserIdforPopUp(user._id ,user?.user_name)"
                                                ngbTooltip="Partnership Sharing"
                                                *ngIf="user?.user_type_id != 1"><i
                                                class="fa fa-share-alt"></i> Sharing</a>
                                            <a class="td-btn btn-secondary" (click)="openModalUserPwd(userPwd);getUserAndParentDetails(user._id)"><i
                                                    class="fa fa-key"></i> Change Password</a>
                                            <span clickOutside (clickOutside)="closeMobilsUserPermissionDiv(i)">
                                                <a class="td-btn btn-secondary" (click)="userMobilePermissionSetting(i)"><i
                                                    class="fa fa-cog"></i> Permission</a>
                                                <div class="UserPermissionDiv" style="left:-125% !important;"
                                                    [ngClass]="mobileDisplayPermissionBox[i] ? 'displayBlock' : 'displayNone'">
                                                    <table class="table tblPermission">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="2">
                                                                    <span>{{user?.user_name}}</span>
                                                                    <span class="close-btn-Round bg-danger pull-right"
                                                                        (click)="closeMobilsUserPermissionDiv(i)">
                                                                        <i class="fa fa-close"></i>
                                                                    </span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <!-- <tr>
                                                                <td>Active</td>
                                                                <td>
                                                                    <label class="switch"> 
                                                                        <input type="checkbox" value="appIsApproved" name="Active" class="chkPermission" checked>
                                                                        <span class="slider round"></span> 
                                                                    </label>
                                                                </td>
                                                            </tr> -->
                                                            <tr *ngIf="loggedInUser?.user_type_id == 0">
                                                                <td>Allow MultiLogin</td>
                                                                <td>
                                                                    <label class="switch">
                                                                        <input type="checkbox" value="true"
                                                                            (change)="allowAndNotAllowAgentsMultiLogin(user._id)"
                                                                            name="Active" class="chkPermission"
                                                                            [checked]="user.is_multi_login_allow==1?true:false">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Bet Allow</td>
                                                                <td>
                                                                    <label class="switch">
                                                                        <input type="checkbox" value="true"  
                                                                        [disabled]="user?.parent_lock_betting == 1"
                                                                            (change)='checkedBetAllow($event,user._id,user.parent_id)'
                                                                            name="Active" class="chkPermission"
                                                                            [checked]="user.self_lock_betting == 0 && user?.parent_lock_betting == 0 ? true : false">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Fancy Bet Allow</td>
                                                                <td>
                                                                    <label class="switch">
                                                                        <input type="checkbox" value="true"
                                                                        [disabled]="user?.parent_lock_fancy_bet == 1"
                                                                            (change)='checkedFancyBetAllow($event,user._id,user.parent_id,user.self_lock_fancy_bet)'
                                                                            name="Active" class="chkPermission"
                                                                            [checked]="user.self_lock_fancy_bet==0 && user?.parent_lock_fancy_bet == 0?true:false">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lock User</td>
                                                                <td>
                                                                    <label class="switch">
                                                                        <input type="checkbox" value="true"
                                                                        (change)="lockAccountOfUserAndTheirChilds(user._id, user.self_lock_user)"
                                                                        name="Active" class="chkPermission"
                                                                        [checked]="user.self_lock_user == 1 || user.parent_lock_user == 1 ? true :  false">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Close User Account</td>
                                                                <td>
                                                                    <label class="switch">
                                                                        <input type="checkbox" value="true"
                                                                            (change)="closeAccountOfUserAndTheirChilds(user._id, user.self_close_account,user.balance,user.liability,user.profit_loss)"
                                                                            name="Active" class="chkPermission"
                                                                            [checked]="user.self_close_account==1?true:false"
                                                                            [disabled]="user.self_close_account==1">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                        
                                                            <tr>
                                                                <td>Sports Settings</td>
                                                                <td>
                                                                    <label class="switch">
                                                                        <input type="checkbox" value="true"
                                                                            (change)="parentValidationToggle(user,i)"
                                                                            name="Active" class="chkPermission"
                                                                            [checked]="user.check_event_limit">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Wallet Access</td>
                                                                <td>
                                                                    <label class="switch">
                                                                        <input type="checkbox" value="true"
                                                                            (change)="walletAcceessPermision(user,i,$event)"
                                                                            name="Active" class="chkPermission"
                                                                            [checked]="user.is_wallet_allow">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Parent Wallet Access</td>
                                                                <td>
                                                                    <label class="switch">
                                                                        <input type="checkbox" value="true"
                                                                            (change)="parentwalletAcceessPermision(user,i,$event)"
                                                                            name="Active" class="chkPermission"
                                                                            [checked]="user.is_wallet_parent_allow">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                        
                                                            <!-- <tr>
                                                                <td colspan="2">Third Party Game</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SuperNova</td>
                                                                <td>
                                                                    <label class="switch"> 
                                                                        <input type="checkbox" value="appIsApproved" name="Active" class="chkPermission">
                                                                        <span class="slider round"></span> 
                                                                    </label>
                                                                </td>
                                                            </tr> -->
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </span>
                                            <a class="td-btn btn-info" (click)="blockMaster(user)"><i
                                                class="fa fa-ban"></i> Child Block Market</a>
                                            <a class="td-btn balance-sheet btn-info" (click)="downlineList(user)"><i
                                                class="fa fa-file-text"></i> Report</a>
                                            <a class="td-btn balance-sheet btn-info" (click)="openModalSportSetting(sportSetting,user);"><i
                                                    class="fa fa-cog"></i> Market Limit</a>
                                            <a class="td-btn balance-sheet btn-info" (click)="openModalPermissionSetting(permissionSetting)"
                                                *ngIf="loggedInUser?.user_type_id == 0"><i
                                                class="fa fa-cog"></i> Permission Setting</a>
                                            <a class="td-btn balance-sheet btn-info" (click)="openModalComissionSetting(comissionSetting,user)"><i
                                                class="fa fa-cog"></i> Comission Setting</a>
                                            <a class="td-btn balance-sheet btn-info" (click)="openModalUserParentList(user,userParentList)"><i
                                                class="fa fa-list"></i> Parent List</a>
                                                <a class="td-btn balance-sheet btn-info" ngbTooltip="Credit Refernce" *ngIf="loggedInUser?.user_type_id == 0 && user?.belongs_to_credit_reference == 1"
                                                (click)="openModalCR(user,creditRefModal)">C-R</a>
                                            <a class="td-btn btn-warning" (click)="redirectToAddChild(user._id,user?.user_type_id,user)"
                                                *ngIf="user?.user_type_id != '1' && user?.self_lock_user != 1 && user?.parent_lock_user != 1 && user?.belongs_to_credit_reference == 0"><i
                                                class="fa fa-plus"></i> Add Child</a>
                                                
                                            <a class="td-btn btn-secondary" *ngIf="user?.user_type_id != '1'"><i
                                                class="fa fa-level-up"></i> Child Level: {{user?.user_type_id}}</a>
                                            <a class="td-btn btn-secondary" *ngIf="user?.user_type_id == '1'"><i
                                                class="fa fa-level-up"></i> Child Level: -</a>
                                                <a class="td-btn btn-warning" *ngIf="user?.user_type_id != '1'" ngbTooltip="Promotion Link"
                                                (click)="copyInputMessage(user)"
                                                ><i class="fa fa-handshake-o" aria-hidden="true"> Link</i></a>
                                       
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </td>
                                <!-- <td>
                                    <a class="td-btn btn-secondary" *ngIf="user?.user_type_id != '1'"
                                        ngbTooltip="Child-level">{{user?.user_type_id}}</a>
                                    <a class="td-btn btn-secondary" *ngIf="user?.user_type_id == '1'">-</a>
                                </td> -->
                            </tr>
                            <tr *ngIf="userList.length == 0">
                                <td colspan="12"> No user data found </td>
                            </tr>
                            <tr *ngIf="userList.length != 0">
                                <td>Total</td>
                                <td> <a class="td-btn" [ngClass]="{'btn-danger btn-soft-danger':expoTotal < 0 ,'btn-success btn-soft-success':expoTotal >= 0}">{{expoTotal | number : '0.0-2'}}</a></td>
                                <td> <a class="td-btn" [ngClass]="{'btn-danger btn-soft-danger':plTotal < 0 ,'btn-success btn-soft-success':plTotal >= 0}">{{plTotal | number : '0.0-2'}}</a></td>
                                <td> <a class="td-btn" [ngClass]="{'btn-danger btn-soft-danger':balTotal < 0 ,'btn-success btn-soft-success':balTotal >= 0}">{{balTotal | number : '0.0-2'}}</a></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="report-bottom-paginate">
                    <pagination-controls (pageChange)="pageChange($event)" class="user-list-pagination">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</main>
<!-- unlimted Point add-->
<ng-template #depositeu>
    <form [formGroup]='depositWithdrawlForm'>
        <ng-container *ngFor="let uPData of userParentData;let i = index;">
            <div class="modal-header">
                <h4 class="modal-title">Deposit to ({{uPData.user_name}})</h4>
                <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i
                        class="fa fa-times"></i></a>
            </div>
            <div class="modal-body no-scroll-body">
                <div class="body-inner">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>Deposite Chips :</td>
                                <td>
                                    <input type="number" class="form-control" min="0" max="10000000000"
                                        oninput="validity.valid||(value='');" placeholder="Enter Chips"
                                        formControlName="accChips">
                                    <span *ngIf="depositWithdrawlForm.controls.accChips.errors"
                                        class="red-alert-message">
                                        {{depositWithdrawlForm.controls.accChips.errors |DisplayErrorMessage}}
                                    </span>
                                </td>
                            </tr>
                           
                           
                            <tr>
                                <td>Remarks :</td>
                                <td><textarea name="" class="form-control" id="deposit-remark" formControlName="reMark"
                                        rows="2"></textarea></td>
                            </tr>
                            <tr>
                                <td>Password :</td>
                                <td><input type="password" class="form-control" placeholder="password"
                                        formControlName="userPass" autocomplete="new-password" required></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer">
                <button (click)="UdepositWithdrawl(uPData?._id,uPData?.parent_id?._id,1)" type="button"
                    class="btn btn-success">Deposit</button>
            </div>
        </ng-container>
    </form>
</ng-template>

<!--unlimted withdraw case-->
<ng-template #withdrawu>
    <form [formGroup]='depositWithdrawlForm'>
        <ng-container *ngFor="let uPData of userParentData;let i = index;">
            <div class="modal-header">
                <h4 class="modal-title">Withdraw from ({{uPData.user_name}})</h4>
                <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i
                        class="fa fa-times"></i></a>
            </div>
            <div class="modal-body no-scroll-body">
                <div class="body-inner">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>Withdraw Chips :</td>
                                <td>
                                    <input type="number" class="form-control" min="0" max="10000000000"
                                        oninput="validity.valid||(value='');" placeholder="Enter Chips"
                                        formControlName="accChips">
                                    <span *ngIf="depositWithdrawlForm.controls.accChips.errors"
                                        class="red-alert-message">
                                        {{depositWithdrawlForm.controls.accChips.errors |DisplayErrorMessage}}
                                    </span>
                                </td>
                            </tr>
                           
                            <tr>
                                <td>{{uPData.user_name}} :</td>
                                <td [innerHtml]="uPData.balance - depositWithdrawlForm.controls.accChips.value"></td>
                            </tr>
                            <tr>
                                <td>Remarks :</td>
                                <td><textarea name="" class="form-control" id="withdraw-remark" formControlName="reMark"
                                        rows="2"></textarea></td>
                            </tr>
                            <tr>
                                <td>Password :</td>
                                <td><input type="password" class="form-control" placeholder="password"
                                        formControlName="userPass" autocomplete="new-password" required></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer">
                <button (click)="UdepositWithdrawl(uPData?._id,uPData?.parent_id?._id,2)" type="button"
                    class="btn btn-success">Withdraw</button>
            </div>
        </ng-container>
    </form>
</ng-template>

<ng-template #deposite>
    <form [formGroup]='depositWithdrawlForm'>
        <ng-container *ngFor="let uPData of userParentData;let i = index;">
            <div class="modal-header">
                <h4 class="modal-title">Deposit to ({{uPData.user_name}})</h4>
                <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i
                        class="fa fa-times"></i></a>
            </div>
            <div class="modal-body no-scroll-body">
                <div class="body-inner">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>Deposite Chips :</td>
                                <td>
                                    <input type="number" class="form-control" min="0" max="10000000000"
                                        oninput="validity.valid||(value='');" placeholder="Enter Chips"
                                        formControlName="accChips">
                                    <span *ngIf="depositWithdrawlForm.controls.accChips.errors"
                                        class="red-alert-message">
                                        {{depositWithdrawlForm.controls.accChips.errors |DisplayErrorMessage}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>{{uPData?.parent_id?.user_name}}:</td>
                                <td
                                    [innerHtml]="uPData.parent_id.balance - depositWithdrawlForm.controls.accChips.value">
                                </td>
                            </tr>
                            <tr>
                                <td>{{uPData.user_name}} :</td>
                                <td [innerHtml]="uPData.balance - (-(depositWithdrawlForm.controls.accChips.value))">
                                </td>
                            </tr>
                            <tr>
                                <td>Remarks :</td>
                                <td><textarea name="" class="form-control" id="deposit-remark" formControlName="reMark"
                                        rows="2"></textarea></td>
                            </tr>
                            <tr>
                                <td>Password :</td>
                                <td><input type="password" class="form-control" placeholder="password"
                                        formControlName="userPass" autocomplete="new-password" required></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer">
                <button (click)="depositWithdrawl(uPData?._id,uPData?.parent_id?._id,1)" type="button"
                    class="btn btn-success">Deposit</button>
            </div>
        </ng-container>
    </form>
</ng-template>

<ng-template #withdraw>
    <form [formGroup]='depositWithdrawlForm'>
        <ng-container *ngFor="let uPData of userParentData;let i = index;">
            <div class="modal-header">
                <h4 class="modal-title">Withdraw from ({{uPData.user_name}})</h4>
                <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i
                        class="fa fa-times"></i></a>
            </div>
            <div class="modal-body no-scroll-body">
                <div class="body-inner">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>Withdraw Chips :</td>
                                <td>
                                    <input type="number" class="form-control" min="0" max="10000000000"
                                        oninput="validity.valid||(value='');" placeholder="Enter Chips"
                                        formControlName="accChips">
                                    <span *ngIf="depositWithdrawlForm.controls.accChips.errors"
                                        class="red-alert-message">
                                        {{depositWithdrawlForm.controls.accChips.errors |DisplayErrorMessage}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>{{uPData?.parent_id?.user_name}}:</td>
                                <td
                                    [innerHtml]="uPData.parent_id.balance + depositWithdrawlForm.controls.accChips.value">
                                </td>
                            </tr>
                            <tr>
                                <td>{{uPData.user_name}} :</td>
                                <td [innerHtml]="uPData.balance - depositWithdrawlForm.controls.accChips.value"></td>
                            </tr>
                            <tr>
                                <td>Remarks :</td>
                                <td><textarea name="" class="form-control" id="withdraw-remark" formControlName="reMark"
                                        rows="2"></textarea></td>
                            </tr>
                            <tr>
                                <td>Password :</td>
                                <td><input type="password" class="form-control" placeholder="password"
                                        formControlName="userPass" autocomplete="new-password" required></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer">
                <button (click)="depositWithdrawl(uPData?._id,uPData?.parent_id?._id,2)" type="button"
                    class="btn btn-success">Withdraw</button>
            </div>
        </ng-container>
    </form>
</ng-template>

<ng-template #sharing>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="loggedInUser?.user_type_id == 0">Update Sharing of ({{showSelectedUserName}})</h4>
        <h4 class="modal-title" *ngIf="loggedInUser?.user_type_id != 0"> Sharing of ({{showSelectedUserName}})</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <div class="modal-body">
        <div class="body-inner">
            <div class="partnershipList" *ngFor="let partnership of addedSportShareList ; let i=index">
                <div class="heading">{{partnership?.name}}
                    <span class="pull-right">
                        {{showSelectedUserName}} Current Share: {{partnership?.percentage[partnership.percentage.length
                        -1].parent_share}}</span>
                    <!-- <span class="pull-right"
                        *ngIf="partnership?.percentage[partnership.percentage.length -1].user_share != tempUserShareSportList[i]?.userCurrentShare">
                        {{showSelectedUserName}} Share: {{(partnership?.percentage[partnership.percentage.length
                        -1].parent_partnership_share)}}</span> -->
                    <br>
                    <!-- <span class="pull-right" *ngIf="partnership?.percentage[partnership.percentage.length -1].user_share == tempUserShareSportList[i]?.userCurrentShare">
                            Parent Partnership: {{partnership?.percentage[partnership.percentage.length -1].parent_partnership_share}}</span> -->
                            
                    <span class="pull-right"
                        *ngIf="loggedInUser?.user_type_id == 0">
                        Parent Updated Partnership: {{(partnership?.percentage[partnership.percentage.length
                        -2].parent_share)-(partnership?.percentage[partnership.percentage.length
                        -1].user_share)}}</span>
                </div>
                <div class="form-group" *ngIf="loggedInUser?.user_type_id == 0">
                    <label class="form-label">Partnership</label>
                    <input class="form-control" type="number" min="0" max="100" oninput="validity.valid||(value='');"
                        [(ngModel)]="partnership?.percentage[partnership.percentage.length -1].user_share"
                        (change)="checkUserEnteredShare(partnership?.percentage[partnership.percentage.length -1].user_share , partnership?.percentage[partnership.percentage.length -1].parent_share,partnership,i)">
                    <!-- <p class="red-message"
                        *ngIf="partnership?.percentage[partnership.percentage.length -1].user_share < partnership?.percentage[partnership.percentage.length -1].parent_share">
                        Please enter share greater than or equal to my partnership </p> -->
                        <p class="red-message"
                        *ngIf="(partnership?.percentage[partnership.percentage.length -2].parent_share)-(partnership?.percentage[partnership.percentage.length -1].user_share) < 0">
                        Please Don't enter partnership from your parent share... </p>
                </div>
            </div>
            <div class="partnershipList" *ngIf="loggedInUser?.user_type_id == 0">
                <div class="form-group">
                    <label class="form-label">Enter Password</label>
                    <input class="form-control" type="password" [(ngModel)]="updatePartnershipPassword">
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer" *ngIf="loggedInUser?.user_type_id == 0">
        <div class="text-end">
            <button type="button" (click)="updatePartnershipList()" class="btn btn-success">Submit</button>
        </div>
    </div>
</ng-template>

<ng-template #resetPwd>
    <div class="modal-header">
        <h4 class="modal-title">Change Password</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form [formGroup]='changePasswordForm' (ngSubmit)='onSubmitChangePassword()'>
        <div class="modal-body">
            <div class="body-inner">
                <div class="form-group">
                    <label>Old Password</label>
                    <input type="password" placeholder="Old Password" class="form-control password-box"
                        formControlName='old_password'>
                    <div *ngIf="submitted && f.old_password.errors">
                        <div *ngIf="f.old_password.errors.required" class="red-message">Old password is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>New Password</label>
                    <input type="password" placeholder="Password" class="form-control password-box"
                        formControlName='new_password'>
                    <div *ngIf="submitted && f.new_password.errors">
                        <div *ngIf="f.new_password.errors.required" class="red-message">Password is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Confirm Password</label>
                    <input type="password" placeholder="Password" class="form-control password-box"
                        formControlName='confirm_password'>
                    <div *ngIf="submitted && f.confirm_password.errors">
                        <div *ngIf="f.confirm_password.errors.required" class="red-message">Confirm password is required
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <div class="text-end">
                <button type="submit" class="btn btn-green">Submit</button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #userPwd>
    <ng-container *ngFor="let userData of userParentData;let i = index;">
        <div class="modal-header">
            <h4 class="modal-title">Reset Password : {{userData.user_name}}</h4>
            <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i
                    class="fa fa-times"></i></a>
        </div>
        <div class="modal-body no-scroll-body">
            <div class="body-inner">
                <div class="form-group inner-addon right-addon">
                    <label class="form-label">Password</label>
                    <div class="input-group" [formGroup]="resetPasswordForm">
                        <input [type]="showPassword ? 'text' : 'password'" id="password-field" pwd-control
                            formControlName="password" placeholder="" class="form-control" />

                        <div>
                            <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword"
                                [class.hide]="showPassword"></i>
                            <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
                                [class.hide]="!showPassword"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="text-end">
                <button type="submit" class="btn btn-success"
                    (click)="changeChildPwd(userData?._id,userData?.parent_id?._id)">Submit</button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #rawPwd>
    <div class="modal-header">
        <h4 class="modal-title">Enter Transaction In Password </h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
            <div class="form-list">
                <label class="form-heading">Enter Logged In Password</label>
                <input type="password" placeholder="Enter Logged In Password" class="password-box form-attribute"
                    [(ngModel)]="transactionPassword" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="getUserRawPassword()">Submit</button>
        </div>
    </div>
</ng-template>

<ng-template #permissionSetting>
    <div class="modal-header">
        <h4 class="modal-title">Update Permission Setting of username </h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="sportPermission" checked>
                <label class="form-check-label" for="sportPermission">Cricket</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="sportPermission1" checked>
                <label class="form-check-label" for="sportPermission1">Soccer</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="sportPermission2" checked>
                <label class="form-check-label" for="sportPermission2">Tennis</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success" >Submit</button>&nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #comissionSetting>
    <div class="modal-header">
        <h4 class="modal-title">Update Comission Setting of username </h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
            <div class="form-list">
                <label class="form-heading">Market Commission</label>
                <input type="number" min="0" max="99" placeholder="Market Commission" [(ngModel)]="marketCommission"
                    value="0" class="password-box form-attribute">
            </div>
           <!-- <div class="form-list">
                <label class="form-heading">Market Commission Loss</label>
                <input type="number" min="0" max="99" placeholder="Market Commission" [(ngModel)]="marketCommissionloss"
                    value="0" class="password-box form-attribute">
            </div> -->
            <div class="form-list">
                <label class="form-heading">Session Commission</label>
                <input type="number" min="0" max="99" placeholder="Session Commission" [(ngModel)]="sessionCommission"
                    value="0" class="password-box form-attribute">
            </div>

           <!-- <div class="form-list">
                <label class="form-heading">Session Commission Loss</label>
                <input type="number" min="0" max="99" placeholder="Session Commission" [(ngModel)]="sessionCommissionloss"
                    value="0" class="password-box form-attribute">
            </div> -->
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="updateUserCommission()">Submit</button> &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #sportSettingOld>
    <div class="modal-header">
        <h4 class="modal-title">User Sports Settings : {{showSelectedUserName}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <div class="modal-body">
        <div class="body-inner">
            <div class="form-main">
                <form [formGroup]='sportsForm'>
                    <div class="accordion" id="accordionExample">
                        <div class="sports-setting-main-section" formArrayName="sports_settings">
                            <div class="market-session-setting"
                                *ngFor="let sportSettingitem of sportsSettingsFormArr.controls; let j=index">
                                <div class="accordion-item" [formGroupName]="j">
                                    <h4 class="accordion-header" id="heading-{{j}}">
                                        <a class="accordion-title" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse-{{j}}" aria-controls="collapse-{{j}}">
                                            {{specificUserDetails[j]?.name}} Settings
                                        </a>
                                    </h4>
                                    <div id="collapse-{{j}}" class="accordion-collapse collapse show"
                                        aria-labelledby="heading-{{j}}" data-bs-parent="#accordionExample"
                                        *ngIf="hiddenpass[j]">
                                        <div class="accordion-body">
                                            <div class="detail-row">
                                                <div class="detail-row-heading">
                                                    <h4>Market Setting</h4>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Min. Stake Amount</label>
                                                                <input type="number" formControlName="market_min_stack"
                                                                    placeholder="" class="form-control" />
                                                                <span>[Note: Point]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.market_min_stack.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.market_min_stack.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Max. Stake Amount</label>
                                                                <input type="number" formControlName="market_max_stack"
                                                                    placeholder="" class="form-control" />
                                                                <span>[Note: Point(if no limit then enter
                                                                    -1)]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.market_max_stack.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.market_max_stack.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Min. Odds Rate</label>
                                                                <input type="number"
                                                                    formControlName="market_min_odds_rate"
                                                                    placeholder="" class="form-control" />
                                                                <span>[Note: Point]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.market_min_odds_rate.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.market_min_odds_rate.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Max. Odds Rate</label>
                                                                <input type="number"
                                                                    formControlName="market_max_odds_rate"
                                                                    placeholder="" class="form-control" />
                                                                <span>[Note: Point(if no limit then enter
                                                                    -1)]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.market_max_odds_rate.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.market_max_odds_rate.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Match Bets Delay</label>
                                                                <input type="number" formControlName="market_bet_delay"
                                                                    placeholder="" class="form-control" />
                                                                <span>[Second]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.market_bet_delay.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.market_bet_delay.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Market Max Profit</label>
                                                                <input type="number" formControlName="market_max_profit"
                                                                    placeholder="" class="form-control" />
                                                                <span>[Note: Point(if no limit then enter
                                                                    -1)]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.market_max_profit.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.market_max_profit.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Before Inplay Match
                                                                    Stake</label>
                                                                <input type="number"
                                                                    formControlName="market_advance_bet_stake"
                                                                    placeholder="" class="form-control" />
                                                                <span>[Note: Point(if no limit then enter
                                                                    -1)]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.market_advance_bet_stake.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.market_advance_bet_stake.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="detail-row" *ngIf="specificUserDetails[j]?.sport_id == '4'">
                                                <div class="detail-row-heading">
                                                    <h4>Session Setting</h4>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Min. Stake Amount</label>
                                                                <input type="number" formControlName="session_min_stack"
                                                                    placeholder="" class="form-control" />
                                                                <span>[Note: Point]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.session_min_stack.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.session_min_stack.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Max. Stake Amount</label>
                                                                <input type="number" formControlName="session_max_stack"
                                                                    placeholder="" class="form-control" />
                                                                <span>[Note: Point(if no limit then enter
                                                                    -1)]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.session_max_stack.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.session_max_stack.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Session Bet Delay</label>
                                                                <input type="number" formControlName="session_bet_delay"
                                                                    placeholder="" class="form-control" />
                                                                <span>[Second]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.session_bet_delay.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.session_bet_delay.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-sm-4 col-12">
                                                        <div class="col-inner">
                                                            <div class="form-group">
                                                                <label class="form-label">Session Max Profit</label>
                                                                <input type="number"
                                                                    formControlName="session_max_profit" placeholder=""
                                                                    class="form-control" />
                                                                <span>[Note: Point(if no limit then enter
                                                                    -1)]</span><br>
                                                                <span
                                                                    *ngIf="sportSettingitem.controls.session_max_profit.errors"
                                                                    class="red-alert-message">
                                                                    {{sportSettingitem.controls.session_max_profit.errors
                                                                    |DisplayErrorMessage}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-success"
                                                    (click)="updateSportWiseSettingDetails(sportSettingitem[j],j)">Save</button>
                                                <!-- <a class="modal-close btn-danger btn" data-dismiss="modal" (click)="hide(j)">Close</a> -->
                                            </div>

                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>


</ng-template>

<ng-template #sportSetting>
    <div class="modal-header">
        <label class="modal-title">User Sports Settings : {{showSelectedUserName}}</label>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <div class="modal-body no-scroll-body sport-setting-body">
        <div class="body-inner">

            <div class="filter-tab">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <ng-container *ngFor="let sportName of specificUserDetails;let i = index">
                            <a class="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-{{sportName?.name}}"
                                [ngClass]="{'active': i === selectedIndex}"
                                (click)="selectPill(i)">{{sportName?.name}}</a>
                        </ng-container>
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-{{sportName?.name}}">
                        <div class="form-main">
                            <form [formGroup]='sportsForm'>
                                <div class="sports-setting-main-section" formArrayName="sports_settings">
                                    <div class="market-session-setting"
                                        *ngFor="let sportSettingitem of sportsSettingsFormArr.controls; let j=index"
                                        [formGroupName]='j'>

                                        <div class="detail-row" *ngIf="selectedIndex === j">
                                            <div class="detail-row-heading">
                                                <h4>Market Setting</h4>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Min. Stake Amount</label>
                                                            <input type="number" formControlName="market_min_stack"
                                                                ngbTooltip="{{sportSettingitem.controls.market_min_stack.errors |DisplayErrorMessage}}"
                                                                placeholder="" class="form-control"
                                                                (keyup)="applyValidatorsForMaxStack(j)" #t1="ngbTooltip"
                                                                triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t1.open()" (key)="t1.open()"
                                                                (blur)="t1.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_min_stack.errors && !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_min_stack.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_min_stack"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_min_stack[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_min_stack[1].long_error}}"></i>
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Max. Stake Amount</label>
                                                            <input type="number" formControlName="market_max_stack"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.market_max_stack.errors |DisplayErrorMessage}}"
                                                                #t2="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t2.open()" (key)="t2.open()"
                                                                (blur)="t2.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_max_stack.errors&& !sportErrorData" class="red-alert-message">
                                                                
                                                                <i class="fa fa-info" ngbTooltip="{{sportSettingitem.controls.market_max_stack.errors |DisplayErrorMessage}}"  ></i>
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_max_stack"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_max_stack[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_max_stack[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Min. Odds Rate</label>
                                                            <input type="number" formControlName="market_min_odds_rate"
                                                                ngbTooltip="{{sportSettingitem.controls.market_min_odds_rate.errors |DisplayErrorMessage}}"
                                                                placeholder="" class="form-control"
                                                                (keyup)="applyValidatorsForMaxOddsRate(j)"
                                                                #t3="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t3.open()" (key)="t3.open()"
                                                                (blur)="t3.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_min_odds_rate.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_min_odds_rate.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_min_odds_rate"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_min_odds_rate[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_min_odds_rate[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Max. Odds Rate</label>
                                                            <input type="number" formControlName="market_max_odds_rate"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.market_max_odds_rate.errors |DisplayErrorMessage}}"
                                                                #t4="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t4.open()" (key)="t4.open()"
                                                                (blur)="t4.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_max_odds_rate.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_max_odds_rate.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_max_odds_rate"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_max_odds_rate[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_max_odds_rate[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Match Bets Delay</label>
                                                            <input type="number" formControlName="market_bet_delay"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.market_bet_delay.errors |DisplayErrorMessage}}"
                                                                #t5="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t5.open()" (key)="t5.open()"
                                                                (blur)="t5.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_bet_delay.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_bet_delay.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_bet_delay"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_bet_delay[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_bet_delay[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Market Max Profit</label>
                                                            <input type="number" formControlName="market_max_profit"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.market_max_profit.errors |DisplayErrorMessage}}"
                                                                #t6="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t6.open()" (key)="t6.open()"
                                                                (blur)="t6.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_max_profit.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_max_profit.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_max_profit"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_max_profit[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_max_profit[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Before Inplay Match Stake</label>
                                                            <input type="number" #t7="ngbTooltip" triggers="manual"
                                                                [autoClose]="false" (mouseenter)="t7.open()"
                                                                (key)="t7.open()" (blur)="t7.open()"
                                                                formControlName="market_advance_bet_stake"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_advance_bet_stake.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_advance_bet_stake"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_advance_bet_stake[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_advance_bet_stake[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="detail-row"
                                            *ngIf="specificUserDetails[j]?.sport_id == '4' && selectedIndex === j">
                                            <div class="detail-row-heading">
                                                <h4>Session Setting</h4>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Min. Stake Amount</label>
                                                            <input type="number" formControlName="session_min_stack"
                                                                ngbTooltip="{{sportSettingitem.controls.session_min_stack.errors |DisplayErrorMessage}}"
                                                                placeholder="" class="form-control"
                                                                (keyup)="applyValidatorsForMaxstake(j)" #t8="ngbTooltip"
                                                                triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t8.open()" (key)="t8.open()"
                                                                (blur)="t8.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.session_min_stack.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.session_min_stack.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.session_min_stack"
                                                                class="red-alert-message">
                                                                {{sportErrorData.session_min_stack[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.session_min_stack[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Max. Stake Amount</label>
                                                            <input type="number" formControlName="session_max_stack"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.session_max_stack.errors |DisplayErrorMessage}}"
                                                                #t9="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t9.open()" (key)="t9.open()"
                                                                (blur)="t9.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.session_max_stack.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.session_max_stack.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.session_max_stack"
                                                                class="red-alert-message">
                                                                {{sportErrorData.session_max_stack[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.session_max_stack[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Session Bet Delay</label>
                                                            <input type="number" formControlName="session_bet_delay"
                                                                #t10="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t10.open()" (key)="t10.open()"
                                                                (blur)="t10.open()" placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.session_bet_delay.errors |DisplayErrorMessage}}" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.session_bet_delay.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.session_bet_delay.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.session_bet_delay"
                                                                class="red-alert-message">
                                                                {{sportErrorData.session_bet_delay[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.session_bet_delay[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Session Max Profit</label>
                                                            <input type="number" formControlName="session_max_profit"
                                                                #t11="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t11.open()" (key)="t11.open()"
                                                                (blur)="t11.open()" placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.session_max_profit.errors |DisplayErrorMessage}}" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.session_max_profit.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.session_max_profit.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.session_max_profit"
                                                                class="red-alert-message">
                                                                {{sportErrorData.session_max_profit[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.session_max_profit[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer" *ngIf="selectedIndex === j">
                                            <div class="text-end">
                                                <button type="submit" class="btn btn-success"
                                                    (click)="updateSportWiseSettingDetails(sportSettingitem[j],j)">Save</button>
                                                &nbsp;
                                                <button type="button" class="btn btn-danger" data-dismiss="modal"
                                                    (click)="modalRef.hide()">Cancel</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #exposure>
    <div class="modal-header">
        <h4 class="modal-title">Exposure of {{expo_User_name}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th>S.No.</th>
                <th>SportName</th>
                <th>Event Name</th>
                <th>Market</th>
                <th>Exposure</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let expo of exposureData;let i=index;">
                <tr *ngIf="i < (expoLength-1)">
                    <td>{{i+1}}</td>
                    <td>{{expo?.sport_name}}</td>
                    <td>{{expo?.match_name}}</td>
                    <td>{{expo?.event_name}}</td>
                    <td [ngClass]="{'red': expo?.liability < 0 ,'green': expo?.liability >= 0}">{{expo?.liability | number : '0.0-2'}}</td>
                </tr>
                <tr *ngIf="i == (expoLength-1)">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td [ngClass]="{'red': expo?.liabilitySum < 0 ,'green': expo?.liabilitySum >= 0}">
                        {{expo?.liabilitySum | number : '0.0-2'}}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</ng-template>
<ng-template #userParentList>
    <div class="modal-header">
        <h4 class="modal-title">Parent List of {{userParentName}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <table class="table">
        <tbody>
            <ng-container *ngFor="let parent of parentList; let i =index">
                <tr>
                    <td>{{parent?.name}}({{parent?.user_name}})</td>
                </tr>
                <!-- <tr>
                    <td>user2</td>
                </tr>
                <tr>
                    <td>user3</td>
                </tr> -->
                
            </ng-container>
        </tbody>
    </table>
</ng-template>

<ng-template #creditRefModal>
    <div class="modal-header">
        <h4 class="modal-title">Edit Credit Reference -  {{selectedUser?.user_name}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <table class="table">
        <tbody>
            <ng-container>
                <tr>
                    <td>Current : </td>
                    <td>{{selectedUser?.credit_reference}}</td>
                </tr>
                <tr>
                    <td>New : </td>
                    <td><input type="number" placeholder="New Credit Reference" class="form-control"  [(ngModel)]="cRefrence"></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success"
                (click)="updateCRefrence()">Update</button>
            &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>