<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="closed-user inner-content">
                <div class="page-title">
                    <h3>{{userName}} <span *ngIf="showBetButton == true">
                         (
                      <span *ngIf="sport_name != undefined">{{sport_name}} </span>       
                      <span *ngIf="series_name != undefined">/ {{series_name}} </span>        
                      <span *ngIf="match_name != undefined">  / {{match_name}}</span>       
                        <span *ngIf="market_name != undefined">  / {{market_name}}</span>        
                             ) </span> Fancy Stake List</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div class="filterDiv">
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                <div class="row form-field">
                                    <div class="col-md-1" style="width: 13.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group dateTimePicker">
                                                <!-- <input class="form-control" name="startDate" [max]="todayDate"
                                                    [(ngModel)]="openStartDate" datetime="HH:mm:ss" [owlDateTime]="dt1"
                                                    [owlDateTimeTrigger]="dt1" placeholder=" Start Date Time">
                                                <owl-date-time #dt1 hour12Timer="true" showSecondsTimer="true">
                                                </owl-date-time> -->
                                                <input type="text" class="form-control" [popover]="popTemplate" placement="bottom" [outsideClick]="true" #startpopoverRef="bs-popover" value="{{startdateTime | date:'yyyy-MM-dd HH:mm:ss '}}" (click)="showDate()">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="width: 13.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group dateTimePicker">
                                                <!-- <input class="form-control" name="endDate" [min]="openStartDate"
                                                    [max]="startAt" [(ngModel)]="openEndDate" [owlDateTime]="dt2"
                                                    [owlDateTimeTrigger]="dt2" placeholder=" End Date Time">
                                                <owl-date-time #dt2 showSecondsTimer="true" hour12Timer="false">
                                                </owl-date-time> -->
                                                <input type="text" class="form-control" [popover]="popTemplate1" placement="bottom" [outsideClick]="true" #endpopoverRef="bs-popover" value="{{enddateTime | date:'yyyy-MM-dd HH:mm:ss '}}" (click)="showDate()">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="width: 12.499999995%">
                                        <div class="col-inner">
                                            <div class="form-group mb-0">

                                                <input name="searchUser"
                                                    class="search-input dropdown-toggle form-control"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" type="text" autocomplete="off"
                                                    [(ngModel)]="searchUser" placeholder="Search User Name...">

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item"
                                                        *ngFor="let sport of userlist |search: searchUser :'user_name'">
                                                        <div class="event-info" (click)="getFancy(sport.match_id)">
                                                            <div class="event-format-strip">
                                                                <div class="event-team">
                                                                    <div class="team-name">{{sport?.user_name}} </div>
                                                                </div>
                                                                <!-- <div class="event-full-date">
                                                                    <span class="date">{{moment(sport?.match_date).utcOffset("+0").format('D')}}
                                                                        {{moment(sport?.match_date).utcOffset("+0").format('MMM')}}
                                                                        {{moment(sport?.match_date).utcOffset("+0").format('HH:mm')}} IST</span>
                                                                </div>
                                 -->
                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1" style="width: 10.499999995%">
                                        <div class="col-inner text-end">
                                            <div class="btn-list">
                                                <button type="button" class="btn btn-success" (click)="sportsPl('','filterBtnClick')">Filter</button>
                                                <button type="button" class="btn btn-danger"
                                                    (click)="onClickClear()">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="report-table">
                    <table datatable class="table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th *ngIf="type == 0">Domain Name</th>
                                <th>Name</th>
                                <th>Fancy Stake</th>
                                <th> <i class="fa fa-arrow-left"
                                        style="margin: 10px;cursor: pointer;" (click)="sportsPl(parentId,'filterBtnClick')"
                                        *ngIf="userDetails.user_id != Id && Id != ''"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let profit of userPlList;let i = index;">
                                <td>{{i+1}}</td>
                                <td *ngIf="type == 0">{{profit?.domain_name}}</td>
                                <td (click)="profit?.user_type_id == 1 ? '' : sportsPl(profit.user_id,'filterBtnClick')" style="cursor: pointer;">

                                    <span class="userLabel">
                                        <span *ngIf="profit?.user_type_id != 1"> A </span>
                                        <span *ngIf="profit?.user_type_id == 1"> C </span>
                                    </span>
                                    {{profit?.user_name}}</td>
                                <td [ngClass]="{'red':profit.stack < 0 ,'green':profit.stack >= 0}">
                                    {{profit?.stack == undefined ? '0': profit?.stack | number : '0.0-2'}}</td>
                                
                               
                                <td>
                                    <button type="button" class="btn btn-sm btn-success"
                                    (click)="openModalUserParentList(profit,userParentList)">P</button> &nbsp;&nbsp;
                                    <button type="button" class="btn btn-sm btn-success"
                                        (click)="goToStatement(profit.user_id,profit.user_type_id)" *ngIf="profit?.user_type_id == 1">Statement</button>
                                </td>
                            </tr>
                            <div *ngIf="userPlList.length == 0" style="text-align: center;">
                                No data avalible 
                            </div>
                        </tbody>
                        <tbody>
                            <tr>
                                <td></td>
                                <td *ngIf="type == 0"></td>
                                <td>Total </td>
                                <td [ngClass]="{'red':total < 0 ,'green':total >= 0}"> {{total | number :
                                    '0.0-2'}}</td>
                         </tr>
                        </tbody>
                    </table>
                    <!-- <pagination-controls (pageChange)="pageChange($event)"
                                                class="user-list-pagination" ></pagination-controls> -->
                </div>
            </div>
        </div>
    </div>
</main>


<!-- Date Time Picker -->
<ng-template #popTemplate>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="date" [minDate]="minDate" [maxDate] ="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="time" (ngModelChange)="updateTime()" showSeconds="true" [showMeridian]="isMeridian">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="close()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>

<ng-template #popTemplate1>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDate" [minDate]="date" [maxDate] ="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateEndDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTime" (ngModelChange)="updateEndTime()"
        [showMeridian]="isMeridian" showSeconds="true">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="closeEndDatepicker()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>

<ng-template #userParentList>
    <div class="modal-header">
        <h4 class="modal-title">Parent List of {{userParentName}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <table class="table">
        <tbody>
            <ng-container *ngFor="let parent of parentList; let i =index">
                <tr>
                    <td>{{parent?.name}}({{parent?.user_name}})</td>
                </tr>
                <!-- <tr>
                    <td>user2</td>
                </tr>
                <tr>
                    <td>user3</td>
                </tr> -->
                
            </ng-container>
        </tbody>
    </table>
</ng-template>