<app-header></app-header>
<app-sidebar></app-sidebar>



<main>
    <div class="full-wrap" id="" #contentToConvert>
        <div class="report-page">
            <div class="user-list inner-content">
                <div class="page-title">
                    <h3>Payment List</h3>
                    <div class="nav-list">
                        <div class="hierarchy">
                           
                        </div>
                    </div>
                    <div class="breadcrumb">
                        <a  class="btn btn-secondary back-btn"><i class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div _ngcontent-serverapp-c232="" class="filterDiv">
                    <div _ngcontent-serverapp-c232="" class="row">
                       <div _ngcontent-serverapp-c232="" class="col-md-12">
                          <form _ngcontent-serverapp-c232="" novalidate="" class="ng-untouched ng-pristine ng-valid">
                             <div _ngcontent-serverapp-c232="" class="row form-field">
                               
                                <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 87.5%;">
                                   <div _ngcontent-serverapp-c232="" class="col-inner">
                                      <div _ngcontent-serverapp-c232="" class="form-group mb-0">
                                         <input _ngcontent-serverapp-c232="" name="searchUser" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="text" autocomplete="off" placeholder="Search Payment Method Name..." class="search-input dropdown-toggle form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="searchUser">
                                         <div _ngcontent-serverapp-c232="" aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                           
                                            <!--bindings={
                                               "ng-reflect-ng-for-of": "[object Object],[object Object"
                                               }-->
                                         </div>
                                         <bfv-messages _nghost-serverapp-c53="" ng-reflect-messages="() => this.getMessages()" class="ng-star-inserted">
                                            <!--bindings={
                                               "ng-reflect-ng-for-of": ""
                                               }-->
                                         </bfv-messages>
                                         <!--bindings={
                                            "ng-reflect-ng-if": "true"
                                            }-->
                                      </div>
                                   </div>
                                </div>
                                <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 10.499999995%;">
                                   <div _ngcontent-serverapp-c232="" class="col-inner text-end">
                                      <div _ngcontent-serverapp-c232="" class="btn-list">
                                        <button (click)="openModalPaymentMethod(paymentMethod)" type="button" class="btn btn-success">
                                            Add Payment</button>
                                        </div>
                                   </div>
                                </div>
                             </div>
                          </form>
                       </div>
                    </div>
                 </div>

              

                <div class="report-table">
                    

                    <table class="table">
                        <thead>
                            <tr>
                               
                                <th>S No.
                                </th>
                                
                                <th>Qr Code
                                   
                                </th>
                                <th>Display Order
                                   
                                </th>
                                <th>Name
                                </th>
                                <th>Min 
                                </th>
                                <th>Max
                                </th>
                                <th>Credentials
                                </th>
                                
                                <th>Status
                                   
                                </th>
                                <th>Action</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of allPaymentMethod;let i=index">
                                <td>
                               {{i+1}}
                                </td>
                                <td>
                                    <span>
                                        <img src="{{Base_Url}}wallets/{{p?.image}}" class="zoom" *ngIf="p?.image" width="20px" height="20px">
                                    </span> 
                                    <span>
                                        <input type="file" id="file"  (change)="handleFileInput($event.target.files,'image',p)">
                                       
                                    </span>
                                </td>

                                
                                <td>
                                    {{p?.order_no}} 
                                    
                                     </td>
                                     <td>
                                        
                                        {{p?.name}}
                                         </td>
                                         <td>
                                        
                                            {{p?.min_amount}}
                                             </td>
                                             <td>
                                        
                                                {{p?.max_amount}}
                                                 </td>
                                         <td>
                                            <div class="row row-td" *ngFor="let d of p?.payment_details">
                                                <div class="row-tr col sm-6">
                                                      {{d?.key}}
                                                </div>
                                                <div class="row-tr col sm-6">
                                                    {{d?.value}}
                                                </div>
                                            </div>
                                             </td>
                                             <td style="text-align: left;">
                                                <img *ngIf="p?.visible==true" src="assets/icon/active.png"  height="20px" width="20px">
                                                <img *ngIf="p?.visible==false" src="assets/icon/remove.jpg" height="20px" width="20px">
                                       
                                               
                                                 </td>
                                                 <td style="text-align: left;">

                                                    <img *ngIf="p?.visible==false" (click)="updatePayment(p,0)" src="assets/icon/active.png"  height="20px" width="20px">
                                                    <img *ngIf="p?.visible==true" (click)="updatePayment(p,0)" src="assets/icon/remove.jpg" height="20px" width="20px">
                                           
                                                    <img src="assets/icon/delete.png" (click)="updatePayment(p,1)" height="20px" width="20px">
                                                    
                                                 </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="report-bottom-paginate">
                    <pagination-controls  class="user-list-pagination">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</main>

<ng-template #paymentMethod>
    <div class="modal-header">
        <h4 class="modal-title">Create Payment Method</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form [formGroup]='createPaymentForm'>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
           <div class="row">
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Name <span style="color:red">*</span></label>
                    <input type="text"  placeholder="Enter Name"  formControlName="name"
                         class="password-box form-attribute">
                         <span *ngIf="createPaymentForm.controls.name.errors && submited"
                         class="red-alert-message">
                        name is required.!
                     </span>
                </div>
            </div>
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Display Order <span style="color:red">*</span></label>
                    <input type="number" min="0" max="99" placeholder="Enter Order No"  formControlName="order_no"
                         class="password-box form-attribute">
                         <span *ngIf="createPaymentForm.controls.order_no.errors && submited"
                         class="red-alert-message">
                        display order is required.!
                     </span>
                </div>
            </div>
            
           </div>
           <div class="row">
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Min Amount <span style="color:red">*</span></label>
                    <input type="number"  placeholder="Enter Min Amount"  formControlName="min_amount"
                         class="password-box form-attribute">
                         <span *ngIf="createPaymentForm.controls.min_amount.errors && submited"
                         class="red-alert-message">
                         Min Amount is required.!
                     </span>
                </div>
            </div>
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Max Amount <span style="color:red">*</span></label>
                    <input type="number"  placeholder="Enter Max Amount"  formControlName="max_amount"
                         class="password-box form-attribute">
                         <span *ngIf="createPaymentForm.controls.max_amount.errors && submited"
                         class="red-alert-message">
                         Max Amount is required.!
                     </span>
                </div>
            </div>
       </div>

       <div class="row">
        <div class="col col-sm-6">
            <div class="form-list">
                <label class="form-heading">Select Payment Mode <span style="color:red">*</span></label>
                <select formControlName="payment_type" class="password-box form-attribute ng-pristine ng-invalid ng-touched">
                    <option>Select</option>
                    <option value="1">Bank</option>
                    <option value="2">PayTm</option>
                    <option value="3">PhonePe</option>
                    <option value="4">G Pay</option>
                    <option value="5">UPI | QR CODE</option>
                    <option value="0">Others</option>
                </select>
                <span *ngIf="createPaymentForm.controls.payment_type.errors && submited"
                class="red-alert-message">
               payment mode is required.!
            </span>
            </div>
        </div>
        <div class="col col-sm-6">
            <div class="form-list qr-code">
                <label class="form-heading">Qr Image </label>
                <input type="file"   
                type="file"
                accept="image/*"
                (change)="processImageFile($event)" formControlName="image"
                     class="">
                  
            </div>
        </div>
       <div class="col col-sm-6">
        <div class="img-pad">
            <img src="{{urlImage}}" *ngIf="urlImage"  height="100px" width="100px">
         </div>
    </div>
   </div>
      <h4>Credentials</h4>
      <div >
        <div class="row">
            <div class="col col-sm-3">
                <div class="form-list">
                    <label class="form-heading">Enter Key</label>
                    
                </div>
            </div>
            <div class="col col-sm-3">
                <div class="form-list">
                    <label class="form-heading">Enter Value</label>
                    
                </div>
            </div>
            <div class="col col-sm-3">
                <div class="form-list">
                    <label class="form-heading" (click)="addRow()">+</label>
                    
                </div>
            </div>

        </div>

        <div class="row"   *ngFor="let item of credentailArr; let pointIndex=index" >
        
            <div class="col col-sm-3">
                <div class="form-list">
                    
                    <input type="text"  placeholder="Enter Key" name="key"   (change)="keyChange($event,pointIndex)"
                         class="password-box">
                </div>
            </div>
            <div class="col col-sm-3">
                <div class="form-list">
                    
                    <input type="text"  placeholder="Enter Value" name="value" (change)="valueChange($event,pointIndex)"
                         class="password-box">
                </div>
            </div>
            <div class="col col-sm-3">
                <div class="form-list">
                    <label class="form-heading" (click)="removeRow(pointIndex)">-</label>
                    
                </div>
            </div>
           </div>
      </div>
    
            
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="subMitForm()" >Submit</button> &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
    </form>
</ng-template>

