<app-header></app-header>
<app-sidebar></app-sidebar>

<main>
  <section class="change-password-section">
    <div class="container password-container-form">

      <div class="change-password-main">

        <div class="change-password-form">

          <form [formGroup]='changePasswordForm' (ngSubmit)='onSubmitChangePassword()'>

            <div class="form-group">
              <label>Old Password</label>
              <input type="password" placeholder="Old Password" class="form-control password-box"
                formControlName='old_password'>
              <div *ngIf="submitted && f.old_password.errors">
                <div *ngIf="f.old_password.errors.required" class="red-message">Old password is required</div>
              </div>
            </div>

            <div class="form-group">
              <label>New Password</label>
              <input type="password" placeholder="New Password" class="form-control password-box"
                formControlName='new_password'>
              <div *ngIf="submitted && f.new_password.errors">
                <div *ngIf="f.new_password.errors.required" class="red-message">New password is required</div>
              </div>
            </div>

            <div class="form-group">
              <label>Confirm Password</label>
              <input type="password" placeholder="Confirm password" class="form-control password-box"
                formControlName='confirm_password'>
              <div *ngIf="submitted && f.confirm_password.errors">
                <div *ngIf="f.confirm_password.errors.required" class="red-message">Confirm password is required</div>
              </div>
            </div>

            <div class="btn-list">
              <button type="submit" class="btn">Submit</button>
            </div>

          </form>

        </div>

      </div>


    </div>
  </section>

</main>