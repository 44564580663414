<app-header></app-header>
<app-sidebar></app-sidebar>

<main class="full-wrap dashboard-page">
  <div class="main-div">
    <div class="right-section">
      <div class="right-sidebar">
          <div class="live-game">
              <div class="game-header">
                  <img src="assets/images/icon/casino-icon.svg"> Live Casino Games
              </div>
              <div class="game-body scroller">
                  <div class="game-inner-list">
                      <!-- <div class="list-title"><h3>Live Casino Games</h3></div> -->

                      <div class="game-item" *ngFor="let gameList of exchangeGameList;let i = index;">
                          <div class="thumb">
                              <img src="assets/Casino/{{gameList?.match_id}}.jpeg">
                          </div>
                          <div class="play-btn">
                              <a>Play Now</a>
                          </div>
                          <div class="caption text-center">
                              <h3>{{gameList?.name}}</h3>
                          </div>
                      </div>


                   </div>
              </div>
          </div>
      </div>
  </div>
  </div>
</main>