import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SportService } from '../services/sport.service';
@Component({
  selector: 'app-scoretv',
  templateUrl: './scoretv.component.html',
  styleUrls: ['./scoretv.component.scss']
})
export class ScoretvComponent implements OnInit {
  matchId:any;
  scroeTv: any;
  scoredata: boolean = false;
  constructor(private sport: SportService,private router: Router,  private locationBack: Location, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getScore()
  }
  goToBack() {
    this.locationBack.back();
  }
  getScore(){
    let data ={
     
    };
    this.sport.getHomeMatchesList(data).subscribe((res)=>{
        this.scroeTv = res.data;
    })
    
  }

  getScoreInput(m,event,index)
  {
    this.scroeTv[index].match_scoreboard_url=event.target.value;
  }

  getTvInput(m,event,index)
  {
   
    this.scroeTv[index].match_tv_url=event.target.value;
   
  }

  updateData(m,index)
  {
    
    let data ={
      "match_tv_url":this.scroeTv[index].match_tv_url,
      "match_scoreboard_url":this.scroeTv[index].match_scoreboard_url,
      "market_id":m?.match_id
    };
    this.sport.scoreTvUpadte(data).subscribe((res)=>{
      if (res.status == false) {
        this.toastr.error(res.msg)
      }
      else
      {
        this.toastr.info(res.msg)
      }
    })
  }
}
