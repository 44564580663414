import { Component, OnInit, ViewChild ,TemplateRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HeaderComponent } from '../header/header.component';
@Component({
  selector: 'app-update-user-data',
  templateUrl: './update-user-data.component.html',
  styleUrls: ['./update-user-data.component.scss'],
  providers: [HeaderComponent],
})
export class UpdateUserDataComponent implements OnInit {
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    "lengthChange": false,
    "ordering": false,
    "paging": false,
    "searching": false
  };
  dtTrigger: Subject<any> = new Subject();
  modalRef: BsModalRef;
  userList: any;
  parentList: any;
  userParentName: any;
  updateUserData: any;
  liability: any;
  amount: any;
  updateUserId: any;
  user_type_id: any = 1;
  constructor(private sport: SportService,private modalService: BsModalService,private route: ActivatedRoute,private head: HeaderComponent,
    private cookie: CookieService,private loginService: LoginService, private router: Router, private locationBack: Location,  private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getUserList()
  }
  goToBack() {
    this.locationBack.back();
  }
  getUserList(){
    let data = {};
    this.sport.getUserLiability(data).subscribe((res) => {
      
      if(res.status == true){
        this.toastr.success(res.msg);
        this.userList = res.data;
      } else {
        this.toastr.error(res.msg)
        if(res.logout == true){
          this.head.logoutUser();
        }
      }
    })
  }

  openModalUserParentList(user, userParentList: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      userParentList,
      Object.assign({}, { class: 'modal-lg' })
    );
    this.userParentName = user.user_name;
    let data ={
      "user_id" : user.user_id
    }
    this.sport.showParentList(data).subscribe((res) => {
      if(res.status == true){
        this.parentList = res.data.agents ;
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        })
        if(res.logout == true){
          this.head.logoutUser();
        }
      }
    })
  }

  openModalUpdateData(user, updateData: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      updateData,
      Object.assign({}, { class: 'modal-lg' })
    );
   this.updateUserData = user;
   this.updateUserId = this.updateUserData.user_id
  }
  updateCustomerData(){
    let data = {
      "filter": {
          "user_id": this.updateUserData.user_id
      },
      "update": {
          "liability": this.liability,
          "balance": this.amount
      }
  }
  this.sport.updateUserData(data).subscribe((res) => {
    if(res.status){
      this.toastr.success(res.msg)
      this.modalRef.hide();
    } else {
      this.toastr.error(res.msg)
      if(res.logout == true){
        this.head.logoutUser();
      }
    }
    
  })
  }
  report(data){
    this.router.navigate(['downline-report/'+data._id +'/'+this.user_type_id])
  }
}
