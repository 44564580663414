<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="main-div">

        <div class="left-section">
            <div class="row main-row">
                <div class="col-md-12">

                    <div class="main-content">
                        <div class="row">
                            <div class="col-md-6 pr-10">
                                <div class="match-heading col-md-12">
                                    <div class="match-heading-name">
                                        <div class="match-name">
                                            <i class="fa fa-arrow-left" style="margin: 10px;cursor: pointer;"
                                                (click)="back()"></i>
                                            <span class="inplay" *ngIf="matchData?.inplay == true"><i
                                                    class="fa fa-play-circle"></i></span>
                                            <span class="icon"><img src="assets/images/icon/Cricket.png"></span>
                                            {{matchName}}
                                        </div>
                                        <div class="betHeadRight">
                                            <div class="dropdown">
                                            <a class="td-btn btn-primary" data-toggle="dropdown"><i class="fa fa-ellipsis-h"></i></a>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <span class="icon-background" ngbTooltip="Fancy"
                                                            [ngStyle]="{'color': (matchData.enable_fancy==1) ? 'green' : 'red'}" (click)="onClickEnableFancy(matchData)"><i
                                                                class="fa fa-facebook" aria-hidden="true"></i>
                                                        </span>
                                                       
                                                            <span class="setting-btn"><i class="fa fa-info-circle info" aria-hidden="true" (click)="openModalCondition(Terms)"></i>
                                                            </span>
                                                            <span ngbTooltip="Market-Setting" class="setting-btn"
                                                                (click)="openModalSportSetting(sportSetting,adminDetails,match_id,2);getMarketLimits(matchData,2)"
                                                                *ngIf="type == 0"><i class="fa fa-cog"></i>
                                                            </span>
                                                            <span class="setting-btn" (click)="updateLimit(match_id,1,lock)"
                                                                *ngIf="lock == false && type == 0">
                                                                <a>
                                                                    <i class="fa fa-unlock"></i>
                                                                </a>
                                                            </span>
                                                            <span class="setting-btn" (click)="updateLimit(match_id,1,lock)"
                                                                *ngIf="lock != false && type == 0">
                                                                <a>
                                                                    <i class="fa fa-lock"></i>
                                                                </a>
                                                            </span>
                                                            <div class="manualInplayBtn"  (click)="updateInplayStatus(matchData.match_id,matchData.manualInplay)" [ngStyle]="{'background-color':matchData.manualInplay == true ? 'green' : 'red'}" >Manual
                                                                Inplay
                                                            </div>
                                                    </div>
                                        </div>
                                            <!-- <span class="icon-background" ngbTooltip="Fancy"
                                            [ngStyle]="{'background-color': (matchData.enable_fancy==1) ? 'green' : 'red'}"
                                            
                                            (click)="onClickEnableFancy()"><i
                                                class="fa fa-facebook" aria-hidden="true"></i></span> -->
                                            <!-- <span class="setting-btn"><i class="fa fa-info-circle info" aria-hidden="true" (click)="openModalCondition(Terms)"></i></span>
                                            <span ngbTooltip="Market-Setting" class="setting-btn"
                                                (click)="openModalSportSetting(sportSetting,adminDetails,match_id,2);getMarketLimits(matchData,2)"
                                                *ngIf="type == 0"><i class="fa fa-cog"></i></span>
                                            <div class="setting-btn" (click)="updateLimit(match_id,1,lock)"
                                                *ngIf="lock == false && type == 0">
                                                <a>
                                                    <i class="fa fa-unlock"></i>
                                                </a>
                                            </div>
                                            <div class="setting-btn" (click)="updateLimit(match_id,1,lock)"
                                                *ngIf="lock != false && type == 0">
                                                <a>
                                                    <i class="fa fa-lock"></i>
                                                </a>
                                            </div> -->
                                            <div class="bet-datetime"><img src="assets/images/icon/clock.svg">
                                                {{(matchDate | date :'dd-MM-yy hh:mm aa')}}</div>
                                            <div class="all-bet"><a (click)="openModalAddMarket(addMarket)"><i
                                                        class="fa fa-plus"></i></a></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="match-heading col-md-12">
                                    <div class="bet-list">
                                        <div class="game-header">
                                            <ul class="nav nav-pills" id="myTab" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link" [class.active]="activeBetTab === 'all'"
                                                        data-bs-toggle="tab" data-bs-target="#allTab">All</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" data-bs-toggle="tab"
                                                        [class.active]="activeBetTab === 'matchOdd'"
                                                        data-bs-target="#matchOddTab">Match Odds</a>
                                                </li>
                                                <!-- <li class="nav-item" *ngIf="matchData.sport_id == 4">
                                                    <a class="nav-link" data-bs-toggle="tab"[class.active] = "activeBetTab === 'bookmaker'"
                                                        data-bs-target="#bookmakerTab">Bookmaker</a>
                                                </li> -->

                                                <li class="nav-item"
                                                    *ngIf="matchData.sport_id == '4' && showFancyList == true">
                                                    <a class="nav-link" data-bs-toggle="tab"
                                                        [class.active]="activeBetTab === 'fancy'"
                                                        data-bs-target="#fancyTab">Fancy</a>
                                                </li>
                                                <li class="nav-item" *ngIf="matchData.sport_id == 4">
                                                    <a class="nav-link" data-bs-toggle="tab"
                                                        [class.active]="activeBetTab === 'other'"
                                                        data-bs-target="#otherTab">Other</a>
                                                </li>
                                                
                                            <label class="switch" *ngIf="type == 0">
                                                <input type="checkbox" name="Active" 
                                                    class="chkPermission" (change)='updateMatchStatus(matchData?.match_id,matchData?.is_active)'
                                                    [checked]="matchData?.is_active == 1 ? true : false">
                                                <div class="slider round">
                                                    <div class="on">On</div>
                                                    <div class="off">Off</div>
                                                </div>
                                            </label>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div class="tab-content leftOddContent" id="myTabContent">
                                    <div class="tab-pane fade  active " id="allTab"
                                        [class.show]="activeBetTab === 'all'" [class.active]="activeBetTab === 'all'">
                                        <!-- Score Board Section -->
                                        <div class="select-bet">
                                            <div class="scoreBoard">
                                                <div class="graphicalscoreBoard" *ngIf="graph == true">
                                                    <iframe id="fp_embed_player" [src]="graphicTvUrl" marginwidth="0"
                                                        marginheight="0" frameborder="0" width="100%" height="100%" style="min-height: 175px !important;"
                                                        scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
                                                </div>
                                                <div class="graphicalscoreBoard" *ngIf="tv == true">
                                                    <iframe id="fp_embed_player" [src]="liveUrl" marginwidth="0"
                                                        marginheight="0" frameborder="0" width="100%" height="100%" style="min-height: 350px !important;"
                                                        scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
                                                </div>

                                                <div id="scoreBoard" *ngIf="score == true">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="score-detail">
                                                                <div class="score-title">
                                                                    <h3>{{scoreBoard?.t1?.n}}
                                                                        &nbsp;{{scoreBoard?.i1?.sc}}-{{scoreBoard?.i1?.wk}}
                                                                        ({{scoreBoard?.i1?.ov}})</h3>
                                                                    <h3 *ngIf="scoreBoard?.i2?.ov != '0.0'">
                                                                        {{scoreBoard?.t2?.n}}
                                                                        &nbsp;{{scoreBoard?.i2?.sc}}-{{scoreBoard?.i2?.wk}}
                                                                        ({{scoreBoard?.i2?.ov}})</h3>
                                                                    <div *ngIf="twenty == false">
                                                                        <div *ngIf=" scoreBoard.i3.ov != '0'">
                                                                            <h3 *ngIf="scoreBoard.i3b == 't1'">
                                                                                {{scoreBoard?.t1?.n}}
                                                                                &nbsp;
                                                                                {{scoreBoard?.i3?.sc}}-{{scoreBoard?.i3?.wk}}
                                                                                ({{scoreBoard?.i3?.ov}})
                                                                            </h3>
                                                                            <h3 *ngIf="scoreBoard.i3b == 't2'">
                                                                                {{scoreBoard?.t2?.n}}
                                                                                &nbsp;
                                                                                {{scoreBoard?.i3?.sc}}-{{scoreBoard?.i3?.wk}}
                                                                                ({{scoreBoard?.i3?.ov}})
                                                                            </h3>
                                                                        </div>
                                                                        <div *ngIf=" scoreBoard.i4.ov != '0'">
                                                                            <span
                                                                                *ngIf="scoreBoard.i3b == 't1'">{{scoreBoard?.t2?.n}}
                                                                                &nbsp;
                                                                                {{scoreBoard?.i4?.sc}}-{{scoreBoard?.i4?.wk}}
                                                                                ({{scoreBoard?.i4?.ov}})
                                                                            </span>
                                                                            <span
                                                                                *ngIf="scoreBoard.i3b == 't2'">{{scoreBoard?.t1?.n}}
                                                                                &nbsp;
                                                                                {{scoreBoard?.i4?.sc}}-{{scoreBoard?.i4?.wk}}
                                                                                ({{scoreBoard?.i4?.ov}})
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="score-table">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>BATSMEN</th>
                                                                                <th>R</th>
                                                                                <th>B</th>
                                                                                <th>4s</th>
                                                                                <th>6s</th>
                                                                                <th>SR</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{{(scoreBoard?.p1 == '') ? '-' :
                                                                                    (scoreBoard?.p1)}}</td>
                                                                                <td>{{(scoreBoard?.b1s[0] == '')?
                                                                                    '-' : (scoreBoard?.b1s[0])}}
                                                                                </td>
                                                                                <td>{{(scoreBoard?.b1s[1] == '')?
                                                                                    '-' : (scoreBoard?.b1s[1])}}
                                                                                </td>
                                                                                <td>{{(scoreBoard?.b1s[2] == '')?
                                                                                    '-' : (scoreBoard?.b1s[2])}}
                                                                                </td>
                                                                                <td>{{(scoreBoard?.b1s[3] == '')?
                                                                                    '-' : (scoreBoard?.b1s[3])}}
                                                                                </td>
                                                                                <td>{{(scoreBoard?.p1 == '') ? '-' :
                                                                                    ((((scoreBoard?.b1s[0])/(scoreBoard?.b1s[1]))*100)|
                                                                                    number : '0.0-2')}}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{{(scoreBoard?.p2 == '') ? '-' :
                                                                                    (scoreBoard?.p2)}}</td>
                                                                                <td>{{(scoreBoard?.b2s[0] == '')?
                                                                                    '-' : (scoreBoard?.b2s[0])}}
                                                                                </td>
                                                                                <td>{{(scoreBoard?.b2s[1] == '')?
                                                                                    '-' : (scoreBoard?.b2s[1])}}
                                                                                </td>
                                                                                <td>{{(scoreBoard?.b2s[2] == '')?
                                                                                    '-' : (scoreBoard?.b2s[2])}}
                                                                                </td>
                                                                                <td>{{(scoreBoard?.b2s[3] == '')?
                                                                                    '-' : (scoreBoard?.b2s[3])}}
                                                                                </td>
                                                                                <td>{{(scoreBoard?.p2 == '') ? '-' :
                                                                                    ((((scoreBoard?.b2s[0])/(scoreBoard?.b2s[1]))*100)|
                                                                                    number : '0.0-2')}}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="score-detail">
                                                                <div class="score-table">
                                                                    <!-- <table class="table table-main">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Target</th>
                                                                                <th>P'SHIP</th>
                                                                                <th>LAST WKT</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{{(scoreBoard?.i2?.tr ==
                                                                                    undefined) ||
                                                                                    (scoreBoard?.i2?.tr == null) ||
                                                                                    (scoreBoard?.i2?.tr == '') ? '-'
                                                                                    : (scoreBoard?.i2?.tr) }}</td>
                                                                                <td *ngIf="scoreBoard?.pt[0] != ''">
                                                                                    {{scoreBoard?.pt[0]}}({{scoreBoard?.pt[1]}})
                                                                                </td>
                                                                                <td *ngIf="scoreBoard?.pt[0] == ''">
                                                                                    0(0)</td>
                                                                                <td *ngIf="scoreBoard?.lw != ''">
                                                                                    {{scoreBoard?.lw}}</td>
                                                                                <td *ngIf="scoreBoard?.lw == ''">-
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table> -->

                                                                    <!-- <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>BOWLER</th>
                                                                                <th>O</th>
                                                                                <th>M</th>
                                                                                <th>R</th>
                                                                                <th>W</th>
                                                                                <th>ECO</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{{(scoreBoard?.bw == '') ? '-'
                                                                                    :(scoreBoard?.bw)}}</td>
                                                                                <td>-</td>
                                                                                <td>-</td>
                                                                                <td>-</td>
                                                                                <td>-</td>
                                                                                <td>-</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table> -->
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th colspan="6">Last 6 Ball Runs</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="6"
                                                                                    class="green-icon ballOver text-left">
                                                                                    <span *ngFor="let ball of perball"
                                                                                        [ngClass]="{'wckball':ball=='W ' , 'wckball2':ball=='W', 'wckball':ball==' W' ,
                                                                                    'fourcolor':ball=='4','sixfourcolor':ball=='6','zerocolor':ball=='0',
                                                                                    'ball1Color':ball=='1' || ball=='2' || ball=='3' || ball=='Wd' }">{{ball}}</span>
                                                                                </td>

                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Market Type section -->
                                        <div class="select-bet">
                                            <div class="row">
                                                <div class="col-md-12 bet-main"
                                                    *ngFor="let matchOddsData of matchDetail;let mDTIndex=index;">
                                                    <div class="bet-list bet-list-header">
                                                        <div class="market-runner">
                                                            <div class="team-market">
                                                                <div class="team1 cteam">
                                                                    <div class="team-name market-type-title">
                                                                        <p class="title">{{matchOddsData?.name}} <img
                                                                                src="assets/images/transparent.gif"></p>

                                                                    </div>
                                                                    <div class="minMax-limit"
                                                                        *ngIf="showSetting == true">
                                                                        <span>Stake Limit: {{matchOddsData?.min_stake}}
                                                                            - {{matchOddsData?.max_stake}}</span>
                                                                        <span>Max Profit:
                                                                            {{matchOddsData?.max_profit}}</span>
                                                                    </div>
                                                                    <div class="bet-value"></div>
                                                                    <div class="bet-value book-bet-div">
                                                                        <div class="matchBetList"
                                                                            (click)="openModalMarketPosition(matchOddsData,marketPosition);">
                                                                            Book</div>
                                                                        <div class="matchBetList"
                                                                            (click)="openModalBets(bets,matchOddsData?.name);getBetsBySearch(matchOddsData.market_id,'','popup');getRunners(matchOddsData)">
                                                                            Bets</div>
                                                                    </div>
                                                                </div>
                                                                <div class="team1 cteam odd-header-list">
                                                                    <div class="bet-value">
                                                                        <span class="small-label"> P/S </span>
                                                                        <label class="switch">
                                                                            <input type="checkbox" value="showShare" name="Active"
                                                                                class="chkPermission" (change)='updateShareWise(showShare)'
                                                                                [checked]="showShare == false ? true : false">
                                                                            <div class="slider round">
                                                                                <div class="on">S</div>
                                                                                <div class="off">F</div>
                                                                            </div>
                                                                        </label>
                                                                        <label class="switch" *ngIf="type == 0">
                                                                            <input type="checkbox" value="matchOddsData?.is_active" name="Active"
                                                                                class="chkPermission" (change)='updateMarketStatus(matchOddsData?.market_id,matchOddsData?.is_active)'
                                                                                [checked]="matchOddsData?.is_active == 1?true:false">
                                                                            <div class="slider round">
                                                                                <div class="on">On</div>
                                                                                <div class="off">Off</div>
                                                                            </div>
                                                                        </label>
                                                                        <span ngbTooltip="Market-Setting"
                                                                            class="setting-btn"
                                                                            (click)="openModalSportSetting(sportSetting,adminDetails,matchOddsData.market_id,3);getMarketLimits(matchOddsData,3)"
                                                                            *ngIf="type == 0"><i
                                                                                class="fa fa-cog"></i></span>
                                                                        <div style="margin: 0 10px; font-size: 18px;" ngbTooltip="UnLock"
                                                                            (click)="updateLimit(matchOddsData.market_id,2,matchOddsData.is_lock)"
                                                                            *ngIf="matchOddsData.is_lock == false && type == 0">
                                                                            <a><i class="fa fa-unlock"></i></a>
                                                                        </div>
                                                                        <div style="margin: 0 10px; font-size: 18px;" ngbTooltip="Lock"
                                                                            (click)="updateLimit(matchOddsData.market_id,2,matchOddsData.is_lock)"
                                                                            *ngIf="matchOddsData.is_lock != false && type == 0">
                                                                            <a><i class="fa fa-lock"></i></a>
                                                                        </div>
                                                                        <div class="bet-value">
                                                                            <div class="matchBetList"
                                                                                (click)="abonded(matchOddsData);" *ngIf=" type == 0">
                                                                                Abond </div>
                                                                        </div> &nbsp;&nbsp;
                                                                        <div class="bet-value">
                                                                            <div class="matchBetList"
                                                                            (click)="openModalNews(news,matchOddsData)" *ngIf=" type == 0">
                                                                                News </div>
                                                                        </div> &nbsp;&nbsp;
                                                                    </div>
                                                                    <div class="bet-value">
                                                                        <div class="bet-value"
                                                                        style="margin-left: 25px;"><a
                                                                            class="back-cell"><img
                                                                                src="assets/images/transparent.gif">
                                                                            Back</a></div>
                                                                        <div class="bet-value"><a class="lay-cell"><img
                                                                                    src="assets/images/transparent.gif">
                                                                                Lay</a></div>
                                                                        <div class="bet-value hide-on-med-and-down">
                                                                        </div>
                                                                        <div class="bet-value hide-on-med-and-down">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="bet-list">
                                                        <div class="market-runner">
                                                            <div class="team-market"
                                                                *ngFor="let runner of matchOddsData.runners; let i = index;trackBy: ">
                                                                <div class="team1 cteam">
                                                                    <div class="team-name">
                                                                        <p>{{runner?.name}}</p>
                                                                        <span class="text-bold" *ngIf="showShare == false"
                                                                            [ngClass]="{'red':(runner.win_loss)<0,'green':(runner.win_loss)>0}">
                                                                            {{(runner.win_loss)| number : '0.0-2'}}
                                                                        </span>
                                                                        <span class="text-bold" *ngIf="showShare == true"
                                                                            [ngClass]="{'red':(runner.win_loss_total_exposure)<0,'green':(runner.win_loss_total_exposure)>0}">
                                                                            {{(runner.win_loss_total_exposure) | number : '0.0-2'}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="bet-rate-section">
                                                                        <div class="bet-value light-back hide-on-med-and-down"
                                                                            *ngIf="runner?.ex?.availableToBack?.length == 3"
                                                                            [ngClass]="runner?.ex?.availableToBack[2]
                                                                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToBack[2]?.size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[2].size,runner?.ex?.availableToBack[2]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[2].price,i,'back') : ''">
                                                                            <a>
                                                                                <p>{{(runner?.ex?.availableToBack[2]
                                                                                    !=undefined?runner?.ex?.availableToBack[2]?.price
                                                                                    :'')}}</p>
                                                                                <span>{{(runner?.ex?.availableToBack[2]
                                                                                    !=undefined?runner?.ex?.availableToBack[2]?.size
                                                                                    :'')}}</span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value light-back hide-on-med-and-down"
                                                                            *ngIf="runner?.ex?.availableToBack?.length != 3">
                                                                            <a>
                                                                                <p></p>
                                                                                <span></span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value light-back hide-on-med-and-down"
                                                                            *ngIf="runner?.ex?.availableToBack?.length != 1"
                                                                            [ngClass]="runner?.ex?.availableToBack[1]
                                                                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToBack[1]?.size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[1].size,runner?.ex?.availableToBack[1]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[1].price,i,'back') : '' ">
                                                                            <a>
                                                                                <p>{{(runner?.ex?.availableToBack[1]
                                                                                    !=undefined?runner?.ex?.availableToBack[1]?.price
                                                                                    :'')}}</p>
                                                                                <span>{{(runner?.ex?.availableToBack[1]
                                                                                    !=undefined?runner?.ex?.availableToBack[1]?.size
                                                                                    :'')}}</span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value light-back hide-on-med-and-down"
                                                                            *ngIf="runner?.ex?.availableToBack?.length == 1">
                                                                            <a>
                                                                                <p></p>
                                                                                <span></span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value back-rate"
                                                                            *ngIf="runner?.ex?.availableToBack?.length > 0"
                                                                            [ngClass]="runner?.ex?.availableToBack[0]
                                                                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToBack[0]?.size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[0].size,runner?.ex?.availableToBack[0]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[0].price,i,'back') : ''">
                                                                            <a>
                                                                                <p>{{(runner?.ex?.availableToBack[0]
                                                                                    !=undefined?runner?.ex?.availableToBack[0]?.price
                                                                                    :'')}}</p>
                                                                                <span>{{(runner?.ex?.availableToBack[0]
                                                                                    !=undefined?runner?.ex?.availableToBack[0]?.size
                                                                                    :'')}}</span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value back-rate"
                                                                            *ngIf="runner?.ex?.availableToBack?.length == undefined ||runner?.ex?.availableToBack?.length == 0">
                                                                            <a>
                                                                                <p>0</p>
                                                                                <span></span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value lay-rate"
                                                                            *ngIf="runner?.ex?.availableToLay?.length > 0"
                                                                            [ngClass]="runner?.ex?.availableToLay[0]
                                                                            !=undefined  ? addClassWhenOddChange(runner?.ex?.availableToLay[0].size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[0].size,runner?.ex?.availableToLay[0].price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[0].price,i,'lay') : ''">
                                                                            <a>
                                                                                <p>{{(runner?.ex?.availableToLay[0]
                                                                                    !=undefined?runner?.ex?.availableToLay[0]?.price
                                                                                    :'')}}</p>
                                                                                <span>{{(runner?.ex?.availableToLay[0]
                                                                                    !=undefined?runner?.ex?.availableToLay[0]?.size
                                                                                    :'')}}</span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value lay-rate"
                                                                            *ngIf="runner?.ex?.availableToLay?.length == undefined || runner?.ex?.availableToLay?.length == 0">
                                                                            <a>
                                                                                <p></p>
                                                                                <span></span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value light-lay hide-on-med-and-down"
                                                                            *ngIf="runner?.ex?.availableToLay?.length != 1"
                                                                            [ngClass]="(runner?.ex?.availableToLay[1] !=undefined) ? addClassWhenOddChange(runner?.ex?.availableToLay[1].size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[1].size,runner?.ex?.availableToLay[1].price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[1].price,i,'lay'):''">
                                                                            <a>
                                                                                <p>{{(runner?.ex?.availableToLay[1]
                                                                                    !=undefined?runner?.ex?.availableToLay[1]?.price
                                                                                    :'')}}</p>
                                                                                <span>{{(runner?.ex?.availableToLay[1]
                                                                                    !=undefined?runner?.ex?.availableToLay[1]?.size
                                                                                    :'')}}</span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value light-lay hide-on-med-and-down"
                                                                            *ngIf="runner?.ex?.availableToLay?.length == 1 ">
                                                                            <a>
                                                                                <p></p>
                                                                                <span></span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value light-lay hide-on-med-and-down"
                                                                            *ngIf="runner?.ex?.availableToLay?.length == 3"
                                                                            [ngClass]="runner?.ex?.availableToLay[2]
                                                                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToLay[2].size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[2].size,runner?.ex?.availableToLay[2].price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[2].price,i,'lay') : ''">
                                                                            <a>
                                                                                <p>{{(runner?.ex?.availableToLay[2]
                                                                                    !=undefined?runner?.ex?.availableToLay[2]?.price
                                                                                    :'')}}</p>
                                                                                <span>{{(runner?.ex?.availableToLay[2]
                                                                                    !=undefined?runner?.ex?.availableToLay[2]?.size
                                                                                    :'')}}</span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value light-lay hide-on-med-and-down"
                                                                            *ngIf="runner?.ex?.availableToLay?.length != 3">
                                                                            <a>
                                                                                <p></p>
                                                                                <span></span>
                                                                            </a>
                                                                        </div>
                                                                        
                                                                        <div class="suspend"
                                                                        *ngIf="matchOddsData.status == 'SUSPENDED' || matchOddsData.status == 'CLOSED' || matchOddsData.status == 'closed' || matchOddsData.status == 'Closed' ">
                                                                            <div class="suspend-status">SUSPENDED</div>
                                                                        </div>
                                                                        <div class="suspend"
                                                                            *ngIf="matchOddsData.status != 'SUSPENDED' && matchOddsData.is_lock == true">
                                                                            <div class="suspend-status">Lock</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="suspend"
                                                                    *ngIf="runner.status == 'SUSPENDED' && matchOddsData.status != 'SUSPENDED' ">
                                                                    <div class="suspend-status">SUSPENDED</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="bet-list">
                                                        <div class="market-runner">
                                                            <div class="team1 cteam" *ngIf="matchOddsData.updateNews != undefined">
                                                                <div class="team-name">
                                                                    <span style="line-height: 15px;"><marquee width="100%" direction="left"> {{matchOddsData.updateNews}}
                                                                        </marquee> </span>
                                                    
                                                                </div>
                                                    
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <!-- fancy List -->
                                        <div class="select-bet"
                                            *ngIf="matchData.sport_id == 4">
                                            <div class="row">

                                                <div class="col-md-12 bet-main">
                                                    <div class="fancy-bet">
                                                        <div class="bet-list bet-list-header">
                                                            <div class="market-runner">
                                                                <div class="team-market">
                                                                    <div class="team1 cteam">
                                                                        <div class="team-name market-type-title">
                                                                            <p class="title">Fancy Market <img
                                                                                    src="assets/images/transparent.gif">
                                                                            </p>
                                                                        </div>
                                                                        <div class="minMax-limit"
                                                                            *ngIf="showSetting == true && fancySetting == false">
                                                                            <span>Stake Limit:
                                                                                {{sessionSettingValues.session_min_stack}}
                                                                                -
                                                                                {{sessionSettingValues.session_max_stack}}</span>
                                                                            <span>Max Profit:
                                                                                {{sessionSettingValues.session_max_profit}}</span>
                                                                        </div>
                                                                        <div class="bet-value"></div>
                                                                        <div class="bet-value"></div>

                                                                        <div class="bet-value">
                                                                        </div>
                                                                    </div>
                                                                    <div class="team1 cteam" style="justify-content: end;">
                                                                        <div class="bet-value"><a>No</a></div>
                                                                        <div class="bet-value"><a>Yes</a></div>
                                                                        <div style="margin: 0 10px; font-size: 18px;line-height: 40px; width: 20px;"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="fancy-list"
                                                            *ngFor="let fancy of fancy;let i = index;">
                                                            <div class="bet-list">
                                                                <div class="team1 cteam">
                                                                    <div class="team-name">
                                                                        <span>
                                                                            <span class="in-text"><b>{{fancy?.name}}</b>
                                                                                <span style="color:red;" *ngIf="fancyLiability" (click)="fancyPositionData(fancy,fancyPosition);">
                                                                                    <span *ngIf="fancyLiability[fancy.fancy_id]"> {{fancyLiability[fancy.fancy_id]}}</span>
                                                                                </span>
                                                                            </span>
                                                                        </span>

                                                                    </div>
                                                                    <div class="bet-value book-bet-div hide-on-down">
                                                                        <div class="matchBetList"
                                                                            (click)="openModalFancyBets(fancybets,fancy.name);getFancyBetsBySearch(fancy.fancy_id);">
                                                                            Bets</div>
                                                                            <div class="matchBetList"
                                                                            (click)="openModalNews(news,fancy)" *ngIf=" type == 0">
                                                                            News</div>
                                                                        <!-- <div class="matchBetList" (click)="fancyPositionData(fancy,fancyPosition);"> Book</div> -->
                                                                    </div>
                                                                    <div class="bet-rate-section">
                                                                        <div class="bet-value lay-rate">
                                                                            <a>
                                                                                <p>{{fancy?.session_value_no == 'Ball' ||
                                                                                    fancy?.session_value_no == '-' ||
                                                                                    fancy?.session_value_no == '0' ? '0' :
                                                                                    fancy?.session_value_no}}</p>
                                                                                <span>{{fancy?.session_size_no ==
                                                                                    'SUSPENDED' ||
                                                                                    fancy?.session_size_no == 'Running' ||
                                                                                    fancy?.session_size_no == '0'? '0' :
                                                                                    fancy?.session_size_no}}</span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value back-rate">
                                                                            <a>
                                                                                <p>{{fancy?.session_value_yes == 'Ball' ||
                                                                                    fancy?.session_value_yes == '-' ||
                                                                                    fancy?.session_value_yes == '0' ? '0' :
                                                                                    fancy?.session_value_yes}}</p>
                                                                                <span> {{fancy?.session_size_yes ==
                                                                                    'SUSPENDED' ||
                                                                                    fancy?.session_size_yes == 'Running'
                                                                                    ||fancy?.session_size_yes == '0' ? '0' :
                                                                                    fancy?.session_size_yes}} </span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="suspend" *ngIf="fancy.display_message != ''">
                                                                            <div class="suspend-status">
                                                                                {{fancy?.display_message}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="suspend"
                                                                            *ngIf="fancy.display_message == '' && fancy.is_lock == true">
                                                                            <div class="suspend-status">Lock
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style="margin: 0 10px; font-size: 18px;line-height: 40px; width: 30px;">
                                                                        <div class="dropdown detail-fancy">
                                                                            <span class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                              <i class="fa fa-ellipsis-h"></i>
                                                                            </span>
                                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                <div class="dropdown-item">
                                                                                    <div (click)="updateLimit(fancy.fancy_id,3,fancy.is_lock)"
                                                                                        *ngIf="fancy.is_lock == false && type == 0">
                                                                                        <a><i class="fa fa-unlock"></i> Lock</a> 
                                                                                    </div>
                                                                                    <div (click)="updateLimit(fancy.fancy_id,3,fancy.is_lock)"
                                                                                        *ngIf="fancy.is_lock != false && type == 0">
                                                                                        <a><i class="fa fa-lock"></i> Unlock</a>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="dropdown-item">
                                                                                    <span ngbTooltip="Market-Setting" class="setting-btn" (click)="openModalSportSetting(sportSetting,adminDetails,fancy.fancy_id,4);getMarketLimits(fancy,4)" *ngIf="type == 0">
                                                                                        <i class="fa fa-cog"></i> Setting
                                                                                    </span>
                                                                                </div>
                                                                                <div class="dropdown-item" (click)="openModalFancyBets(fancybets,fancy.name);getFancyBetsBySearch(fancy.fancy_id);">My Bets</div>
                                                                                <div class="dropdown-item">
                                                                                    <div class="min-max-value" *ngIf="fancySetting == true">
                                                                                        <span><b>Min / Max</b> {{fancy?.min_stake}}
                                                                                            / {{fancy?.max_stake}}</span>
                                                                                        <span><b>Max-Profit</b>
                                                                                            {{fancy?.max_profit}}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                          </div>
                                                                    </div>
                                                                </div>
                                                                <div class="team1 cteam" *ngIf="fancy.news != undefined">
                                                                    <div class="team-name">
                                                                        <span style="line-height: 15px;">News :  <marquee width="94%" direction="left"> {{fancy.news}}</marquee> </span>

                                                                    </div>
                                                                   
                                                                </div>
                                                            </div>
                                                            <!-- <div class="bet-list" >
                                                                {{fancy?.news}}
                                                            </div> -->
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade " id="matchOddTab"
                                        [class.show]="activeBetTab === 'matchOdd'"
                                        [class.active]="activeBetTab === 'matchOdd'">
                                        <div class="select-bet">
                                            <div class="row">
                                                <ng-container
                                                    *ngFor="let matchOddsData of matchDetail;let mDTIndex=index;">
                                                    <div class="col-md-12 bet-main"
                                                        *ngIf="matchOddsData?.market_name == 'Match Odds'">
                                                        <div class="bet-list bet-list-header">
                                                            <div class="market-runner">
                                                                <div class="team-market">
                                                                    <div class="team1 cteam">
                                                                        <div class="team-name market-type-title">
                                                                            <p class="title">{{matchOddsData?.name}}
                                                                                <img
                                                                                    src="assets/images/transparent.gif">
                                                                            </p>
                                                                            <div class="minMax-limit"
                                                                                *ngIf="showSetting == true">
                                                                                <span>Stake Limit:
                                                                                    {{matchOddsData?.min_stake}} -
                                                                                    {{matchOddsData?.max_stake}}</span>
                                                                                <span>Max Profit:
                                                                                    {{matchOddsData?.max_profit}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="bet-value book-bet-div">
                                                                            <div class="matchBetList"
                                                                                (click)="openModalMarketPosition(matchOddsData,marketPosition);">
                                                                                Book</div>
                                                                            <div class="matchBetList"
                                                                                (click)="openModalBets(bets,matchOddsData?.name);getBetsBySearch(matchOddsData.market_id,'','popup');getRunners(matchOddsData)">
                                                                                Bets</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="team1 cteam odd-header-list">
                                                                        <div class="bet-value">
                                                                            <span class="small-label"> Show sharing wise book </span>
                                                                            <label class="switch">
                                                                                <input type="checkbox" value="showShare" name="Active"
                                                                                    class="chkPermission" (change)='updateShareWise(showShare)'
                                                                                    [checked]="showShare == false ? true : false">
                                                                                <div class="slider round">
                                                                                    <div class="on">S</div>
                                                                                    <div class="off">F</div>
                                                                                </div>
                                                                            </label>
                                                                            <label class="switch" *ngIf="type == 0">
                                                                                <input type="checkbox" value="matchOddsData?.is_active" name="Active"
                                                                                    class="chkPermission" (change)='updateMarketStatus(matchOddsData?.market_id,matchOddsData?.is_active)'
                                                                                    [checked]="matchOddsData?.is_active == 1?true:false">
                                                                                <div class="slider round">
                                                                                    <div class="on">On</div>
                                                                                    <div class="off">Off</div>
                                                                                </div>
                                                                            </label>
                                                                            <span ngbTooltip="Market-Setting"
                                                                                class="setting-btn"
                                                                                (click)="openModalSportSetting(sportSetting,adminDetails,matchOddsData.market_id,3);getMarketLimits(matchOddsData,3)"
                                                                                *ngIf="type == 0"><i
                                                                                    class="fa fa-cog"></i></span>
                                                                            <div style="margin: 0 10px; font-size: 18px;"
                                                                                (click)="updateLimit(matchOddsData.market_id,2,matchOddsData.is_lock)"
                                                                                *ngIf="matchOddsData.is_lock == false && type == 0">
                                                                                <a><i class="fa fa-unlock"></i></a>
                                                                            </div>
                                                                            <div style="margin: 0 10px; font-size: 18px;"
                                                                                (click)="updateLimit(matchOddsData.market_id,2,matchOddsData.is_lock)"
                                                                                *ngIf="matchOddsData.is_lock != false && type == 0">
                                                                                <a><i class="fa fa-lock"></i></a>
                                                                            </div>
                                                                            <div class="bet-value">
                                                                                <div class="matchBetList"
                                                                                    (click)="abonded(matchOddsData);" *ngIf=" type == 0">
                                                                                    Abond</div>
                                                                            </div> &nbsp;&nbsp; 
                                                                           
                                                                        </div>
                                                                        <div class="bet-value">
                                                                            <div class="bet-value"
                                                                            style="margin-left: 25px;"><a
                                                                                class="back-cell"><img
                                                                                    src="assets/images/transparent.gif">
                                                                                Back</a></div>
                                                                            <div class="bet-value"><a class="lay-cell"><img
                                                                                        src="assets/images/transparent.gif">
                                                                                    Lay</a></div>
                                                                            <div class="bet-value hide-on-med-and-down">
                                                                            </div>
                                                                            <div class="bet-value hide-on-med-and-down">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="bet-list odd-market-runner">
                                                            <div class="market-runner">
                                                                <div class="team-market"
                                                                    *ngFor="let runner of matchOddsData.runners; let i = index;trackBy: ">
                                                                    <div class="team1 cteam">
                                                                        <div class="team-name">
                                                                            <p>{{runner?.name}}</p>
                                                                            <span class="text-bold" *ngIf="showShare == false"
                                                                            [ngClass]="{'red':(runner.win_loss)<0,'green':(runner.win_loss)>0}">
                                                                            {{(runner.win_loss)| number : '0.0-2'}}
                                                                        </span>
                                                                        <span class="text-bold" *ngIf="showShare == true"
                                                                            [ngClass]="{'red':(runner.win_loss_total_exposure)<0,'green':(runner.win_loss_total_exposure)>0}">
                                                                            {{(runner.win_loss_total_exposure) | number : '0.0-2'}}
                                                                        </span>
                                                                        </div>
                                                                        <div class="bet-rate-section">
                                                                            <div class="bet-value light-back hide-on-med-and-down"
                                                                                *ngIf="runner?.ex?.availableToBack?.length == 3"
                                                                                [ngClass]="runner?.ex?.availableToBack[2]
                                                                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToBack[2]?.size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[2].size,runner?.ex?.availableToBack[2]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[2].price,i,'back') : ''">
                                                                                <a>
                                                                                    <p>{{(runner?.ex?.availableToBack[2]
                                                                                        !=undefined?runner?.ex?.availableToBack[2]?.price
                                                                                        :'')}}</p>
                                                                                    <span>{{(runner?.ex?.availableToBack[2]
                                                                                        !=undefined?runner?.ex?.availableToBack[2]?.size
                                                                                        :'')}}</span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value light-back hide-on-med-and-down"
                                                                                *ngIf="runner?.ex?.availableToBack?.length != 3">
                                                                                <a>
                                                                                    <p></p>
                                                                                    <span></span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value light-back hide-on-med-and-down"
                                                                                *ngIf="runner?.ex?.availableToBack?.length != 1"
                                                                                [ngClass]="runner?.ex?.availableToBack[1]
                                                                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToBack[1]?.size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[1].size,runner?.ex?.availableToBack[1]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[1].price,i,'back') : '' ">
                                                                                <a>
                                                                                    <p>{{(runner?.ex?.availableToBack[1]
                                                                                        !=undefined?runner?.ex?.availableToBack[1]?.price
                                                                                        :'')}}</p>
                                                                                    <span>{{(runner?.ex?.availableToBack[1]
                                                                                        !=undefined?runner?.ex?.availableToBack[1]?.size
                                                                                        :'')}}</span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value light-back hide-on-med-and-down"
                                                                                *ngIf="runner?.ex?.availableToBack?.length == 1">
                                                                                <a>
                                                                                    <p></p>
                                                                                    <span></span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value back-rate"
                                                                                *ngIf="runner?.ex?.availableToBack?.length > 0"
                                                                                [ngClass]="runner?.ex?.availableToBack[0]
                                                                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToBack[0]?.size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[0].size,runner?.ex?.availableToBack[0]?.price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToBack[0].price,i,'back') : ''">
                                                                                <a>
                                                                                    <p>{{(runner?.ex?.availableToBack[0]
                                                                                        !=undefined?runner?.ex?.availableToBack[0]?.price
                                                                                        :'')}}</p>
                                                                                    <span>{{(runner?.ex?.availableToBack[0]
                                                                                        !=undefined?runner?.ex?.availableToBack[0]?.size
                                                                                        :'')}}</span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value back-rate"
                                                                                *ngIf="runner?.ex?.availableToBack?.length == undefined">
                                                                                <a>
                                                                                    <p></p>
                                                                                    <span></span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value lay-rate"
                                                                                *ngIf="runner?.ex?.availableToLay?.length > 0"
                                                                                [ngClass]="runner?.ex?.availableToLay[0]
                                                                            !=undefined  ? addClassWhenOddChange(runner?.ex?.availableToLay[0].size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[0].size,runner?.ex?.availableToLay[0].price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[0].price,i,'lay') : ''">
                                                                                <a>
                                                                                    <p>{{(runner?.ex?.availableToLay[0]
                                                                                        !=undefined?runner?.ex?.availableToLay[0]?.price
                                                                                        :'')}}</p>
                                                                                    <span>{{(runner?.ex?.availableToLay[0]
                                                                                        !=undefined?runner?.ex?.availableToLay[0]?.size
                                                                                        :'')}}</span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value lay-rate"
                                                                                *ngIf="runner?.ex?.availableToLay?.length == undefined">
                                                                                <a>
                                                                                    <p></p>
                                                                                    <span></span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value light-lay hide-on-med-and-down"
                                                                                *ngIf="runner?.ex?.availableToLay?.length != 1"
                                                                                [ngClass]="(runner?.ex?.availableToLay[1] !=undefined) ? addClassWhenOddChange(runner?.ex?.availableToLay[1].size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[1].size,runner?.ex?.availableToLay[1].price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[1].price,i,'lay'):''">
                                                                                <a>
                                                                                    <p>{{(runner?.ex?.availableToLay[1]
                                                                                        !=undefined?runner?.ex?.availableToLay[1]?.price
                                                                                        :'')}}</p>
                                                                                    <span>{{(runner?.ex?.availableToLay[1]
                                                                                        !=undefined?runner?.ex?.availableToLay[1]?.size
                                                                                        :'')}}</span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value light-lay hide-on-med-and-down"
                                                                                *ngIf="runner?.ex?.availableToLay?.length == 1 || runner?.ex?.availableToLay?.length == 0">
                                                                                <a>
                                                                                    <p></p>
                                                                                    <span></span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value light-lay hide-on-med-and-down"
                                                                                *ngIf="runner?.ex?.availableToLay?.length == 3"
                                                                                [ngClass]="runner?.ex?.availableToLay[2]
                                                                            !=undefined ? addClassWhenOddChange(runner?.ex?.availableToLay[2].size,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[2].size,runner?.ex?.availableToLay[2].price,matchDetail[mDTIndex]?.shadowRunner[i]?.ex?.availableToLay[2].price,i,'lay') : ''">
                                                                                <a>
                                                                                    <p>{{(runner?.ex?.availableToLay[2]
                                                                                        !=undefined?runner?.ex?.availableToLay[2]?.price
                                                                                        :'')}}</p>
                                                                                    <span>{{(runner?.ex?.availableToLay[2]
                                                                                        !=undefined?runner?.ex?.availableToLay[2]?.size
                                                                                        :'')}}</span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="bet-value light-lay hide-on-med-and-down"
                                                                                *ngIf="runner?.ex?.availableToLay?.length != 3">
                                                                                <a>
                                                                                    <p></p>
                                                                                    <span></span>
                                                                                </a>
                                                                            </div>
                                                                            
                                                                            <div class="suspend"
                                                                            *ngIf="matchOddsData.status == 'SUSPENDED' || matchOddsData.status == 'CLOSED' || matchOddsData.status == 'closed' || matchOddsData.status == 'Closed' ">
                                                                                <div class="suspend-status">SUSPENDED</div>
                                                                            </div>
                                                                            <div class="suspend"
                                                                                *ngIf="matchOddsData.status != 'SUSPENDED' && matchOddsData.is_lock == true">
                                                                                <div class="suspend-status">Lock</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="suspend"
                                                                        *ngIf="runner.status == 'SUSPENDED' && matchOddsData.status != 'SUSPENDED' ">
                                                                        <div class="suspend-status">SUSPENDED</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="tab-pane fade " id="bookmakerTab" [class.show] = "activeBetTab === 'bookmaker'" [class.active] = "activeBetTab === 'bookmaker'" *ngIf="matchData.sport_id == 4">
                                    </div> -->

                                    <div class="tab-pane fade " id="fancyTab" [class.show]="activeBetTab === 'fancy'"
                                        [class.active]="activeBetTab === 'fancy'" *ngIf="matchData.sport_id == 4">
                                        <div class="select-bet" *ngIf="matchData.sport_id == 4">
                                            <div class="row">

                                                <div class="col-md-12 bet-main">
                                                    <div class="fancy-bet">
                                                        <div class="bet-list bet-list-header">
                                                            <div class="market-runner">
                                                                <div class="team-market">
                                                                    <div class="team1 cteam">
                                                                        <div class="team-name market-type-title">
                                                                            <p class="title">Fancy Market <img
                                                                                    src="assets/images/transparent.gif">
                                                                            </p>
                                                                        </div>
                                                                        <div class="minMax-limit"
                                                                            *ngIf="showSetting == true && fancySetting == false">
                                                                            <span>Stake Limit:
                                                                                {{sessionSettingValues.session_min_stack}}
                                                                                -
                                                                                {{sessionSettingValues.session_max_stack}}</span>
                                                                            <span>Max Profit:
                                                                                {{sessionSettingValues.session_max_profit}}</span>
                                                                        </div>
                                                                        <div class="bet-value"></div>
                                                                        <div class="bet-value"></div>

                                                                        <div class="bet-value">
                                                                        </div>
                                                                    </div>
                                                                    <div class="team1 cteam" style="justify-content: end;">
                                                                        <div class="bet-value"><a>No</a></div>
                                                                        <div class="bet-value"><a>Yes</a></div>
                                                                        <div style="margin: 0 10px; font-size: 18px;line-height: 40px; width: 20px;"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="fancy-list"
                                                            *ngFor="let fancy of fancy;let i = index;">
                                                            <div class="bet-list">
                                                                <div class="team1 cteam">
                                                                    <div class="team-name">
                                                                        <span>
                                                                            <span class="in-text"><b>{{fancy?.name}}</b>
                                                                                <span style="color:red;" *ngIf="fancyLiability" (click)="fancyPositionData(fancy,fancyPosition);">
                                                                                    <span *ngIf="fancyLiability[fancy.fancy_id]"> {{fancyLiability[fancy.fancy_id]}}</span>
                                                                                </span>
                                                                            </span>
                                                                        </span>

                                                                    </div>
                                                                    <div class="bet-value book-bet-div hide-on-down">
                                                                        <div class="matchBetList"
                                                                            (click)="openModalFancyBets(fancybets,fancy.name);getFancyBetsBySearch(fancy.fancy_id);">
                                                                            Bets</div>
                                                                        <!-- <div class="matchBetList" (click)="fancyPositionData(fancy,fancyPosition);"> Book</div> -->
                                                                    </div>
                                                                    <div class="bet-rate-section">
                                                                        <div class="bet-value lay-rate">
                                                                            <a>
                                                                                <p>{{fancy?.session_value_no == 'Ball' ||
                                                                                    fancy?.session_value_no == '-' ||
                                                                                    fancy?.session_value_no == '0' ? '0' :
                                                                                    fancy?.session_value_no}}</p>
                                                                                <span>{{fancy?.session_size_no ==
                                                                                    'SUSPENDED' ||
                                                                                    fancy?.session_size_no == 'Running' ||
                                                                                    fancy?.session_size_no == '0'? '0' :
                                                                                    fancy?.session_size_no}}</span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="bet-value back-rate">
                                                                            <a>
                                                                                <p>{{fancy?.session_value_yes == 'Ball' ||
                                                                                    fancy?.session_value_yes == '-' ||
                                                                                    fancy?.session_value_yes == '0' ? '0' :
                                                                                    fancy?.session_value_yes}}</p>
                                                                                <span> {{fancy?.session_size_yes ==
                                                                                    'SUSPENDED' ||
                                                                                    fancy?.session_size_yes == 'Running'
                                                                                    ||fancy?.session_size_yes == '0' ? '0' :
                                                                                    fancy?.session_size_yes}} </span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="suspend" *ngIf="fancy.display_message != ''">
                                                                            <div class="suspend-status">
                                                                                {{fancy?.display_message}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="suspend"
                                                                            *ngIf="fancy.display_message == '' && fancy.is_lock == true">
                                                                            <div class="suspend-status">Lock
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style="margin: 0 10px; font-size: 18px;line-height: 40px; width: 30px;">
                                                                        <div class="dropdown detail-fancy">
                                                                            <span class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                              <i class="fa fa-ellipsis-h"></i>
                                                                            </span>
                                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                <div class="dropdown-item">
                                                                                    <div (click)="updateLimit(fancy.fancy_id,3,fancy.is_lock)"
                                                                                        *ngIf="fancy.is_lock == false && type == 0">
                                                                                        <a><i class="fa fa-unlock"></i> Lock</a> 
                                                                                    </div>
                                                                                    <div (click)="updateLimit(fancy.fancy_id,3,fancy.is_lock)"
                                                                                        *ngIf="fancy.is_lock != false && type == 0">
                                                                                        <a><i class="fa fa-lock"></i> Unlock</a>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="dropdown-item">
                                                                                    <span ngbTooltip="Market-Setting" class="setting-btn" (click)="openModalSportSetting(sportSetting,adminDetails,fancy.fancy_id,4);getMarketLimits(fancy,4)" *ngIf="type == 0">
                                                                                        <i class="fa fa-cog"></i> Setting
                                                                                    </span>
                                                                                </div>
                                                                                <div class="dropdown-item" (click)="openModalFancyBets(fancybets,fancy.name);getFancyBetsBySearch(fancy.fancy_id);">My Bets</div>
                                                                                <div class="dropdown-item">
                                                                                    <div class="min-max-value" *ngIf="fancySetting == true">
                                                                                        <span><b>Min / Max</b> {{fancy?.min_stake}}
                                                                                            / {{fancy?.max_stake}}</span>
                                                                                        <span><b>Max-Profit</b>
                                                                                            {{fancy?.max_profit}}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                          </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade " id="otherTab" [class.show]="activeBetTab === 'other'"
                                        [class.active]="activeBetTab === 'other'" *ngIf="matchData.sport_id == 4">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pl-10">
                                <div class="bet-list">
                                    <div class="match-heading">
                                        <div class="game-header">
                                            <ul class="nav nav-pills" id="myRightTab" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link active" data-bs-toggle="tab"
                                                        data-bs-target="#allBetRight" (click)="getMyBets(0,1)">All Bet
                                                        ({{betDataLength == 0 ?
                                                        '0' : betDataLength}})</a>
                                                </li>
                                                <li class="nav-item">
                                                   
                                                    <a class="nav-link" data-bs-toggle="tab"
                                                        data-bs-target="#matchBetRight" (click)="getMyBets(1,1)">Match
                                                        Bet ({{matchedBetsLength == 0 ? '0' : matchedBetsLength}})</a>
                                                </li>
                                                <li class="nav-item" *ngIf="matchData.sport_id == 4">
                                                    <a class="nav-link" data-bs-toggle="tab"
                                                        data-bs-target="#fancyBetRight" (click)="getMyBets(2,1)">Fancy
                                                        Bet ({{fancyBetsLength == 0 ? '0' : fancyBetsLength}})</a>
                                                </li>
                                                <li class="nav-item" *ngIf="type == 0">
                                                    <a class="nav-link" data-bs-toggle="tab"
                                                        data-bs-target="#DeleteBetRight" (click)="getMyBets(3,1)">Delete
                                                        Bet ({{deletedBetsLength == 0 ?
                                                        '0' : deletedBetsLength}})</a>
                                                </li>

                                                <li class="nav-item" *ngIf="type == 0">
                                                    <a class="nav-link" data-bs-toggle="tab"
                                                        data-bs-target="#VoidBetRight" (click)="getMyBets(4,1)">Void
                                                        Bet ({{voidBetsLength == 0 ?
                                                        '0' : voidBetsLength}})</a>
                                                </li>
                                                <li class="nav-item" (click)="getFancyList()"
                                                    *ngIf="type == 0 && matchData.sport_id == '4' && enableFancy == 1">
                                                    <a class="nav-link" data-bs-toggle="tab"
                                                        data-bs-target="#addFancyRight">Add
                                                        Fancy</a>
                                                </li>
                                                <!-- <li class="nav-item" (click)="getFancyList()"
                                                    *ngIf="type == 0 && matchData.sport_id == '4' && enableFancy == 1">
                                                    <a class="nav-link" (click)="clearFancy()">clear
                                                        Fancy</a>
                                                </li> -->
                                                <li class="nav-item" (click)="getFancyList()"
                                                    *ngIf="type != 0 && matchData.sport_id == 4">
                                                    <a class="nav-link" data-bs-toggle="tab"
                                                        data-bs-target="#addFancyRight">Show
                                                        Fancy</a>
                                                </li>
                                                <!-- <li class="nav-item">
                                                    <a class="nav-link" data-bs-toggle="tab" data-bs-target="#search"
                                                        (click)="openModalSearchMarket(matchBet)"><i
                                                            class="fa fa-search"></i></a>
                                                </li> -->
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="game-body">
                                        <div class="tab-content" id="myRightTabContent">
                                            <div class="tab-pane fade show active" id="allBetRight">
                                                <div class="betFilter">
                                                    <div class="filter-input-list">
                                                        <div class="form-group" *ngIf ="type == 0">
                                                            <input type="text" [(ngModel)]="allBetDomain"
                                                                placeholder="Domain" value="" class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" [(ngModel)]="allBetUserName"
                                                                placeholder="Name" value="" class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" [(ngModel)]="allBetSelection"
                                                                placeholder="Selection" value="" class="form-control" />
                                                        </div>
                                                        <div class="form-group" style="width:10%">
                                                            <select class="user-select form-control" style="color:black"
                                                                (change)="onSelectionList($event.target.value)">
                                                                <option label=""
                                                                    *ngFor="let numvalue of betdataType | keyvalue ; let i= index;"
                                                                    [value]="numvalue.value">
                                                                    {{numvalue.value}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" placeholder="Rate"
                                                                [(ngModel)]="allBetRate" value=""
                                                                class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" placeholder="Stake"
                                                                [(ngModel)]="allBetStake" value=""
                                                                class="form-control" />
                                                        </div>
                                                        <div class="form-group" style="width:10%">
                                                            <button name="Submit" class="filter-bet-btn"
                                                                (click)="getBetsBySearch(market_id,1)"> Filter </button>
                                                        </div>
                                                        <div class="form-group" style="width:10%">

                                                            <button name="Submit" class="filter-bet-btn"
                                                                (click)="clearAllBet()"> Clear </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="report-table">
                                                        <table class="table" datatable>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.No.</th>
                                                                    <th><span *ngIf="type == 0">Domain </span>
                                                                        <span>Name</span></th>
                                                                    <!-- <th *ngIf="type != 0">Name</th> -->
                                                                    <th><span>Market </span>
                                                                        <span>Selection</span> </th>
                                                                    <!-- <th>Bet Type</th> -->
                                                                    <!-- <th>Selection</th> -->
                                                                    <th><span>Rate </span>
                                                                        <span>Stake</span> </th>
                                                                    <!-- <th>Stake</th> -->
                                                                    <th><span>Liability </span>
                                                                        <span>Placed Time</span> </th>
                                                                    <!-- <th>Placed Time</th> -->
                                                                    <th><span>BetId </span>
                                                                        <span>IP Address</span></th>
                                                                    <!-- <th>IP Address</th> -->
                                                                    <!-- <th *ngIf="type == 0"></th> -->
                                                                </tr>
                                                            </thead>
                                                            <tbody *ngIf="betValue == true">
                                                                <ng-container
                                                                    *ngFor="let bet of betData| paginate: {id: 'first', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems } ;let i= index;">
                                                                    <tr [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">

                                                                        <td ><span style="cursor: pointer;" ngxClipboard
                                                                            [cbContent]="'Content to be copied.'"
                                                                            (cbOnSuccess)="contentCopied($event)"
                                                                            (click)="copyContent(bet.bet_id)"> {{itemsPerPage *(currentPage-1)+i+1}}<div
                                                                                class="box"
                                                                                *ngIf="objectId == bet.bet_id && displayTooltip == true">
                                                                                <h5>Copied</h5>
                                                                            </div></span> <br>
                                                                            <span *ngIf="type == 0"> <a class="btn btn-warning btn-sm actionbtn"
                                                                                role="button" tabindex="0"
                                                                                title="Delete bet"
                                                                                (click)="openModaldeleteBet(deleteBet, bet.bet_id,bet.user_id,bet.is_fancy,bet.user_name)"
                                                                                *ngIf="bet.delete_status == '0'"><i
                                                                                    class="fa fa-trash"></i></a>
                                                                            <button class="btn btn-danger"
                                                                                *ngIf="bet.delete_status =='1'">D</button>
                                                                            <button class="btn btn-warning"
                                                                                *ngIf="bet.delete_status =='2'">v</button></span>
                                                                        </td>
                                                                        <td ><span (click)="openModalUserParentList(bet,userParentList)">{{bet?.user_name}}</span><br><span *ngIf="type == 0">{{bet?.domain_name}}</span></td>
                                                                        <!-- <td class="name-cell" (click)="openModalUserParentList(bet,userParentList)">{{bet?.user_name}}</td> -->
                                                                        <td><span>{{bet?.market_name}}</span><br><span>{{bet.is_fancy == 0 ? bet.selection_name :
                                                                            bet.selection_name}}</span></td>
                                                                        <!-- <td *ngIf="(bet.is_back)==0"
                                                                            [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">
                                                                            <span>Lay</span>
                                                                        </td>
                                                                        <td *ngIf="(bet.is_back)==1"
                                                                            [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">
                                                                            <span>Back</span>
                                                                        </td> -->
                                                                        <!-- <td class="selection">{{bet.is_fancy == 0 ? bet.selection_name :
                                                                            bet.selection_name}}</td> -->
                                                                        <td><span>{{bet?.odds}} / {{bet?.size}}</span><br><span>{{bet?.stack}}</span></td>
                                                                        <!-- <td class="stack-name">{{bet?.stack}}</td> -->
                                                                        <td><span [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}">{{(bet?.liability)| number : '0.0-2'}}</span><br>
                                                                            <span>{{(bet?.createdAt | date :'dd-MM-yyyy
                                                                                hh:mm:ss aa')}}</span>
                                                                        </td>
                                                                        <!-- <td>{{(bet?.createdAt | date :'dd-MM-yyyy
                                                                            hh:mm:ss aa')}}</td> -->
                                                                        <td><span>{{bet?.bet_id}}</span><br><span>{{bet?.ip_address}}</span></td>
                                                                        <!-- <td>{{bet?.ip_address}}</td> -->
                                                                        <!-- <td *ngIf="type == 0">
                                                                            <a class="btn btn-warning btn-sm actionbtn"
                                                                                role="button" tabindex="0"
                                                                                title="Delete bet"
                                                                                (click)="openModaldeleteBet(deleteBet, bet.bet_id,bet.user_id,bet.is_fancy)"
                                                                                *ngIf="bet.delete_status == '0'"><i
                                                                                    class="fa fa-trash"></i></a>
                                                                            <button class="btn btn-danger"
                                                                                *ngIf="bet.delete_status =='1'">D</button>
                                                                            <button class="btn btn-warning"
                                                                                *ngIf="bet.delete_status =='2'">v</button>
                                                                        </td> -->
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>
                                                            <tbody *ngIf="betValue == false">
                                                                <td colspan="8" class="text-center"><span>{{messageData}}</span></td>
                                                            </tbody>
                                                        </table>
                                                        <div class="report-bottom-paginate">
                                                            <pagination-controls (pageChange)="pageChange($event)"
                                                                class="user-list-pagination" id="first"
                                                                *ngIf="betValue == true">
                                                            </pagination-controls>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade " id="matchBetRight">
                                                <div class="betFilter">


                                                    <div class="filter-input-list">
                                                        <div class="form-group" *ngIf ="type == 0">
                                                            <input type="text" [(ngModel)]="matchBetDomain"
                                                                placeholder="Domain" value="" class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" [(ngModel)]="matchBetUserName"
                                                                placeholder="Name" value="" class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" [(ngModel)]="matchBetSelection"
                                                                placeholder="Selection" value="" class="form-control" />
                                                        </div>
                                                        <div class="form-group" style="width:10%">
                                                            <select class="user-select form-control"
                                                                style="color:black">
                                                                <option label=""
                                                                    *ngFor="let numvalue of betdataType | keyvalue ; let i= index;"
                                                                    [value]="numvalue.value">
                                                                    {{numvalue.value}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" placeholder="Rate"
                                                                [(ngModel)]="matchBetRate" value=""
                                                                class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" placeholder="Stake"
                                                                [(ngModel)]="matchBetStake" value=""
                                                                class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <button name="Submit" class="filter-bet-btn"
                                                                (click)="getBetsBySearch(market_id,2)"> Filter </button>
                                                        </div>
                                                        <div class="form-group">
                                                            <button name="Submit" class="filter-bet-btn"
                                                                (click)="clearMatchBet()"> Clear </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="report-table">
                                                    <table class="table" datatable>
                                                        <thead>
                                                            <tr>
                                                                <th>S.No.</th>
                                                                    <th>
                                                                        <span>Name</span><span *ngIf="type == 0">Domain </span></th>
                                                                    <th><span>Market </span>
                                                                        <span>Selection</span> </th>
                                                                    <th><span>Rate </span>
                                                                        <span>Stake</span> </th>
                                                                    <th><span>Liability </span>
                                                                        <span>Placed Time</span> </th>
                                                                    <th><span>BetId </span>
                                                                        <span>IP Address</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngIf="matchValue == true">
                                                            <ng-container
                                                                *ngFor="let bet of matchedBets | paginate: { id: 'two',itemsPerPage: matchItemsPerPage, currentPage: matchcurrentPage, totalItems: matchtotal_items };let i= index;">
                                                                <tr [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">
                                                                    <td><span>{{matchItemsPerPage *(matchcurrentPage-1)+i+1}}</span><br>
                                                                        <span *ngIf="type == 0"><a
                                                                            class="btn btn-warning btn-sm actionbtn"
                                                                            role="button" tabindex="0"
                                                                            title="Delete bet" *ngIf="bet.delete_status =='0'"
                                                                            (click)="openModaldeleteBet(deleteBet, bet.bet_id,bet.user_id,bet.user_name)"><i
                                                                                class="fa fa-trash"></i></a>
                                                                                <button class="btn btn-danger"
                                                                                *ngIf="bet.delete_status =='1'">D</button>
                                                                            <button class="btn btn-warning"
                                                                                *ngIf="bet.delete_status =='2'">v</button></span>
                                                                    </td>
                                                                    <td ><span (click)="openModalUserParentList(bet,userParentList)">{{bet?.user_name}}</span><br>
                                                                        <span *ngIf="type == 0">{{bet?.domain_name}}</span></td>
                                                                    <td><span>{{bet?.market_name}}</span><span>{{bet.is_fancy == 0 ? bet.selection_name :
                                                                        bet.name}}</span></td>
                                                                    <td><span>{{bet?.odds}} / {{bet?.size}}</span><br><span>{{bet?.stack}}</span></td>

                                                                    <td>
                                                                        <span [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}"> {{(bet?.liability)| number : '0.0-2'}}</span><br>
                                                                        <span>{{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}</span>
                                                                       </td>
                                                                    <td><span>{{bet?.bet_id}}</span><br><span>{{bet?.ip_address}}</span></td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                        <tbody *ngIf="matchValue == false">
                                                            <td colspan="9" class="text-center"><span>{{messageData}}</span></td>
                                                        </tbody>
                                                    </table>
                                                    <div class="report-bottom-paginate">
                                                        <pagination-controls (pageChange)="matchedBetpageChange($event)"
                                                        class="user-list-pagination" id="two"
                                                        *ngIf="matchValue == true"></pagination-controls>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="fancyBetRight">
                                                <div class="betFilter">
                                                    <div class="filter-input-list">
                                                        <div class="form-group" *ngIf ="type == 0">
                                                            <input type="text" [(ngModel)]="fancyBetDomain"
                                                                placeholder="Domain" value="" class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" [(ngModel)]="fancyBetUserName"
                                                                placeholder="Name" value="" class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" [(ngModel)]="fancyBetSelection"
                                                                placeholder="Fancy Name" value=""
                                                                class="form-control" />
                                                        </div>
                                                        <div class="form-group" style="width:10%">
                                                            <select class="user-select form-control" style="color:black"
                                                                (change)="onFancySelectionList($event.target.value)">
                                                                <option label=""
                                                                    *ngFor="let numvalue of betdataType | keyvalue ; let i= index;"
                                                                    [value]="numvalue.value">
                                                                    {{numvalue.value}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" placeholder="Rate"
                                                                [(ngModel)]="fancyBetRate" value=""
                                                                class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" placeholder="Stake"
                                                                [(ngModel)]="fancyBetStake" value=""
                                                                class="form-control" />
                                                        </div>
                                                        <div class="form-group">
                                                            <button name="Submit" class="filter-bet-btn"
                                                                (click)="getFancyBetsBySearch(market_id)"> Filter
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <button name="Submit" class="filter-bet-btn"
                                                            (click)="clearFancyBet()"> Clear </button>
                                                    </div>
                                                </div>
                                                <div class="report-table">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <!-- <th>S.No.</th>
                                                                <th *ngIf="type == 0">Domain</th>
                                                                <th>Name</th>
                                                                <th>Market</th>
                                                                <th>Bet Type</th>
                                                                <th>Selection</th>
                                                                <th>Rate</th>
                                                                <th>Stake</th>
                                                                <th>Liability</th>
                                                                <th>Placed Time</th>
                                                                <th>BetId</th>
                                                                <th>IP Address</th>
                                                                <th *ngIf="type == 0"></th> -->
                                                                <th>S.No.</th>
                                                                    <th>
                                                                        <span>Name</span><span *ngIf="type == 0">Domain </span></th>
                                                                    <th><span>Market </span>
                                                                        <span>Selection</span> </th>
                                                                    <th><span>Rate </span>
                                                                        <span>Stake</span> </th>
                                                                    <th><span>Liability </span>
                                                                        <span>Placed Time</span> </th>
                                                                    <th><span>BetId </span>
                                                                        <span>IP Address</span></th>
                                                            </tr>
                                                        <tbody *ngIf="fancyValue == true">
                                                            <ng-container
                                                                *ngFor="let bet of fancyBets | paginate: { id: 'three',itemsPerPage: fancyItemsPerPage, currentPage: fancycurrentPage, totalItems: fancytotal_items };let i= index;">
                                                                <tr [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">

                                                                    <td >
                                                                        <span style="cursor: pointer;" ngxClipboard
                                                                        [cbContent]="'Content to be copied.'"
                                                                        (cbOnSuccess)="contentCopied($event)"
                                                                        (click)="copyContent(bet.bet_id)">
                                                                        {{fancyItemsPerPage *(fancycurrentPage-1)+i+1}}
                                                                        <div class="box"
                                                                            *ngIf="objectId == bet.bet_id && displayTooltip == true">
                                                                            <h5>Copied</h5>
                                                                        </div></span> <br>
                                                                        <span  *ngIf="type == 0">
                                                                            <a class="btn btn-warning btn-sm actionbtn"
                                                                            role="button" tabindex="0"
                                                                            title="Delete bet"
                                                                            (click)="openModaldeleteBet(deleteBet, bet.bet_id,bet.user_id,bet.is_fancy,bet.user_name)"
                                                                            *ngIf="bet.delete_status == '0'"><i
                                                                                class="fa fa-trash"></i></a>
                                                                        <button class="btn btn-danger"
                                                                            *ngIf="bet.delete_status =='1'">D</button>
                                                                        <button class="btn btn-warning"
                                                                            *ngIf="bet.delete_status =='2'">v</button>
                                                                        </span>
                                                                    </td>
                                                                    <td ><span (click)="openModalUserParentList(bet,userParentList)">{{bet?.user_name}}</span><br><span *ngIf="type == 0">{{bet?.domain_name}}</span></td>
                                                                    <!-- <td class="name-cell" (click)="openModalUserParentList(bet,userParentList)">{{bet?.user_name}}</td> -->
                                                                    <td><span>{{bet?.market_name}}</span><br><span>{{bet.is_fancy == 0 ? bet.selection_name :
                                                                        bet.selection_name}}</span></td>
                                                                    <!-- <td *ngIf="(bet.is_back)==0"
                                                                        [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">
                                                                        <span>Lay</span>
                                                                    </td>
                                                                    <td *ngIf="(bet.is_back)==1"
                                                                        [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">
                                                                        <span>Back</span>
                                                                    </td> -->
                                                                    <!-- <td>{{bet.is_fancy == 0 ? bet.selection_name :
                                                                        bet.selection_name}}</td> -->
                                                                    <td><span>{{bet?.odds}} / {{bet?.size}}</span><br><span>{{bet?.stack}}</span></td>
                                                                    <!-- <td>{{bet?.stack}}</td> -->
                                                                    <td>
                                                                        <span [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}">{{(bet?.liability)| number : '0.0-2'}}</span><br>
                                                                        <span>{{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}</span>
                                                                        </td>
                                                                    <!-- <td>{{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}</td> -->
                                                                    <td><span>{{bet?.bet_id}}</span><br><span>{{bet?.ip_address}}</span></td>
                                                                    <!-- <td>{{bet?.ip_address}}</td>
                                                                    <td>
                                                                        
                                                                    </td> -->
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                        <tbody *ngIf="fancyValue == false">
                                                            <td colspan="8" class="text-center"><span>{{messageData}}</span></td>
                                                        </tbody>
                                                    </table>
                                                    <div class="report-bottom-paginate">
                                                        <pagination-controls (pageChange)="fancypageChange($event)"
                                                        class="user-list-pagination" id="three"
                                                        *ngIf="fancyValue == true"></pagination-controls>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="DeleteBetRight">
                                                <div class="report-table" style="height:calc(100vh - 170px) !important">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>S.No.</th>
                                                                <th>Status</th>
                                                                <th *ngIf="type == 0">Domain</th>
                                                                <th>Name</th>
                                                                <th>Market</th>
                                                                <th>Bet Type</th>
                                                                <th>Selection</th>
                                                                <th>Rate</th>
                                                                <th>Stake</th>
                                                                <th>P/L</th>
                                                                <th>Placed Time</th>
                                                                <th>BetId</th>
                                                                <th>IP Address</th>
                                                                <!-- <th  *ngIf="type == 0"></th> -->
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngIf="deleteValue == true">
                                                            <ng-container
                                                                *ngFor="let bet of deletedBets| paginate: { id: 'four',itemsPerPage: deleteBetItemsPerPage, currentPage: deleteBetcurrentPage, totalItems: deleteBettotal_items };let i= index;">
                                                                <tr>
                                                                    <td>{{i+1}}</td>
                                                                    <td>
                                                                        <button class="btn btn-danger"
                                                                            *ngIf="bet.delete_status =='1'">D</button>
                                                                        <button class="btn btn-warning"
                                                                            *ngIf="bet.delete_status =='2'">v</button>
                                                                    </td>
                                                                    <td *ngIf="type == 0">{{bet?.domain_name}}</td>
                                                                    <td class="name-cell" (click)="openModalUserParentList(bet,userParentList)">{{bet?.user_name}}</td>
                                                                    <td>{{bet?.market_name}}</td>
                                                                    <td *ngIf="(bet.is_back)==0"
                                                                        [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">
                                                                        <span>Lay</span>
                                                                    </td>
                                                                    <td *ngIf="(bet.is_back)==1"
                                                                        [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">
                                                                        <span>Back</span>
                                                                    </td>
                                                                    <td>{{bet.is_fancy == 0 ? bet.selection_name :
                                                                        bet.selection_name}}</td>
                                                                    <td>{{bet?.odds}} / {{bet?.size}}</td>

                                                                    <td>{{bet?.stack}}</td>
                                                                    <td
                                                                        [ngClass]="{'red':(bet?.p_l)<0,'green':(bet?.p_l)>0}">
                                                                        {{(bet?.p_l)| number : '0.0-2'}}</td>
                                                                    <td>{{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss
                                                                        aa')}} </td>
                                                                    <td>{{bet?.bet_id}}</td>
                                                                    <td>{{bet?.ip_address}}</td>
                                                                    <!-- <td  *ngIf="type == 0"><a class="btn btn-warning btn-sm actionbtn"
                                                                            role="button" tabindex="0"
                                                                            title="Delete bet"
                                                                            (click)="openModaldeleteBet(deleteBet, bet.bet_id,bet.user_id)"><i class="fa fa-trash"></i></a>
                                                                    </td> -->
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                        <tbody *ngIf="deleteValue == false">
                                                            <td colspan="9" class="text-center"><span>{{messageData}}</span></td>
                                                        </tbody>


                                                
                                                       
                                                    </table>
                                                    <div class="report-bottom-paginate">
                                                        <pagination-controls (pageChange)="deleteBetpageChange($event)"
                                                        class="user-list-pagination" id="four"
                                                        *ngIf="deleteValue == true"></pagination-controls>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab-pane fade" id="VoidBetRight">
                                                <div class="report-table" style="height:calc(100vh - 170px) !important">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>S.No.</th>
                                                                <th>Status</th>
                                                                <th *ngIf="type == 0">Domain</th>
                                                                <th>Name</th>
                                                                <th>Market</th>
                                                                <th>Bet Type</th>
                                                                <th>Selection</th>
                                                                <th>Rate</th>
                                                                <th>Stake</th>
                                                                <th>P/L</th>
                                                                <th>Placed Time</th>
                                                                <th>BetId</th>
                                                                <th>IP Address</th>
                                                                <!-- <th  *ngIf="type == 0"></th> -->
                                                            </tr>
                                                        </thead>
                                                        


                                                        <tbody *ngIf="voidValue == true">
                                                            <ng-container
                                                                *ngFor="let bet of voidBets| paginate: { id: 'four',itemsPerPage: deleteBetItemsPerPage, currentPage: deleteBetcurrentPage, totalItems: deleteBettotal_items };let i= index;">
                                                                <tr *ngIf="bet.delete_status =='2'">
                                                                    <td>{{i+1}}</td>
                                                                    <td>
                                                                        <button class="btn btn-danger"
                                                                            *ngIf="bet.delete_status =='1'">D</button>
                                                                        <button class="btn btn-warning"
                                                                            *ngIf="bet.delete_status =='2'">v</button>
                                                                    </td>
                                                                    <td *ngIf="type == 0">{{bet?.domain_name}}</td>
                                                                    <td class="name-cell" (click)="openModalUserParentList(bet,userParentList)">{{bet?.user_name}}</td>
                                                                    <td>{{bet?.market_name}}</td>
                                                                    <td *ngIf="(bet.is_back)==0"
                                                                        [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">
                                                                        <span>Lay</span>
                                                                    </td>
                                                                    <td *ngIf="(bet.is_back)==1"
                                                                        [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">
                                                                        <span>Back</span>
                                                                    </td>
                                                                    <td>{{bet.is_fancy == 0 ? bet.selection_name :
                                                                        bet.selection_name}}</td>
                                                                    <td>{{bet?.odds}} / {{bet?.size}}</td>

                                                                    <td>{{bet?.stack}}</td>
                                                                    <td
                                                                        [ngClass]="{'red':(bet?.p_l)<0,'green':(bet?.p_l)>0}">
                                                                        {{(bet?.p_l)| number : '0.0-2'}}</td>
                                                                    <td>{{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss
                                                                        aa')}} </td>
                                                                    <td>{{bet?.bet_id}}</td>
                                                                    <td>{{bet?.ip_address}}</td>
                                                                    <!-- <td  *ngIf="type == 0"><a class="btn btn-warning btn-sm actionbtn"
                                                                            role="button" tabindex="0"
                                                                            title="Delete bet"
                                                                            (click)="openModaldeleteBet(deleteBet, bet.bet_id,bet.user_id)"><i class="fa fa-trash"></i></a>
                                                                    </td> -->
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                        <tbody *ngIf="voidValue == false">
                                                            <td colspan="9" class="text-center"><span>{{messageData}}</span></td>
                                                        </tbody>
                                                       
                                                    </table>
                                                    <div class="report-bottom-paginate">
                                                        <pagination-controls (pageChange)="deleteBetpageChange($event)"
                                                        class="user-list-pagination" id="four"
                                                        *ngIf="deleteValue == true"></pagination-controls>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="tab-pane fade" id="addFancyRight">
                                                <div class="report-table" style="height:calc(100vh - 170px) !important">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>S.No.</th>
                                                                <th>Fancy Name</th>
                                                                <th>Action</th>
                                                                <th *ngIf="type == 0">Status <i class="fa fa-refresh"
                                                                        aria-hidden="true" (click)="getFancyList()"></i>
                                                                </th>
                                                                <!-- <th>Selection</th>
                                                                <th>Rate</th>
                                                                <th>Stake</th>
                                                                <th>P/L</th>
                                                                <th>Placed Time</th> -->
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container
                                                                *ngFor="let fancy of fancyList | paginate: { id: 'five',itemsPerPage: currentPositionItemsPerPage, currentPage: currentPositioncurrentPage, totalItems: currentPositiontotal_items };let i= index;">
                                                                <tr>
                                                                    <td>{{i+1}}</td>
                                                                    <td>{{fancy?.name}}</td>
                                                                    <td> <input type="checkbox"
                                                                            (click)="updateStatus(fancy)"
                                                                            [checked]="fancy.is_active == 0 ? false : true">
                                                                    </td> 
                                                                    <td *ngIf="fancy.is_created == '1' && type == 0">
                                                                        <div>
                                                                            <input _ngcontent-c8="" type="radio"
                                                                                name="280{{i}}"
                                                                                [checked]="fancy.is_active == '1'"
                                                                                (click)="updateFancyById(fancy,1)"
                                                                                checked="checked">
                                                                            <img _ngcontent-c8=""
                                                                                src="assets/images/active.png">
                                                                            <input _ngcontent-c8="" type="radio"
                                                                                name="280{{i}}"
                                                                                [checked]="fancy.is_active == '0'"
                                                                                (click)="updateFancyById(fancy,0)">
                                                                            <img _ngcontent-c8=""
                                                                                src="assets/images/inactive.png">
                                                                            <input _ngcontent-c8="" type="radio"
                                                                                name="280{{i}}"
                                                                                [checked]="fancy.is_active == '2'"
                                                                                (click)="updateFancyById(fancy,2)">
                                                                            <img _ngcontent-c8=""
                                                                                src="assets/images/suspend.png">
                                                                            <button _ngcontent-c8=""
                                                                                class="btn-success btn-success2"
                                                                                (click)="fancyAbondoned(fancy,i)">Abandoned</button>
                                                                        </div>

                                                                        <input _ngcontent-c8="" type="number"
                                                                            class="input-text ng-untouched ng-pristine ng-valid box-size"
                                                                            name="280{{i}}" id="{{i}}"
                                                                            [(ngModel)]="fancyResultInputValue[i]"
                                                                            class="input-text"
                                                                            style="color:black;font-size: 16px;width:100px; margin-right: 10px;">
                                                                            <span _ngcontent-c8=""><button _ngcontent-c8=""
                                                                                class="btn-success" href=""
                                                                                (click)="getFancyReult(fancy,i)">R</button>
                                                                        </span> &nbsp;
                                                                        <span _ngcontent-c8="" *ngIf="fancyResultInputValue[i] != '-999'"><button _ngcontent-c8=""
                                                                                class="btn-success" href=""
                                                                                (click)="fancyResultDeclare(fancy,fancyResultInputValue[i])">Result</button>
                                                                        </span>
                                                                    </td>
                                                                    <td *ngIf="fancy.is_created == 0 && type == 0">First
                                                                        create Fancy</td>
                                                                    <!-- <td>{{bet?.p_l}}</td>
                                                                    <td>{{bet?.created_at* 1000 | date : 'medium'}}</td> -->
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                    <div class="report-bottom-paginate">
                                                        <pagination-controls
                                                        (pageChange)="currentPositionpageChange($event)"
                                                        class="user-list-pagination" id="five"></pagination-controls>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer></app-footer> -->
    </div>
</main>

<!-- add market list -->
<ng-template #addMarket>
    <div class="modal-header">
        <h4 class="modal-title">Market List</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>

    <div class="modal-body">
        <div class="body-inner">
            <div class="sport-market-list" *ngFor="let homeMatch of homeData | keyvalue;let i = index;">
                <h3><img src="assets/images/icon/DashBoard-icons/{{homeMatch?.key}}.png"> {{homeMatch?.key}}</h3>
                <table class="table">
                    <tbody>
                        <tr *ngFor="let matchData of homeMatch.value|orderBy : 'match_date';let j = index;"
                            (click)="addOtherMarket(matchData)">
                            <td class="market-name">
                                <div class="event-info">
                                    <div class="event-format-strip">
                                        <div *ngIf="matchData?.inplay == true" class="inplay-div">
                                            <div class="inplay-btn inplay"><span>InPlay</span> </div>
                                        </div>
                                        <div class="event-full-date">
                                            <span class="date">{{(matchData?.match_date | date :'dd-MM hh:mm aa')}}
                                                IST</span>
                                        </div>
                                        <span class="event-format-series">{{matchData?.series_name}}</span>
                                    </div>
                                    <div class="event-team">
                                        <div class="team-name">{{matchData?.match_name}}</div>
                                    </div>
                                </div>

                            </td>
                            <td class="text-right"><a><i class="fa fa-plus-circle"></i></a></td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
    </div>

    <!-- <div class="modal-footer">
        <a class="modal-close red_button btn" data-dismiss="modal" (click)="modalRef.hide()">Close</a>
    </div> -->
</ng-template>

<!-- <ng-template #matchBet>
    <div class="modal-header">
        <h4 class="modal-title">Match Bet</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>

    <div class="modal-body">
        <div class="body-inner">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="search with Username" />
            </div>
            <div class="report-table">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>ID</th>
                            <th>BetId</th>
                            <th>Name</th>
                            <th>Selection</th>
                            <th>Rate</th>
                            <th>Stake</th>
                            <th>P/L</th>
                            <th>Placed Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let bet of betData;let i= index;">
                            <tr [ngClass]="{'pink':(bet.is_back)==0,'blue':(bet.is_back)==1}">
                                <td><a class="btn btn-warning btn-sm actionbtn" role="button" tabindex="0"
                                        title="Delete bet" (click)="openModaldeleteBet(deleteBet)">D</a></td>
                                <td>{{i+1}}</td>
                                <td>{{bet?.bet_id}}</td>
                                <td>{{bet?.user_name}}</td>
                                <td>{{bet.is_fancy == 0 ? bet.selection_name : bet.name}}</td>
                                <td>{{bet?.odds}}</td>
                                <td>{{bet?.stack}}</td>
                                <td>{{bet?.p_l}}</td>
                                <td>{{bet?.created_at* 1000 | date : 'medium'}}</td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template> -->

<ng-template #deleteBet>
    <div class="modal-header">
        <h4 class="modal-title">Delete Bet </h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form>
        <div class="modal-body delete-bet">
            <div class="body-inner">
                <div class="form-list">
                    <label class="form-heading">Password</label>
                    <input type="password" placeholder="Password" class="password-box form-attribute"
                        [(ngModel)]="transactionPassword" [ngModelOptions]="{standalone: true}">
                </div>
                <!-- <div class="title">
                    <div class="left">
                        <i class="fa fa-exclamation-triangle"></i>
                    </div>
                    <div class="right">
                        <h3> Delete Bet</h3>
                        <p>This action can not be undone.</p>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="modal-footer">
            <div class="text-center">
                <button type="button" class="btn btn-info" (click)="modalRef.hide();">Cancel</button>
                <button type="button" class="btn btn-danger"
                    (click)="deleteUserBet(deleteBetId,deleteBetUserId,0,is_fancy,deleteBetUser)">Delete</button>
                <button type="button" class="btn btn-danger"
                    (click)="deleteUserBet(deleteBetId,deleteBetUserId,1,is_fancy,deleteBetUser)">Void</button>
            </div>
        </div>
    </form>
</ng-template>
<ng-template #deleteBetOld>
    <div class="modal-header">
        <h4 class="modal-title">Delete Bet</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form>
        <div class="modal-body " style="height:150px">
            <div class="body-inner">
                <div class="form-group">
                    <label class="col-md-6">Password</label>
                    <input type="password" class="form-control" placeholder="password" autocomplete="off" required>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <div class="text-end">
                <button type="button" class="btn btn-success">Submit</button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #bets>
    <div class="modal-header">
        <h4 class="modal-title">Bet List - {{betsPopupMarketName}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide();clearPopUp();"><i
                class="fa fa-times"></i></a>
    </div>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
            <form>
                <!-- <div class="betFilter">
                    <div class="form-check">
                        <input class="form-check-input" name="betpopupFilter" type="radio" value="All"
                            [ngModelOptions]="{standalone: true}" id="allBetRadio" checked [(ngModel)]="selectedFilter">
                        <label class="form-check-label" for="allBetRadio">All</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" name="betpopupFilter" type="radio" value="Back"
                            [ngModelOptions]="{standalone: true}" id="backBet" [(ngModel)]="selectedFilter">
                        <label class="form-check-label" for="backBet">Back</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" name="betpopupFilter" type="radio" value="Lay"
                            [ngModelOptions]="{standalone: true}" id="layBet" [(ngModel)]="selectedFilter">
                        <label class="form-check-label" for="layBet">Lay</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" name="betpopupFilter" type="radio" value="Delete"
                            [ngModelOptions]="{standalone: true}" id="deleteBet" [(ngModel)]="selectedFilter">
                        <label class="form-check-label" for="deleteBet">Delete Bet</label>
                    </div>
                </div> -->
            </form>
            <form>
                <table class="table">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>ID</th>
                            <th *ngIf="type == 0">Domain</th>
                            <th>User Name</th>
                            <th>Selection</th>
                            <th>Bet Type</th>
                            <th>Rate</th>
                            <th>Stack</th>
                            <th>P|L</th>
                            <th colspan="2">Placed Time</th>
                            <th>BetID</th>
                            <th>IP Address</th>
                        </tr>
                        <tr>
                            <th>
                                <!-- <input type="number" placeholder="ID" name="userid" [(ngModel)]="userId"
                                    class="form-control"> -->
                            </th>
                            <th>
                                <input type="text" placeholder="Name" name="username" [(ngModel)]="userName"
                                    class="form-control">
                            </th>
                            <th>
                                <!-- <input type="text" placeholder="Selection" name="selection" [(ngModel)]="selectionName"  class="form-control"> -->
                                <ng-select [items]="matchRunnerData" bindLabel="name"
                                    bindValue="name" [ngModelOptions]="{standalone: true}"
                                    placeholder="Search by Selection" [(ngModel)]="selectionName"> </ng-select>
                            </th>
                            <th>
                                <select class="user-select form-control" (change)="onSelectionList($event.target.value)"
                                    style="color:black">
                                    <option label="" *ngFor="let numvalue of betdataType | keyvalue ; let i= index;"
                                        [value]="numvalue.value">{{numvalue.value}}</option>
                                </select>
                            </th>
                            <th>
                                <input type="number" placeholder="Rate" name="rate" [(ngModel)]="oddsRate"
                                    class="form-control">
                            </th>
                            <th>
                                <input type="number" placeholder="Stack" name="stack" [(ngModel)]="stakeAmount"
                                    class="form-control">
                            </th>
                            <th>
                                -
                            </th>
                            <th colspan="2">
                                <!-- <input type="text" placeholder="Placed Time" name="placedTime" class="form-control"> -->
                                <button name="Submit" class="btn btn-success"
                                    [disabled]="!stakeAmount && !oddsRate && !betTypeFilter && !selectionName && !userName && !userId"
                                    (click)="getBetsBySearch(market_id,'','popup')"> Submit </button>
                                <button name="Submit" class="btn btn-danger" (click)="clearBetPopUp()"> Clear </button>
                            </th>
                            <!-- <th>
                               
                            </th> -->
                        </tr>
                    </thead>
                    <tbody *ngIf="popBetData == true">
                        <tr *ngFor="let bet of popbetData| paginate: { id: 'betspopUp',itemsPerPage: betPoupItemsPerPage, currentPage: betPoupcurrentPage, totalItems: betPouptotal_items };let i = index;">
                            
                            <td ngxClipboard [cbContent]="'Content to be copied.'" (cbOnSuccess)="contentCopied($event)"
                            (click)="copyContent(bet.user_name)" style="cursor: pointer;">{{betPoupItemsPerPage *(betPoupcurrentPage-1)+i+1}} <div
                            class="box" *ngIf="objectId == bet.bet_id && displayTooltip == true">
                            <h5>Copied</h5>
                        </div>
                    </td>
                    <td *ngIf="type == 0">{{bet?.domain_name}}</td>
                            <td  (click)="openModalUserParentList(bet,userParentList)" >{{bet?.user_name}}</td>
                            <td>{{(bet?.is_fancy == 1) ? (bet?.market_name) : (bet?.selection_name)}}</td>
                            <td>{{bet?.is_back == 1 ? 'Back' : 'Lay'}}</td>
                            <td>{{bet?.odds}}</td>
                            <td>{{bet?.stack}}</td>
                            <td>{{(bet?.p_l)| number : '0.0-2'}}</td>
                            <td>{{moment(bet?.createdAt).utcOffset("+0").format('lll')}}</td>
                            <td>{{bet?.bet_id}}</td>
                            <td>{{bet?.ip_address}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="popBetData == false">
                        <tr>
                            <td colspan="8" class="text-center">No Record Found..</td>
                        </tr>
                    </tbody>
                </table>
                <div class="report-bottom-paginate">
                    <pagination-controls (pageChange)="betPouppageChange(market_id,$event)" class="betpopup-list-pagination"
                    id="betspopUp"></pagination-controls>
                </div>
            </form>
        </div>
    </div>

    <!-- <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success">Submit</button>
        </div>
    </div> -->
</ng-template>

<ng-template #fancybets>
    <div class="modal-header">
        <h4 class="modal-title">Bet List of {{betsPopupFancyName}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide();clearPopUp();"><i
                class="fa fa-times"></i></a>
    </div>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
            <!-- <div class="betFilter">
                <div class="form-check">
                    <input class="form-check-input" name="betpopupFilter" type="radio" value="All" id="allBetRadio"
                        checked>
                    <label class="form-check-label" for="allBetRadio">All</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="betpopupFilter" type="radio" id="backBet" value="Back">
                    <label class="form-check-label" for="backBet">Back</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="betpopupFilter" type="radio" id="layBet" value="Lay">
                    <label class="form-check-label" for="layBet">Lay</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="betpopupFilter" type="radio" id="deleteBet" value="Delete">
                    <label class="form-check-label" for="deleteBet">Delete Bet</label>
                </div>
            </div> -->
            <form>
                <table class="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>User Name</th>
                            <!-- <th>Selection</th> -->
                            <th>Bet Type</th>
                            <th>Rate</th>
                            <th colspan="4">Stack</th>
                            <!-- <th>P|L</th> -->
                            <!-- <th>Placed Time</th> -->
                            <!-- <th></th> -->
                        </tr>
                        <tr>
                            <th>
                                <input type="text" placeholder="ID" name="userid" pattern="[a-zA-Z0-9]+"
                                    [(ngModel)]="userId" class="form-control">
                            </th>
                            <th>
                                <input type="text" placeholder="Name" name="username" [(ngModel)]="userName"
                                    class="form-control">
                            </th>
                            <!-- <th>
                                <ng-select style="width:170px" [items]="matchRunnerData" bindLabel="name"
                                bindValue="name"
                                
                                [ngModelOptions]="{standalone: true}"
                                placeholder="Search by Selection"
                                [(ngModel)]="selectionName" >  </ng-select>
                            </th> -->
                            <!-- <th>-</th> -->
                            <th>
                                <select class="user-select form-control" style="color:black"
                                    (change)="onFancySelectionList($event.target.value)">
                                    <option label="" *ngFor="let numvalue of betdataType | keyvalue ; let i= index;"
                                        [value]="numvalue.value">{{numvalue.value}}</option>
                                </select>
                            </th>
                            <th>
                                <input type="text" placeholder="Rate" name="rate" [(ngModel)]="oddsRate"
                                    class="form-control">
                            </th>
                            <th>
                                <input type="text" placeholder="Stack" name="stack" [(ngModel)]="stakeAmount"
                                    class="form-control">
                            </th>
                            <!-- <th>
                                -
                            </th> -->
                            <th colspan="3">
                                <button name="Submit" class="btn btn-success" (click)="getFancyBetsBySearch(market_id,'popup')">Submit </button>
                                <!-- <input type="text" placeholder="Placed Time" name="placedTime" class="form-control"> -->
                                <button name="Submit" class="btn btn-danger" (click)="clearFancyBetPopupFilter()">Clear </button>
                            </th>
                            <!-- <th>
                               
                            </th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>S.No.</th>
                            <th>Domain</th>
                            <th>Name</th>
                            <th>Session Name</th>
                            <th>Type</th>
                            <th>Selection</th>
                            <th>Stack</th>
                            <th>P | L</th>
                            <th>Placed Time</th>
                            <td>BetId</td>
                            <td>IP Address</td>
                        </tr>
                        <ng-container *ngIf="fancyBetData == true">
                        <tr *ngFor="let bet of popbetData;let i = index;">
                            <td>{{i+1}}</td>
                            <td *ngIf="type == 0">{{bet?.domain_name}}</td>
                            <td ngxClipboard [cbContent]="'Content to be copied.'" (cbOnSuccess)="contentCopied($event)"
                                (click)="copyContent(bet.user_name);openModalUserParentList(bet,userParentList)" style="cursor: pointer;">{{bet?.user_name}}<div
                                    class="box" *ngIf="objectId == bet.bet_id && displayTooltip == true">
                                    <h5>Copied</h5>
                                </div>
                            </td>
                            <td>{{(bet?.is_fancy == 1) ? (bet?.market_name) : (bet?.selection_name)}}</td>
                            <td>{{bet?.is_back == 1 ? 'Back' : 'Lay'}}</td>
                            <td>{{bet?.odds}}</td>
                            <td>{{bet?.stack}}</td>
                            <td>{{(bet?.p_l)| number : '0.0-2'}}</td>
                            <td>{{moment(bet?.createdAt).utcOffset("+0").format('lll')}}</td>
                            <td>{{bet?.bet_id}}</td>
                            <td>{{bet?.ip_address}}</td>
                        </tr>
                    </ng-container>
                        <ng-container *ngIf="fancyBetData == false"> {{messageData}}</ng-container>
                    </tbody>
                </table>
            </form>
        </div>
    </div>

    <!-- <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success">Submit</button>
        </div>
    </div> -->
</ng-template>

<ng-template #fancyPosition>
    <div class="modal-header">
        <h4 class="modal-title">Fancy Position - {{selectedFancyMarket.name}}</h4>
        <label class="switch">
            <input type="checkbox" value="showShare" name="Active" class="chkPermission"
                (change)='updateShareWise(showShare)' [checked]="showShare == false ? true : false">
            <div class="slider round">
                <div class="on">S</div>
                <div class="off">F</div>
            </div>
        </label>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>

    <div class="modal-body">
        <div class="body-inner">
            <div class="fpmain">

                <div class="fpBody">
                    <table>
                        <thead>
                            <tr>
                                <th>Runs</th>
                                <th>P&L</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let fancyPos of fancyPosData;let i=index;">
                                <td><span class="range">{{fancyPos.key}}</span></td>
                                <td *ngIf="showShare == false"><span class="plRange positive"
                                        [ngClass]="{'red':(fancyPos.value)<0,'green':(fancyPos.value)>0}">{{fancyPos.value
                                        | number : '0.0-2'}}</span>
                                </td>
                                <td *ngIf="showShare == true"><span class="plRange positive"
                                        [ngClass]="{'red':(fancyPos.valueFull)<0,'green':(fancyPos.valueFull)>0}">{{fancyPos.valueFull
                                        | number : '0.0-2'}}</span>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td><span class="range">0 - 23</span></td>
                                <td><span class="plRange error"> -100</span></td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="modal-footer">
        <a class="modal-close red_button btn" data-dismiss="modal" (click)="modalRef.hide()">Close</a>
    </div> -->
</ng-template>

<ng-template #marketPosition>
    <div class="modal-header">
        <h4 class="modal-title">Market Position - {{selectedMarket.name}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>

    <div class="modal-body">
        <div class="body-inner">
            <div class="report-table" style="height:calc(100vh - 170px) !important">
                <table class="table">
                    <thead>
                        <tr>
                            <th><i class="fa fa-refresh" aria-hidden="true"
                                    (click)="userpos(loggedInUser.user_id, this.selectedMarket.market_id)"></i>
                            </th>
                            <th> Position of {{accountHolder}}</th>
                            <th>
                                <label class="switch">
                                    <input type="checkbox" value="showShare" name="Active"
                                        class="chkPermission" (change)='updateCurrentPosition(showShare)'
                                        [checked]="showShare == false ? true : false">
                                    <div class="slider round">
                                        <div class="on">S</div>
                                        <div class="off">F</div>
                                    </div>
                                </label>
                            </th>
                        </tr>
                        <tr>
                            <th *ngIf="type == 0">Domain Name</th>
                            <th><i class="fa fa-arrow-left" style="margin: 10px;cursor: pointer;"
                                    (click)="userpos(parentId, this.selectedMarket.market_id)"
                                    *ngIf="loggedInUser.user_id != id && id != ''"></i>
                                Account</th>
                            <th *ngFor="let teamname of team_name;let i = index;">
                                {{teamname}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let userdata of userDetails; index as i;">
                            <td *ngIf="type == 0">{{userdata?.domain_name}}</td>
                            <td style="color: blue !important;cursor: pointer;"
                                (click)="userpos(userdata.user_id, this.selectedMarket.market_id)">
                                <span class="userLabel">
                                    <span *ngIf="userdata?.user_type_id != 1" (click)="openModalUserParentList(bet,userParentList)"> A </span>
                                    <span *ngIf="userdata?.user_type_id == 1" (click)="openModalUserParentList(bet,userParentList)"> C </span>
                                </span>
                                {{userdata?.user_name}}
                            </td> 
                            <ng-container *ngIf = "showShare == false">
                            <td *ngFor="let own of column_name ;let i = index;"
                                [ngClass]="{'red':(userdata[own])<0,'green':(userdata[own])>0}">
                                {{(userdata[own])}} </td></ng-container>
                            <ng-container *ngIf = "showShare == true">
                            <td *ngFor="let own of total_Column_name ;let i = index;"
                                [ngClass]="{'red':(userdata[own])<0,'green':(userdata[own])>0}">
                                {{(userdata[own])}} </td></ng-container>
                        </tr>
                        <tr>
                            <td *ngIf="type == 0"></td>
                            <td>Total</td>
                            <ng-container *ngIf = "showShare == false">
                                <td *ngFor=" let total of totalData;let i= index;"
                                    [ngClass]="{'red':(total.win_loss)<0,'green':(total.win_loss)>0}">
                                    {{(total.win_loss)}}</td>
                            </ng-container>
                            <ng-container *ngIf = "showShare == true">
                                <td *ngFor=" let total of totalExpoData;let i= index;"
                                    [ngClass]="{'red':(total.win_loss_total_exposure)<0,'green':(total.win_loss_total_exposure)>0}">
                                    {{(total.win_loss_total_exposure)}}</td>
                            </ng-container>
                        </tr>
                        <tr *ngIf="showShare == false">
                            <td *ngIf="type == 0"></td>
                            <td>Own</td>
                            <td *ngFor=" let own of ownData;let i= index;"
                                [ngClass]="{'red':(own.win_loss)<0,'green':(own.win_loss)>0}">
                                {{(own.win_loss)}}</td>
                        </tr>
                        <tr *ngIf="parentId != null && showShare == false">
                            <td *ngIf="type == 0"></td>
                            <td>Parent</td>
                            <td *ngFor=" let parent of parentData;let i= index;"
                                [ngClass]="{'red':(parent.win_loss)<0,'green':(parent.win_loss)>0}">
                                {{(parent.win_loss) == undefined ? '0' : (parent.win_loss)}}</td>
                        </tr>

                        <!-- <tr *ngFor="let parent of ownParentData;let i = index;">
                            <td>{{parent.status}}</td>
                            <td *ngFor="let own of ownData;let j = index;"
                                [ngClass]="{'red':(parent[own])<0,'green':(parent[own])>0}">
                                {{parent[own]}}</td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- <div class="modal-footer">
        <a class="modal-close red_button btn" data-dismiss="modal" (click)="modalRef.hide()">Close</a>
    </div> -->
</ng-template>

<ng-template #sportSetting>
    <div class="modal-header">
        <label class="modal-title">{{popUpHead}} Settings : {{showSelectedUserName}}</label>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <div class="modal-body no-scroll-body sport-setting-body">
        <div class="body-inner">

            <div class="filter-tab">
                <nav>
                    <!-- <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <ng-container *ngFor="let sportName of specificUserDetails;let i = index">
                      <a class="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-{{sportName?.name}}" [ngClass]="{'active': i === selectedIndex}"
                      (click)="selectPill(i)">{{sportName?.name}}</a>
                    </ng-container>
                    </div> -->
                </nav>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-{{sportName?.name}}">
                        <div class="form-main">
                            <form [formGroup]='sportsForm'>
                                <div class="sports-setting-main-section" formArrayName="sports_settings">
                                    <div class="market-session-setting"
                                        *ngFor="let sportSettingitem of sportsSettingsFormArr.controls; let j=index"
                                        [formGroupName]='j'>

                                        <div class="detail-row" *ngIf="marketSetting == true ">
                                            <div class="detail-row-heading">
                                                <h4>Market Setting</h4>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Min. Stake Amount</label>
                                                            <input type="number" formControlName="market_min_stack"
                                                                ngbTooltip="{{sportSettingitem.controls.market_min_stack.errors |DisplayErrorMessage}}"
                                                                placeholder="" class="form-control"
                                                                (keyup)="applyValidatorsForMaxStack(j)" #t1="ngbTooltip"
                                                                triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t1.open()" (key)="t1.open()"
                                                                (blur)="t1.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_min_stack.errors && !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_min_stack.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_min_stack"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_min_stack[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_min_stack[1].long_error}}"></i>
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Max. Stake Amount</label>
                                                            <input type="number" formControlName="market_max_stack"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.market_max_stack.errors |DisplayErrorMessage}}"
                                                                #t2="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t2.open()" (key)="t2.open()"
                                                                (blur)="t2.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_max_stack.errors&& !sportErrorData" class="red-alert-message">
                                                                
                                                                <i class="fa fa-info" ngbTooltip="{{sportSettingitem.controls.market_max_stack.errors |DisplayErrorMessage}}"  ></i>
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_max_stack"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_max_stack[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_max_stack[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Min. Odds Rate</label>
                                                            <input type="number" formControlName="market_min_odds_rate"
                                                                ngbTooltip="{{sportSettingitem.controls.market_min_odds_rate.errors |DisplayErrorMessage}}"
                                                                placeholder="" class="form-control"
                                                                (keyup)="applyValidatorsForMaxOddsRate(j)"
                                                                #t3="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t3.open()" (key)="t3.open()"
                                                                (blur)="t3.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_min_odds_rate.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_min_odds_rate.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_min_odds_rate"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_min_odds_rate[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_min_odds_rate[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Max. Odds Rate</label>
                                                            <input type="number" formControlName="market_max_odds_rate"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.market_max_odds_rate.errors |DisplayErrorMessage}}"
                                                                #t4="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t4.open()" (key)="t4.open()"
                                                                (blur)="t4.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_max_odds_rate.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_max_odds_rate.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_max_odds_rate"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_max_odds_rate[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_max_odds_rate[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Match Bets Delay</label>
                                                            <input type="number" formControlName="market_bet_delay"
                                                                placeholder="" class="form-control" ngbTooltip="{{sportSettingitem.controls.market_bet_delay.errors |DisplayErrorMessage}}"
                                                                #t5="ngbTooltip" triggers="manual" [autoClose]="false"(mouseenter)="t5.open()" (key)="t5.open()" (blur)="t5.open()"/>
                                                            
                                                           
                                                            <span *ngIf="sportErrorData?.market_bet_delay" class="red-alert-message">
                                                                {{sportErrorData.market_bet_delay[0].short_error}}
                                                                <i class="fa fa-info" ngbTooltip="{{sportErrorData.market_bet_delay[1].long_error}}"  ></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Market Max Profit</label>
                                                            <input type="number" formControlName="market_max_profit"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.market_max_profit.errors |DisplayErrorMessage}}"
                                                                #t6="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t6.open()" (key)="t6.open()"
                                                                (blur)="t6.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_max_profit.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_max_profit.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_max_profit"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_max_profit[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_max_profit[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Before Inplay Match Stake</label>
                                                            <input type="number" #t7="ngbTooltip" triggers="manual"
                                                                [autoClose]="false" (mouseenter)="t7.open()"
                                                                (key)="t7.open()" (blur)="t7.open()"
                                                                formControlName="market_advance_bet_stake"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_advance_bet_stake.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.market_advance_bet_stake"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_advance_bet_stake[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_advance_bet_stake[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Min Volume Stake</label>
                                                            <input type="number" 
                                                                formControlName="min_volume_limit"
                                                                placeholder="" class="form-control"/>

                                                            <!-- <span *ngIf="sportSettingitem.controls.market_advance_bet_stake.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <!-- <span *ngIf="sportErrorData?.market_advance_bet_stake"
                                                                class="red-alert-message">
                                                                {{sportErrorData.market_advance_bet_stake[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.market_advance_bet_stake[1].long_error}}"></i>
                                                            </span> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="detail-row" *ngIf="marketSetting == true">
                                            <div class="detail-row-heading">
                                                <h4>Advance Market Stake Setting</h4>
                                                <label class="switch">
                                                    <input type="checkbox" value="volumeLimitEnable" name="Active"
                                                        class="chkPermission" (change)='updateVolumeLimit(volumeLimitEnable,j)'
                                                        [checked]="volumeLimitEnable == true?true:false">
                                                    <div class="slider round">
                                                        <div class="on">On</div>
                                                        <div class="off">Off</div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 col-sm-4 col-12">
													<div class="col-inner">
														<div class="form-group">
															<label class="form-label">Max Stake (0-10k gbp)</label>
															<input type="number" triggers="manual" [autoClose]="false"
																formControlName="max_volume_stake_0_10" placeholder="" class="form-control" />

															<!-- <span *ngIf="sportSettingitem.controls.market_advance_bet_stake.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}
                                                            </span> -->
															<!-- <span *ngIf="sportErrorData?.market_advance_bet_stake" class="red-alert-message">
																{{sportErrorData.market_advance_bet_stake[0].short_error}}
																<i class="fa fa-info"
																	ngbTooltip="{{sportErrorData.market_advance_bet_stake[1].long_error}}"></i>
															</span> -->
														</div>
													</div>
												</div>
												<div class="col-md-3 col-sm-4 col-12">
													<div class="col-inner">
														<div class="form-group">
															<label class="form-label">Max Stake (10k-40k gbp)</label>
															<input type="number" triggers="manual" [autoClose]="false"
																formControlName="max_volume_stake_10_40" placeholder="" class="form-control" />

															<!-- <span *ngIf="sportSettingitem.controls.market_advance_bet_stake.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}
                                                            </span> -->
															<!-- <span *ngIf="sportErrorData?.market_advance_bet_stake" class="red-alert-message">
																{{sportErrorData.market_advance_bet_stake[0].short_error}}
																<i class="fa fa-info"
																	ngbTooltip="{{sportErrorData.market_advance_bet_stake[1].long_error}}"></i>
															</span> -->
														</div>
													</div>
												</div>
												<div class="col-md-3 col-sm-4 col-12">
													<div class="col-inner">
														<div class="form-group">
															<label class="form-label">Max Stake (>40k gbp)</label>
															<input type="number" triggers="manual" [autoClose]="false"
																formControlName="max_volume_stake_40" placeholder="" class="form-control" />

															<!-- <span *ngIf="sportSettingitem.controls.market_advance_bet_stake.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}
                                                            </span> -->
															<!-- <span *ngIf="sportErrorData?.market_advance_bet_stake" class="red-alert-message">
																{{sportErrorData.market_advance_bet_stake[0].short_error}}
																<i class="fa fa-info"
																	ngbTooltip="{{sportErrorData.market_advance_bet_stake[1].long_error}}"></i>
															</span> -->
														</div>
													</div>
												</div>
                                            </div>
                                        </div>
                                        <div class="detail-row" *ngIf="marketSetting == true">
                                            <div class="detail-row-heading">
                                                <h4>Market Stake Setting</h4>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 col-sm-4 col-12">
													<div class="col-inner">
														<div class="form-group">
															<label class="form-label">Max Stake (0-10k gbp)</label>
															<input type="number" triggers="manual" [autoClose]="false"
																formControlName="inplay_max_volume_stake_0_10" placeholder="" class="form-control" />

															<!-- <span *ngIf="sportSettingitem.controls.market_advance_bet_stake.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}
                                                            </span> -->
															<!-- <span *ngIf="sportErrorData?.market_advance_bet_stake" class="red-alert-message">
																{{sportErrorData.market_advance_bet_stake[0].short_error}}
																<i class="fa fa-info"
																	ngbTooltip="{{sportErrorData.market_advance_bet_stake[1].long_error}}"></i>
															</span> -->
														</div>
													</div>
												</div>
												<div class="col-md-3 col-sm-4 col-12">
													<div class="col-inner">
														<div class="form-group">
															<label class="form-label">Max Stake (10k-40k gbp)</label>
															<input type="number" triggers="manual" [autoClose]="false"
																formControlName="inplay_max_volume_stake_10_40" placeholder="" class="form-control" />

															<!-- <span *ngIf="sportSettingitem.controls.market_advance_bet_stake.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}
                                                            </span> -->
															<!-- <span *ngIf="sportErrorData?.market_advance_bet_stake" class="red-alert-message">
																{{sportErrorData.market_advance_bet_stake[0].short_error}}
																<i class="fa fa-info"
																	ngbTooltip="{{sportErrorData.market_advance_bet_stake[1].long_error}}"></i>
															</span> -->
														</div>
													</div>
												</div>
												<div class="col-md-3 col-sm-4 col-12">
													<div class="col-inner">
														<div class="form-group">
															<label class="form-label">Max Stake (>40k gbp)</label>
															<input type="number" triggers="manual" [autoClose]="false"
																formControlName="inplay_max_volume_stake_40" placeholder="" class="form-control" />

															<!-- <span *ngIf="sportSettingitem.controls.market_advance_bet_stake.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.market_advance_bet_stake.errors |DisplayErrorMessage}}
                                                            </span> -->
															<!-- <span *ngIf="sportErrorData?.market_advance_bet_stake" class="red-alert-message">
																{{sportErrorData.market_advance_bet_stake[0].short_error}}
																<i class="fa fa-info"
																	ngbTooltip="{{sportErrorData.market_advance_bet_stake[1].long_error}}"></i>
															</span> -->
														</div>
													</div>
												</div>
                                            </div>
                                        </div>

                                        <div class="detail-row" *ngIf="sessionSetting == true ">
                                            <div class="detail-row-heading">
                                                <h4>Session Setting </h4>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Min. Stake Amount</label>
                                                            <input type="number" formControlName="session_min_stack"
                                                                ngbTooltip="{{sportSettingitem.controls.session_min_stack.errors |DisplayErrorMessage}}"
                                                                placeholder="" class="form-control"
                                                                (keyup)="applyValidatorsForMaxstake(j)" #t8="ngbTooltip"
                                                                triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t8.open()" (key)="t8.open()"
                                                                (blur)="t8.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.session_min_stack.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.session_min_stack.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.session_min_stack"
                                                                class="red-alert-message">
                                                                {{sportErrorData.session_min_stack[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.session_min_stack[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Max. Stake Amount</label>
                                                            <input type="number" formControlName="session_max_stack"
                                                                placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.session_max_stack.errors |DisplayErrorMessage}}"
                                                                #t9="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t9.open()" (key)="t9.open()"
                                                                (blur)="t9.open()" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.session_max_stack.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.session_max_stack.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.session_max_stack"
                                                                class="red-alert-message">
                                                                {{sportErrorData.session_max_stack[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.session_max_stack[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Session Bet Delay</label>
                                                            <input type="number" formControlName="session_bet_delay"
                                                            #t10="ngbTooltip" triggers="manual" [autoClose]="false"(mouseenter)="t10.open()" (key)="t10.open()" (blur)="t10.open()"
                                                                placeholder="" class="form-control" ngbTooltip="{{sportSettingitem.controls.session_bet_delay.errors |DisplayErrorMessage}}"/>
                                                           
                                                            <span *ngIf="sportErrorData?.session_bet_delay" class="red-alert-message">
                                                                {{sportErrorData.session_bet_delay[0].short_error}}
                                                                <i class="fa fa-info" ngbTooltip="{{sportErrorData.session_bet_delay[1].long_error}}"  ></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-md-3 col-sm-4 col-12">
                                                    <div class="col-inner">
                                                        <div class="form-group">
                                                            <label class="form-label">Session Max Profit</label>
                                                            <input type="number" formControlName="session_max_profit"
                                                                #t11="ngbTooltip" triggers="manual" [autoClose]="false"
                                                                (mouseenter)="t11.open()" (key)="t11.open()"
                                                                (blur)="t11.open()" placeholder="" class="form-control"
                                                                ngbTooltip="{{sportSettingitem.controls.session_max_profit.errors |DisplayErrorMessage}}" />

                                                            <!-- <span *ngIf="sportSettingitem.controls.session_max_profit.errors&& !sportErrorData" class="red-alert-message">
                                                                {{sportSettingitem.controls.session_max_profit.errors |DisplayErrorMessage}}
                                                            </span> -->
                                                            <span *ngIf="sportErrorData?.session_max_profit"
                                                                class="red-alert-message">
                                                                {{sportErrorData.session_max_profit[0].short_error}}
                                                                <i class="fa fa-info"
                                                                    ngbTooltip="{{sportErrorData.session_max_profit[1].long_error}}"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <div class="text-end">
                                                <button type="submit" class="btn btn-success"
                                                    (click)="updateMarketSetting(sportSettingitem[j],j)">Save</button>
                                                &nbsp;
                                                <button type="button" class="btn btn-danger" data-dismiss="modal"
                                                    (click)="modalRef.hide()">Cancel</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>
<ng-template #userParentList>
    <div class="modal-header">
        <h4 class="modal-title">Parent List of {{userParentName}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <table class="table">
        <tbody>
            <ng-container *ngFor="let parent of parentList; let i =index">
                <tr>
                    <td>{{parent?.name}}({{parent?.user_name}})</td>
                </tr>
                <!-- <tr>
                    <td>user2</td>
                </tr>
                <tr>
                    <td>user3</td>
                </tr> -->
                
            </ng-container>
        </tbody>
    </table>
</ng-template>

<ng-template #Terms>
    <div class="modal-content custom-modal-main">
        <div class="modal-header">
            <h4 class="modal-title">Terms & Conditions</h4>
            <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
        </div>

        <div class="modal-body">
            <p>AAJ SE TIED MATCH <br>  ME FANCY KA LEN <br>DEN KARNGE.</p>
            <p>1.If you not accept this agreement do not place any bet.</p>
            <p>2.Cheating bets deleted automatically or manual. No Claim.</p>
            <p>3.Admin decision is final and no claim on it.</p>
            <p>4. Batsman Runs (In-Play) Over/Under (back/lay) runs bets will stand after batsman has faced one ball or is given out before first ball is faced. Score counts if batsman is Not-Out including if innings is declared. In case of rain, match abandoned etc. settled bets will be valid.</p>
            <p>5.Current/Next Innings Runs Odd/Even Extras and Penalty runs will be included for settlement purposes.</p>
            <p>6.Runs at Fall of 1st Wicket At least one delivery must be bowled, if no wickets fall bets will be void unless settlement is already determined.</p>
            <p>7.Runs at Fall of Next Wicket The total innings runs scored by a team before the fall of the specified wicket determines the result of the market. If a team declares or reaches their target then the total amassed will be the result of the market. Bets will be void should no more play take place following the intervention of rain, or any other delay, as the ability to reach previous quotes offered will have been removed . In case of rain, match abandoned etc. settled bets will be valid.</p>
            <p>8.We do not accept manual bet.</p>
            <p>9.In case of anyone found using two different IDs logged in same IP address his winning in both account will be cancelled.</p>
            <p>10.In case of cheating and betting in unfair rates we will cancel the bet even after settling.</p>
            <p>11.Local fancy are based on Haar - Jeet.</p>
            <p>12.Incomplete session will be cancelled but complete session will be settled.</p>
            <p>13.In case of match abandoned, cancelled, no result etc. completed sessions will be settled.</p>
            <p>14.Lambi Paari : In 20-20 match entire twenty overs should be bowled, in case of rain or any delay if even one over is deducted the bets will be cancelled. In One Day match entire 50 overs should be bowled, in case of rain or any delay if even one over is deducted the bets will be cancelled.</p>
            <p>15.Advance Session,Lambi 1`st Inning Valid Only</p>
            <p>16.Total Match Four ,Total Match Siixes,Total Match Runs,Total Match Wides,Total Match Wicket. If Over Gets Less Then All BET Will Cancel This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game</p>
            <p>17.1st Over Total Runs Prices will be offered for the total runs scored during the 1st over of the match. Extras and penalty runs will be included. The over must be completed for bets to stand unless settlement is already determined.</p>
            <p>18.Limited Overs Matches - Individual Batsmen Runs or Partnerships In a limited overs match where an individual batsman or partnership runs are traded in-play and the innings is curtailed or subject to any reduction in overs, then these markets will be settled at the midpoint of the last available quote before the overs were reduced. If the innings resumes at a later time, a new market may be formed. If a client wants a position in the new market they are required to place a new trade. If there are any subsequent reductions in overs, exactly the same rules will continue to apply i.e. the market is settled at the midpoint of the last available quote before the overs were reduced and a new market may be formed.</p>
            <p>19.Test Matches - Individual Batsmen Runs / Partnerships All bets, open or closed, on an individual batsman or partnership runs shall be void if 50 full overs are not bowled unless one team has won, is dismissed or declares prior to that point. Bets on partnership totals make up when the next wicket falls. If a batsman in the relevant partnership retires hurt, the partnership is treated as continuing between the remaining batsman and the batsman who comes to the wicket. A partnership is also treated as being ended by the end of an innings.</p>
            <p>20.Trading bets are not allowed.</p>
            
         </div>

        <div class="modal-footer">
            <a href="javascript:void(0)" class="modal-close red_button btn" data-dismiss="modal" (click)="modalRef.hide()">Close</a>
        </div>
    </div>
</ng-template>

<ng-template #news>
    <div class="modal-header">
        <h4 class="modal-title">Update News</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <table class="table">
        <tbody>
            <tr>
                <td>Content :</td>
                <td><textarea name="content" 
                    class="form-control"  autocomplete="off"  rows="4" cols="100"
                    [(ngModel)]="description"></textarea></td>
            </tr>
            <tr> <button type="button" class="btn btn-success" style="margin-right: 17px;background-color: green; color: white; margin-left: 15px;padding: 2px;margin-top: 8px;" (click)="updateNews()">Update</button></tr>
        </tbody>
    </table>
</ng-template>