import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { HttpClient, HttpHeaders, HttpHeaderResponse, HttpParams } from '@angular/common/http';
import { resolve } from 'q';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { SocketServiceService } from '../services/socket-service.service';
import { ReportService } from '../services/report.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import * as exchange from '../../data.json';
import Swal from 'sweetalert2'
const _ = require("lodash");
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [HeaderComponent, SidebarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  moment: any = moment;
  private socket: any;
  timer: any;
  homeData: any;
  allSportsList: any;
  allmatchDetails: any;
  userData: any;
  firstData: any;
  a: any = [];
  runnerData: any;
  parameter: any;
  isSocket;
  firstDataLength: any;
  runnerDataLength: any;
  updateRunnerData: any = [];
  exchangeGameList: any;
  adminDetails: any;
  type: any;
  callingType = 1;
  marketId: any = [];
  callType = 1;
  marketData: any = [];
  socketData: any;
  updateFirstData: any = [];
  heading: any;
  allData: any;
  adminData: any;
  agentData: any;
  adminHeading: any;
  agentHeading: any;
  agentHead: any = [];
  public Base_Url = environment['adminServerUrl'];
  constructor(private loginService: LoginService, private cdRef: ChangeDetectorRef, private report: ReportService,private head: HeaderComponent,
    private cookie: CookieService, private socketService: SocketServiceService, private route: ActivatedRoute, private router: Router, private http: HttpClient, private sport: SportService, private toastr: ToastrService) {
    this.exchangeGame();
    this.getAllSportsListData();
    // this.getNews();
  }
  socketStatus() {
    this.sport.getSocketStatus().subscribe((res) => {
      this.isSocket = res.is_socket;
      this.socketService.setUpSocketConnection();
      this.socketListenersUser();
      this.socketError();
      this.socketSuccess();
      this.route.params.subscribe(params => {
        if (params.sportName == undefined) {
          this.userData = {};
          this.homematches();

        } else {
          this.parameter = params.sportName;
          this.homematches();
          //  this.getHomeMatchCallEvent(this.parameter)
        }

      })
    })
  }
  async ngOnInit() {
    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    this.type = this.adminDetails.user_type_id;
    this.getNews();
  }
  popData() {
    this.router.navigate(['**'])
  }
  socketOnEvent(eventName, callback) {
    this.socketService.socket.on(eventName, data => callback(data));
  }

  socketEmitEvent(eventName, data) {
    this.socketService.socket.emit(eventName, data);
  }
  socketError() {
    this.socketService.socket.on(`error`, (res) => {
      if (res.status == false) {
        this.toastr.error(res.msg)
      }
    })
  }
  socketSuccess() {
    this.socketService.socket.on(`success`, (res) => {
      if (res.status == true) {
        if (res.event_code == "subscribe_event") {
          this.marketData = [];
          for (let i = 0; i < this.marketId.length; i++) {
            this.socketOnEvent(this.marketId[i], result => {
              if (result.status == true) {
                this.marketData = result.data;
                this.runnerDataLength = this.marketData.length;
                this.runnerData = this.marketData;
                for (let i = 0; i < this.firstData.length; i++) {
                  if (this.firstData[i].marketId == this.runnerData.marketId) {
                    this.firstData[i].runners = this.runnerData.runners;
                    this.firstData[i].status = this.runnerData.status;
                    this.firstData[i].inplay = this.runnerData.inplay;
                    this.socketData = this.firstData[i]
                    break
                  }
                }
                for (let i = 0; i < Object.keys(this.homeData).length; i++) {
                  if (Object.keys(this.homeData)[i] == this.socketData.sport_name) {
                    let key = Object.values(this.homeData)[i];
                    for (let l = 0; l < Object.keys(key).length; l++) {
                      if (key[l].marketId == this.socketData.marketId) {
                        key[l].runners = this.socketData.runners;
                        key[l].status = this.socketData.status;
                        key[l].inplay = this.socketData.inplay;
                      }
                    }
                  }
                }
                this.cdRef.detectChanges();
              }
            })
          }
        } else {
          this.toastr.success(res.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
        }
      }
    })
  }
  exchangeGame() {
    let data = {
      sport_id: "-100"
    };
    this.sport.getHomeMatchesList(data).subscribe((res) => {
      if (res.status == true) {
        this.exchangeGameList = res.data
      } else {
        if (res.logout == true) {
          this.head.logoutUser();
        }
      }
    })
  }

  homematches() {
    this.sport.getHomeMatchesList(this.userData).subscribe(res => {
      if (res.status) {
        // this.socketService.socket.connected;
        this.marketId = [];
        if (this.callingType == 1) {
          this.firstData = res.data;
          this.callingType = 2;
          this.firstDataLength = this.firstData.length;
          for (let i = 0; i < this.firstDataLength; i++) {
            this.firstData[i].manualInplay = this.firstData[i].inplay
          }
          this.firstData.forEach(matDta => {
            matDta.manualInplay = matDta.inplay;
          });
          this.updateFirstData = []
          for (let i = 0; i < this.firstData.length; i++) {
            for (let j = 0; j < this.allSportsList.length; j++) {
              if (this.firstData[i].sport_id == this.allSportsList[j].sport_id) {
                this.updateFirstData.push(this.firstData[i])
              }
            }
          }
          this.firstData = this.updateFirstData;
          if (this.isSocket == 1) {
            for (let i = 0; i < this.updateFirstData.length; i++) {
              this.updateRunnerData[i] = {
                "marketId": this.firstData[i].marketId,
                "status": "SUSPENDED",
                "inplay": false,
                "runners": [
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  },
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  }
                ]
              }
            }
            const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }));
            this.a = a3;
            let matches;
            if (this.parameter == undefined || this.parameter == 'All') {
              matches = this.a;
              matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
            } else if (this.parameter == 'inplay') {
              matches = this.a.filter(t => t.inplay == true);
              matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
            } else {
              matches = this.a.filter(t => t.sport_name == this.parameter);
              matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
            }
            matches = matches.reduce((groupSport, sports) => {
              const { sport_name } = sports;
              groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
              return groupSport;
            }, {});
            this.homeData = matches;
            this.runners();
          } else {
            this.runners();
          }
        } else {
          this.firstData = res.data;
          this.firstDataLength = this.firstData.length;
          for (let i = 0; i < this.firstDataLength; i++) {
            this.firstData[i].manualInplay = this.firstData[i].inplay
          }
          this.firstData.forEach(matDta => {
            matDta.manualInplay = matDta.inplay;
          });
          this.updateFirstData = []
          for (let i = 0; i < this.firstData.length; i++) {
            for (let j = 0; j < this.allSportsList.length; j++) {
              if (this.firstData[i].sport_id == this.allSportsList[j].sport_id) {
                this.updateFirstData.push(this.firstData[i])
              }
            }
          }
          this.firstData = this.updateFirstData;
          if (this.isSocket == 1) {
            for (let i = 0; i < this.updateFirstData.length; i++) {
              this.updateRunnerData[i] = {
                "marketId": this.firstData[i].marketId,
                "status": "SUSPENDED",
                "inplay": false,
                "runners": [
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  },
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  }
                ]
              }
            }
            const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }));
            this.a = a3;
            let matches;
            if (this.parameter == undefined || this.parameter == 'All') {
              matches = this.a;
              matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
            } else if (this.parameter == 'inplay') {
              matches = this.a.filter(t => t.inplay == true);
              matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
            } else {
              matches = this.a.filter(t => t.sport_name == this.parameter);
              matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
            }
            matches = matches.reduce((groupSport, sports) => {
              const { sport_name } = sports;
              groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
              return groupSport;
            }, {});
            this.homeData = matches;
            this.runners();
          } else {
            this.runners();
          }
          this.cdRef.detectChanges();
        }

      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
        if (res.logout == true) {
          this.head.logoutUser();
        }
      }
    }, (err) => {
      console.log(err);
    },
      () => {
        if (this.router.url.split('?')[0] == '/dashboard') {
          if (this.isSocket != 1) {
            this.timer = setTimeout(() => resolve(this.homematches()), 10000);
          } else {
            clearTimeout(this.timer);
          }
        }
      })
  }

  runners() {
    this.userData = {};
    this.sport.runnerList(this.userData).subscribe((res) => {
      if (res.status) {
        this.runnerDataLength = res.data.length;
        this.runnerData = res.data;
        for (let i = 0; i < this.firstDataLength; i++) {
          for (let j = 0; j < this.runnerDataLength; j++) {
            if (this.firstData[i].marketId == this.runnerData[j].marketId) {
              this.updateRunnerData[i] = []
              this.updateRunnerData[i] = (this.runnerData[j]);
              break
            } else {
              this.updateRunnerData[i] = {
                "marketId": this.firstData[i].marketId,
                "status": "SUSPENDED",
                "inplay": false,
                "runners": [
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  },
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  }
                ]
              }
            }
          }
        }
        const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }))
        // var original = [...this.firstData, ...this.updateRunnerData],
        //   updateRunners = Array.from(
        //     original
        //       .reduce(
        //         (m, o) => m.set(o.match_id, Object.assign({}, m.get(o.match_id) || {}, o)),
        //         new Map
        //       )
        //       .values()
        //   );
        this.a = a3;
        let matches;
        if (this.parameter == undefined || this.parameter == 'All') {
          matches = this.a;
          matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
        } else if (this.parameter == 'inplay') {
          matches = this.a.filter(t => t.inplay == true || t.manualInplay == true);
          matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
        } else {
          matches = this.a.filter(t => t.sport_name == this.parameter);
          matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
        }
        // matches = matches.map(data => {
        //   if (!Array.isArray(data.runners))
        //     data.runners = data.runners.runners;
        //   return data;
        // })
        matches = matches.reduce((groupSport, sports) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});

        this.homeData = matches;
        this.getRunnerEmit();
        this.a = [];
        // original = [];
        // } else {
        //   // this.homematches();
        // }

      } else {
        for (let i = 0; i < this.firstDataLength; i++) {
          this.updateRunnerData[i] = {
            "marketId": this.firstData[i].marketId,
            "status": "SUSPENDED",
            "inplay": false,
            "runners": [
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              },
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              }
            ]
          }
        }
        const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }));
        this.a = a3;
        let matches;
        if (this.parameter == undefined || this.parameter == 'All') {
          matches = this.a;
          matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
        } else if (this.parameter == 'inplay') {
          matches = this.a.filter(t => t.inplay == true);
          matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
        } else {
          matches = this.a.filter(t => t.sport_name == this.parameter);
          matches = _.orderBy(matches, ['hasRate', 'inplay', 'date'], ['asc', 'desc', 'asc']);
        }
        // matches = matches.map(data => {
        //   if (!Array.isArray(data.runners))
        //     data.runners = data.runners.runners;
        //   return data;
        // })
        matches = matches.reduce((groupSport, sports) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});
        this.homeData = matches;
        this.getRunnerEmit();
        this.a = [];
      }
    }, (err) => {
      console.log(err);
    }
      // , () => {
      //   if (this.router.url.split('?')[0] == '/dashboard' || this.router.url.split('?')[0] == '/dashboard/' + this.parameter) {
      //     this.timer = setTimeout(() => resolve(this.runners()), 1000);
      //   }
      // }
    )
  }
  home() {
    this.router.navigate(['home'])
  }
  scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 250), behavior: 'smooth' });
  }

  scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 250), behavior: 'smooth' });
  }
  getAllSportsListData() {
    let userData = {};
    this.sport.getAllSportsList(userData).subscribe(res => {
      this.allSportsList = res.data;

      this.socketStatus();
      this.allSportsList = this.allSportsList.filter(t => t.is_active == '1');
      this.allSportsList = (this.allSportsList).sort(function(a, b){return b.sport_id - a.sport_id});
    })
    // this.allSportsList = JSON.parse(localStorage.getItem('sportList'))
  }

  goToInplay(sportName) {
    this.router.navigate(['dashboard/' + sportName]);
  }

  goToDashboard(sportName, id, data) {
    if (id == -100) {
      this.router.navigate(['casino']);
    } if (id < -100) {
      this.router.navigate(['snova/' + id + '/' + data.name + '/' + data.providerCode])
    } else if (id > -100) {
      this.router.navigate(['dashboard/' + sportName]);
    }
  }

  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['match-detail']);
  }
  goToAll(sportName) {
    this.router.navigate(['dashboard/' + sportName]);
  }
  socketListenersUser() {
    this.socketOnEvent(`homeMatches`, res => {
      // try {
      // if (res.status) {
      this.firstData = res.data;
      this.firstDataLength = this.firstData.length
      for (let i = 0; i < this.firstData.length; i++) {
        this.marketId.push(this.firstData[i].marketId)
      }
      this.getRunnerEmit();
      // }
      // } catch (e) {
      //   this.homematches()
      // }
    });

    this.socketOnEvent(`subscribe_event`, res => {
    });
    this.socketOnEvent(`unsubscribe_event`, res => {
    });
    this.socketOnEvent(`new_market_added`, res => {
      if (res.status == true) {
        this.toastr.success(res.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        })
        this.homematches();
      }
    });
    this.socketOnEvent(`socket_state_changed`, res => {
      this.isSocket = res.is_socket
      this.route.params.subscribe(params => {
        if (params.sportName == undefined) {
          this.userData = {};
          this.homematches();
        } else {
          this.parameter = params.sportName;
          this.homematches();
        }
      })
      if (this.isSocket == 0) {
        this.marketId = [];
        for (let i = 0; i < this.firstData.length; i++) {
          this.marketId.push(this.firstData[i].marketId)
        }
        let event = {
          "eventIds": this.marketId
        }
        this.socketEmitEvent('unsubscribe_event', event);
      } else {
        clearTimeout(this.timer);
      }
    });
  }

  getHomeMatchCallEvent(param) {
    this.userData = {};
    this.socketEmitEvent('homeMatches', this.userData);
  }

  getRunnerEmit() {
    for (let i = 0; i < this.firstData.length; i++) {
      this.marketId.push(this.firstData[i].marketId)
    }
    let event = {
      "eventIds": this.marketId
    }
    this.socketEmitEvent('subscribe_event', event);
  }

  marketAdded() {
    this.userData = {};
    this.socketEmitEvent('new_market_added', this.userData);
  }
  ngOnDestroy() {
    this.socketService.socket.close();
  }

  updateStatus(match) {
    if (confirm("Are you sure want to deactive Match ")) {
      let data;
      if (match.is_active == 1) {
        data = {
          "event": "match",
          "filter": {
            "match_id": match.match_id
          },
          "update": {
            "is_visible": false
          }
        }
      } else if (match.is_active == 0) {
        data = {
          "event": "match",
          "filter": {
            "match_id": match.match_id
          },
          "update": {
            "is_visible": true
          }
        }
      }
      this.sport.visible(data).subscribe((res) => {
        if (res.status == true) {
          this.toastr.success(res.data, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 3000
          });
          this.updateMatchStatus(match);
        } else {

          this.toastr.error(res.data, '', {
            timeOut: 10000,
          });
          if (res.logout == true) {
            this.head.logoutUser();
          }
        }
      })
    }

  }
  updateMatchStatus(match) {

    let data = {
      "match_id": match.match_id,
      "is_active": 0
    }
    this.sport.updateMatchStatus(data).subscribe((result) => {
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        });
        this.homematches();
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
        if (result.logout == true) {
          this.head.logoutUser();
        }
      }
    })
  }

  enableFancy(id, status) {
    let data = {
      'match_id': id,
      'enable_fancy': status == 1 ? 0 : 1
    };
    this.sport.enableFancy(data).subscribe((res) => {
      if (res.status == true) {
        this.toastr.success(res.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        });
        this.homematches();
      } else {
        this.toastr.error(res.msg)
        if (res.logout == true) {
          this.head.logoutUser();
        }
      }
    })
  }
  closeCasino() {
    let data = {};
    this.sport.closeCasino(data).subscribe((res) => {
      if (res.status == true) {
        this.toastr.success(res.msg)
      } else {
        this.toastr.error(res.msg)
      }
    })
  }

  getNews() {
    let data = {};
    this.report.getNews(data).subscribe((res) => {
      if (res.status) {
        if (res.data == null) {
          this.adminData = [];
          this.agentData = [];
        } else {
          this.allData = res.data;
          this.adminData = this.allData.filter(t => t.user_type_id == 0);
          if (this.adminData.length != 0) {
            this.adminHeading = this.adminData[0].heading;
          }
          this.agentData = this.allData.filter(t => t.user_type_id != 0);
          if (this.agentData.length != 0) {
            this.agentHeading = '';
            for (let i = 0; i < this.agentData.length; i++) {
              this.agentHead.push(this.agentData[i].heading);
            }
            this.agentHeading = this.agentHead.join(' | ');
          }
        }
      }
    })
  }

  openNews(){
    let a = this.Base_Url.split('api/v1/');
    // let b = 'http://192.168.0.12:4200/'+ 'openNews' 
      let b = a[0] + 'openNews';
      window.open(b, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    
  }
}
