<div class="loginPage">
  <div class="login-main">

      <div class="login-inner">
          <div class="logo">
              <img src="assets/loginLogo/{{logo}}.png" alt="{{logo}}">
          </div>
          <div class="row form-row">
              <div class="col-md-12 p-0 left-col">
                  <div class="col-inner right">
                      <div class="form-div">
                          <form [formGroup]='Form' (ngSubmit)='onSubmitLogin()'>
                              <!-- <div class="login-title">LOGIN</div> -->
                              <div class="form-group username">
                                  <!-- <label class="form-label">Username</label> -->
                                  <div class="input-icons">
                                      <i class="fa fa-user-o icon"></i>
                                      <input type="text" placeholder="Enter Username" class="form-control"
                                          formControlName='user_name'>
                                  </div>
                                  <!-- <div *ngIf="submitted && f.userName.errors">
                                      <div *ngIf="f.userName.errors.required">Username is required</div>
                                  </div> -->
                              </div>
                              <div class="form-group inner-addon right-addon">
                                  <!-- <label class="form-label">Password</label> -->
                                  <div class="input-group">
                                      <!-- <i class="fa fa-key icon"></i> -->
                                      <input [type]="showPassword ? 'text' : 'password'" placeholder="Enter Password"
                                          class="form-control" formControlName='password' id="password-field">
                                      <div>
                                          <i alt="show" class="fa fa-eye eye-show"
                                              (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
                                          <i alt="hide" class="fa fa-eye-slash eye-hide"
                                              (click)="showPassword = !showPassword" [class.hide]="!showPassword"></i>
                                      </div>
                                  </div>
                                  <!-- <div *ngIf="submitted && f.password.errors">
                                      <div *ngIf="f.password.errors.required">Password is required</div>
                                  </div> -->
                              </div>
                              <!-- <div class="checkbox">
                                  <label><input type="checkbox" value=""> Keep me signed in</label>
                              </div> -->
                              <div class="btn-list">
                                  <button type="submit" class="btn " [disabled]="loginButtonDisable">LOGIN NOW <i
                                          class="fa fa-sign-in icon"></i></button>
                                  <!-- <div class="downloadapk">
                                      Download APK
                                      <button class="downloadBtn"> <i class="fa fa-download icon"></i></button>
                                  </div> -->
                              </div>

                              <p class="red-message" *ngIf="showResponseMessage"> {{showResponseMessage}}</p>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>