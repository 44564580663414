<app-header></app-header>
<app-sidebar></app-sidebar>



<main>
    <div class="full-wrap" id="" #contentToConvert>
        <div class="report-page">
            <div class="user-list inner-content">
                <div class="page-title">
                    <h3>Withdraw Method List</h3>
                    <div class="nav-list">
                        <div class="hierarchy">
                           
                        </div>
                    </div>
                    <div class="breadcrumb">
                        <a  class="btn btn-secondary back-btn"><i class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div _ngcontent-serverapp-c232="" class="filterDiv">
                    <div _ngcontent-serverapp-c232="" class="row">
                       <div _ngcontent-serverapp-c232="" class="col-md-12">
                          <form _ngcontent-serverapp-c232="" novalidate="" class="ng-untouched ng-pristine ng-valid">
                             <div _ngcontent-serverapp-c232="" class="row form-field">
                               
                                <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 87.5%;">
                                   <div _ngcontent-serverapp-c232="" class="col-inner">
                                      <div _ngcontent-serverapp-c232="" class="form-group mb-0">
                                         <input _ngcontent-serverapp-c232="" name="searchUser" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="text" autocomplete="off" placeholder="Search Payment Method Name..." class="search-input dropdown-toggle form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="searchUser">
                                         <div _ngcontent-serverapp-c232="" aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                           
                                            <!--bindings={
                                               "ng-reflect-ng-for-of": "[object Object],[object Object"
                                               }-->
                                         </div>
                                         <bfv-messages _nghost-serverapp-c53="" ng-reflect-messages="() => this.getMessages()" class="ng-star-inserted">
                                            <!--bindings={
                                               "ng-reflect-ng-for-of": ""
                                               }-->
                                         </bfv-messages>
                                         <!--bindings={
                                            "ng-reflect-ng-if": "true"
                                            }-->
                                      </div>
                                   </div>
                                </div>
                                <div _ngcontent-serverapp-c232="" class="col-md-1" style="width: 10.499999995%;">
                                   <div _ngcontent-serverapp-c232="" class="col-inner text-end">
                                      <div _ngcontent-serverapp-c232="" class="btn-list">
                                        <button (click)="openModalPaymentMethod(paymentMethod)" type="button" class="btn btn-success">
                                            Add Method</button>
                                        </div>
                                   </div>
                                </div>
                             </div>
                          </form>
                       </div>
                    </div>
                 </div>

              

                <div class="report-table">
                    

                    <table class="table">
                        <thead>
                            <tr>
                               
                                <th>S No.
                                </th>
                                
                                <th>Image
                                   
                                </th>
                                
                                <th>Name
                                    
                                </th>
                                <th>Min Amount
                                    
                                </th>
                                <th>Max Amount
                                    
                                </th>
                                
                                <th>Status
                                   
                                </th>
                                <th>Action</th>
                                
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let m of allPaymentMethod;let i=index;">
                               
                                <td>{{i+1}}
                                </td>
                                
                                <td>
                                    <img src="{{Base_Url}}wallets/{{m?.image}}" class="zoom" *ngIf="m?.image" width="20px" height="20px">
                                   
                                </td>
                                
                                <td>{{m?.name}}
                                    
                                </td>
                                <td>{{m?.min_amount}}
                                    
                                </td>

                                <td>{{m?.max_amount}}
                                    
                                </td>
                                
                                <td>
                                    <img _ngcontent-serverapp-c250="" *ngIf="m?.visible" src="assets/icon/active.png" height="20px" width="20px" class="ng-star-inserted">
                                    <img _ngcontent-serverapp-c250="" *ngIf="!m?.visible" src="assets/icon/remove.jpg" height="20px" width="20px" class="ng-star-inserted">
                                </td>
                                <td>
                                    <img _ngcontent-serverapp-c250="" *ngIf="!m?.visible" (click)="updatePayment(m,0)" src="assets/icon/active.png" height="20px" width="20px" class="ng-star-inserted">
                                    <img _ngcontent-serverapp-c250="" *ngIf="m?.visible" (click)="updatePayment(m,0)" src="assets/icon/remove.jpg" height="20px" width="20px" class="ng-star-inserted">
                                    <img _ngcontent-serverapp-c250=""  (click)="updatePayment(m,1)" src="assets/icon/delete.png" height="20px" width="20px" class="ng-star-inserted">
                                </td>
                                
                            </tr>
                        </tbody>
                       
                    </table>
                </div>

                <div class="report-bottom-paginate">
                    <pagination-controls  class="user-list-pagination">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</main>

<ng-template #paymentMethod>
    <div class="modal-header">
        <h4 class="modal-title">Create Withdraw Method</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form [formGroup]='createPaymentForm'>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
           <div class="row">
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Name <span style="color:red">*</span></label>
                    <input type="text"  placeholder="Enter Name"  formControlName="name"
                         class="password-box form-attribute ng-pristine ng-invalid ng-touched">
                         <span *ngIf="createPaymentForm.controls.name.errors && submited"
                         class="red-alert-message">
                        name is required.!
                     </span>
                </div>
            </div>
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Type <span style="color:red">*</span></label>
                   <select formControlName="type" class="password-box form-attribute ng-pristine ng-invalid ng-touched">
                    <option value="">Select</option>
                    <option value="Bank">Bank</option>
                    <option value="PayTm">PayTm</option>
                    <option value="gPay">gPay</option>
                    <option value="PhonePe">PhonePe</option>
                    <option value="whatsappPay">whatsappPay</option>
                    <option value="Upi">Upi</option>
                   </select>
                         <span *ngIf="createPaymentForm.controls.type.errors && submited"
                         class="red-alert-message">
                         type is required.!
                     </span>
                </div>
            </div>
            
            
           </div>

           <div class="row">
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Min Amount <span style="color:red">*</span></label>
                    <input type="text"  placeholder="Enter Min Amount"  formControlName="min_amount"
                         class="password-box form-attribute ng-pristine ng-invalid ng-touched">
                         <span *ngIf="createPaymentForm.controls.min_amount.errors && submited"
                         class="red-alert-message">
                         Min Amount  is required.!
                     </span>
                </div>
            </div>
            <div class="col col-sm-6">
                <div class="form-list">
                    <label class="form-heading">Max Amount <span style="color:red">*</span></label>
                    <input type="text"  placeholder="Enter Max Amount"  formControlName="max_amount"
                         class="password-box form-attribute ng-pristine ng-invalid ng-touched">
                         <span *ngIf="createPaymentForm.controls.max_amount.errors && submited"
                         class="red-alert-message">
                         Max Amount is required.!
                     </span>
                </div>
            </div>
            
            
           </div>
          
          <div class="row">
            <div class="col col-sm-4">
                <div class="form-list">
                    <label class="form-heading">Image </label>
                    <input type="file"   
                    type="file"
                    accept="image/*"
                    (change)="processImageFile($event)" formControlName="image"
                         class="">
                      
                         <span *ngIf="createPaymentForm.controls.image.errors && submited"
                         class="red-alert-message">
                         image is required.!
                     </span>

                    
                </div>
            </div>

          </div>
    
    
            
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success" (click)="subMitForm()" >Submit</button> &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
    </form>
</ng-template>

