<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="closed-user inner-content">
                <div class="page-title" style="margin-bottom: 0;">
                    <h3>WCO Log</h3>
                </div>

                <div class="filterDiv" style="margin-top: 0;">
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                <div class="row form-field">
                                    <div class="col-md-2">
                                        <div class="col-inner">
                                            <div class="form-group dateTimePicker">
                                                <!-- <input class="form-control" [max]="min" name="startDate"
                                                    (monthSelected)="chosenMonthHandler($event, dt1)"
                                                    [(ngModel)]="openStartDate" datetime="HH:mm:ss" [owlDateTime]="dt1"
                                                    [owlDateTimeTrigger]="dt1" placeholder=" Start Date Time">
                                                <owl-date-time #dt1 hour12Timer="true" showSecondsTimer="true">
                                                </owl-date-time> -->
                                            <input type="text" class="form-control" [popover]="popTemplate" placement="bottom" [outsideClick]="true" #startpopoverRef="bs-popover" value="{{startdateTime | date:'yyyy-MM-dd HH:mm:ss '}}" (click)="showDate()">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="col-inner">
                                            <div class="form-group dateTimePicker">
                                                <!-- <input class="form-control" [min]="openStartDate" [max]="startAt"
                                                    name="endDate" [(ngModel)]="openEndDate" [owlDateTime]="dt2"
                                                    [owlDateTimeTrigger]="dt2" placeholder=" End Date Time">
                                                <owl-date-time #dt2 showSecondsTimer="true" hour12Timer="false">
                                                </owl-date-time> -->
                                                <input type="text" class="form-control" [popover]="popTemplate1" placement="bottom" [outsideClick]="true" #endpopoverRef="bs-popover" value="{{enddateTime | date:'yyyy-MM-dd HH:mm:ss '}}" (click)="showDate()">
                                            
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="col-inner">
                                            <div class="form-group">
                                                <input type="Password" class="form-control" placeholder="Enter Password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="col-inner text-end">
                                            <div class="btn-list">
                                                <button type="button" class="btn btn-success" (click)="downloadLog()">Download</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</main>


<!-- Date Time Picker -->
<ng-template #popTemplate>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="date" [minDate]="minDate" [maxDate] ="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="time" [min]="minTime" [max]="maxTime" (ngModelChange)="updateTime()" [showMeridian]="isMeridian" showSeconds="true">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="close()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>
<ng-template #popTemplate1>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDate"[minDate]="date" [maxDate] ="todayDateTime" [showWeeks]="false"
        (ngModelChange)="updateEndDate()" (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTime" (ngModelChange)="updateEndTime()"
        [showMeridian]="isMeridian" showSeconds="true"  [min]="minTime" [max]="maxTime">
    </timepicker>

    <div style="padding:5px">
        <span class="btn-group pull-left" style="margin-right:10px;">
            <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
            <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
        </span>
        <span class="btn-group pull-right">
            <!-- <button type=button class="btn btn-sm btn-light" [hidden]="isDateVisible" 
            (click)="showDate()">Date
            </button>
            <button type=button class="btn btn-sm btn-light" [hidden]="!isDateVisible" 
            (click)="showTime()">Time
            </button> -->
            <button type=button class="btn btn-sm btn-success" (click)="closeEndDatepicker()">Close</button>
        </span> <span class=clearfix></span>
    </div>
</ng-template>

<ng-template #userParentList>
    <div class="modal-header">
        <h4 class="modal-title">Parent List of {{userParentName}}</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <table class="table">
        <tbody>
            <ng-container *ngFor="let parent of parentList; let i =index">
                <tr>
                    <td>{{parent?.name}}({{parent?.user_name}})</td>
                </tr>
                <!-- <tr>
                    <td>user2</td>
                </tr>
                <tr>
                    <td>user3</td>
                </tr> -->
                
            </ng-container>
        </tbody>
    </table>
</ng-template>
