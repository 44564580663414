<app-header></app-header>
<main>

    <div class="full-wrap">
        <div class="report-page">
            <div class="website-setting inner-content">
                <div class="page-title">
                    <h3>Banner Setting</h3>  
                    <button type="button" class="td-btn btn-success"
                                        (click)="openModalUploadBanner(banner);">Add Banner</button>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>
                <br>
                <div class="report-table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Slug</th>
                                <th>Image</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of bannerData; let i = index;">
                                <td>{{data?.title}}</td>
                                <td>{{data?.slug}}</td>
                                <td><img src="{{data?.bannerUrl}}" style="height: 150px;"></td>
                                <td><button type="button" class="td-btn btn-success"
                                  (click)="openModalUploadCurrentBanner(currentBanner,data);">Update Current Banner</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</main>

<ng-template #banner>
    <div class="modal-header">
        <h4 class="modal-title">Upload Banner </h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form  [formGroup]="bannerForm">
    <table class="table">
        <tbody>
            <ng-container>
                <tr>
                    <td>Title : </td>
                    <td><input type="text" placeholder="Title" class="form-control" formControlName="title" [(ngModel)]="titleBanner"></td>
                </tr>
                <tr>
                    <td>Slug : </td>
                    <td><input type="text" placeholder="" class="form-control" formControlName="slug" [(ngModel)]="slugBanner"></td>
                </tr>
                <tr>
                    <td>Image : </td>
                    <td><input type="file" id="file" class="form-control" (change)="handleFileInput($event.target.files)"></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</form>
    <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success"
                (click)="updateBanner()">Save</button>
            &nbsp;
            <button type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>
<ng-template #currentBanner>
  <div class="modal-header">
      <h4 class="modal-title">Update Banner </h4>
      <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
  </div>
  <form  [formGroup]="bannerForm">
  <table class="table">
      <tbody>
          <ng-container>
              <tr>
                  <td>Title : </td>
                  <td><input type="text" placeholder="Title" class="form-control" formControlName="title" [(ngModel)]="titleBanner"></td>
              </tr>
              <tr>
                  <td>Slug : </td>
                  <td><input type="text" placeholder="" class="form-control" formControlName="slug" [(ngModel)]="slugBanner" readonly></td>
              </tr>
              <tr>
                  <td>Image : </td>
                  <td><input type="file" id="file" class="form-control" (change)="handleFileInputBanner($event.target.files)"></td>
              </tr>
          </ng-container>
      </tbody>
  </table>
</form>
  <div class="modal-footer">
      <div class="text-end">
          <button type="submit" class="btn btn-success"
              (click)="updateBanner()">Save</button>
          &nbsp;
          <button type="button" class="btn btn-danger" data-dismiss="modal"
              (click)="modalRef.hide()">Cancel</button>
      </div>
  </div>
</ng-template>