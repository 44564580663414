import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WebsiteSettingService } from '../services/website-setting.service'
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoginService } from '../services/login.service'
import { CookieService } from 'ngx-cookie-service';
import { SocketServiceService } from '../services/socket-service.service';
import { Location } from '@angular/common';
import { UsersService } from '../services/users.service';


@Component({
  selector: 'app-social-media-list',
  templateUrl: './social-media-list.component.html',
  styleUrls: ['./social-media-list.component.scss']
})
export class SocialMediaListComponent implements OnInit {

  websiteSetting: FormGroup;
  websiteList: object[];
  submitted = false;
  websiteId: any;
  modalRef: any;
  userPassword: any;
  selectedWebsiteId: any;
  user_id: string;
  updatewebsiteSettingForm: FormGroup;
  checkWebsiteName: string;
  updatecheckWebsiteName: string;
  checkSiteTitle: string;
  checkUpdateTitle: string;
  isSocket: any;
  searchDomain: string;
  state: any;
  adminDetails:any;
  logoForm: FormGroup;
  socialList:any;
  constructor(private fb: FormBuilder, private locationBack: Location,private loginService: LoginService,
    private websiteSettingService: WebsiteSettingService, private socketService: SocketServiceService,
    private toastr: ToastrService, private modalService: BsModalService, private usersService: UsersService,
    private cookie: CookieService
  ) { }

  async ngOnInit() {
    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    
    this.isSocket = this.cookie.get('is_socket');
    this.isSocket = 0;
    this.user_id = localStorage.getItem('userId');
    this.createWebstieSetting();
    this.getAllwebsite();
    this.getList();
    this.logoForm = this.fb.group({
      title: ['',[Validators.required]],
      slug:['',[Validators.required]],
      logo: ['',[Validators.required]],
    })
    // this.socketListenersUser();
  }

  goToBack() {
    this.locationBack.back();
  }

  get f() { return this.websiteSetting.controls; }
  createWebstieSetting() {
    const reg = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
    this.websiteSetting = this.fb.group({
      domain_id: ['', [Validators.required]],
      host_name: [''],
      whatsapp: ['', [Validators.required]],
      telegram: ['',[Validators.required]],
      twitter: ['',[Validators.required]],
      instagram: ['',[Validators.required]],
      support_no: [''],
      parent_id: [''],
    })
  }


  openModalSetting(setting: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      setting,
      Object.assign({}, { class: 'setting-modal modal-lg' })
    );
  }


  

 
  addSocialMedia(from?: string) {
    this.submitted = true;
    from = this.state
    if(!this.websiteSetting.valid){
       return;
    }
    else{
     
      let domain_id:any=this.websiteList.filter((val:any)=>val._id==this.websiteSetting.controls.domain_id.value);
     
      this.websiteSetting.controls['host_name'].setValue(domain_id[0]?.domain_name);
      this.websiteSetting.controls['parent_id'].setValue(this.adminDetails?.user_id);
      
      this.websiteSettingService.createSocialLink(this.websiteSetting.value).subscribe(response => {
        if(response.status){
          this.toastr.success(response.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          this.getList();
          this.submitted=false;
          this.websiteSetting.reset();
        }
        else{
          this.toastr.error(response.msg, '', {
            positionClass: 'toast-top-right',
            timeOut: 1000
          });
        }
       
        
      }, error => {
        console.log(error)
      })

    }

  }

  getAllwebsite() {
    if (this.isSocket != 1) {
      // stop here if form is invalid
      this.websiteSettingService.getAllwebsite().subscribe(response => {
        if(response.status == true){
          this.websiteList = response.data
        } else {
          if(response.logout == true){
            
          }
        }
        
      }, error => {
        console.log(error)
      })
    }
    else {

    }

  }

  getList() {
    if (this.isSocket != 1) {
      // stop here if form is invalid
      let params={parent_id:this.adminDetails?.user_id}
      this.websiteSettingService.getSocialLink(params).subscribe(response => {
        if(response.status == true){
          this.socialList = response.data
        } else {
          if(response.logout == true){
          }
        }
        
      }, error => {
        console.log(error)
      })
    }
    else {

    }

  }

  removeData(data){
    let message = "Are you sure you want to remove link!"
    Swal.fire({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let params={_id:data?._id}
        this.websiteSettingService.removeLink(params).subscribe(response => {
          if(response.status == true){
            this.toastr.success(response.msg, '', {
              positionClass: 'toast-bottom-right',
              timeOut: 1000
            });
            this.getList();
          } else {
            this.toastr.error(response.msg, '', {
              positionClass: 'toast-top-right',
              timeOut: 1000
            });
            if(response.logout == true){
            }
          }
          
        }, error => {
          console.log(error)
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })


    
    }
   
  
 
}
