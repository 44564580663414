<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="closed-user inner-content">
                <div class="page-title">
                    <h3>Account Info</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i
                                class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

                <div class="report-table">
                    <table datatable class="table">
                        <thead>
                            <tr>
                                <th><input type="checkbox"></th>
                                <th>Chips</th>
                                <th>Liability</th>
                                <th>Wallet</th>
                                <th>Up</th>
                                <th>Down</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="checkbox"></td>
                                <td>-41610000</td>
                                <td>0</td>
                                <td>99958400609.5</td>
                                <td>2234.1</td>
                                <td>-795.72</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
</main>