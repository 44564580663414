import { Component, OnInit, ViewChild } from '@angular/core';
import { DateTime } from '../../dateTime';
import { Router } from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Location } from '@angular/common';
import { ReportService } from '../services/report.service';
import { LoginService } from '../services/login.service'
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {DatePipe} from '@angular/common';  

@Component({
  selector: 'app-news-update',
  templateUrl: './news-update.component.html',
  styleUrls: ['./news-update.component.scss']
})
export class NewsUpdateComponent implements OnInit {
  heading:any;
  description:any;
  adminDetails: any;
  constructor(private router: Router, public datepipe: DatePipe,private locationBack: Location,
    private cookie: CookieService,private loginService: LoginService, private report: ReportService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    this.getNews();
  }

  updateNews() {
    if (this.heading == '' || this.heading == undefined || this.heading == null) {
      this.toastr.error('Please enter heading of news....')
    } else {
      let data = {
        heading: this.heading,
        description: this.description
      }
      this.report.newsCreate(data).subscribe((res)=>{
        if(res.status){
          this.toastr.success(res.msg);
          this.getNews();
        } else {
          this.toastr.error(res.msg)
        }
      })
    }
  }
  deleteNews() {
    if (confirm("Are you sure want to Delete this News for your child ")) {
      let data = {};
      this.report.newsDelete(data).subscribe((res) => {
        if (res.status) {
          this.toastr.success(res.msg);
          this.getNews();
        } else {
          this.toastr.error(res.msg)
        }
      })
    }
  }

  getNews(){
    let data = {
      user_id : this.adminDetails.user_id,
      full : 1
    };
    this.report.getNews(data).subscribe((res) => {
      console.log("64",res);
      if(res.status){
        if(res.data == null){
          this.heading = '' ; 
          this.description = '';
        } else {
          this.heading = res.data.heading;
          this.description = res.data.description;
        }
      }
      
    })
  }
}
