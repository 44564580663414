import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { SportService } from '../services/sport.service'
import { LoginService } from '../services/login.service'
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  allSportsList: any
  allSeriesList: any;
  constructor(private cookie: CookieService,private loginService: LoginService,private router: Router, private sport: SportService) {
    
    this.getAllSportsListData()
   }

  ngOnInit(): void {
    // this.getAllSportsListData()
  }
  getAllSportsListData() {
    let userData = {}
    this.sport.getAllSportsList(userData).subscribe(res => {
      this.allSportsList = res.data;
      this.allSportsList = this.allSportsList.filter(t => t.is_active == '1');
      this.allSportsList = (this.allSportsList).sort(function(a, b){return b.sport_id - a.sport_id});
    })
  }
  getSeriesList(id) {
    let data = {
      sport_id: id
    };
    this.sport.getSeriesList(data).subscribe(res => {
      this.allSeriesList = res.data
    })
  }
  goToDashboard(sportId,seriesId) {
    if(sportId == -100){
      this.router.navigate(['casino' ]);
    } if( sportId < -100){
      this.router.navigate(['snova/' + sportId])
    } else {
      console.log(sportId);
      this.router.navigate(['home/' + sportId + '/' + seriesId]);
    }
  }

  dashboard() {
    this.router.navigate(['dashboard'])
  }
  inplay() {
    this.router.navigate(['inplay'])
  }
  accountStatement() {
    this.router.navigate(['statement'])
  }

  accountPayment() {
    this.router.navigate(['wallet-payment-list'])
  }

  role() {
    this.router.navigate(['role'])
  }

  userList() {
    this.router.navigate(['wallet-user-list'])
  }

  campaignList() {
    this.router.navigate(['campaign-list'])
  }

}
